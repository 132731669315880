/* eslint-disable import/no-extraneous-dependencies */
import { createStoreon, StoreonModule } from 'storeon';
import { persistState } from '@storeon/localstorage';

import * as I from 'interfaces';

interface TokenProps {
  access_token?: string;
  token_type?: string;
  expires_in?: string;
  refresh_token?: string;
  scope?: string;
  created_at?: string;
}

interface AppProps {
  token: TokenProps | null,
  path: string,
  user: I.LKBackendPersonProps,
}

interface Events {
  clear: unknown;
  set: TokenProps;
  changePath: string;
  setUser: I.LKBackendPersonProps,
}

const initState = {
  token: {},
  path: '',
};

const appStore: StoreonModule<AppProps, Events> = (store) => {
  store.on('@init', () => (initState));
  store.on('clear', () => (initState));
  store.on('set', (state, newToken) => ({ token: newToken }));
  store.on('changePath', (state, page) => ({ path: page }));
  store.on('setUser', (state, newUser) => ({ user: newUser }));
};

const store = createStoreon<AppProps, Events>([
  appStore,
  persistState(['token'], {
    key: 'Etnamed',
  }),
]);

export default store;
