/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useEffect, useState } from 'react';

import { Interfaces, UI, Validators } from '@etnamed/ui-kit';

import { Form } from 'react-final-form';

import { useStoreon } from 'storeon/react';

import {
  useDmsGroupsQuery,
  useCreateDmsGroupMutation,
  CreateDmsGroupMutation,
  AddInsuredOneMutation,
  useAddInsuredOneMutation,
  useAddInsuredToGroupInsuredsMutation,
  AddInsuredToGroupInsuredsMutation,
} from 'generated/graphql';

import { Adapted } from 'components/ui/Сontrols';

const validationFields = {
  ...Validators.presenceFieldConstraint('last_name'),
  ...Validators.presenceFieldConstraint('first_name'),
  ...Validators.presenceFieldConstraint('birthday'),
  ...Validators.selectValueConstraint('groups'),
};

function Insured({ onPersonAdded }: any) {
  const { user } = useStoreon('user');
  const [groups, setGroups] = useState<Array<Interfaces.OptionProps> | null>(null);
  const [addedGroupId, setAddedGroupId] = useState(0);
  const [sameAsInsurant, setSameAsInsurant] = useState(false);
  const [formInitValues, setFormInitValues] = useState<any>(null);

  const userGroups = useDmsGroupsQuery({
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const groupsOptions = data.dms_groups.map(
        (item) => ({ value: item.id, label: item.group_name || '' }),
      );
      setGroups(groupsOptions);
    },
  });

  const [createDmsGroupMutation] = useCreateDmsGroupMutation({
    onCompleted: (createDmsGroupData: CreateDmsGroupMutation) => {
      const createdGroupId = createDmsGroupData.insert_dms_groups_one?.id;
      setAddedGroupId(createdGroupId);
      userGroups.refetch();
    },
    fetchPolicy: 'network-only',
  });

  const [addInsuredToGroupMutation] = useAddInsuredToGroupInsuredsMutation({
    fetchPolicy: 'network-only',
    onCompleted: (addInsuredToGroupData: AddInsuredToGroupInsuredsMutation) => {

    },
  });
  const [addInsuredOneMutation] = useAddInsuredOneMutation({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!sameAsInsurant) {
      return;
    }
    setFormInitValues(
      {
        birthday: user.birthday,
        first_name: user.first_name,
        tel: user.phone_number,
        email: user.email,
        gender: user.sex,
        last_name: user.last_name,
        passport: user.passport,
        second_name: user.second_name,
        foreigner: user.foreigner,
        foreign_passport: user.foreign_passport,
        oms: user.oms,
      },
    );
  }, [sameAsInsurant]);

  const handleCreateGroup = (groupName: string) => {
    createDmsGroupMutation({
      variables: {
        group_name: groupName,
        user_id: user.id,
      },
    });
  };

  const handleAddPerson = async (values:any) => {
    const insured = {
      bithday: values.birthday,
      email: values.email,
      first_name: values.first_name,
      gender: values.gender,
      user_id: user.id,
      last_name: values.last_name,
      passport: values.passport,
      phone: values.tel,
      second_name: values.second_name,
      foreign: values.foreigner,
      foreign_passport: values.foreign_passport,
      oms: values.oms,
    };

    const resultAddInsured = await addInsuredOneMutation({
      variables: {
        user: insured,
      },
    });
    if (resultAddInsured.data?.insert_dms_insureds_one?.id) {
      const resultAddInsuredToGroup = await addInsuredToGroupMutation({
        variables: {
          group_id: values.groups.value,
          insured_id: resultAddInsured.data.insert_dms_insureds_one.id,
          user_id: user.id,
        },
      });
      if ((resultAddInsuredToGroup).data?.insert_dms_group_insureds_one?.insured?.id) {
        onPersonAdded();
      }
    }
  };

  const formValidate = (values: any) => {
    const res = Validators.default(values, validationFields, {
      fullMessages: false,
    });
    return res;
  };

  const handleSameAsInsurant = () => {
    setSameAsInsurant((oldState) => !oldState);
  };

  const renderForm = useCallback(({ handleSubmit }: any) => (
    <form onSubmit={handleSubmit}>
      <div className="card mt-10 w-auto lg:w-3/4 block m-1 lg:m-0 lg:mt-10">
        <h2 className="font-bold text-primary text-lg">
          Добавить застрахованного
        </h2>
        <div className="form mt-10">
          <div className="grid  lg:grid-cols-2 gap-6">
            <div className="form-control">

              <div className="flex flex-row justify-between items-center w-3/4 mb-4">
                <span>Совпадает со страхователем</span>
                <UI.Checkbox type="checkbox" onChange={handleSameAsInsurant} />
              </div>

              <div className="flex flex-row justify-between items-center w-3/4 mb-6 hidden">
                <span>Иностранный гражданин</span>
                <Adapted.Checkbox disabled={sameAsInsurant} name="foreigner" />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="form-control">
              <label>Фамилия:</label>
              <Adapted.Input disabled={sameAsInsurant} name="last_name" type="text" placeholder="Введите фамилию" />
            </div>
            <div className="form-control">
              <label>Имя:</label>
              <Adapted.Input disabled={sameAsInsurant} name="first_name" type="text" placeholder="Введите имя" />
            </div>
            <div className="form-control">
              <label>Отчество:</label>
              <Adapted.Input disabled={sameAsInsurant} name="second_name" type="text" placeholder="Введите отчество" />
            </div>
            <div className="form-control">
              <label>Дата рождения:</label>
              <div className="grid grid-cols-2 gap-3">
                <div className="relative">

                  <Adapted.Input disabled={sameAsInsurant && user.birthday !== null} type="date" placeholder="Выберите дату рождения" name="birthday" />
                </div>
                <div className="flex flex-col justify-center">
                  <Adapted.GenderSelector disabled={sameAsInsurant} name="gender" />
                </div>
              </div>
            </div>
            <div className="form-control">
              <label>Телефон:</label>
              <Adapted.Input disabled={sameAsInsurant} name="tel" type="text" placeholder="Введите телефон" />
            </div>
            <div className="form-control">
              <label>Email:</label>
              <Adapted.Input disabled={sameAsInsurant} name="email" type="text" placeholder="Введите электронную почту" />
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-4">
            <div className="form-control">
              <label>Паспортные данные (РФ):</label>
              <Adapted.Input disabled={sameAsInsurant} name="passport" type="text" placeholder="Введите паспортные данные (РФ)" />
            </div>
            <div className="form-control">
              <label>Паспортные данные (загран РФ):</label>
              <Adapted.Input disabled={false} name="foreign_passport" type="text" placeholder="Введите паспортные данные (Загран)" />
            </div>
            <div className="form-control">
              <label>Номер полиса:</label>
              <Adapted.Input name="oms" type="text" placeholder="Введите номер полиса" />
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-4">
            <div className="form-control">
              <label>Добавить в группу:</label>
              <Adapted.CreatableSelect
                name="groups"
                options={groups}
                placeholder="Выберите группу"
                onCreateOption={handleCreateGroup}
                defaultValue={addedGroupId}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10 mb-10 lg:w-2/12">
        <UI.Button primary type="submit">
          Сохранить
        </UI.Button>
      </div>
    </form>
  ), [groups, sameAsInsurant]);

  return (
    <Form
      onSubmit={handleAddPerson}
      validate={formValidate}
      validateOnBlur
      render={renderForm}
      initialValues={formInitValues}
    />
  );
}

export default Insured;
