import React from 'react';
import ReactDOM from 'react-dom';

import { StoreContext } from 'storeon/react';

import '@etnamed/ui-kit/dist/ui-kit.css';
import './index.css';
import './App.css';

import Store from 'store';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <StoreContext.Provider value={Store}>
      <App />
    </StoreContext.Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
