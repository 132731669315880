import React, { useEffect, useState } from 'react';

import { Interfaces, UI } from '@etnamed/ui-kit';
import { DmsGroupGetPolicyCardsQuery, useDmsGroupGetPolicyCardsQuery } from 'generated/graphql';

function Polices() {
  const [isComplete, setisComplete] = useState(false);
  const [insuredGroupsPolicy, setInsuredGroupsPolicy] = useState<any>(null);
  const dmsGroupGetPolicyCardsQuery = useDmsGroupGetPolicyCardsQuery({
    variables: {
      group_id: null,
    },
    onCompleted: (dmsGroupGetPolicyCardsData) => {
      setisComplete(true);
    },
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (isComplete && dmsGroupGetPolicyCardsQuery.data?.dmsGetPolicyCard) {
      setInsuredGroupsPolicy(dmsGroupGetPolicyCardsQuery.data.dmsGetPolicyCard.policy_card);
    }
  }, [isComplete, dmsGroupGetPolicyCardsQuery]);

  if (isComplete && insuredGroupsPolicy !== null) {
    if (insuredGroupsPolicy.length !== 0) {
      return insuredGroupsPolicy.map((police:any, index:any) => (
      // eslint-disable-next-line react/no-array-index-key
        <div className="card mt-10 w-3/4" key={index}>
          <div className="mb-4 flex flex-col">
            <span className="text-secondary text-[15px]">Полис</span>
            <span className="font-bold text-[18px]">
              «
              {police.product_name}
              »
            </span>
          </div>
          <div className="mb-4 flex flex-col">
            <span className="text-secondary text-[15px]">Срок действия</span>
            <span className="text-[18px]">
              {police.start_date}
              {' '}
              —
              {' '}
              {police.end_date}
              {' '}
            </span>
          </div>
          <div className="mb-4 flex flex-col">
            <span className="text-secondary text-[15px]">Стоимость</span>
            <span className="text-[18px]">
              {police.price}
              {' '}
              ₽
            </span>
          </div>
          <div className="w-4/12 mt-8">
            <UI.Button primary><a href={police.url} target="_blank" rel="noreferrer">Скачать полис</a></UI.Button>
          </div>
        </div>
      ));
    }
    return <div className="mt-10">Нет активных полисов</div>;
  }

  return <div />;
}

export default Polices;
