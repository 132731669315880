/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';

import { Form } from 'react-final-form';

import { UI, Validators } from '@etnamed/ui-kit';

import { useStoreon } from 'storeon/react';
import { useToast } from 'components/ui/Toast';
import { LKPerson } from 'interfaces';

import * as I from 'interfaces';

import {
  UpdateCurrentUserMutation,
  useUpdateCurrentUserMutation,
  useGetUserInsuredsQuery,
} from 'generated/graphql';

import {
  formPersonInitialValues,
  Adapted,
} from 'components/ui/Сontrols';

import AddInsured from './addInsured';
import AutoSave from './autoSaveForm';
import InsuredCard from './insuredCard';

const validationFields = {
  ...Validators.presenceFieldConstraint('last_name'),
  ...Validators.presenceFieldConstraint('first_name'),
  ...Validators.emailConstraint('email'),
  ...Validators.phoneNumberConstraint('tel'),
};

function Profile() {
  const { user, dispatch } = useStoreon('user');
  const [formInitValues, setFormInitValues] = useState<any>(null);
  const [showAddPersonForm, setShowAddPersonForm] = useState(false);

  const toast = useToast();

  const userInsureds = useGetUserInsuredsQuery({
    fetchPolicy: 'cache-first',
  });

  const [updateCurrentUserMutation] = useUpdateCurrentUserMutation({
    onCompleted: (updatecurrentUserData: UpdateCurrentUserMutation) => {
      const result = updatecurrentUserData.update_auth_users;
      if (result?.affected_rows === 1) {
        dispatch('setUser', result.returning[0]);
      }
    },
    fetchPolicy: 'network-only',
  });

  const onSubmit = async (values: LKPerson) => {
    // Проверка что бы не сохранять стартовое неизмененное значение
    if (user.email === values.email
      && user.first_name === values.first_name
      && user.foreigner === values.foreigner
      && user.id === values.id
      && user.last_name === values.last_name
      && user.phone_number === values.tel
      && user.sex === values.sex
      && user.birthday === values.bday
      && user.international_passport === values.foreign_passport
      && user.passport === values.passport
      && user.second_name === values.additional_name) {
      return;
    }

    const resultUpdateUser = await updateCurrentUserMutation({
      variables: {
        email: values.email || '',
        first_name: values.first_name || '',
        foreigner: values.foreigner || false,
        id: values.id,
        last_name: values.last_name || '',
        phone_number: values.tel || '',
        sex: values.sex || false,
        birthday: values.bday,
        international_passport: values.foreign_passport || '',
        passport: values.passport || '',
        second_name: values.additional_name,
      },
    });
    if (resultUpdateUser.data?.update_auth_users?.affected_rows === 0) {
      console.log('Some errors on update current user. Break');
    } else {
      // dispatch('setUser', resultUpdateUser.data?.update_auth_users?.returning);
      toast.open('Данные сохранены');
    }
  };

  useEffect(() => {
    if (user === undefined || user === null) {
      setFormInitValues(formPersonInitialValues);
    } else {
      const initInsurant: I.LKPerson = {
        id: user.id,
        bday: user.birthday,
        email: user.email,
        first_name: user.first_name,
        foreigner: user.foreigner,
        last_name: user.last_name,
        passport: user.passport,
        tel: user.phone_number,
        additional_name: user.second_name,
        sex: user.sex,
        foreign_passport: user.international_passport,
        oms: user.oms,
      };
      setFormInitValues({
        ...initInsurant,
      });
    }
  }, [user]);

  const formValidate = (values: LKPerson) => {
    const res = Validators.default(values, validationFields, {
      fullMessages: false,
    });
    return res;
  };

  const showAddInsuredForm = () => {
    setShowAddPersonForm(true);
  };

  const hideAddPersonForm = () => {
    setShowAddPersonForm(false);
    userInsureds.refetch();
  };

  const renderInsureds = () => {
    if (userInsureds.loading) {
      return <div className="text-primary">LOADING...</div>;
    }

    return userInsureds.data?.dms_insureds.map((item:any, index:number) => (
      // eslint-disable-next-line react/no-array-index-key
      <InsuredCard key={`insured-card-${index}`} person={item} refetchInsureds={userInsureds.refetch} />
    ));
  };

  const renderForm = ({
    handleSubmit,
  }: any) => (
    <div className="mb-20">
      <form onSubmit={handleSubmit}>
        <AutoSave debounce={1000} save={handleSubmit} />
        <div className="card mt-10 w-full lg:w-3/4">
          <h2 className="font-bold text-primary text-lg">Контактные данные</h2>
          <div className="mt-10">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div>
                <label htmlFor="">Телефон:</label>
                <Adapted.Input
                  name="tel"
                  type="text"
                  placeholder="Введите телефон"
                />
              </div>
              <div>
                <label htmlFor="">Email:</label>
                <Adapted.Input
                  name="email"
                  type="text"
                  placeholder="Введите электронную почту"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-10 w-full lg:w-3/4">
          <h2 className="font-bold text-primary text-lg">Страхователь</h2>
          <p>тот, кто оплачивает услуги</p>
          <div className="form mt-10">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div>
                <label htmlFor="">Фамилия:</label>
                <Adapted.Input
                  name="last_name"
                  type="text"
                  placeholder="Введите фамилию"
                />
              </div>
              <div>
                <label htmlFor="">Имя:</label>
                <Adapted.Input
                  name="first_name"
                  type="text"
                  placeholder="Введите имя"
                />
              </div>
              <div>
                <label htmlFor="">Отчество:</label>
                <Adapted.Input
                  name="additional_name"
                  type="text"
                  placeholder="Введите отчество"
                />
              </div>
              <div>
                <label htmlFor="">Дата рождения:</label>
                <div className="grid grid-cols-2 gap-3">
                  <div className="relative">
                    <Adapted.Input
                      name="bday"
                      type="date"
                      placeholder="Выберите дату рождения"
                    />
                  </div>
                  <div className="flex flex-col justify-center">
                    <Adapted.GenderSelector name="sex" />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-4">
              <div>
                <label htmlFor="">Паспортные данные (РФ):</label>
                <Adapted.Input
                  name="passport"
                  type="text"
                  placeholder="Введите паспортные данные (РФ)"
                />
              </div>
              <div>
                <label htmlFor="">Паспортные данные (загран РФ):</label>
                <Adapted.Input
                  name="foreign_passport"
                  type="text"
                  placeholder="Введите паспортные данные (Загран)"
                />
              </div>
            </div>
          </div>
        </div>
      </form>

      <div className="mt-10 flex justify-between w-full items-center lg:w-3/4">
        <div className="text-primary text-2xl font-semibold">Застрахованные</div>
        <div className="  hidden lg:block">
          <UI.Button
            disabled={showAddPersonForm}
            primary
            onClick={showAddInsuredForm}
          >
            Добавить застрахованного
          </UI.Button>
        </div>
      </div>
      {showAddPersonForm && (
        <AddInsured onPersonAdded={hideAddPersonForm} />
      )}
      <div className="mt-8 w-full lg:w-3/4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 w-full">
          {renderInsureds()}
        </div>
      </div>

    </div>
  );

  return (
    <Form
      onSubmit={onSubmit}
      validate={formValidate}
      validateOnBlur
      render={renderForm}
      initialValues={formInitValues}
    />
  );
}

export default Profile;
