/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import { Field } from 'react-final-form';

import { UI } from '@etnamed/ui-kit';

import * as I from 'interfaces';

export const formPersonInitialValues: I.LKPerson = {
  id: undefined,
  last_name: '',
  first_name: '',
  additional_name: '',
  sex: true,
  bday: '',
  passport: '',
  division_code: null,
  division_issuing: null,
  date_release: '',
  birth_place: '',
  tel: '',
  email: '',
  street_address: null,
  foreign_passport: '',
  foreigner: false,
  oms: '',
  groups: [],
};

export const SendedPersonInitValues: I.LKBackendPersonProps = {
  id: null,
  address_residence: '',
  birthday: '',
  email: '',
  first_name: '',
  foreigner: false,
  last_name: '',
  passport: '',
  phone_number: '',
  place_birth: '',
  second_name: '',
  sex: true,
  international_passport: '',
  oms: '',
};

export const formInitialValues = {
  insurant: { ...formPersonInitialValues },
  insured: [],
  same_as_insurant: false,
};

export const availableGroups = [
  {
    value: 'colleagues',
    label: 'Коллеги',
  },
  {
    value: 'friends',
    label: 'Друзья',
  },
  {
    value: 'relatives',
    label: 'Родственники',
  },
];

const renderInput = (props: any) => (
  <UI.Input
        // className="mb-2"
    {...props}
  />
);

const renderCheckbox = ({ ...rest }) => (
  <UI.Checkbox
    {...rest}
  />
);

function ReactCreatableSelectAdapter({ ...rest }: any) {
  return (
    <div className="relative">
      <UI.CreatableSelect {...rest} />
    </div>
  );
}

function GenderSelectorAdapter({ ...rest }: any) {
  return <UI.GenderSelector {...rest} />;
}

export const Adapted = ({
  // eslint-disable-next-line react/no-unstable-nested-components
  Input: (props: any) => <Field {...props} render={renderInput} />,
  // eslint-disable-next-line react/no-unstable-nested-components
  Checkbox: (props: any) => <Field {...props} render={renderCheckbox} />,
  // eslint-disable-next-line react/no-unstable-nested-components
  CreatableSelect: (props: any) => (<Field {...props} component={ReactCreatableSelectAdapter} />),
  GenderSelector: (props:any) => (<Field {...props} component={GenderSelectorAdapter} />),
});
