import React, { useCallback } from 'react';

import { Block, UI } from '@etnamed/ui-kit/dist';

function SuccessRegister() {
  const Logo = useCallback(() => <UI.Logo link="/" />, []);

  return (
    <div className="flex flex-col items-center w-full">
      <Block.SuccessRegister LogoComponent={Logo} />
    </div>
  );
}

export default SuccessRegister;
