import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useStoreon } from "storeon/react";

import { Block, UI } from "@etnamed/ui-kit/dist"

import { useRequests } from '../hooks'

const SignUp = () => {
  const { token } = useStoreon("token");
  const navigate = useNavigate();

  const Logo = useCallback(() => <UI.Logo link="/" />, []);

  useEffect(() => {
    if (token.access_token !== undefined) {
      navigate('/')
    }
  }, [token, navigate])

  const { signUp } = useRequests()

  return (
    <div className="flex flex-col items-center w-full">
      <Block.SignUpBlock
        LogoComponent={Logo}
        signInPageLink="/signin"
        signUp={signUp}
      />
    </div>
  );
};

export default SignUp;
