/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useEffect, useState } from 'react';

import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { UI, Validators } from '@etnamed/ui-kit';

import { useStoreon } from 'storeon/react';
import { useToast } from 'components/ui/Toast';
import { FormValuesProps } from 'interfaces';
import { useNavigate } from 'react-router-dom';
import * as I from 'interfaces';
import {
  UpdateCurrentUserMutation,
  useUpdateCurrentUserMutation,
  AddInsuredOneMutation,
  useAddInsuredToGroupMutation,
  useAddInsuredOneMutation,
} from 'generated/graphql';
import {
  formPersonInitialValues,
  availableGroups,
  formInitialValues,
} from 'components/ui/Сontrols';

import * as Icons from '../assets/svgs';

import InsuredInfo from '../components/blocks/profile/addInsured';

const validationFields = {
  ...Validators.presenceFieldConstraint('last_name'),
  ...Validators.presenceFieldConstraint('first_name'),
  ...Validators.emailConstraint('email'),
  ...Validators.phoneNumberConstraint('tel'),
};

function AddInsuredPage() {
  const { user, dispatch } = useStoreon('user');
  const [formInitValues, setFormInitValues] = useState<any>(null);
  const navigate = useNavigate();
  const toast = useToast();
  const [updateCurrentUserMutation] = useUpdateCurrentUserMutation({
    onCompleted: (updatecurrentUserData: UpdateCurrentUserMutation) => {
      const result = updatecurrentUserData.update_auth_users;
      if (result?.affected_rows === 1) {
        dispatch('setUser', result.returning[0]);
      }
    },
    fetchPolicy: 'network-only',
  });

  const [addInsuredToGroupMutation] = useAddInsuredToGroupMutation({
    fetchPolicy: 'network-only',
    onCompleted: (addInsuredToGroupData: AddInsuredOneMutation) => {

    },
  });

  const [addInsuredOneMutation] = useAddInsuredOneMutation({
    fetchPolicy: 'network-only',
  });

  const onSubmit = async (values: FormValuesProps, update: any) => {
    const resultUpdateUser = await updateCurrentUserMutation({
      variables: {
        // address_residence: values.insurant?.street_address,
        email: values.insurant?.email || '',
        first_name: values.insurant?.first_name || '',
        foreigner: values.insurant?.foreigner || false,
        id: values.insurant?.id,
        last_name: values.insurant?.last_name || '',
        phone_number: values.insurant?.tel || '',
        sex: values.insurant?.sex || false,
        birthday: values.insurant?.bday,
        international_passport: values.insurant?.foreign_passport || '',
        passport: values.insurant?.passport || '',
        // place_birth: values.insurant?.birth_place || '',
        second_name: values.insurant?.additional_name,
      },
    });
    if (resultUpdateUser.data?.update_auth_users?.affected_rows === 0) {
      console.log('Some errors on update current user. Break');
    }
    values?.insured?.map(async (item) => {
      const insured = {
        bithday: item.bday,
        email: item.email,
        first_name: item.first_name,
        gender: item.sex,
        user_id: user.id,
        last_name: item.last_name,
        passport: item.passport,
        phone: item.tel,
        second_name: item.additional_name,
        foreign_passport: item.foreign_passport,
        oms: item.oms,
      };

      const resultAddInsured = await addInsuredOneMutation({
        variables: {
          user: insured,
        },
      });
      if (resultAddInsured.data?.insert_dms_insureds_one?.id) {
        const resultAddInsuredToGroup = await addInsuredToGroupMutation({
          variables: {
            group_id: item.groups.value,
            insured_id: resultAddInsured.data.insert_dms_insureds_one.id,
            user_id: user.id,
          },
        });
        // if ((resultAddInsuredToGroup).data?.update_dms_insureds_by_pk) {}
      }
    });
    navigate('/');
  };

  useEffect(() => {
    if (user === undefined || user === null) {
      setFormInitValues(formInitialValues);
    } else {
      const initInsurant: I.LKPerson = {
        id: user.id,
        // street_address: user.address_residence, Если будет Select, присваивать объект подходящий
        bday: user.birthday,
        email: user.email,
        first_name: user.first_name,
        foreigner: user.foreigner,
        last_name: user.last_name,
        passport: user.passport,
        tel: user.phone_number,
        // birth_place: user.place_birth,     Пока в личном кабинете нигде не отображается
        additional_name: user.second_name,
        sex: user.sex,
        foreign_passport: user.international_passport,
        oms: user.oms,
      };
      const insured: I.LKPerson[] = [];
      insured.push(formPersonInitialValues);

      setFormInitValues((oldValues: any) => ({
        ...oldValues,
        insurant: initInsurant,
        insured,
      }));
    }
  }, [user]);

  const formValidate = (values: FormValuesProps) => {
    const res = Validators.default(values.insurant, validationFields, {
      fullMessages: false,
    });
    return { insurant: res };
  };

  const renderInsured = useCallback(
    (values: any, update: any) => (
      <InsuredInfo />
    ),
    [availableGroups],
  );

  const renderForm = () => (
    <InsuredInfo />
  );
  return (
    <div className="min-h-screen relative">
      <div className="container mx-auto p-2 mt-0 lg:mt-10">
        <UI.Logo link='/'/>
        <div className="flex flex-row justify-start items-center gap-4 mt-10">
          <Icons.GreyLeftArrow onClick={() => navigate('/')} className=" cursor-pointer" />
          {' '}
          <h1 className=" font-bold text-primary text-2xl">Личный кабинет</h1>
        </div>
        <Form
          onSubmit={onSubmit}
          validate={formValidate}
          mutators={{
            ...arrayMutators,
            setFormAttribute: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          validateOnBlur
          render={renderForm}
          initialValues={formInitValues}
        />
      </div>
    </div>
  );
}

export default AddInsuredPage;
