/* eslint-disable import/no-extraneous-dependencies */
import { useStoreon } from 'storeon/react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

interface SignUpValueProps {
  email?: string;
  password?: string;
  phone_number?: string;
  first_name?: string;
  last_name?: string;
}

const useRequests = () => {
  const { token, dispatch } = useStoreon('token');

  // `${process.env.REACT_APP_HOST_AUTH_API}${path}`,

  async function sendRequest(path: string, body: object, method = 'POST') {
    const result = await fetch(
      `https://dev.auth.etnamed.ru/${path}`,
      {
        method,
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token.access_token}`,
        },
        body: JSON.stringify(body),
      },
    )
      .then((response) => response.json())
      .catch((e) => {
        throw new Error(`there is some errors on request: ${e}`);
      });

    return result;
  }

  function signUp(value: SignUpValueProps) {
    const path = 'users';
    const body = {
      user: {
        email: value.email,
        password: value.password,
        phone_number: value.phone_number,
        first_name: value.first_name,
        last_name: value.last_name,
      },
    };
    const result = sendRequest(path, body)
      .then((res) => {
        if (res?.errors !== undefined || res?.error !== undefined) {
          return { ok: false, errors: res?.error || res?.errors, timeout: res.timeout };
        }
        return { ok: true, timeout: res.timeout };
      })
      .catch((error) => {
        console.log('Error:', error);
        return { ok: false, error: 'Request error' };
      });
    return result;
  }

  function sendOTP(phone: string) {
    const path = 'api/auth/send_otp';
    const body = { otp: { phone_number: phone } };
    const result = sendRequest(path, body);
    // .then((res) => {
    //   if (res?.errors !== undefined || res?.error !== undefined) {
    //     return {
    //       ok: false, error: res?.error, errors: res?.errors, timeout: res.timeout,
    //     };
    //   }
    //   return { ok: true, timeout: res.timeout };
    // })
    // .catch((error) => {
    //   console.log('Error:', error);
    //   return { ok: false };
    // });
    return result;
  }

  function signInByPhone(phone: string, otpCode: string) {
    const path = 'oauth/token';
    const body = {
      grant_type: 'password',
      // client_id: process.env.REACT_APP_APP_ID,
      client_id: '_ViSWllYnNJCULOADykJSrcOs5tfNGopFdnwjp01wHs',
      phone_number: phone,
      otp_code: otpCode,
    };
    const result = sendRequest(path, body).then((res) => {
      if (res?.errors !== undefined || res?.error !== undefined) {
        return { ok: false, errors: 'Wrong OTP code' };
      }
      dispatch('set', res);
      return { ok: true };
    });
    return result;
  }

  function signInByEmail(email: string, password: string) {
    const path = 'oauth/token';
    const body = {
      grant_type: 'password',
      // client_id: process.env.REACT_APP_APP_ID,
      client_id: '_ViSWllYnNJCULOADykJSrcOs5tfNGopFdnwjp01wHs',
      email,
      password,
    };
    const result = sendRequest(path, body)
      .then((res) => {
        if (res?.errors !== undefined || res?.error !== undefined) {
          return res;
        }
        dispatch('set', res);
        return { ok: true };
      })
      .catch((error) => {
        console.log('Error:', error);
        return { ok: false };
      });

    return result;
  }

  function refreshToken() {
    if (isEmpty(token.refresh_token)) {
      return;
    }
    const path = 'oauth/token';
    const body = {
      grant_type: 'refresh_token',
      // client_id: process.env.REACT_APP_APP_ID,
      client_id: '_ViSWllYnNJCULOADykJSrcOs5tfNGopFdnwjp01wHs',
      refresh_token: token.refresh_token,
    };
    const result = sendRequest(path, body)
      .then((res) => {
        if (res?.errors !== undefined || res?.error !== undefined) {
          return res;
        }
        dispatch('set', res);
        return { ok: true };
      })
      .catch((error) => {
        console.log('Error:', error);
        return { ok: false };
      });
  }

  function resetPassword(email: string) {
    const path = 'users/password';
    const body = {
      user: {
        email,
      },
    };
    const result = sendRequest(path, body)
      .then((res) => res) // Вот тут вот вопросики по взаимодействию
      .catch((error) => {
        console.log('Error:', error);
        return { ok: false };
      });
    return result;
  }

  function saveNewPassword(
    reset_password_token: string,
    password: string,
    password_confirmation: string,
  ) {
    const path = 'users/password';
    const body = {
      user: {
        reset_password_token,
        password,
        password_confirmation,
      },
    };
    const result = sendRequest(path, body, 'PUT')
      .then((res) => {
        if (res?.errors !== undefined || res?.error !== undefined) {
          return { ok: false, errors: res?.error || res?.errors, timeout: res.timeout };
        }
        return { ok: true };
      })
      .catch((error) => {
        console.log(error);
        return { ok: false };
      });
    return result;
  }

  function checkAuthorize() {
    const path = 'api/hasura/auth';
    const result = sendRequest(path, {}).then((resultRole) => ({ role: get(resultRole, 'X-Hasura-Role') }));
    return result;
  }

  return {
    signUp,
    sendOTP,
    signInByPhone,
    signInByEmail,
    resetPassword,
    checkAuthorize,
    refreshToken,
    saveNewPassword,
  };
};

export default useRequests;
