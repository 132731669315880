import React, { useCallback, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { UI, Block } from '@etnamed/ui-kit/dist';

import { useRequests } from '../hooks';

function ChangePasswordPage() {
  const { saveNewPassword } = useRequests();
  const location = useLocation();
  const [resetPasswordToken, setResetPasswordToken] = useState('');

  const Logo = useCallback(() => <UI.Logo link="/" />, []);

  useEffect(() => {
    if (location && location?.search?.includes('reset_password_token')) {
      setResetPasswordToken(new URLSearchParams(location.search).get('reset_password_token') || '');
    }
  }, [location]);

  return (
    <div className="flex flex-col items-center w-full">
      <Block.NewPassword
        LogoComponent={Logo}
        resetPasswordToken={resetPasswordToken}
        sendNewPassword={saveNewPassword}
      />
    </div>
  );
}

export default ChangePasswordPage;
