import React from 'react';
import { useTimeout } from 'hooks/useTimeout'
export const Toast = (props) => {
  useTimeout(props.close, 5000);

  return (
    <div className="toast push w-[340px] bg-primary rounded-lg flex flex-row justify-between text-white p-5 font-bold items-center">
      <span className='w-[20px] h-[20px] text-primary rounded-full p-1 bg-[#94E619] flex flex-col items-center justify-center'>&#10003;</span>
      <div className="toast__text">{props.children}</div>
      <div>
        <button onClick={props.close} className="toast__close-btn">
          &times;
        </button>
      </div>
    </div>
  );
};
