/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, {
  useState, useEffect,
} from 'react';

import { UI } from '@etnamed/ui-kit';

import { useToast } from 'components/ui/Toast';

import { Form } from 'react-final-form';

import { useStoreon } from 'storeon/react';

import * as I from 'interfaces';
import {
  useAddInsuredOneMutation,
} from 'generated/graphql';

import { Adapted, formInitialValues } from 'components/ui/Сontrols';

function AddInsuredToGroupForm({
  group, index, refetch, isOne,
}: any) {
  const { user, dispatch } = useStoreon('user');
  const [formInitValues, setFormInitValues] = useState<any>([]);
  const toast = useToast();
  const [addInsuredOneMutation] = useAddInsuredOneMutation({
    fetchPolicy: 'network-only',
  });

  // const [addInsuredToGroupMutation] = useAddInsuredToGroupMutation({
  //   fetchPolicy: 'network-only',
  //   onCompleted: (addInsuredToGroupData: AddInsuredOneMutation) => {
  //     refetch();
  //     toast.open('Данные сохранены');
  //   },
  // });

  useEffect(() => {
    if (user === undefined || user === null) {
      setFormInitValues(formInitialValues);
    } else {
      const initInsurant: I.LKPerson = {
        id: user.id,
        bday: user.birthday,
        email: user.email,
        first_name: user.first_name,
        foreigner: user.foreigner,
        last_name: user.last_name,
        passport: user.passport,
        tel: user.phone_number,
        additional_name: user.second_name,
        sex: user.sex,
        foreign_passport: user.international_passport,
        oms: user.oms,
      };
      setFormInitValues((oldValues: any) => ({
        same_as_insurant: false,
        ...oldValues,
        insurant: initInsurant,
      }));
    }
  }, [user]);

  const onSubmitAddInsured = async (values: any) => {
    const insured = {
      bithday: values.bday,
      email: '',
      first_name: values.first_name,
      gender: values.sex,
      user_id: user.id,
      last_name: values.last_name,
      passport: values.passport,
      phone: '',
      second_name: values.additional_name,
      foreign_passport: values.foreign_passport,
      oms: values.oms,
    };
    const resultAddInsured = await addInsuredOneMutation({
      variables: {
        user: insured,
      },
    });
    if (resultAddInsured.data?.insert_dms_insureds_one?.id) {
      // const resultAddInsuredToGroup = await addInsuredToGroupMutation({
      //   variables: {
      //     group_id: group,
      //     user_id: resultAddInsured.data.insert_dms_insureds_one.id,
      //   },
      // });
    }
  };

  return (
    <Form
      key={`addinsuredform_${index}`}
      onSubmit={onSubmitAddInsured}
      mutators={{
        same: (values, state, { changeValue }) => {
          const theSame = !values[0].same_as_insurant;
          changeValue(state, 'last_name', () => (theSame ? user.last_name : ''));
          changeValue(state, 'first_name', () => (theSame ? user.first_name : ''));
          changeValue(state, 'additional_name', () => (theSame ? user.second_name : ''));
          changeValue(state, 'bday', () => (theSame ? user.birthday : ''));
          changeValue(state, 'sex', () => (theSame ? user.sex : false));
          changeValue(state, 'passport', () => (theSame ? user.passport : ''));
          changeValue(state, 'foreign_passport', () => (theSame ? user.international_passport : ''));
        },
      }}
      render={({
        handleSubmit, form, values,
      }) => (
        <form onSubmit={handleSubmit} id={`addInsuredForm_${index}`} className={!isOne ? 'hidden' : ''}>
          <div className="card mt-10 w-full lg:w-3/4">
            <h2 className="font-bold text-primary text-lg">
              Добавить застрахованного
            </h2>
            <div className="form mt-10">
              <div className="grid lg:grid-cols-2 gap-6">
                <div className="form-control">

                  <div className="flex flex-row justify-between items-center w-full lg:w-3/4 mb-4">
                    <span>Совпадает со страхователем</span>
                    <Adapted.Checkbox name="same_as_insurant" type="checkbox" onClick={() => form.mutators.same(values)} />
                  </div>

                  <div className="flex flex-row justify-between items-center w-full lg:w-3/4 mb-6">
                    <span>Иностранный гражданин</span>
                    <Adapted.Checkbox name="foreigner" />
                  </div>
                </div>
              </div>
              <div className="grid lg:grid-cols-2 gap-6">
                <div className="form-control">
                  <label>Фамилия:</label>
                  <Adapted.Input disabled={values.same_as_insurant} name="last_name" type="text" placeholder="Введите фамилию" />
                </div>
                <div className="form-control">
                  <label>Имя:</label>
                  <Adapted.Input disabled={values.same_as_insurant} name="first_name" type="text" placeholder="Введите имя" />
                </div>
                <div className="form-control">
                  <label>Отчество:</label>
                  <Adapted.Input disabled={values.same_as_insurant} name="additional_name" type="text" placeholder="Введите отчество" />
                </div>
                <div className="form-control">
                  <label>Дата рождения:</label>
                  <div className="grid grid-cols-2 gap-3">
                    <div className="relative">
                      <Adapted.Input disabled={values.same_as_insurant} type="date" placeholder="Выберите дату рождения" name="bday" />
                    </div>
                    <div className="flex flex-col justify-center">
                      <Adapted.GenderSelector name="sex" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid lg:grid-cols-2 gap-6 mt-4">
                <div className="form-control">
                  <label htmlFor="">Паспортные данные (РФ):</label>
                  <Adapted.Input disabled={values.same_as_insurant} name="passport" type="text" placeholder="Введите паспортные данные (РФ)" />
                </div>
                <div className="form-control">
                  <label htmlFor="">Паспортные данные (загран РФ):</label>
                  <Adapted.Input disabled={values.same_as_insurant} name="foreign_passport" type="text" placeholder="Введите паспортные данные (Загран)" />
                </div>
                <div className="form-control">
                  <label htmlFor="">Номер полиса:</label>
                  <Adapted.Input name="oms" type="text" placeholder="Введите номер полиса" />
                </div>
              </div>

              <div className="grid lg:grid-cols-2 gap-6 mt-4">
                <div className="form-control">
                  <UI.Button primary type="submit">
                    Добавить
                  </UI.Button>
                </div>
              </div>

            </div>
          </div>
        </form>
      )}
      initialValues={formInitValues}
    />
  );
}

export default AddInsuredToGroupForm;
