import React, { useEffect } from 'react';

import { useStoreon } from 'storeon/react';
import { ToastProvider } from 'components/ui/Toast';
import {
  ApolloProvider, InMemoryCache, ApolloClient, createHttpLink, split,
} from '@apollo/client';

import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

import { Router } from 'services';
import {ModalProvider} from "./components/ui/Modal";

function App() {
  const { token } = useStoreon('token');
  // const token = {
  // eslint-disable-next-line max-len
  //   access_token: 'Gv3aosNg7zQzlbopGyDwv_Py8YRu0IVxqbsp6w8X55Y', token_type: 'Bearer', expires_in: 7200, refresh_token: 'ZQF8DvdGW3B-xKazMFo8Xtxa94QDuEubG-9fm9870qg', scope: 'read', created_at: 1664262121,
  // };
  useEffect(() => {
    // console.log('Token:', token);
  }, []);

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_LINK,
    headers:
      token !== null
        ? {
          Authorization: `Bearer ${token.access_token}`,
        }
        : {},
  });

  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_GRAPHQL_WS_LINK || '',
    options: {
      reconnect: true,
    },
  });

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
  );

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <ModalProvider>
        <ToastProvider>
          <Router />
        </ToastProvider>
      </ModalProvider>
    </ApolloProvider>
  );
}

export default App;
