import React, { useCallback } from "react";

import { UI, Block } from "@etnamed/ui-kit/dist";

import { useRequests } from '../hooks'

const ResetPage = () => {
  const { resetPassword } = useRequests()

  const Logo = useCallback(() => <UI.Logo link="/" />, []);

  return (
    <div className="flex flex-col items-center w-full">
      <Block.PasswordRecovery
        LogoComponent={Logo}
        signInPageLink="/signin"
        resetPassword={resetPassword}
      />
    </div>
  );
};

export default ResetPage;
