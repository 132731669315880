import React, { useCallback, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useStoreon } from 'storeon/react';

// eslint-disable-next-line import/no-extraneous-dependencies
import isEmpty from 'lodash/isEmpty';

import {
  useCurrentUserLazyQuery,
} from 'generated/graphql';

import * as Pages from 'pages';
import { useRequests } from '../hooks';

function AuthRouter() {
  const { dispatch } = useStoreon('user');
  const { token } = useStoreon('token');

  const { checkAuthorize, refreshToken } = useRequests();

  useEffect(() => {
    const checkRole = async () => {
      const userRole = await checkAuthorize();
      if (userRole.role !== 'user') {
        refreshToken();
      }
    };
    checkRole();
  }, [checkAuthorize, refreshToken]);

  const [getCurrentUserQuery] = useCurrentUserLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      if (data.current_user.length > 0) { dispatch('setUser', data.current_user[0]); }
    },
    onError: (error) => {
      console.log('Error on get current user info:', error);
    },
  });

  useEffect(() => {
    if (isEmpty(token.access_token)) {
      return;
    }
    getCurrentUserQuery();
  }, [token]);

  // useCurrentUserQuery({
  //   fetchPolicy: 'cache-first',
  //   onCompleted: (data) => {
  //     if (data.current_user.length > 0)
  //       dispatch('setUser', data.current_user[0]);
  //   },
  //   onError: (error) => {
  //     if (!isEmpty(token.refresh_token))
  //       refreshToken(token.refresh_token).catch(() => {
  //       alert('Some errors on refresh token');
  //     });
  //     console.log('Error on get current user info:', error);
  //   },
  // });

  const withAuth = useCallback((node: any) => {
    if (isEmpty(token)) {
      return <Navigate to="/signin" replace />;
    }
    return node;
  }, [token]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={withAuth(<Pages.Home />)} />
        <Route path="/addinsured" element={withAuth(<Pages.AddInsured />)} />
        <Route path="/addgroup" element={withAuth(<Pages.AddGroup />)} />
        <Route path="/group" element={withAuth(<Pages.ViewGroup />)}>
          <Route path=":groupId" element={withAuth(<Pages.ViewGroup />)} />
        </Route>
        <Route path="/insured">
          <Route path=":insuredId" element={withAuth(<Pages.EditInsuredPage />)} />
        </Route>
        <Route path="signin" element={<Pages.SignIn />} />
        <Route path="signup" element={<Pages.SignUp />} />
        <Route path="reset" element={<Pages.ResetPage />} />
        <Route path="signup-success" element={<Pages.SignUpSucessPage />} />
        <Route path="signup-failed" element={<Pages.SignUpFailedPage />} />
        <Route path="change_password" element={<Pages.ChangePasswordPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AuthRouter;
