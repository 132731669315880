import React, { useEffect, useState } from 'react';
import {UI} from "@etnamed/ui-kit";
import {Form} from "react-final-form";

export const Modal = (props) => {
    const [modalActive, setModalActive] = useState(false);

    function closeModal(){
        setModalActive(false);
    }
    function runAction(){
        props.action()
        props.close();
    }
    return (
        <>
            <div className={`modal delete_modal`}>
                <div className="modal_overlay flex flex-col justify-center items-center">
                    <div className="modal_content text-center max-w-xs">
                        <b className="text-[18px] mb-8 block">
                            {props.text}
                        </b>
                        <div className="mb-4">
                            <UI.Button secondary onClick={props.close}>
                                {props.noText}
                            </UI.Button>
                        </div>
                        <div>
                            <UI.Button primary onClick={() => runAction()}>
                                {props.yesText}
                            </UI.Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default Modal;
