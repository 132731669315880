/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, {
  useState, useEffect, useCallback,
} from 'react';

import { UI } from '@etnamed/ui-kit';

import { useToast } from 'components/ui/Toast';

import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import { useStoreon } from 'storeon/react';
import * as Icons from 'assets/svgs';

import {
  useAddInsuredOneMutation,
  // useAddInsuredToGroupMutation,
  useAddInsuredArrayToGroupMutation,
  useDmsCleanGroupMutation,
} from 'generated/graphql';

import { Adapted, formInitialValues } from 'components/ui/Сontrols';

function AddInsuredToGroupList({
  group, index, refetch, isOne, insureds, groupInsureds, complete,
}: any) {
  const { user, dispatch } = useStoreon('user');
  const [formInitValues, setFormInitValues] = useState([]);
  const [persons, setPersons] = useState<any>([]);
  const toast = useToast();
  const [addInsuredOneMutation] = useAddInsuredOneMutation({
    fetchPolicy: 'network-only',
  });
  const [dmsCleanGroup] = useDmsCleanGroupMutation({
    fetchPolicy: 'network-only',
  });

  const [addInsuredToGroupMutation] = useAddInsuredArrayToGroupMutation({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
    },
  });
  useEffect(() => {
    if (insureds.dms_insureds !== undefined
      && insureds.dms_insureds.length !== 0
      && groupInsureds !== undefined && groupInsureds.length !== 0 && groupInsureds !== null) {

        const tmpPersons = {
          dms_insureds: insureds.dms_insureds.map((person: any) => {

            const find = groupInsureds.find(
              (o: any) => {
                if(o.insured !== null){
                return o.insured.id === person.id && o.group_id === group
                }
              }
            );
            return {
              ...person,
              inThisGroup: find !== undefined,
            };
          }),
        };

        setPersons(tmpPersons);

    }
  }, [insureds, groupInsureds]);

  const onSubmitAddInsured = async (values: any) => {
    const cleanGroup = await dmsCleanGroup({
      variables: {
        group_id: group,
      },
    });
    const object: any[] = [];
    values.dms_insureds.forEach((insured: any) => {
      if (insured.inThisGroup) {
        const insuredToAdd = {
          group_id: group,
          user_id: user.id,
          insured_id: insured.id,
        };

        object.push(insuredToAdd);
      }
    });

    const insertInsuredsTogroup = await addInsuredToGroupMutation({
      variables: {
        objects: object,
      },
    });
    refetch();
    toast.open('Данные сохранены');
  };

  const RenderLines = useCallback(() => {
    if (persons.dms_insureds && persons.dms_insureds.length !== 0) {
      return (
        <Form
          key={`addinsuredform_${index}`}
          onSubmit={onSubmitAddInsured}
          mutators={{
            // potentially other mutators could be merged here
            ...arrayMutators,
          }}
          render={({
            handleSubmit, form, values,
          }) => (
            <form onSubmit={handleSubmit}>
              <FieldArray name="dms_insureds">
                {({ fields }) => (
                  <div>

                    {fields.map((name, ix) => (
                      <div key={name} className="flex flex-row mb-2">
                        <div>
                          <Adapted.Checkbox name={`${name}.inThisGroup`} type="checkbox" />
                        </div>
                        <div className="rounded border border-gray-400 p-4 w-full flex flex-row gap-3">
                          <Icons.Human />
                          <div>
                            {insureds.dms_insureds[ix].first_name}
                            {' '}
                            {insureds.dms_insureds[ix].last_name}
                          </div>

                        </div>
                      </div>
                    ))}
                    <UI.Button primary type="submit">
                      Добавить
                    </UI.Button>
                  </div>
                )}
              </FieldArray>
            </form>
          )}
          initialValues={persons}
        />
      );
    }
    return <div>Застрахованных нет</div>;
  }, [persons]);

  return RenderLines();
}

export default AddInsuredToGroupList;
