/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';

import { Form } from 'react-final-form';

import { UI, Validators } from '@etnamed/ui-kit';

import { useStoreon } from 'storeon/react';
import { useToast } from 'components/ui/Toast';
import { FormValuesProps } from 'interfaces';
import { useNavigate } from 'react-router-dom';

import {
  useCreateDmsGroupMutation,
  CreateDmsGroupMutation,
} from 'generated/graphql';

import {
  Adapted,
} from 'components/ui/Сontrols';

import * as Icons from '../assets/svgs';

const validationFields = {
  ...Validators.presenceFieldConstraint('last_name'),
  ...Validators.presenceFieldConstraint('first_name'),
  ...Validators.emailConstraint('email'),
  ...Validators.phoneNumberConstraint('tel'),
};

function AddGroupPage() {
  const { user } = useStoreon('user');
  const [formInitValues, setFormInitValues] = useState<any>(null);
  const [addedGroupId, setAddedGroupId] = useState<any>(null);

  const navigate = useNavigate();
  const toast = useToast();

  const [createDmsGroupMutation] = useCreateDmsGroupMutation({
    onCompleted: (createDmsGroupData: CreateDmsGroupMutation) => {
      const createdGroupId = createDmsGroupData.insert_dms_groups_one?.id;
      setAddedGroupId(createdGroupId);
    },
    fetchPolicy: 'network-only',
  });

  const onSubmit = async (values: any, update: any) => {
    const result = await createDmsGroupMutation({
      variables: {
        group_name: values.group_name,
        user_id: user.id,
      },
    });

    toast.open('Данные сохранены');
    navigate(`/group/${result?.data?.insert_dms_groups_one?.id}`);
  };

  useEffect(() => {

  }, [user]);

  const formValidate = (values: FormValuesProps) => {
    const res = Validators.default(values.insurant, validationFields, {
      fullMessages: false,
    });
    return { insurant: res };
  };

  const renderForm = ({
    handleSubmit,
    values,
    form,
  }: any) => (
    <form onSubmit={handleSubmit}>
      <div className="card mt-10 w-auto lg:w-3/4 block m-1 lg:m-0 ">
        <h2 className="font-bold text-primary text-lg mb-4">
          Добавить группу
        </h2>
        <Adapted.Input name="group_name" type="text" placeholder="Введите название группы" />
      </div>
      <div className="mt-10 lg:w-2/12">
        <UI.Button primary type="submit">
          Добавить группу
        </UI.Button>
      </div>
    </form>
  );
  return (
    <div className="min-h-screen relative">
      <div className="container mx-auto p-2 mt-0 lg:mt-10">
        <UI.Logo link='/'/>
        <div className="flex flex-row justify-start items-center gap-4 mt-10">
          <Icons.GreyLeftArrow onClick={() => navigate('/')} className=" cursor-pointer" />
          {' '}
          <h1 className=" font-bold text-primary text-2xl">Личный кабинет</h1>
        </div>
        <Form
          onSubmit={onSubmit}
          validateOnBlur
          render={renderForm}
          initialValues={{ group_name: '' }}
        />
      </div>
    </div>
  );
}

export default AddGroupPage;
