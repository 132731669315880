import React, { useState, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { ModalContext } from './ModalContext';
import { Modal } from './Modal';

// Create a random ID
function generateUEID() {
    let first = (Math.random() * 46656) | 0;
    let second = (Math.random() * 46656) | 0;
    first = ('000' + first.toString(36)).slice(-3);
    second = ('000' + second.toString(36)).slice(-3);

    return first + second;
}

export const ModalProvider = (props) => {
    const [modals, setModals] = useState([]);
    const open = (content, action, yesText="Да", noText="Нет") =>
        setModals((currentModals) => [
            ...currentModals,
            { id: generateUEID(), content, action, yesText, noText },
        ]);
    const close = (id) =>
        setModals((currentModals) =>
            currentModals.filter((modal) => modal.id !== id)
        );
    const contextValue = useMemo(() => ({ open }), []);

    return (
        <ModalContext.Provider value={contextValue}>
            {props.children}

            {createPortal(
                <>
                    {modals.map((modal) => (
                        <Modal key={modal.id} close={() => close(modal.id)} text={modal.content} action={modal.action} yesText={modal.yesText} noText={modal.noText}/>
                    ))}
                </>,
                document.body
            )}
        </ModalContext.Provider>
    );
};
