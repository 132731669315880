import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ISO8601Date: any;
  ISO8601DateTime: any;
  JSON: any;
  bigint: any;
  date: any;
  float8: any;
  jsonb: any;
  timestamp: any;
  timestamptz: any;
};

export type AddressPlace = {
  __typename?: 'AddressPlace';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  codeFias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  index?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  work?: Maybe<Array<Work>>;
};

export type Attachment = {
  __typename?: 'Attachment';
  blob: Blob;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['String'];
  thumbnailUrl: Scalars['String'];
  url: Scalars['String'];
};

/** Attributes for query attachments for a specific model */
export type AttachmentsAttributes = {
  /** Model attribute name */
  attribute: Scalars['String'];
  /** Model instance id */
  recordId: Scalars['bigint'];
  /** Model class name */
  recordType: Scalars['String'];
};

export type Auth = {
  __typename?: 'Auth';
  bithday?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  foreigner?: Maybe<Scalars['Boolean']>;
  lastname?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  passport?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  secondname?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
};

export type Blob = {
  __typename?: 'Blob';
  byteSize: Scalars['bigint'];
  checksum: Scalars['String'];
  contentType: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  filename: Scalars['String'];
  id: Scalars['String'];
  key: Scalars['String'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type Calc = {
  __typename?: 'Calc';
  calcid?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  countries?: Maybe<Array<Country>>;
  currency?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  enddate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  insured?: Maybe<Array<Scalars['Int']>>;
  premiumrur?: Maybe<Scalars['Float']>;
  program?: Maybe<Scalars['String']>;
  sport?: Maybe<Scalars['String']>;
  startdate?: Maybe<Scalars['String']>;
  summedicine?: Maybe<Scalars['Int']>;
};

export type Citizenship = {
  __typename?: 'Citizenship';
  CODE?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Value1?: Maybe<Scalars['String']>;
};

export type Client = {
  __typename?: 'Client';
  addressResidence?: Maybe<Scalars['Int']>;
  bithday?: Maybe<Scalars['ISO8601Date']>;
  cloneUser?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  foreigner?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  internationalPassport?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  passport?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  placeBirth?: Maybe<Scalars['Int']>;
  policeOms?: Maybe<Scalars['String']>;
  secondname?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['ISO8601DateTime'];
  userId?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of ClientCreate */
export type ClientCreateInput = {
  bithday?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  cloneUser?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  foreigner?: InputMaybe<Scalars['Boolean']>;
  lastname?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  policeOms?: InputMaybe<Scalars['String']>;
  secondname?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['Boolean']>;
  token: Scalars['String'];
};

/** Autogenerated return type of ClientCreate */
export type ClientCreatePayload = {
  __typename?: 'ClientCreatePayload';
  clientList?: Maybe<Array<ClientList>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
};

/** Autogenerated input type of ClientDestroy */
export type ClientDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  token: Scalars['String'];
};

/** Autogenerated return type of ClientDestroy */
export type ClientDestroyPayload = {
  __typename?: 'ClientDestroyPayload';
  clientList?: Maybe<Array<ClientList>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
};

export type ClientInput = {
  addressResidence?: InputMaybe<Scalars['Int']>;
  bithday?: InputMaybe<Scalars['ISO8601Date']>;
  cloneUser?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  foreigner?: InputMaybe<Scalars['Boolean']>;
  internationalPassport?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  placeBirth?: InputMaybe<Scalars['Int']>;
  policeOms?: InputMaybe<Scalars['String']>;
  secondname?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type ClientList = {
  __typename?: 'ClientList';
  bithday?: Maybe<Scalars['String']>;
  cloneUser?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  foreigner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  lastname?: Maybe<Scalars['String']>;
  passport?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  policeOms?: Maybe<Scalars['String']>;
  secondname?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of ClientUpdate */
export type ClientUpdateInput = {
  bithday?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  cloneUser?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  foreigner?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lastname?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  policeOms?: InputMaybe<Scalars['String']>;
  secondname?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['Boolean']>;
  token: Scalars['String'];
};

/** Autogenerated return type of ClientUpdate */
export type ClientUpdatePayload = {
  __typename?: 'ClientUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
};

export type ClinicDms = {
  __typename?: 'ClinicDms';
  active?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  filial?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  logotype?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ClinicDmsCreate */
export type ClinicDmsCreateInput = {
  active: Scalars['Boolean'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  logotype?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  token: Scalars['String'];
};

/** Autogenerated return type of ClinicDmsCreate */
export type ClinicDmsCreatePayload = {
  __typename?: 'ClinicDmsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
};

/** Autogenerated input type of ClinicDmsDelete */
export type ClinicDmsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  token: Scalars['String'];
};

/** Autogenerated return type of ClinicDmsDelete */
export type ClinicDmsDeletePayload = {
  __typename?: 'ClinicDmsDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
};

/** Autogenerated input type of ClinicDmsUpdate */
export type ClinicDmsUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  logotype?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

/** Autogenerated return type of ClinicDmsUpdate */
export type ClinicDmsUpdatePayload = {
  __typename?: 'ClinicDmsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
};

export type Country = {
  __typename?: 'Country';
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  pari?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CountryCurrency = {
  __typename?: 'CountryCurrency';
  countryListInsurancesId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['ISO8601DateTime'];
  currencyListInsurancesId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type CountryCurrencyInput = {
  countryListInsurancesId?: InputMaybe<Scalars['Int']>;
  currencyListInsurancesId?: InputMaybe<Scalars['Int']>;
};

export type CountryListInsurance = {
  __typename?: 'CountryListInsurance';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  value?: Maybe<Scalars['String']>;
  vzrInsuranceCompaniesId?: Maybe<Scalars['Int']>;
  vzrListCountriesId?: Maybe<Scalars['Int']>;
};

export type CountryListInsuranceInput = {
  label?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
  vzrInsuranceCompaniesId?: InputMaybe<Scalars['Int']>;
  vzrListCountriesId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated input type of CreateAttachment */
export type CreateAttachmentInput = {
  attribute: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  relatedId: Scalars['Int'];
  relatedType: Scalars['String'];
  signedId: Scalars['String'];
};

export type CreateAttachmentOutput = {
  __typename?: 'CreateAttachmentOutput';
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateAttachments */
export type CreateAttachmentsInput = {
  attribute: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  relatedId: Scalars['Int'];
  relatedType: Scalars['String'];
  signedIds: Array<Scalars['String']>;
};

export type CreateAttachmentsOutput = {
  __typename?: 'CreateAttachmentsOutput';
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateClient */
export type CreateClientInput = {
  attributes: ClientInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateClient */
export type CreateClientPayload = {
  __typename?: 'CreateClientPayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateCountryCurrency */
export type CreateCountryCurrencyInput = {
  attributes: CountryCurrencyInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateCountryCurrency */
export type CreateCountryCurrencyPayload = {
  __typename?: 'CreateCountryCurrencyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  countryCurrency?: Maybe<CountryCurrency>;
};

/** Autogenerated input type of CreateCountryListInsurance */
export type CreateCountryListInsuranceInput = {
  attributes: CountryListInsuranceInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateCountryListInsurance */
export type CreateCountryListInsurancePayload = {
  __typename?: 'CreateCountryListInsurancePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  countryListInsurance?: Maybe<CountryListInsurance>;
};

/** Autogenerated input type of CreateCurrencyListInsurance */
export type CreateCurrencyListInsuranceInput = {
  attributes: CurrencyListInsuranceInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateCurrencyListInsurance */
export type CreateCurrencyListInsurancePayload = {
  __typename?: 'CreateCurrencyListInsurancePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  currencyListInsurance?: Maybe<CurrencyListInsurance>;
};

export type CreateDirectUploadInput = {
  byte_size: Scalars['Int'];
  checksum: Scalars['String'];
  content_type: Scalars['String'];
  filename: Scalars['String'];
};

export type CreateDirectUploadOutput = {
  __typename?: 'CreateDirectUploadOutput';
  direct_upload?: Maybe<Scalars['jsonb']>;
};

/** Autogenerated input type of CreateListCountryProgram */
export type CreateListCountryProgramInput = {
  attributes: ListCountryProgramInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateListCountryProgram */
export type CreateListCountryProgramPayload = {
  __typename?: 'CreateListCountryProgramPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  listCountryProgram?: Maybe<ListCountryProgram>;
};

/** Autogenerated input type of CreateProgramInsurance */
export type CreateProgramInsuranceInput = {
  attributes: ProgramInsuranceInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateProgramInsurance */
export type CreateProgramInsurancePayload = {
  __typename?: 'CreateProgramInsurancePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  programInsurance?: Maybe<ProgramInsurance>;
};

/** Autogenerated input type of CreatePromoCode */
export type CreatePromoCodeInput = {
  attributes: PromoCodeInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreatePromoCode */
export type CreatePromoCodePayload = {
  __typename?: 'CreatePromoCodePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  promoCode?: Maybe<PromoCode>;
};

/** Autogenerated input type of CreateSubscriber */
export type CreateSubscriberInput = {
  attributes: SubscriberInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateSubscriber */
export type CreateSubscriberPayload = {
  __typename?: 'CreateSubscriberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  subscriber?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateSumMedicalList */
export type CreateSumMedicalListInput = {
  attributes: SumMedicalListInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateSumMedicalList */
export type CreateSumMedicalListPayload = {
  __typename?: 'CreateSumMedicalListPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  sumMedicalList?: Maybe<SumMedicalList>;
};

/** Autogenerated input type of CreateUser */
export type CreateUserInput = {
  attributes: UserInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateUser */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of CreateVzrInsuranceCompany */
export type CreateVzrInsuranceCompanyInput = {
  attributes: VzrInsuranceCompanyInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateVzrInsuranceCompany */
export type CreateVzrInsuranceCompanyPayload = {
  __typename?: 'CreateVzrInsuranceCompanyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  vzrInsuranceCompany?: Maybe<VzrInsuranceCompany>;
};

/** Autogenerated input type of CreateVzrListCountry */
export type CreateVzrListCountryInput = {
  attributes: VzrListCountryInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateVzrListCountry */
export type CreateVzrListCountryPayload = {
  __typename?: 'CreateVzrListCountryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  vzrListCountry?: Maybe<VzrListCountry>;
};

/** Autogenerated input type of CreateVzrListCurrency */
export type CreateVzrListCurrencyInput = {
  attributes: VzrListCurrencyInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateVzrListCurrency */
export type CreateVzrListCurrencyPayload = {
  __typename?: 'CreateVzrListCurrencyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  vzrListCurrency?: Maybe<VzrListCurrency>;
};

export type CurrencyList = {
  __typename?: 'CurrencyList';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CurrencyListInsurance = {
  __typename?: 'CurrencyListInsurance';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  value?: Maybe<Scalars['String']>;
  vzrInsuranceCompaniesId?: Maybe<Scalars['Int']>;
};

export type CurrencyListInsuranceInput = {
  label?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
  vzrInsuranceCompaniesId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated input type of DeleteClient */
export type DeleteClientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteClient */
export type DeleteClientPayload = {
  __typename?: 'DeleteClientPayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DeleteCountryCurrency */
export type DeleteCountryCurrencyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteCountryCurrency */
export type DeleteCountryCurrencyPayload = {
  __typename?: 'DeleteCountryCurrencyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  countryCurrency?: Maybe<CountryCurrency>;
};

/** Autogenerated input type of DeleteCountryListInsurance */
export type DeleteCountryListInsuranceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteCountryListInsurance */
export type DeleteCountryListInsurancePayload = {
  __typename?: 'DeleteCountryListInsurancePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  countryListInsurance?: Maybe<CountryListInsurance>;
};

/** Autogenerated input type of DeleteCurrencyListInsurance */
export type DeleteCurrencyListInsuranceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteCurrencyListInsurance */
export type DeleteCurrencyListInsurancePayload = {
  __typename?: 'DeleteCurrencyListInsurancePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  currencyListInsurance?: Maybe<CurrencyListInsurance>;
};

/** Autogenerated input type of DeleteListCountryProgram */
export type DeleteListCountryProgramInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteListCountryProgram */
export type DeleteListCountryProgramPayload = {
  __typename?: 'DeleteListCountryProgramPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  listCountryProgram?: Maybe<ListCountryProgram>;
};

/** Autogenerated input type of DeleteOrderPolice */
export type DeleteOrderPoliceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteOrderPolice */
export type DeleteOrderPolicePayload = {
  __typename?: 'DeleteOrderPolicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  orderPolice?: Maybe<OrderPolice>;
};

/** Autogenerated input type of DeleteProgramInsurance */
export type DeleteProgramInsuranceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteProgramInsurance */
export type DeleteProgramInsurancePayload = {
  __typename?: 'DeleteProgramInsurancePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  programInsurance?: Maybe<ProgramInsurance>;
};

/** Autogenerated input type of DeletePromoCode */
export type DeletePromoCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeletePromoCode */
export type DeletePromoCodePayload = {
  __typename?: 'DeletePromoCodePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  promoCode?: Maybe<PromoCode>;
};

/** Autogenerated input type of DeleteSumMedicalList */
export type DeleteSumMedicalListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteSumMedicalList */
export type DeleteSumMedicalListPayload = {
  __typename?: 'DeleteSumMedicalListPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  sumMedicalList?: Maybe<SumMedicalList>;
};

/** Autogenerated input type of DeleteUser */
export type DeleteUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteUser */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of DeleteVzrInsuranceCompany */
export type DeleteVzrInsuranceCompanyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteVzrInsuranceCompany */
export type DeleteVzrInsuranceCompanyPayload = {
  __typename?: 'DeleteVzrInsuranceCompanyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  vzrInsuranceCompany?: Maybe<VzrInsuranceCompany>;
};

/** Autogenerated input type of DeleteVzrListCountry */
export type DeleteVzrListCountryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteVzrListCountry */
export type DeleteVzrListCountryPayload = {
  __typename?: 'DeleteVzrListCountryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  vzrListCountry?: Maybe<VzrListCountry>;
};

/** Autogenerated input type of DeleteVzrListCurrency */
export type DeleteVzrListCurrencyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteVzrListCurrency */
export type DeleteVzrListCurrencyPayload = {
  __typename?: 'DeleteVzrListCurrencyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  vzrListCurrency?: Maybe<VzrListCurrency>;
};

/** Autogenerated input type of FilialDmsCreate */
export type FilialDmsCreateInput = {
  /** Активность филиала клиники */
  active: Scalars['Boolean'];
  /** Адрес филиала клиники */
  address: Scalars['String'];
  /** Город филиала клиники */
  city: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID клиники */
  clinicId: Scalars['Int'];
  /** Описание филиала клиники */
  description?: InputMaybe<Scalars['String']>;
  /** Список загруженных документов и лицензий, где нужно передать signied_id, полученных при загрузки с помощью DireactUpload (npm @rails/atcivestorage)  */
  document?: InputMaybe<Array<Scalars['String']>>;
  /** Контактный емаил филиала */
  email: Scalars['String'];
  /** Ближайшее метро филиала клиники */
  metro?: InputMaybe<Scalars['String']>;
  /** Название филиала клиники */
  name: Scalars['String'];
  /** Режим работы филиала */
  operatingMode?: InputMaybe<Array<OperatingModeInput>>;
  /** Контактный телефон филиала */
  phone: Scalars['String'];
  /** Список загруженных фото филиала, где нужно передать signied_id, полученных при загрузки с помощью DireactUpload (npm @rails/atcivestorage)  */
  photo?: InputMaybe<Array<Scalars['String']>>;
  /** Сайт клиники или ссылка на страницу филиала */
  site: Scalars['String'];
  /** Token авторизации пользователя */
  token: Scalars['String'];
};

/** Autogenerated return type of FilialDmsCreate */
export type FilialDmsCreatePayload = {
  __typename?: 'FilialDmsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
};

/** Autogenerated input type of FilialDmsDelete */
export type FilialDmsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  token: Scalars['String'];
};

/** Autogenerated return type of FilialDmsDelete */
export type FilialDmsDeletePayload = {
  __typename?: 'FilialDmsDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
};

/** Autogenerated input type of FilialDmsPhotoDelete */
export type FilialDmsPhotoDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  token: Scalars['String'];
};

/** Autogenerated return type of FilialDmsPhotoDelete */
export type FilialDmsPhotoDeletePayload = {
  __typename?: 'FilialDmsPhotoDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
};

/** Autogenerated input type of FilialDmsUpdate */
export type FilialDmsUpdateInput = {
  /** Активность филиала клиники */
  active: Scalars['Boolean'];
  /** Адрес филиала клиники */
  address: Scalars['String'];
  /** Город филиала клиники */
  city: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID клиники */
  clinicId: Scalars['Int'];
  /** Описание филиала клиники */
  description?: InputMaybe<Scalars['String']>;
  /** Список загруженных документов и лицензий, где нужно передать signied_id, полученных при загрузки с помощью DireactUpload (npm @rails/atcivestorage)  */
  document?: InputMaybe<Array<Scalars['String']>>;
  /** Контактный емаил филиала */
  email: Scalars['String'];
  /** ID филиала */
  id: Scalars['ID'];
  /** Ближайшее метро филиала клиники */
  metro?: InputMaybe<Scalars['String']>;
  /** Название филиала клиники */
  name: Scalars['String'];
  /** Режим работы филиала */
  operatingMode?: InputMaybe<Array<OperatingModeInput>>;
  /** Контактный телефон филиала */
  phone: Scalars['String'];
  /** Список загруженных фото филиала, где нужно передать signied_id, полученных при загрузки с помощью DireactUpload (npm @rails/atcivestorage)  */
  photo?: InputMaybe<Array<Scalars['String']>>;
  /** Сайт клиники или ссылка на страницу филиала */
  site: Scalars['String'];
  /** Token авторизации пользователя */
  token: Scalars['String'];
};

/** Autogenerated return type of FilialDmsUpdate */
export type FilialDmsUpdatePayload = {
  __typename?: 'FilialDmsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
};

export type Hello = {
  __typename?: 'Hello';
  value?: Maybe<Scalars['String']>;
};

export type InsuranceDms = {
  __typename?: 'InsuranceDms';
  id?: Maybe<Scalars['ID']>;
  logotype?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pathApi?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of InsuranceDmsCreate */
export type InsuranceDmsCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  logotype?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  pathApi: Scalars['String'];
  token: Scalars['String'];
};

/** Autogenerated return type of InsuranceDmsCreate */
export type InsuranceDmsCreatePayload = {
  __typename?: 'InsuranceDmsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  insuranceDms?: Maybe<Array<InsuranceDms>>;
  message: Scalars['String'];
  status: Scalars['String'];
};

/** Autogenerated input type of InsuranceDmsDelete */
export type InsuranceDmsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  token: Scalars['String'];
};

/** Autogenerated return type of InsuranceDmsDelete */
export type InsuranceDmsDeletePayload = {
  __typename?: 'InsuranceDmsDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
};

/** Autogenerated input type of InsuranceDmsUpdate */
export type InsuranceDmsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  logotype?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pathApi?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

/** Autogenerated return type of InsuranceDmsUpdate */
export type InsuranceDmsUpdatePayload = {
  __typename?: 'InsuranceDmsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  insuranceDms?: Maybe<Array<InsuranceDms>>;
  message: Scalars['String'];
  status: Scalars['String'];
};

export type Insurant = {
  __typename?: 'Insurant';
  bithday?: Maybe<Scalars['String']>;
  cloneUser?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Country>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  foreigner?: Maybe<Scalars['Boolean']>;
  lastname?: Maybe<Scalars['String']>;
  passport?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  secondname?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type License = {
  __typename?: 'License';
  abbreviatedNameLicensee?: Maybe<Scalars['String']>;
  activityType?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  addressRegion?: Maybe<Scalars['String']>;
  brandNameLicensee?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  dateDuplicate?: Maybe<Scalars['String']>;
  dateOrder?: Maybe<Scalars['String']>;
  dateRegister?: Maybe<Scalars['String']>;
  dateTermination?: Maybe<Scalars['String']>;
  form?: Maybe<Scalars['String']>;
  fullNameLicensee?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  information?: Maybe<Scalars['String']>;
  informationCancellation?: Maybe<Scalars['String']>;
  informationRegulations?: Maybe<Scalars['String']>;
  informationReissuing?: Maybe<Scalars['String']>;
  informationSuspensionResumption?: Maybe<Scalars['String']>;
  inn?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  numberDuplicate?: Maybe<Scalars['String']>;
  numberOrders?: Maybe<Scalars['Int']>;
  ogrn?: Maybe<Scalars['String']>;
  termination?: Maybe<Scalars['String']>;
};

export type ListCountryProgram = {
  __typename?: 'ListCountryProgram';
  countryListInsurancesId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  programInsurancesId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
  vzrInsuranceCompaniesId?: Maybe<Scalars['Int']>;
};

export type ListCountryProgramInput = {
  countryListInsurancesId?: InputMaybe<Scalars['Int']>;
  programInsurancesId?: InputMaybe<Scalars['Int']>;
  vzrInsuranceCompaniesId?: InputMaybe<Scalars['Int']>;
};

export type MeOutput = {
  __typename?: 'MeOutput';
  avatar?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type OperatingModeInput = {
  /** Рабочий день: true - рабочий день, false - выходной */
  active: Scalars['Boolean'];
  /** Время завершения рабочего дня (формат HH:mm) */
  end: Scalars['String'];
  /** Название дня недели (Пн,Вт,Ср,Чт,Пт,Сб,Вс) */
  label: Scalars['String'];
  /** Время начала рабочего дня (формат HH:mm) */
  start: Scalars['String'];
};

export type OrderPolice = {
  __typename?: 'OrderPolice';
  bonuscard?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  insurant: Insurant;
  insurants: Array<Insurant>;
  paymentUrl?: Maybe<Scalars['String']>;
  policeFile?: Maybe<Scalars['String']>;
  product: Calc;
  promoCodeId?: Maybe<Scalars['Int']>;
  typePolicy?: Maybe<Scalars['String']>;
  uid: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  userId?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of OrderPoliceCreate */
export type OrderPoliceCreateInput = {
  backUrl?: InputMaybe<Scalars['String']>;
  bonuscard?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  insurant?: InputMaybe<Scalars['String']>;
  insurants?: InputMaybe<Scalars['String']>;
  product: Scalars['String'];
  promoCode?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of OrderPoliceCreate */
export type OrderPoliceCreatePayload = {
  __typename?: 'OrderPoliceCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  status: Scalars['String'];
  url: Scalars['String'];
};

export type PariTokenCheck = {
  __typename?: 'PariTokenCheck';
  message?: Maybe<Scalars['String']>;
};

export type PaymentStatus = {
  __typename?: 'PaymentStatus';
  status?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PaymentUrl = {
  __typename?: 'PaymentUrl';
  url?: Maybe<Scalars['String']>;
};

export type Police = {
  __typename?: 'Police';
  insurant?: Maybe<Insurant>;
  insurants?: Maybe<Array<Insurant>>;
  status?: Maybe<Scalars['String']>;
};

export type PoliceData = {
  __typename?: 'PoliceData';
  message?: Maybe<Scalars['String']>;
  policeData?: Maybe<PoliceInfo>;
  status?: Maybe<Scalars['String']>;
};

export type PoliceInfo = {
  __typename?: 'PoliceInfo';
  buy?: Maybe<Scalars['Boolean']>;
  calcid?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  countries?: Maybe<Array<Country>>;
  currency?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  enddate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  insured?: Maybe<Array<Scalars['Int']>>;
  policeid?: Maybe<Scalars['String']>;
  premiumrur?: Maybe<Scalars['Float']>;
  program?: Maybe<Scalars['String']>;
  sport?: Maybe<Scalars['String']>;
  startdate?: Maybe<Scalars['String']>;
  summedicine?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type PoliceList = {
  __typename?: 'PoliceList';
  message?: Maybe<Scalars['String']>;
  policeList?: Maybe<Array<PoliceInfo>>;
  status?: Maybe<Scalars['String']>;
};

export type Post = {
  __typename?: 'Post';
  content?: Maybe<PostTitle>;
  date?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  title?: Maybe<PostTitle>;
};

export type PostTitle = {
  __typename?: 'PostTitle';
  protected?: Maybe<Scalars['String']>;
  rendered?: Maybe<Scalars['String']>;
};

export type ProductRecalc = {
  __typename?: 'ProductRecalc';
  calcid?: Maybe<Scalars['Int']>;
  company?: Maybe<Scalars['String']>;
  countries?: Maybe<Array<Country>>;
  currency?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  enddate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insured?: Maybe<Array<Scalars['Int']>>;
  premiumrur?: Maybe<Scalars['Float']>;
  program?: Maybe<Scalars['String']>;
  sport?: Maybe<Scalars['Float']>;
  startdate?: Maybe<Scalars['String']>;
  summedicine?: Maybe<Scalars['Int']>;
};

export type ProgramInsurance = {
  __typename?: 'ProgramInsurance';
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ProgramInsuranceInput = {
  description?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
};

export type ProgramList = {
  __typename?: 'ProgramList';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PromoCode = {
  __typename?: 'PromoCode';
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  discount?: Maybe<Scalars['Int']>;
  expiration?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
  typeCode?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  use?: Maybe<Scalars['Boolean']>;
};

export type PromoCodeInput = {
  code?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['Int']>;
  expiration?: InputMaybe<Scalars['ISO8601Date']>;
  typeCode?: InputMaybe<Scalars['String']>;
  use?: InputMaybe<Scalars['Boolean']>;
};

export type RestrictionsCountryList = {
  __typename?: 'RestrictionsCountryList';
  airline?: Maybe<RestrictionsStatusDesciption>;
  carantine?: Maybe<RestrictionsStatusDesciption>;
  conditions?: Maybe<Scalars['String']>;
  countries?: Maybe<Scalars['String']>;
  input?: Maybe<RestrictionsStatusDesciption>;
  visa?: Maybe<Scalars['String']>;
  visaInfo?: Maybe<Scalars['String']>;
};

export type RestrictionsStatusDesciption = {
  __typename?: 'RestrictionsStatusDesciption';
  desciption?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  transfers?: Maybe<Scalars['Boolean']>;
};

export type SearchVzr = {
  __typename?: 'SearchVzr';
  currency?: Maybe<Array<CurrencyList>>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  insured?: Maybe<Array<Scalars['Int']>>;
  program?: Maybe<Scalars['String']>;
  programs?: Maybe<Array<ProgramList>>;
  summedical?: Maybe<Array<Summedical>>;
};

export type SignIn = {
  __typename?: 'SignIn';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type SignUp = {
  __typename?: 'SignUp';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Static = {
  __typename?: 'Static';
  policeCount?: Maybe<Scalars['Int']>;
  totalPrice?: Maybe<Scalars['Float']>;
  userCount?: Maybe<Scalars['Int']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type Subscriber = {
  __typename?: 'Subscriber';
  createdAt: Scalars['ISO8601DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type SubscriberInput = {
  email?: InputMaybe<Scalars['String']>;
};

export type SumMedicalList = {
  __typename?: 'SumMedicalList';
  createdAt: Scalars['ISO8601DateTime'];
  currencyListInsuranceId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  programInsurancesId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
  value: Scalars['JSON'];
};

export type SumMedicalListInput = {
  currencyListInsuranceId?: InputMaybe<Scalars['Int']>;
  programInsurancesId?: InputMaybe<Scalars['Int']>;
  value: Scalars['JSON'];
};

export type Summedical = {
  __typename?: 'Summedical';
  currency?: Maybe<Scalars['String']>;
  value?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of UpdateClient */
export type UpdateClientInput = {
  attributes: ClientInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of UpdateClient */
export type UpdateClientPayload = {
  __typename?: 'UpdateClientPayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateCountryCurrency */
export type UpdateCountryCurrencyInput = {
  attributes: CountryCurrencyInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of UpdateCountryCurrency */
export type UpdateCountryCurrencyPayload = {
  __typename?: 'UpdateCountryCurrencyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  countryCurrency?: Maybe<CountryCurrency>;
};

/** Autogenerated input type of UpdateCountryListInsurance */
export type UpdateCountryListInsuranceInput = {
  attributes: CountryListInsuranceInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of UpdateCountryListInsurance */
export type UpdateCountryListInsurancePayload = {
  __typename?: 'UpdateCountryListInsurancePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  countryListInsurance?: Maybe<CountryListInsurance>;
};

/** Autogenerated input type of UpdateCurrencyListInsurance */
export type UpdateCurrencyListInsuranceInput = {
  attributes: CurrencyListInsuranceInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of UpdateCurrencyListInsurance */
export type UpdateCurrencyListInsurancePayload = {
  __typename?: 'UpdateCurrencyListInsurancePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  currencyListInsurance?: Maybe<CurrencyListInsurance>;
};

/** Autogenerated input type of UpdateListCountryProgram */
export type UpdateListCountryProgramInput = {
  attributes: ListCountryProgramInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of UpdateListCountryProgram */
export type UpdateListCountryProgramPayload = {
  __typename?: 'UpdateListCountryProgramPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  listCountryProgram?: Maybe<ListCountryProgram>;
};

/** Autogenerated input type of UpdateProgramInsurance */
export type UpdateProgramInsuranceInput = {
  attributes: ProgramInsuranceInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of UpdateProgramInsurance */
export type UpdateProgramInsurancePayload = {
  __typename?: 'UpdateProgramInsurancePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  programInsurance?: Maybe<ProgramInsurance>;
};

/** Autogenerated input type of UpdatePromoCode */
export type UpdatePromoCodeInput = {
  attributes: PromoCodeInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of UpdatePromoCode */
export type UpdatePromoCodePayload = {
  __typename?: 'UpdatePromoCodePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  promoCode?: Maybe<PromoCode>;
};

/** Autogenerated input type of UpdateSumMedicalList */
export type UpdateSumMedicalListInput = {
  attributes: SumMedicalListInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of UpdateSumMedicalList */
export type UpdateSumMedicalListPayload = {
  __typename?: 'UpdateSumMedicalListPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  sumMedicalList?: Maybe<SumMedicalList>;
};

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  attributes: UserInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

export type UpdateUserOutput = {
  __typename?: 'UpdateUserOutput';
  avatar?: Maybe<Scalars['jsonb']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateUser */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type UpdateUserUsersPkColumnsInput = {
  id: Scalars['bigint'];
};

export type UpdateUserUsersSetInput = {
  allow_password_change?: InputMaybe<Scalars['Boolean']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']>;
  confirmation_token?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  encrypted_password?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  image?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  otp_secret_key?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_token?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  unconfirmed_email?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  username?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateVzrInsuranceCompany */
export type UpdateVzrInsuranceCompanyInput = {
  attributes: VzrInsuranceCompanyInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of UpdateVzrInsuranceCompany */
export type UpdateVzrInsuranceCompanyPayload = {
  __typename?: 'UpdateVzrInsuranceCompanyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  vzrInsuranceCompany?: Maybe<VzrInsuranceCompany>;
};

/** Autogenerated input type of UpdateVzrListCountry */
export type UpdateVzrListCountryInput = {
  attributes: VzrListCountryInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of UpdateVzrListCountry */
export type UpdateVzrListCountryPayload = {
  __typename?: 'UpdateVzrListCountryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  vzrListCountry?: Maybe<VzrListCountry>;
};

/** Autogenerated input type of UpdateVzrListCurrency */
export type UpdateVzrListCurrencyInput = {
  attributes: VzrListCurrencyInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Autogenerated return type of UpdateVzrListCurrency */
export type UpdateVzrListCurrencyPayload = {
  __typename?: 'UpdateVzrListCurrencyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  vzrListCurrency?: Maybe<VzrListCurrency>;
};

export type User = {
  __typename?: 'User';
  addressResidence?: Maybe<Scalars['String']>;
  bithday?: Maybe<Scalars['ISO8601Date']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  datecode?: Maybe<Scalars['ISO8601DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  foreigner?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  internationalPassport?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  passport?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  placeBirth?: Maybe<Scalars['String']>;
  secondname?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['Boolean']>;
  smscode?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type UserInput = {
  addressResidence?: InputMaybe<Scalars['String']>;
  bithday?: InputMaybe<Scalars['ISO8601Date']>;
  country?: InputMaybe<Scalars['String']>;
  datecode?: InputMaybe<Scalars['ISO8601DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  foreigner?: InputMaybe<Scalars['Boolean']>;
  internationalPassport?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  placeBirth?: InputMaybe<Scalars['String']>;
  secondname?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['Boolean']>;
  smscode?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type VzrInsuranceCompany = {
  __typename?: 'VzrInsuranceCompany';
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  site?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  value?: Maybe<Scalars['String']>;
};

export type VzrInsuranceCompanyInput = {
  description?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  site?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type VzrListCountry = {
  __typename?: 'VzrListCountry';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  value?: Maybe<Scalars['String']>;
};

export type VzrListCountryInput = {
  label?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type VzrListCurrency = {
  __typename?: 'VzrListCurrency';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  value?: Maybe<Scalars['String']>;
};

export type VzrListCurrencyInput = {
  label?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Work = {
  __typename?: 'Work';
  id?: Maybe<Scalars['ID']>;
  workCode?: Maybe<WorkCode>;
};

export type WorkCode = {
  __typename?: 'WorkCode';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type AuthFindUserInput = {
  email: Scalars['String'];
  phone: Scalars['String'];
};

export type AuthFindUserOutput = {
  __typename?: 'authFindUserOutput';
  id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "users" */
export type Auth_Users = {
  __typename?: 'auth_users';
  /** Адрес проживания */
  address_residence?: Maybe<Scalars['String']>;
  allow_password_change?: Maybe<Scalars['Boolean']>;
  /** Баланс */
  balance?: Maybe<Scalars['float8']>;
  /** Дата рождения */
  birthday?: Maybe<Scalars['date']>;
  /** Гражданство */
  citizenship?: Maybe<Scalars['String']>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamp']>;
  /** Страна */
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  date_send_code?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  encrypted_password: Scalars['String'];
  /** Имя */
  first_name?: Maybe<Scalars['String']>;
  foreign_passport?: Maybe<Scalars['String']>;
  /** Иностранец */
  foreigner?: Maybe<Scalars['Boolean']>;
  id: Scalars['bigint'];
  /** Загранпаспорт */
  international_passport?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  /** Фамилия */
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  oms?: Maybe<Scalars['String']>;
  otp_secret_key?: Maybe<Scalars['String']>;
  /** Паспорт */
  passport?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  /** Место рождения */
  place_birth?: Maybe<Scalars['String']>;
  remember_created_at?: Maybe<Scalars['timestamp']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']>;
  reset_password_token?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  /** Отчество */
  second_name?: Maybe<Scalars['String']>;
  /** Пол */
  sex?: Maybe<Scalars['Boolean']>;
  unconfirmed_email?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  username?: Maybe<Scalars['String']>;
};

/** aggregated selection of "users" */
export type Auth_Users_Aggregate = {
  __typename?: 'auth_users_aggregate';
  aggregate?: Maybe<Auth_Users_Aggregate_Fields>;
  nodes: Array<Auth_Users>;
};

/** aggregate fields of "users" */
export type Auth_Users_Aggregate_Fields = {
  __typename?: 'auth_users_aggregate_fields';
  avg?: Maybe<Auth_Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Auth_Users_Max_Fields>;
  min?: Maybe<Auth_Users_Min_Fields>;
  stddev?: Maybe<Auth_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Auth_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Auth_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Auth_Users_Sum_Fields>;
  var_pop?: Maybe<Auth_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Auth_Users_Var_Samp_Fields>;
  variance?: Maybe<Auth_Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Auth_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Auth_Users_Avg_Fields = {
  __typename?: 'auth_users_avg_fields';
  /** Баланс */
  balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Auth_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Users_Bool_Exp>>;
  _not?: InputMaybe<Auth_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Users_Bool_Exp>>;
  address_residence?: InputMaybe<String_Comparison_Exp>;
  allow_password_change?: InputMaybe<Boolean_Comparison_Exp>;
  balance?: InputMaybe<Float8_Comparison_Exp>;
  birthday?: InputMaybe<Date_Comparison_Exp>;
  citizenship?: InputMaybe<String_Comparison_Exp>;
  confirmation_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  confirmation_token?: InputMaybe<String_Comparison_Exp>;
  confirmed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  date_send_code?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  encrypted_password?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  foreign_passport?: InputMaybe<String_Comparison_Exp>;
  foreigner?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  international_passport?: InputMaybe<String_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  oms?: InputMaybe<String_Comparison_Exp>;
  otp_secret_key?: InputMaybe<String_Comparison_Exp>;
  passport?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  place_birth?: InputMaybe<String_Comparison_Exp>;
  remember_created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  reset_password_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  reset_password_token?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  second_name?: InputMaybe<String_Comparison_Exp>;
  sex?: InputMaybe<Boolean_Comparison_Exp>;
  unconfirmed_email?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Auth_Users_Constraint {
  /** unique or primary key constraint on columns "confirmation_token" */
  IndexUsersOnConfirmationToken = 'index_users_on_confirmation_token',
  /** unique or primary key constraint on columns "email" */
  IndexUsersOnEmail = 'index_users_on_email',
  /** unique or primary key constraint on columns "reset_password_token" */
  IndexUsersOnResetPasswordToken = 'index_users_on_reset_password_token',
  /** unique or primary key constraint on columns "username" */
  IndexUsersOnUsername = 'index_users_on_username',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** input type for incrementing numeric columns in table "users" */
export type Auth_Users_Inc_Input = {
  /** Баланс */
  balance?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "users" */
export type Auth_Users_Insert_Input = {
  /** Адрес проживания */
  address_residence?: InputMaybe<Scalars['String']>;
  allow_password_change?: InputMaybe<Scalars['Boolean']>;
  /** Баланс */
  balance?: InputMaybe<Scalars['float8']>;
  /** Дата рождения */
  birthday?: InputMaybe<Scalars['date']>;
  /** Гражданство */
  citizenship?: InputMaybe<Scalars['String']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']>;
  confirmation_token?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']>;
  /** Страна */
  country?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  date_send_code?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  encrypted_password?: InputMaybe<Scalars['String']>;
  /** Имя */
  first_name?: InputMaybe<Scalars['String']>;
  foreign_passport?: InputMaybe<Scalars['String']>;
  /** Иностранец */
  foreigner?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  /** Загранпаспорт */
  international_passport?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  /** Фамилия */
  last_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  oms?: InputMaybe<Scalars['String']>;
  otp_secret_key?: InputMaybe<Scalars['String']>;
  /** Паспорт */
  passport?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  /** Место рождения */
  place_birth?: InputMaybe<Scalars['String']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_token?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  /** Отчество */
  second_name?: InputMaybe<Scalars['String']>;
  /** Пол */
  sex?: InputMaybe<Scalars['Boolean']>;
  unconfirmed_email?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Auth_Users_Max_Fields = {
  __typename?: 'auth_users_max_fields';
  /** Адрес проживания */
  address_residence?: Maybe<Scalars['String']>;
  /** Баланс */
  balance?: Maybe<Scalars['float8']>;
  /** Дата рождения */
  birthday?: Maybe<Scalars['date']>;
  /** Гражданство */
  citizenship?: Maybe<Scalars['String']>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamp']>;
  /** Страна */
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_send_code?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  encrypted_password?: Maybe<Scalars['String']>;
  /** Имя */
  first_name?: Maybe<Scalars['String']>;
  foreign_passport?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  /** Загранпаспорт */
  international_passport?: Maybe<Scalars['String']>;
  /** Фамилия */
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  oms?: Maybe<Scalars['String']>;
  otp_secret_key?: Maybe<Scalars['String']>;
  /** Паспорт */
  passport?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  /** Место рождения */
  place_birth?: Maybe<Scalars['String']>;
  remember_created_at?: Maybe<Scalars['timestamp']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']>;
  reset_password_token?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  /** Отчество */
  second_name?: Maybe<Scalars['String']>;
  unconfirmed_email?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Auth_Users_Min_Fields = {
  __typename?: 'auth_users_min_fields';
  /** Адрес проживания */
  address_residence?: Maybe<Scalars['String']>;
  /** Баланс */
  balance?: Maybe<Scalars['float8']>;
  /** Дата рождения */
  birthday?: Maybe<Scalars['date']>;
  /** Гражданство */
  citizenship?: Maybe<Scalars['String']>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamp']>;
  /** Страна */
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_send_code?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  encrypted_password?: Maybe<Scalars['String']>;
  /** Имя */
  first_name?: Maybe<Scalars['String']>;
  foreign_passport?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  /** Загранпаспорт */
  international_passport?: Maybe<Scalars['String']>;
  /** Фамилия */
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  oms?: Maybe<Scalars['String']>;
  otp_secret_key?: Maybe<Scalars['String']>;
  /** Паспорт */
  passport?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  /** Место рождения */
  place_birth?: Maybe<Scalars['String']>;
  remember_created_at?: Maybe<Scalars['timestamp']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']>;
  reset_password_token?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  /** Отчество */
  second_name?: Maybe<Scalars['String']>;
  unconfirmed_email?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  username?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "users" */
export type Auth_Users_Mutation_Response = {
  __typename?: 'auth_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Users>;
};

/** on_conflict condition type for table "users" */
export type Auth_Users_On_Conflict = {
  constraint: Auth_Users_Constraint;
  update_columns?: Array<Auth_Users_Update_Column>;
  where?: InputMaybe<Auth_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Auth_Users_Order_By = {
  address_residence?: InputMaybe<Order_By>;
  allow_password_change?: InputMaybe<Order_By>;
  balance?: InputMaybe<Order_By>;
  birthday?: InputMaybe<Order_By>;
  citizenship?: InputMaybe<Order_By>;
  confirmation_sent_at?: InputMaybe<Order_By>;
  confirmation_token?: InputMaybe<Order_By>;
  confirmed_at?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_send_code?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  encrypted_password?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  foreign_passport?: InputMaybe<Order_By>;
  foreigner?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  international_passport?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  oms?: InputMaybe<Order_By>;
  otp_secret_key?: InputMaybe<Order_By>;
  passport?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  place_birth?: InputMaybe<Order_By>;
  remember_created_at?: InputMaybe<Order_By>;
  reset_password_sent_at?: InputMaybe<Order_By>;
  reset_password_token?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  second_name?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  unconfirmed_email?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth_users */
export type Auth_Users_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "users" */
export enum Auth_Users_Select_Column {
  /** column name */
  AddressResidence = 'address_residence',
  /** column name */
  AllowPasswordChange = 'allow_password_change',
  /** column name */
  Balance = 'balance',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  Citizenship = 'citizenship',
  /** column name */
  ConfirmationSentAt = 'confirmation_sent_at',
  /** column name */
  ConfirmationToken = 'confirmation_token',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  Country = 'country',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateSendCode = 'date_send_code',
  /** column name */
  Email = 'email',
  /** column name */
  EncryptedPassword = 'encrypted_password',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  ForeignPassport = 'foreign_passport',
  /** column name */
  Foreigner = 'foreigner',
  /** column name */
  Id = 'id',
  /** column name */
  InternationalPassport = 'international_passport',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Name = 'name',
  /** column name */
  Oms = 'oms',
  /** column name */
  OtpSecretKey = 'otp_secret_key',
  /** column name */
  Passport = 'passport',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PlaceBirth = 'place_birth',
  /** column name */
  RememberCreatedAt = 'remember_created_at',
  /** column name */
  ResetPasswordSentAt = 'reset_password_sent_at',
  /** column name */
  ResetPasswordToken = 'reset_password_token',
  /** column name */
  Role = 'role',
  /** column name */
  SecondName = 'second_name',
  /** column name */
  Sex = 'sex',
  /** column name */
  UnconfirmedEmail = 'unconfirmed_email',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "users" */
export type Auth_Users_Set_Input = {
  /** Адрес проживания */
  address_residence?: InputMaybe<Scalars['String']>;
  allow_password_change?: InputMaybe<Scalars['Boolean']>;
  /** Баланс */
  balance?: InputMaybe<Scalars['float8']>;
  /** Дата рождения */
  birthday?: InputMaybe<Scalars['date']>;
  /** Гражданство */
  citizenship?: InputMaybe<Scalars['String']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']>;
  confirmation_token?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']>;
  /** Страна */
  country?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  date_send_code?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  encrypted_password?: InputMaybe<Scalars['String']>;
  /** Имя */
  first_name?: InputMaybe<Scalars['String']>;
  foreign_passport?: InputMaybe<Scalars['String']>;
  /** Иностранец */
  foreigner?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  /** Загранпаспорт */
  international_passport?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  /** Фамилия */
  last_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  oms?: InputMaybe<Scalars['String']>;
  otp_secret_key?: InputMaybe<Scalars['String']>;
  /** Паспорт */
  passport?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  /** Место рождения */
  place_birth?: InputMaybe<Scalars['String']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_token?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  /** Отчество */
  second_name?: InputMaybe<Scalars['String']>;
  /** Пол */
  sex?: InputMaybe<Scalars['Boolean']>;
  unconfirmed_email?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Auth_Users_Stddev_Fields = {
  __typename?: 'auth_users_stddev_fields';
  /** Баланс */
  balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Auth_Users_Stddev_Pop_Fields = {
  __typename?: 'auth_users_stddev_pop_fields';
  /** Баланс */
  balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Auth_Users_Stddev_Samp_Fields = {
  __typename?: 'auth_users_stddev_samp_fields';
  /** Баланс */
  balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "auth_users" */
export type Auth_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Users_Stream_Cursor_Value_Input = {
  /** Адрес проживания */
  address_residence?: InputMaybe<Scalars['String']>;
  allow_password_change?: InputMaybe<Scalars['Boolean']>;
  /** Баланс */
  balance?: InputMaybe<Scalars['float8']>;
  /** Дата рождения */
  birthday?: InputMaybe<Scalars['date']>;
  /** Гражданство */
  citizenship?: InputMaybe<Scalars['String']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']>;
  confirmation_token?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']>;
  /** Страна */
  country?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  date_send_code?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  encrypted_password?: InputMaybe<Scalars['String']>;
  /** Имя */
  first_name?: InputMaybe<Scalars['String']>;
  foreign_passport?: InputMaybe<Scalars['String']>;
  /** Иностранец */
  foreigner?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  /** Загранпаспорт */
  international_passport?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  /** Фамилия */
  last_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  oms?: InputMaybe<Scalars['String']>;
  otp_secret_key?: InputMaybe<Scalars['String']>;
  /** Паспорт */
  passport?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  /** Место рождения */
  place_birth?: InputMaybe<Scalars['String']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_token?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  /** Отчество */
  second_name?: InputMaybe<Scalars['String']>;
  /** Пол */
  sex?: InputMaybe<Scalars['Boolean']>;
  unconfirmed_email?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Auth_Users_Sum_Fields = {
  __typename?: 'auth_users_sum_fields';
  /** Баланс */
  balance?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "users" */
export enum Auth_Users_Update_Column {
  /** column name */
  AddressResidence = 'address_residence',
  /** column name */
  AllowPasswordChange = 'allow_password_change',
  /** column name */
  Balance = 'balance',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  Citizenship = 'citizenship',
  /** column name */
  ConfirmationSentAt = 'confirmation_sent_at',
  /** column name */
  ConfirmationToken = 'confirmation_token',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  Country = 'country',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateSendCode = 'date_send_code',
  /** column name */
  Email = 'email',
  /** column name */
  EncryptedPassword = 'encrypted_password',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  ForeignPassport = 'foreign_passport',
  /** column name */
  Foreigner = 'foreigner',
  /** column name */
  Id = 'id',
  /** column name */
  InternationalPassport = 'international_passport',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Name = 'name',
  /** column name */
  Oms = 'oms',
  /** column name */
  OtpSecretKey = 'otp_secret_key',
  /** column name */
  Passport = 'passport',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PlaceBirth = 'place_birth',
  /** column name */
  RememberCreatedAt = 'remember_created_at',
  /** column name */
  ResetPasswordSentAt = 'reset_password_sent_at',
  /** column name */
  ResetPasswordToken = 'reset_password_token',
  /** column name */
  Role = 'role',
  /** column name */
  SecondName = 'second_name',
  /** column name */
  Sex = 'sex',
  /** column name */
  UnconfirmedEmail = 'unconfirmed_email',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

export type Auth_Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Auth_Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Users_Set_Input>;
  where: Auth_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Auth_Users_Var_Pop_Fields = {
  __typename?: 'auth_users_var_pop_fields';
  /** Баланс */
  balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Auth_Users_Var_Samp_Fields = {
  __typename?: 'auth_users_var_samp_fields';
  /** Баланс */
  balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Auth_Users_Variance_Fields = {
  __typename?: 'auth_users_variance_fields';
  /** Баланс */
  balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

export type BillingCheckPromoCodeInput = {
  code: Scalars['String'];
};

export type BillingCheckPromoCodeOutput = {
  __typename?: 'billingCheckPromoCodeOutput';
  discount: Scalars['Int'];
  error: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type BillingDmsPaytureGetStatusInput = {
  order_id: Scalars['String'];
};

export type BillingDmsPaytureGetStatusOutput = {
  __typename?: 'billingDmsPaytureGetStatusOutput';
  status: Scalars['String'];
  url: Scalars['String'];
};

export type BillingGetPaytureOrderInput = {
  order_id: Scalars['String'];
};

export type BillingGetPaytureOrderOutput = {
  __typename?: 'billingGetPaytureOrderOutput';
  order_police: Scalars['jsonb'];
  pay_link: Scalars['jsonb'];
};

export type BillingOrderPoliceCreateInput = {
  back_url: Scalars['String'];
  bonuscard?: InputMaybe<Scalars['String']>;
  insurant: Scalars['String'];
  insurants: Scalars['String'];
  order_id: Scalars['String'];
  product: Scalars['String'];
  promo_code?: InputMaybe<Scalars['String']>;
  type_product: Scalars['String'];
};

export type BillingOrderPoliceCreateOutput = {
  __typename?: 'billingOrderPoliceCreateOutput';
  message: Scalars['String'];
  status: Scalars['String'];
  url: Scalars['String'];
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

export type DmsCheckPromoCodeInput = {
  code: Scalars['String'];
};

export type DmsCheckPromoCodeOutput = {
  __typename?: 'dmsCheckPromoCodeOutput';
  discount: Scalars['Int'];
  error: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type DmsCreateInsurantInput = {
  insurant: Scalars['String'];
  insured: Scalars['String'];
  product_id: Scalars['Int'];
  promo_code: Scalars['String'];
};

export type DmsCreateInsurantOutput = {
  __typename?: 'dmsCreateInsurantOutput';
  error: Scalars['String'];
  ok: Scalars['Boolean'];
  order_id: Scalars['String'];
  payment_link: Scalars['String'];
};

export type DmsCreateOrderInput = {
  back_url: Scalars['String'];
  insurant: Scalars['String'];
  insured: Scalars['String'];
  order_id: Scalars['String'];
  product_id: Scalars['Int'];
  promo_code?: InputMaybe<Scalars['String']>;
  start_date: Scalars['date'];
};

export type DmsCreateOrderNumberOutput = {
  __typename?: 'dmsCreateOrderNumberOutput';
  order_id: Scalars['String'];
};

export type DmsCreateOrderOutput = {
  __typename?: 'dmsCreateOrderOutput';
  error: Scalars['String'];
  ok: Scalars['Boolean'];
  order_id: Scalars['String'];
  payment_link: Scalars['String'];
};

export type DmsGetOrderDiscountInput = {
  order_id: Scalars['String'];
};

export type DmsGetOrderDiscountOutput = {
  __typename?: 'dmsGetOrderDiscountOutput';
  discount: Scalars['Int'];
};

export type DmsGetPolicyCardInput = {
  group_id?: InputMaybe<Scalars['Int']>;
};

export type DmsGetPolicyCardOutput = {
  __typename?: 'dmsGetPolicyCardOutput';
  policy_card: Scalars['jsonb'];
};

export type DmsPrintOrderInput = {
  order_id: Scalars['String'];
};

export type DmsPrintOrderOutput = {
  __typename?: 'dmsPrintOrderOutput';
  description: Scalars['String'];
  status: Scalars['Boolean'];
};

/** columns and relationships of "group_insureds" */
export type Dms_Group_Insureds = {
  __typename?: 'dms_group_insureds';
  created_at: Scalars['timestamp'];
  /** An object relationship */
  group?: Maybe<Dms_Groups>;
  group_id?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  /** An object relationship */
  insured?: Maybe<Dms_Insureds>;
  insured_id?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamp'];
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "group_insureds" */
export type Dms_Group_Insureds_Aggregate = {
  __typename?: 'dms_group_insureds_aggregate';
  aggregate?: Maybe<Dms_Group_Insureds_Aggregate_Fields>;
  nodes: Array<Dms_Group_Insureds>;
};

/** aggregate fields of "group_insureds" */
export type Dms_Group_Insureds_Aggregate_Fields = {
  __typename?: 'dms_group_insureds_aggregate_fields';
  avg?: Maybe<Dms_Group_Insureds_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dms_Group_Insureds_Max_Fields>;
  min?: Maybe<Dms_Group_Insureds_Min_Fields>;
  stddev?: Maybe<Dms_Group_Insureds_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Group_Insureds_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Group_Insureds_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Group_Insureds_Sum_Fields>;
  var_pop?: Maybe<Dms_Group_Insureds_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Group_Insureds_Var_Samp_Fields>;
  variance?: Maybe<Dms_Group_Insureds_Variance_Fields>;
};


/** aggregate fields of "group_insureds" */
export type Dms_Group_Insureds_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Group_Insureds_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "group_insureds" */
export type Dms_Group_Insureds_Aggregate_Order_By = {
  avg?: InputMaybe<Dms_Group_Insureds_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Dms_Group_Insureds_Max_Order_By>;
  min?: InputMaybe<Dms_Group_Insureds_Min_Order_By>;
  stddev?: InputMaybe<Dms_Group_Insureds_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Dms_Group_Insureds_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Dms_Group_Insureds_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Dms_Group_Insureds_Sum_Order_By>;
  var_pop?: InputMaybe<Dms_Group_Insureds_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Dms_Group_Insureds_Var_Samp_Order_By>;
  variance?: InputMaybe<Dms_Group_Insureds_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "group_insureds" */
export type Dms_Group_Insureds_Arr_Rel_Insert_Input = {
  data: Array<Dms_Group_Insureds_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Dms_Group_Insureds_On_Conflict>;
};

/** aggregate avg on columns */
export type Dms_Group_Insureds_Avg_Fields = {
  __typename?: 'dms_group_insureds_avg_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insured_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "group_insureds" */
export type Dms_Group_Insureds_Avg_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insured_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "group_insureds". All fields are combined with a logical 'AND'. */
export type Dms_Group_Insureds_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Group_Insureds_Bool_Exp>>;
  _not?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Group_Insureds_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  group?: InputMaybe<Dms_Groups_Bool_Exp>;
  group_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  insured?: InputMaybe<Dms_Insureds_Bool_Exp>;
  insured_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "group_insureds" */
export enum Dms_Group_Insureds_Constraint {
  /** unique or primary key constraint on columns "id" */
  GroupInsuredsPkey = 'group_insureds_pkey'
}

/** input type for incrementing numeric columns in table "group_insureds" */
export type Dms_Group_Insureds_Inc_Input = {
  group_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  insured_id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "group_insureds" */
export type Dms_Group_Insureds_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  group?: InputMaybe<Dms_Groups_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  insured?: InputMaybe<Dms_Insureds_Obj_Rel_Insert_Input>;
  insured_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Dms_Group_Insureds_Max_Fields = {
  __typename?: 'dms_group_insureds_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  group_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  insured_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "group_insureds" */
export type Dms_Group_Insureds_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insured_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Dms_Group_Insureds_Min_Fields = {
  __typename?: 'dms_group_insureds_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  group_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  insured_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "group_insureds" */
export type Dms_Group_Insureds_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insured_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "group_insureds" */
export type Dms_Group_Insureds_Mutation_Response = {
  __typename?: 'dms_group_insureds_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Group_Insureds>;
};

/** on_conflict condition type for table "group_insureds" */
export type Dms_Group_Insureds_On_Conflict = {
  constraint: Dms_Group_Insureds_Constraint;
  update_columns?: Array<Dms_Group_Insureds_Update_Column>;
  where?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
};

/** Ordering options when selecting data from "group_insureds". */
export type Dms_Group_Insureds_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group?: InputMaybe<Dms_Groups_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insured?: InputMaybe<Dms_Insureds_Order_By>;
  insured_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dms_group_insureds */
export type Dms_Group_Insureds_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "group_insureds" */
export enum Dms_Group_Insureds_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  InsuredId = 'insured_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "group_insureds" */
export type Dms_Group_Insureds_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  group_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  insured_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Dms_Group_Insureds_Stddev_Fields = {
  __typename?: 'dms_group_insureds_stddev_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insured_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "group_insureds" */
export type Dms_Group_Insureds_Stddev_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insured_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dms_Group_Insureds_Stddev_Pop_Fields = {
  __typename?: 'dms_group_insureds_stddev_pop_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insured_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "group_insureds" */
export type Dms_Group_Insureds_Stddev_Pop_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insured_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dms_Group_Insureds_Stddev_Samp_Fields = {
  __typename?: 'dms_group_insureds_stddev_samp_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insured_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "group_insureds" */
export type Dms_Group_Insureds_Stddev_Samp_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insured_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "dms_group_insureds" */
export type Dms_Group_Insureds_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Group_Insureds_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Group_Insureds_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  group_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  insured_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Dms_Group_Insureds_Sum_Fields = {
  __typename?: 'dms_group_insureds_sum_fields';
  group_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  insured_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "group_insureds" */
export type Dms_Group_Insureds_Sum_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insured_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "group_insureds" */
export enum Dms_Group_Insureds_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  InsuredId = 'insured_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Dms_Group_Insureds_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Group_Insureds_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Group_Insureds_Set_Input>;
  where: Dms_Group_Insureds_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Group_Insureds_Var_Pop_Fields = {
  __typename?: 'dms_group_insureds_var_pop_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insured_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "group_insureds" */
export type Dms_Group_Insureds_Var_Pop_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insured_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dms_Group_Insureds_Var_Samp_Fields = {
  __typename?: 'dms_group_insureds_var_samp_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insured_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "group_insureds" */
export type Dms_Group_Insureds_Var_Samp_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insured_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Dms_Group_Insureds_Variance_Fields = {
  __typename?: 'dms_group_insureds_variance_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insured_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "group_insureds" */
export type Dms_Group_Insureds_Variance_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insured_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "groups" */
export type Dms_Groups = {
  __typename?: 'dms_groups';
  created_at: Scalars['timestamp'];
  /** An array relationship */
  group_insureds: Array<Dms_Group_Insureds>;
  /** An aggregate relationship */
  group_insureds_aggregate: Dms_Group_Insureds_Aggregate;
  group_name?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  updated_at: Scalars['timestamp'];
  user_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "groups" */
export type Dms_GroupsGroup_InsuredsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Group_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Group_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type Dms_GroupsGroup_Insureds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Group_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Group_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
};

/** aggregated selection of "groups" */
export type Dms_Groups_Aggregate = {
  __typename?: 'dms_groups_aggregate';
  aggregate?: Maybe<Dms_Groups_Aggregate_Fields>;
  nodes: Array<Dms_Groups>;
};

/** aggregate fields of "groups" */
export type Dms_Groups_Aggregate_Fields = {
  __typename?: 'dms_groups_aggregate_fields';
  avg?: Maybe<Dms_Groups_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dms_Groups_Max_Fields>;
  min?: Maybe<Dms_Groups_Min_Fields>;
  stddev?: Maybe<Dms_Groups_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Groups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Groups_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Groups_Sum_Fields>;
  var_pop?: Maybe<Dms_Groups_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Groups_Var_Samp_Fields>;
  variance?: Maybe<Dms_Groups_Variance_Fields>;
};


/** aggregate fields of "groups" */
export type Dms_Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dms_Groups_Avg_Fields = {
  __typename?: 'dms_groups_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "groups". All fields are combined with a logical 'AND'. */
export type Dms_Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Groups_Bool_Exp>>;
  _not?: InputMaybe<Dms_Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Groups_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  group_insureds?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
  group_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "groups" */
export enum Dms_Groups_Constraint {
  /** unique or primary key constraint on columns "id" */
  GroupsPkey = 'groups_pkey'
}

/** input type for incrementing numeric columns in table "groups" */
export type Dms_Groups_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "groups" */
export type Dms_Groups_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  group_insureds?: InputMaybe<Dms_Group_Insureds_Arr_Rel_Insert_Input>;
  group_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Dms_Groups_Max_Fields = {
  __typename?: 'dms_groups_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  group_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Dms_Groups_Min_Fields = {
  __typename?: 'dms_groups_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  group_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "groups" */
export type Dms_Groups_Mutation_Response = {
  __typename?: 'dms_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Groups>;
};

/** input type for inserting object relation for remote table "groups" */
export type Dms_Groups_Obj_Rel_Insert_Input = {
  data: Dms_Groups_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Dms_Groups_On_Conflict>;
};

/** on_conflict condition type for table "groups" */
export type Dms_Groups_On_Conflict = {
  constraint: Dms_Groups_Constraint;
  update_columns?: Array<Dms_Groups_Update_Column>;
  where?: InputMaybe<Dms_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "groups". */
export type Dms_Groups_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_insureds_aggregate?: InputMaybe<Dms_Group_Insureds_Aggregate_Order_By>;
  group_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dms_groups */
export type Dms_Groups_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "groups" */
export enum Dms_Groups_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupName = 'group_name',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "groups" */
export type Dms_Groups_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  group_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Dms_Groups_Stddev_Fields = {
  __typename?: 'dms_groups_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dms_Groups_Stddev_Pop_Fields = {
  __typename?: 'dms_groups_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dms_Groups_Stddev_Samp_Fields = {
  __typename?: 'dms_groups_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dms_groups" */
export type Dms_Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Groups_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  group_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Dms_Groups_Sum_Fields = {
  __typename?: 'dms_groups_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "groups" */
export enum Dms_Groups_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupName = 'group_name',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Dms_Groups_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Groups_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Groups_Set_Input>;
  where: Dms_Groups_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Groups_Var_Pop_Fields = {
  __typename?: 'dms_groups_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dms_Groups_Var_Samp_Fields = {
  __typename?: 'dms_groups_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dms_Groups_Variance_Fields = {
  __typename?: 'dms_groups_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "insurants" */
export type Dms_Insurants = {
  __typename?: 'dms_insurants';
  birth_place?: Maybe<Scalars['String']>;
  bithday: Scalars['date'];
  created_at: Scalars['timestamp'];
  date_release: Scalars['date'];
  division_code: Scalars['String'];
  division_issuing: Scalars['String'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  foreign_passport?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Boolean']>;
  id: Scalars['bigint'];
  /** An array relationship */
  insureds: Array<Dms_Insureds>;
  /** An aggregate relationship */
  insureds_aggregate: Dms_Insureds_Aggregate;
  last_name: Scalars['String'];
  order_uid?: Maybe<Scalars['String']>;
  passport: Scalars['String'];
  phone: Scalars['String'];
  registration_address: Scalars['String'];
  residence?: Maybe<Scalars['String']>;
  second_name?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  user_id?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "insurants" */
export type Dms_InsurantsInsuredsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Insureds_Bool_Exp>;
};


/** columns and relationships of "insurants" */
export type Dms_InsurantsInsureds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Insureds_Bool_Exp>;
};

/** aggregated selection of "insurants" */
export type Dms_Insurants_Aggregate = {
  __typename?: 'dms_insurants_aggregate';
  aggregate?: Maybe<Dms_Insurants_Aggregate_Fields>;
  nodes: Array<Dms_Insurants>;
};

/** aggregate fields of "insurants" */
export type Dms_Insurants_Aggregate_Fields = {
  __typename?: 'dms_insurants_aggregate_fields';
  avg?: Maybe<Dms_Insurants_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dms_Insurants_Max_Fields>;
  min?: Maybe<Dms_Insurants_Min_Fields>;
  stddev?: Maybe<Dms_Insurants_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Insurants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Insurants_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Insurants_Sum_Fields>;
  var_pop?: Maybe<Dms_Insurants_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Insurants_Var_Samp_Fields>;
  variance?: Maybe<Dms_Insurants_Variance_Fields>;
};


/** aggregate fields of "insurants" */
export type Dms_Insurants_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Insurants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dms_Insurants_Avg_Fields = {
  __typename?: 'dms_insurants_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "insurants". All fields are combined with a logical 'AND'. */
export type Dms_Insurants_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Insurants_Bool_Exp>>;
  _not?: InputMaybe<Dms_Insurants_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Insurants_Bool_Exp>>;
  birth_place?: InputMaybe<String_Comparison_Exp>;
  bithday?: InputMaybe<Date_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  date_release?: InputMaybe<Date_Comparison_Exp>;
  division_code?: InputMaybe<String_Comparison_Exp>;
  division_issuing?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  foreign_passport?: InputMaybe<String_Comparison_Exp>;
  gender?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  insureds?: InputMaybe<Dms_Insureds_Bool_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  order_uid?: InputMaybe<String_Comparison_Exp>;
  passport?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  registration_address?: InputMaybe<String_Comparison_Exp>;
  residence?: InputMaybe<String_Comparison_Exp>;
  second_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "insurants" */
export enum Dms_Insurants_Constraint {
  /** unique or primary key constraint on columns "id" */
  InsurantsPkey = 'insurants_pkey'
}

/** input type for incrementing numeric columns in table "insurants" */
export type Dms_Insurants_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "insurants" */
export type Dms_Insurants_Insert_Input = {
  birth_place?: InputMaybe<Scalars['String']>;
  bithday?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  date_release?: InputMaybe<Scalars['date']>;
  division_code?: InputMaybe<Scalars['String']>;
  division_issuing?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  foreign_passport?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  insureds?: InputMaybe<Dms_Insureds_Arr_Rel_Insert_Input>;
  last_name?: InputMaybe<Scalars['String']>;
  order_uid?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  registration_address?: InputMaybe<Scalars['String']>;
  residence?: InputMaybe<Scalars['String']>;
  second_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Dms_Insurants_Max_Fields = {
  __typename?: 'dms_insurants_max_fields';
  birth_place?: Maybe<Scalars['String']>;
  bithday?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_release?: Maybe<Scalars['date']>;
  division_code?: Maybe<Scalars['String']>;
  division_issuing?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  foreign_passport?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  order_uid?: Maybe<Scalars['String']>;
  passport?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  registration_address?: Maybe<Scalars['String']>;
  residence?: Maybe<Scalars['String']>;
  second_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Dms_Insurants_Min_Fields = {
  __typename?: 'dms_insurants_min_fields';
  birth_place?: Maybe<Scalars['String']>;
  bithday?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_release?: Maybe<Scalars['date']>;
  division_code?: Maybe<Scalars['String']>;
  division_issuing?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  foreign_passport?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  order_uid?: Maybe<Scalars['String']>;
  passport?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  registration_address?: Maybe<Scalars['String']>;
  residence?: Maybe<Scalars['String']>;
  second_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "insurants" */
export type Dms_Insurants_Mutation_Response = {
  __typename?: 'dms_insurants_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Insurants>;
};

/** input type for inserting object relation for remote table "insurants" */
export type Dms_Insurants_Obj_Rel_Insert_Input = {
  data: Dms_Insurants_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Dms_Insurants_On_Conflict>;
};

/** on_conflict condition type for table "insurants" */
export type Dms_Insurants_On_Conflict = {
  constraint: Dms_Insurants_Constraint;
  update_columns?: Array<Dms_Insurants_Update_Column>;
  where?: InputMaybe<Dms_Insurants_Bool_Exp>;
};

/** Ordering options when selecting data from "insurants". */
export type Dms_Insurants_Order_By = {
  birth_place?: InputMaybe<Order_By>;
  bithday?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_release?: InputMaybe<Order_By>;
  division_code?: InputMaybe<Order_By>;
  division_issuing?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  foreign_passport?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insureds_aggregate?: InputMaybe<Dms_Insureds_Aggregate_Order_By>;
  last_name?: InputMaybe<Order_By>;
  order_uid?: InputMaybe<Order_By>;
  passport?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  registration_address?: InputMaybe<Order_By>;
  residence?: InputMaybe<Order_By>;
  second_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dms_insurants */
export type Dms_Insurants_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "insurants" */
export enum Dms_Insurants_Select_Column {
  /** column name */
  BirthPlace = 'birth_place',
  /** column name */
  Bithday = 'bithday',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateRelease = 'date_release',
  /** column name */
  DivisionCode = 'division_code',
  /** column name */
  DivisionIssuing = 'division_issuing',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  ForeignPassport = 'foreign_passport',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  OrderUid = 'order_uid',
  /** column name */
  Passport = 'passport',
  /** column name */
  Phone = 'phone',
  /** column name */
  RegistrationAddress = 'registration_address',
  /** column name */
  Residence = 'residence',
  /** column name */
  SecondName = 'second_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "insurants" */
export type Dms_Insurants_Set_Input = {
  birth_place?: InputMaybe<Scalars['String']>;
  bithday?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  date_release?: InputMaybe<Scalars['date']>;
  division_code?: InputMaybe<Scalars['String']>;
  division_issuing?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  foreign_passport?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  last_name?: InputMaybe<Scalars['String']>;
  order_uid?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  registration_address?: InputMaybe<Scalars['String']>;
  residence?: InputMaybe<Scalars['String']>;
  second_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Dms_Insurants_Stddev_Fields = {
  __typename?: 'dms_insurants_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dms_Insurants_Stddev_Pop_Fields = {
  __typename?: 'dms_insurants_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dms_Insurants_Stddev_Samp_Fields = {
  __typename?: 'dms_insurants_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dms_insurants" */
export type Dms_Insurants_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Insurants_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Insurants_Stream_Cursor_Value_Input = {
  birth_place?: InputMaybe<Scalars['String']>;
  bithday?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  date_release?: InputMaybe<Scalars['date']>;
  division_code?: InputMaybe<Scalars['String']>;
  division_issuing?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  foreign_passport?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  last_name?: InputMaybe<Scalars['String']>;
  order_uid?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  registration_address?: InputMaybe<Scalars['String']>;
  residence?: InputMaybe<Scalars['String']>;
  second_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Dms_Insurants_Sum_Fields = {
  __typename?: 'dms_insurants_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "insurants" */
export enum Dms_Insurants_Update_Column {
  /** column name */
  BirthPlace = 'birth_place',
  /** column name */
  Bithday = 'bithday',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateRelease = 'date_release',
  /** column name */
  DivisionCode = 'division_code',
  /** column name */
  DivisionIssuing = 'division_issuing',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  ForeignPassport = 'foreign_passport',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  OrderUid = 'order_uid',
  /** column name */
  Passport = 'passport',
  /** column name */
  Phone = 'phone',
  /** column name */
  RegistrationAddress = 'registration_address',
  /** column name */
  Residence = 'residence',
  /** column name */
  SecondName = 'second_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Dms_Insurants_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Insurants_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Insurants_Set_Input>;
  where: Dms_Insurants_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Insurants_Var_Pop_Fields = {
  __typename?: 'dms_insurants_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dms_Insurants_Var_Samp_Fields = {
  __typename?: 'dms_insurants_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dms_Insurants_Variance_Fields = {
  __typename?: 'dms_insurants_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "insureds" */
export type Dms_Insureds = {
  __typename?: 'dms_insureds';
  birth_place?: Maybe<Scalars['String']>;
  bithday: Scalars['date'];
  created_at: Scalars['timestamp'];
  date_release?: Maybe<Scalars['date']>;
  division_code?: Maybe<Scalars['String']>;
  division_issuing?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  foreign?: Maybe<Scalars['Boolean']>;
  foreign_passport?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Boolean']>;
  group_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  group_insureds: Array<Dms_Group_Insureds>;
  /** An aggregate relationship */
  group_insureds_aggregate: Dms_Group_Insureds_Aggregate;
  id: Scalars['bigint'];
  /** An object relationship */
  insurant?: Maybe<Dms_Insurants>;
  insurant_id?: Maybe<Scalars['bigint']>;
  last_name: Scalars['String'];
  number?: Maybe<Scalars['Int']>;
  oms?: Maybe<Scalars['String']>;
  order_uid?: Maybe<Scalars['String']>;
  passport: Scalars['String'];
  phone: Scalars['String'];
  product_info?: Maybe<Scalars['jsonb']>;
  registration_address?: Maybe<Scalars['String']>;
  residence?: Maybe<Scalars['String']>;
  second_name?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  user_id?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "insureds" */
export type Dms_InsuredsGroup_InsuredsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Group_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Group_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
};


/** columns and relationships of "insureds" */
export type Dms_InsuredsGroup_Insureds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Group_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Group_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
};


/** columns and relationships of "insureds" */
export type Dms_InsuredsProduct_InfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "insureds" */
export type Dms_Insureds_Aggregate = {
  __typename?: 'dms_insureds_aggregate';
  aggregate?: Maybe<Dms_Insureds_Aggregate_Fields>;
  nodes: Array<Dms_Insureds>;
};

/** aggregate fields of "insureds" */
export type Dms_Insureds_Aggregate_Fields = {
  __typename?: 'dms_insureds_aggregate_fields';
  avg?: Maybe<Dms_Insureds_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dms_Insureds_Max_Fields>;
  min?: Maybe<Dms_Insureds_Min_Fields>;
  stddev?: Maybe<Dms_Insureds_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Insureds_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Insureds_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Insureds_Sum_Fields>;
  var_pop?: Maybe<Dms_Insureds_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Insureds_Var_Samp_Fields>;
  variance?: Maybe<Dms_Insureds_Variance_Fields>;
};


/** aggregate fields of "insureds" */
export type Dms_Insureds_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Insureds_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "insureds" */
export type Dms_Insureds_Aggregate_Order_By = {
  avg?: InputMaybe<Dms_Insureds_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Dms_Insureds_Max_Order_By>;
  min?: InputMaybe<Dms_Insureds_Min_Order_By>;
  stddev?: InputMaybe<Dms_Insureds_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Dms_Insureds_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Dms_Insureds_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Dms_Insureds_Sum_Order_By>;
  var_pop?: InputMaybe<Dms_Insureds_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Dms_Insureds_Var_Samp_Order_By>;
  variance?: InputMaybe<Dms_Insureds_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Dms_Insureds_Append_Input = {
  product_info?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "insureds" */
export type Dms_Insureds_Arr_Rel_Insert_Input = {
  data: Array<Dms_Insureds_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Dms_Insureds_On_Conflict>;
};

/** aggregate avg on columns */
export type Dms_Insureds_Avg_Fields = {
  __typename?: 'dms_insureds_avg_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurant_id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "insureds" */
export type Dms_Insureds_Avg_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "insureds". All fields are combined with a logical 'AND'. */
export type Dms_Insureds_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Insureds_Bool_Exp>>;
  _not?: InputMaybe<Dms_Insureds_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Insureds_Bool_Exp>>;
  birth_place?: InputMaybe<String_Comparison_Exp>;
  bithday?: InputMaybe<Date_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  date_release?: InputMaybe<Date_Comparison_Exp>;
  division_code?: InputMaybe<String_Comparison_Exp>;
  division_issuing?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  foreign?: InputMaybe<Boolean_Comparison_Exp>;
  foreign_passport?: InputMaybe<String_Comparison_Exp>;
  gender?: InputMaybe<Boolean_Comparison_Exp>;
  group_id?: InputMaybe<Bigint_Comparison_Exp>;
  group_insureds?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  insurant?: InputMaybe<Dms_Insurants_Bool_Exp>;
  insurant_id?: InputMaybe<Bigint_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  number?: InputMaybe<Int_Comparison_Exp>;
  oms?: InputMaybe<String_Comparison_Exp>;
  order_uid?: InputMaybe<String_Comparison_Exp>;
  passport?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  product_info?: InputMaybe<Jsonb_Comparison_Exp>;
  registration_address?: InputMaybe<String_Comparison_Exp>;
  residence?: InputMaybe<String_Comparison_Exp>;
  second_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "insureds" */
export enum Dms_Insureds_Constraint {
  /** unique or primary key constraint on columns "id" */
  InsuredsPkey = 'insureds_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Dms_Insureds_Delete_At_Path_Input = {
  product_info?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Dms_Insureds_Delete_Elem_Input = {
  product_info?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Dms_Insureds_Delete_Key_Input = {
  product_info?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "insureds" */
export type Dms_Insureds_Inc_Input = {
  group_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  insurant_id?: InputMaybe<Scalars['bigint']>;
  number?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "insureds" */
export type Dms_Insureds_Insert_Input = {
  birth_place?: InputMaybe<Scalars['String']>;
  bithday?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  date_release?: InputMaybe<Scalars['date']>;
  division_code?: InputMaybe<Scalars['String']>;
  division_issuing?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  foreign?: InputMaybe<Scalars['Boolean']>;
  foreign_passport?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['Boolean']>;
  group_id?: InputMaybe<Scalars['bigint']>;
  group_insureds?: InputMaybe<Dms_Group_Insureds_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  insurant?: InputMaybe<Dms_Insurants_Obj_Rel_Insert_Input>;
  insurant_id?: InputMaybe<Scalars['bigint']>;
  last_name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Int']>;
  oms?: InputMaybe<Scalars['String']>;
  order_uid?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  product_info?: InputMaybe<Scalars['jsonb']>;
  registration_address?: InputMaybe<Scalars['String']>;
  residence?: InputMaybe<Scalars['String']>;
  second_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Dms_Insureds_Max_Fields = {
  __typename?: 'dms_insureds_max_fields';
  birth_place?: Maybe<Scalars['String']>;
  bithday?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_release?: Maybe<Scalars['date']>;
  division_code?: Maybe<Scalars['String']>;
  division_issuing?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  foreign_passport?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  insurant_id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  oms?: Maybe<Scalars['String']>;
  order_uid?: Maybe<Scalars['String']>;
  passport?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  registration_address?: Maybe<Scalars['String']>;
  residence?: Maybe<Scalars['String']>;
  second_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "insureds" */
export type Dms_Insureds_Max_Order_By = {
  birth_place?: InputMaybe<Order_By>;
  bithday?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_release?: InputMaybe<Order_By>;
  division_code?: InputMaybe<Order_By>;
  division_issuing?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  foreign_passport?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  oms?: InputMaybe<Order_By>;
  order_uid?: InputMaybe<Order_By>;
  passport?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  registration_address?: InputMaybe<Order_By>;
  residence?: InputMaybe<Order_By>;
  second_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Dms_Insureds_Min_Fields = {
  __typename?: 'dms_insureds_min_fields';
  birth_place?: Maybe<Scalars['String']>;
  bithday?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_release?: Maybe<Scalars['date']>;
  division_code?: Maybe<Scalars['String']>;
  division_issuing?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  foreign_passport?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  insurant_id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  oms?: Maybe<Scalars['String']>;
  order_uid?: Maybe<Scalars['String']>;
  passport?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  registration_address?: Maybe<Scalars['String']>;
  residence?: Maybe<Scalars['String']>;
  second_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "insureds" */
export type Dms_Insureds_Min_Order_By = {
  birth_place?: InputMaybe<Order_By>;
  bithday?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_release?: InputMaybe<Order_By>;
  division_code?: InputMaybe<Order_By>;
  division_issuing?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  foreign_passport?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  oms?: InputMaybe<Order_By>;
  order_uid?: InputMaybe<Order_By>;
  passport?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  registration_address?: InputMaybe<Order_By>;
  residence?: InputMaybe<Order_By>;
  second_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "insureds" */
export type Dms_Insureds_Mutation_Response = {
  __typename?: 'dms_insureds_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Insureds>;
};

/** input type for inserting object relation for remote table "insureds" */
export type Dms_Insureds_Obj_Rel_Insert_Input = {
  data: Dms_Insureds_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Dms_Insureds_On_Conflict>;
};

/** on_conflict condition type for table "insureds" */
export type Dms_Insureds_On_Conflict = {
  constraint: Dms_Insureds_Constraint;
  update_columns?: Array<Dms_Insureds_Update_Column>;
  where?: InputMaybe<Dms_Insureds_Bool_Exp>;
};

/** Ordering options when selecting data from "insureds". */
export type Dms_Insureds_Order_By = {
  birth_place?: InputMaybe<Order_By>;
  bithday?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_release?: InputMaybe<Order_By>;
  division_code?: InputMaybe<Order_By>;
  division_issuing?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  foreign?: InputMaybe<Order_By>;
  foreign_passport?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  group_insureds_aggregate?: InputMaybe<Dms_Group_Insureds_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  insurant?: InputMaybe<Dms_Insurants_Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  oms?: InputMaybe<Order_By>;
  order_uid?: InputMaybe<Order_By>;
  passport?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  product_info?: InputMaybe<Order_By>;
  registration_address?: InputMaybe<Order_By>;
  residence?: InputMaybe<Order_By>;
  second_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dms_insureds */
export type Dms_Insureds_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Dms_Insureds_Prepend_Input = {
  product_info?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "insureds" */
export enum Dms_Insureds_Select_Column {
  /** column name */
  BirthPlace = 'birth_place',
  /** column name */
  Bithday = 'bithday',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateRelease = 'date_release',
  /** column name */
  DivisionCode = 'division_code',
  /** column name */
  DivisionIssuing = 'division_issuing',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Foreign = 'foreign',
  /** column name */
  ForeignPassport = 'foreign_passport',
  /** column name */
  Gender = 'gender',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  InsurantId = 'insurant_id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Number = 'number',
  /** column name */
  Oms = 'oms',
  /** column name */
  OrderUid = 'order_uid',
  /** column name */
  Passport = 'passport',
  /** column name */
  Phone = 'phone',
  /** column name */
  ProductInfo = 'product_info',
  /** column name */
  RegistrationAddress = 'registration_address',
  /** column name */
  Residence = 'residence',
  /** column name */
  SecondName = 'second_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "insureds" */
export type Dms_Insureds_Set_Input = {
  birth_place?: InputMaybe<Scalars['String']>;
  bithday?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  date_release?: InputMaybe<Scalars['date']>;
  division_code?: InputMaybe<Scalars['String']>;
  division_issuing?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  foreign?: InputMaybe<Scalars['Boolean']>;
  foreign_passport?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['Boolean']>;
  group_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  insurant_id?: InputMaybe<Scalars['bigint']>;
  last_name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Int']>;
  oms?: InputMaybe<Scalars['String']>;
  order_uid?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  product_info?: InputMaybe<Scalars['jsonb']>;
  registration_address?: InputMaybe<Scalars['String']>;
  residence?: InputMaybe<Scalars['String']>;
  second_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Dms_Insureds_Stddev_Fields = {
  __typename?: 'dms_insureds_stddev_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurant_id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "insureds" */
export type Dms_Insureds_Stddev_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dms_Insureds_Stddev_Pop_Fields = {
  __typename?: 'dms_insureds_stddev_pop_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurant_id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "insureds" */
export type Dms_Insureds_Stddev_Pop_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dms_Insureds_Stddev_Samp_Fields = {
  __typename?: 'dms_insureds_stddev_samp_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurant_id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "insureds" */
export type Dms_Insureds_Stddev_Samp_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "dms_insureds" */
export type Dms_Insureds_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Insureds_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Insureds_Stream_Cursor_Value_Input = {
  birth_place?: InputMaybe<Scalars['String']>;
  bithday?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  date_release?: InputMaybe<Scalars['date']>;
  division_code?: InputMaybe<Scalars['String']>;
  division_issuing?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  foreign?: InputMaybe<Scalars['Boolean']>;
  foreign_passport?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['Boolean']>;
  group_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  insurant_id?: InputMaybe<Scalars['bigint']>;
  last_name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Int']>;
  oms?: InputMaybe<Scalars['String']>;
  order_uid?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  product_info?: InputMaybe<Scalars['jsonb']>;
  registration_address?: InputMaybe<Scalars['String']>;
  residence?: InputMaybe<Scalars['String']>;
  second_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Dms_Insureds_Sum_Fields = {
  __typename?: 'dms_insureds_sum_fields';
  group_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  insurant_id?: Maybe<Scalars['bigint']>;
  number?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "insureds" */
export type Dms_Insureds_Sum_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "insureds" */
export enum Dms_Insureds_Update_Column {
  /** column name */
  BirthPlace = 'birth_place',
  /** column name */
  Bithday = 'bithday',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateRelease = 'date_release',
  /** column name */
  DivisionCode = 'division_code',
  /** column name */
  DivisionIssuing = 'division_issuing',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Foreign = 'foreign',
  /** column name */
  ForeignPassport = 'foreign_passport',
  /** column name */
  Gender = 'gender',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  InsurantId = 'insurant_id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Number = 'number',
  /** column name */
  Oms = 'oms',
  /** column name */
  OrderUid = 'order_uid',
  /** column name */
  Passport = 'passport',
  /** column name */
  Phone = 'phone',
  /** column name */
  ProductInfo = 'product_info',
  /** column name */
  RegistrationAddress = 'registration_address',
  /** column name */
  Residence = 'residence',
  /** column name */
  SecondName = 'second_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Dms_Insureds_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Dms_Insureds_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Dms_Insureds_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Dms_Insureds_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Dms_Insureds_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Insureds_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Dms_Insureds_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Insureds_Set_Input>;
  where: Dms_Insureds_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Insureds_Var_Pop_Fields = {
  __typename?: 'dms_insureds_var_pop_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurant_id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "insureds" */
export type Dms_Insureds_Var_Pop_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dms_Insureds_Var_Samp_Fields = {
  __typename?: 'dms_insureds_var_samp_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurant_id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "insureds" */
export type Dms_Insureds_Var_Samp_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Dms_Insureds_Variance_Fields = {
  __typename?: 'dms_insureds_variance_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurant_id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "insureds" */
export type Dms_Insureds_Variance_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "order_links" */
export type Dms_Order_Links = {
  __typename?: 'dms_order_links';
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  link?: Maybe<Scalars['String']>;
  /** An object relationship */
  order?: Maybe<Dms_Orders>;
  order_id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "order_links" */
export type Dms_Order_Links_Aggregate = {
  __typename?: 'dms_order_links_aggregate';
  aggregate?: Maybe<Dms_Order_Links_Aggregate_Fields>;
  nodes: Array<Dms_Order_Links>;
};

/** aggregate fields of "order_links" */
export type Dms_Order_Links_Aggregate_Fields = {
  __typename?: 'dms_order_links_aggregate_fields';
  avg?: Maybe<Dms_Order_Links_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dms_Order_Links_Max_Fields>;
  min?: Maybe<Dms_Order_Links_Min_Fields>;
  stddev?: Maybe<Dms_Order_Links_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Order_Links_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Order_Links_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Order_Links_Sum_Fields>;
  var_pop?: Maybe<Dms_Order_Links_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Order_Links_Var_Samp_Fields>;
  variance?: Maybe<Dms_Order_Links_Variance_Fields>;
};


/** aggregate fields of "order_links" */
export type Dms_Order_Links_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Order_Links_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dms_Order_Links_Avg_Fields = {
  __typename?: 'dms_order_links_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "order_links". All fields are combined with a logical 'AND'. */
export type Dms_Order_Links_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Order_Links_Bool_Exp>>;
  _not?: InputMaybe<Dms_Order_Links_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Order_Links_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Dms_Orders_Bool_Exp>;
  order_id?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_links" */
export enum Dms_Order_Links_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrderLinksPkey = 'order_links_pkey'
}

/** input type for incrementing numeric columns in table "order_links" */
export type Dms_Order_Links_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  order_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "order_links" */
export type Dms_Order_Links_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  link?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Dms_Orders_Obj_Rel_Insert_Input>;
  order_id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dms_Order_Links_Max_Fields = {
  __typename?: 'dms_order_links_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  link?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Dms_Order_Links_Min_Fields = {
  __typename?: 'dms_order_links_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  link?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "order_links" */
export type Dms_Order_Links_Mutation_Response = {
  __typename?: 'dms_order_links_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Order_Links>;
};

/** on_conflict condition type for table "order_links" */
export type Dms_Order_Links_On_Conflict = {
  constraint: Dms_Order_Links_Constraint;
  update_columns?: Array<Dms_Order_Links_Update_Column>;
  where?: InputMaybe<Dms_Order_Links_Bool_Exp>;
};

/** Ordering options when selecting data from "order_links". */
export type Dms_Order_Links_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  order?: InputMaybe<Dms_Orders_Order_By>;
  order_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dms_order_links */
export type Dms_Order_Links_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "order_links" */
export enum Dms_Order_Links_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Status = 'status',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "order_links" */
export type Dms_Order_Links_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  link?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dms_Order_Links_Stddev_Fields = {
  __typename?: 'dms_order_links_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dms_Order_Links_Stddev_Pop_Fields = {
  __typename?: 'dms_order_links_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dms_Order_Links_Stddev_Samp_Fields = {
  __typename?: 'dms_order_links_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dms_order_links" */
export type Dms_Order_Links_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Order_Links_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Order_Links_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  link?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Dms_Order_Links_Sum_Fields = {
  __typename?: 'dms_order_links_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "order_links" */
export enum Dms_Order_Links_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Status = 'status',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Dms_Order_Links_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Order_Links_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Order_Links_Set_Input>;
  where: Dms_Order_Links_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Order_Links_Var_Pop_Fields = {
  __typename?: 'dms_order_links_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dms_Order_Links_Var_Samp_Fields = {
  __typename?: 'dms_order_links_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dms_Order_Links_Variance_Fields = {
  __typename?: 'dms_order_links_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "order_numbers" */
export type Dms_Order_Numbers = {
  __typename?: 'dms_order_numbers';
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  order_id?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "order_numbers" */
export type Dms_Order_Numbers_Aggregate = {
  __typename?: 'dms_order_numbers_aggregate';
  aggregate?: Maybe<Dms_Order_Numbers_Aggregate_Fields>;
  nodes: Array<Dms_Order_Numbers>;
};

/** aggregate fields of "order_numbers" */
export type Dms_Order_Numbers_Aggregate_Fields = {
  __typename?: 'dms_order_numbers_aggregate_fields';
  avg?: Maybe<Dms_Order_Numbers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dms_Order_Numbers_Max_Fields>;
  min?: Maybe<Dms_Order_Numbers_Min_Fields>;
  stddev?: Maybe<Dms_Order_Numbers_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Order_Numbers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Order_Numbers_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Order_Numbers_Sum_Fields>;
  var_pop?: Maybe<Dms_Order_Numbers_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Order_Numbers_Var_Samp_Fields>;
  variance?: Maybe<Dms_Order_Numbers_Variance_Fields>;
};


/** aggregate fields of "order_numbers" */
export type Dms_Order_Numbers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Order_Numbers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dms_Order_Numbers_Avg_Fields = {
  __typename?: 'dms_order_numbers_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "order_numbers". All fields are combined with a logical 'AND'. */
export type Dms_Order_Numbers_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Order_Numbers_Bool_Exp>>;
  _not?: InputMaybe<Dms_Order_Numbers_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Order_Numbers_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  order_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_numbers" */
export enum Dms_Order_Numbers_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrderNumbersPkey = 'order_numbers_pkey'
}

/** input type for incrementing numeric columns in table "order_numbers" */
export type Dms_Order_Numbers_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "order_numbers" */
export type Dms_Order_Numbers_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  order_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dms_Order_Numbers_Max_Fields = {
  __typename?: 'dms_order_numbers_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Dms_Order_Numbers_Min_Fields = {
  __typename?: 'dms_order_numbers_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  order_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "order_numbers" */
export type Dms_Order_Numbers_Mutation_Response = {
  __typename?: 'dms_order_numbers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Order_Numbers>;
};

/** on_conflict condition type for table "order_numbers" */
export type Dms_Order_Numbers_On_Conflict = {
  constraint: Dms_Order_Numbers_Constraint;
  update_columns?: Array<Dms_Order_Numbers_Update_Column>;
  where?: InputMaybe<Dms_Order_Numbers_Bool_Exp>;
};

/** Ordering options when selecting data from "order_numbers". */
export type Dms_Order_Numbers_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dms_order_numbers */
export type Dms_Order_Numbers_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "order_numbers" */
export enum Dms_Order_Numbers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "order_numbers" */
export type Dms_Order_Numbers_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  order_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dms_Order_Numbers_Stddev_Fields = {
  __typename?: 'dms_order_numbers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dms_Order_Numbers_Stddev_Pop_Fields = {
  __typename?: 'dms_order_numbers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dms_Order_Numbers_Stddev_Samp_Fields = {
  __typename?: 'dms_order_numbers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dms_order_numbers" */
export type Dms_Order_Numbers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Order_Numbers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Order_Numbers_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  order_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Dms_Order_Numbers_Sum_Fields = {
  __typename?: 'dms_order_numbers_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "order_numbers" */
export enum Dms_Order_Numbers_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Dms_Order_Numbers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Order_Numbers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Order_Numbers_Set_Input>;
  where: Dms_Order_Numbers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Order_Numbers_Var_Pop_Fields = {
  __typename?: 'dms_order_numbers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dms_Order_Numbers_Var_Samp_Fields = {
  __typename?: 'dms_order_numbers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dms_Order_Numbers_Variance_Fields = {
  __typename?: 'dms_order_numbers_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "orders" */
export type Dms_Orders = {
  __typename?: 'dms_orders';
  created_at: Scalars['timestamp'];
  discount?: Maybe<Scalars['Int']>;
  id: Scalars['bigint'];
  insurant_id?: Maybe<Scalars['bigint']>;
  insurant_json?: Maybe<Scalars['jsonb']>;
  insured_json?: Maybe<Scalars['jsonb']>;
  number?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['String']>;
  order_number_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  promo_code_str?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  /** An object relationship */
  status?: Maybe<Dms_Statuses>;
  status_id?: Maybe<Scalars['bigint']>;
  uid?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  user_id?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "orders" */
export type Dms_OrdersInsurant_JsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "orders" */
export type Dms_OrdersInsured_JsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "orders" */
export type Dms_Orders_Aggregate = {
  __typename?: 'dms_orders_aggregate';
  aggregate?: Maybe<Dms_Orders_Aggregate_Fields>;
  nodes: Array<Dms_Orders>;
};

/** aggregate fields of "orders" */
export type Dms_Orders_Aggregate_Fields = {
  __typename?: 'dms_orders_aggregate_fields';
  avg?: Maybe<Dms_Orders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dms_Orders_Max_Fields>;
  min?: Maybe<Dms_Orders_Min_Fields>;
  stddev?: Maybe<Dms_Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Orders_Sum_Fields>;
  var_pop?: Maybe<Dms_Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Orders_Var_Samp_Fields>;
  variance?: Maybe<Dms_Orders_Variance_Fields>;
};


/** aggregate fields of "orders" */
export type Dms_Orders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Dms_Orders_Append_Input = {
  insurant_json?: InputMaybe<Scalars['jsonb']>;
  insured_json?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Dms_Orders_Avg_Fields = {
  __typename?: 'dms_orders_avg_fields';
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurant_id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  order_number_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "orders". All fields are combined with a logical 'AND'. */
export type Dms_Orders_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Orders_Bool_Exp>>;
  _not?: InputMaybe<Dms_Orders_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Orders_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  discount?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  insurant_id?: InputMaybe<Bigint_Comparison_Exp>;
  insurant_json?: InputMaybe<Jsonb_Comparison_Exp>;
  insured_json?: InputMaybe<Jsonb_Comparison_Exp>;
  number?: InputMaybe<Int_Comparison_Exp>;
  order_id?: InputMaybe<String_Comparison_Exp>;
  order_number_id?: InputMaybe<Bigint_Comparison_Exp>;
  product_id?: InputMaybe<Bigint_Comparison_Exp>;
  promo_code_str?: InputMaybe<String_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  status?: InputMaybe<Dms_Statuses_Bool_Exp>;
  status_id?: InputMaybe<Bigint_Comparison_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "orders" */
export enum Dms_Orders_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrdersPkey = 'orders_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Dms_Orders_Delete_At_Path_Input = {
  insurant_json?: InputMaybe<Array<Scalars['String']>>;
  insured_json?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Dms_Orders_Delete_Elem_Input = {
  insurant_json?: InputMaybe<Scalars['Int']>;
  insured_json?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Dms_Orders_Delete_Key_Input = {
  insurant_json?: InputMaybe<Scalars['String']>;
  insured_json?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "orders" */
export type Dms_Orders_Inc_Input = {
  discount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  insurant_id?: InputMaybe<Scalars['bigint']>;
  number?: InputMaybe<Scalars['Int']>;
  order_number_id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['bigint']>;
  status_id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "orders" */
export type Dms_Orders_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  discount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  insurant_id?: InputMaybe<Scalars['bigint']>;
  insurant_json?: InputMaybe<Scalars['jsonb']>;
  insured_json?: InputMaybe<Scalars['jsonb']>;
  number?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['String']>;
  order_number_id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['bigint']>;
  promo_code_str?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['date']>;
  status?: InputMaybe<Dms_Statuses_Obj_Rel_Insert_Input>;
  status_id?: InputMaybe<Scalars['bigint']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Dms_Orders_Max_Fields = {
  __typename?: 'dms_orders_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  discount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  insurant_id?: Maybe<Scalars['bigint']>;
  number?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['String']>;
  order_number_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  promo_code_str?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  status_id?: Maybe<Scalars['bigint']>;
  uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Dms_Orders_Min_Fields = {
  __typename?: 'dms_orders_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  discount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  insurant_id?: Maybe<Scalars['bigint']>;
  number?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['String']>;
  order_number_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  promo_code_str?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  status_id?: Maybe<Scalars['bigint']>;
  uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "orders" */
export type Dms_Orders_Mutation_Response = {
  __typename?: 'dms_orders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Orders>;
};

/** input type for inserting object relation for remote table "orders" */
export type Dms_Orders_Obj_Rel_Insert_Input = {
  data: Dms_Orders_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Dms_Orders_On_Conflict>;
};

/** on_conflict condition type for table "orders" */
export type Dms_Orders_On_Conflict = {
  constraint: Dms_Orders_Constraint;
  update_columns?: Array<Dms_Orders_Update_Column>;
  where?: InputMaybe<Dms_Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "orders". */
export type Dms_Orders_Order_By = {
  created_at?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  insurant_json?: InputMaybe<Order_By>;
  insured_json?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  order_number_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  promo_code_str?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  status?: InputMaybe<Dms_Statuses_Order_By>;
  status_id?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dms_orders */
export type Dms_Orders_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Dms_Orders_Prepend_Input = {
  insurant_json?: InputMaybe<Scalars['jsonb']>;
  insured_json?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "orders" */
export enum Dms_Orders_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  InsurantId = 'insurant_id',
  /** column name */
  InsurantJson = 'insurant_json',
  /** column name */
  InsuredJson = 'insured_json',
  /** column name */
  Number = 'number',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  OrderNumberId = 'order_number_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  PromoCodeStr = 'promo_code_str',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "orders" */
export type Dms_Orders_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  discount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  insurant_id?: InputMaybe<Scalars['bigint']>;
  insurant_json?: InputMaybe<Scalars['jsonb']>;
  insured_json?: InputMaybe<Scalars['jsonb']>;
  number?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['String']>;
  order_number_id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['bigint']>;
  promo_code_str?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['date']>;
  status_id?: InputMaybe<Scalars['bigint']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Dms_Orders_Stddev_Fields = {
  __typename?: 'dms_orders_stddev_fields';
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurant_id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  order_number_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dms_Orders_Stddev_Pop_Fields = {
  __typename?: 'dms_orders_stddev_pop_fields';
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurant_id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  order_number_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dms_Orders_Stddev_Samp_Fields = {
  __typename?: 'dms_orders_stddev_samp_fields';
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurant_id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  order_number_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dms_orders" */
export type Dms_Orders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Orders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Orders_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  discount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  insurant_id?: InputMaybe<Scalars['bigint']>;
  insurant_json?: InputMaybe<Scalars['jsonb']>;
  insured_json?: InputMaybe<Scalars['jsonb']>;
  number?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['String']>;
  order_number_id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['bigint']>;
  promo_code_str?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['date']>;
  status_id?: InputMaybe<Scalars['bigint']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Dms_Orders_Sum_Fields = {
  __typename?: 'dms_orders_sum_fields';
  discount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  insurant_id?: Maybe<Scalars['bigint']>;
  number?: Maybe<Scalars['Int']>;
  order_number_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  status_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "orders" */
export enum Dms_Orders_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  InsurantId = 'insurant_id',
  /** column name */
  InsurantJson = 'insurant_json',
  /** column name */
  InsuredJson = 'insured_json',
  /** column name */
  Number = 'number',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  OrderNumberId = 'order_number_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  PromoCodeStr = 'promo_code_str',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Dms_Orders_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Dms_Orders_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Dms_Orders_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Dms_Orders_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Dms_Orders_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Orders_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Dms_Orders_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Orders_Set_Input>;
  where: Dms_Orders_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Orders_Var_Pop_Fields = {
  __typename?: 'dms_orders_var_pop_fields';
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurant_id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  order_number_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dms_Orders_Var_Samp_Fields = {
  __typename?: 'dms_orders_var_samp_fields';
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurant_id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  order_number_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dms_Orders_Variance_Fields = {
  __typename?: 'dms_orders_variance_fields';
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurant_id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  order_number_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "products" */
export type Dms_Products = {
  __typename?: 'dms_products';
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  medical_sum?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['jsonb']>;
  program?: Maybe<Scalars['jsonb']>;
  uid?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
};


/** columns and relationships of "products" */
export type Dms_ProductsMedical_SumArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "products" */
export type Dms_ProductsPriceArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "products" */
export type Dms_ProductsProgramArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "products" */
export type Dms_Products_Aggregate = {
  __typename?: 'dms_products_aggregate';
  aggregate?: Maybe<Dms_Products_Aggregate_Fields>;
  nodes: Array<Dms_Products>;
};

/** aggregate fields of "products" */
export type Dms_Products_Aggregate_Fields = {
  __typename?: 'dms_products_aggregate_fields';
  avg?: Maybe<Dms_Products_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dms_Products_Max_Fields>;
  min?: Maybe<Dms_Products_Min_Fields>;
  stddev?: Maybe<Dms_Products_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Products_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Products_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Products_Sum_Fields>;
  var_pop?: Maybe<Dms_Products_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Products_Var_Samp_Fields>;
  variance?: Maybe<Dms_Products_Variance_Fields>;
};


/** aggregate fields of "products" */
export type Dms_Products_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Products_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Dms_Products_Append_Input = {
  medical_sum?: InputMaybe<Scalars['jsonb']>;
  price?: InputMaybe<Scalars['jsonb']>;
  program?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Dms_Products_Avg_Fields = {
  __typename?: 'dms_products_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "products". All fields are combined with a logical 'AND'. */
export type Dms_Products_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Products_Bool_Exp>>;
  _not?: InputMaybe<Dms_Products_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Products_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  medical_sum?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Jsonb_Comparison_Exp>;
  program?: InputMaybe<Jsonb_Comparison_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "products" */
export enum Dms_Products_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductsPkey = 'products_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Dms_Products_Delete_At_Path_Input = {
  medical_sum?: InputMaybe<Array<Scalars['String']>>;
  price?: InputMaybe<Array<Scalars['String']>>;
  program?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Dms_Products_Delete_Elem_Input = {
  medical_sum?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  program?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Dms_Products_Delete_Key_Input = {
  medical_sum?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  program?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "products" */
export type Dms_Products_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "products" */
export type Dms_Products_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  medical_sum?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['jsonb']>;
  program?: InputMaybe<Scalars['jsonb']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dms_Products_Max_Fields = {
  __typename?: 'dms_products_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Dms_Products_Min_Fields = {
  __typename?: 'dms_products_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "products" */
export type Dms_Products_Mutation_Response = {
  __typename?: 'dms_products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Products>;
};

/** on_conflict condition type for table "products" */
export type Dms_Products_On_Conflict = {
  constraint: Dms_Products_Constraint;
  update_columns?: Array<Dms_Products_Update_Column>;
  where?: InputMaybe<Dms_Products_Bool_Exp>;
};

/** Ordering options when selecting data from "products". */
export type Dms_Products_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medical_sum?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  program?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dms_products */
export type Dms_Products_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Dms_Products_Prepend_Input = {
  medical_sum?: InputMaybe<Scalars['jsonb']>;
  price?: InputMaybe<Scalars['jsonb']>;
  program?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "products" */
export enum Dms_Products_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MedicalSum = 'medical_sum',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  Program = 'program',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "products" */
export type Dms_Products_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  medical_sum?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['jsonb']>;
  program?: InputMaybe<Scalars['jsonb']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dms_Products_Stddev_Fields = {
  __typename?: 'dms_products_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dms_Products_Stddev_Pop_Fields = {
  __typename?: 'dms_products_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dms_Products_Stddev_Samp_Fields = {
  __typename?: 'dms_products_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dms_products" */
export type Dms_Products_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Products_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  medical_sum?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['jsonb']>;
  program?: InputMaybe<Scalars['jsonb']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Dms_Products_Sum_Fields = {
  __typename?: 'dms_products_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "products" */
export enum Dms_Products_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MedicalSum = 'medical_sum',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  Program = 'program',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Dms_Products_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Dms_Products_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Dms_Products_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Dms_Products_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Dms_Products_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Products_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Dms_Products_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Products_Set_Input>;
  where: Dms_Products_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Products_Var_Pop_Fields = {
  __typename?: 'dms_products_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dms_Products_Var_Samp_Fields = {
  __typename?: 'dms_products_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dms_Products_Variance_Fields = {
  __typename?: 'dms_products_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "promo_codes" */
export type Dms_Promo_Codes = {
  __typename?: 'dms_promo_codes';
  code?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  discount?: Maybe<Scalars['Int']>;
  expiration?: Maybe<Scalars['date']>;
  id: Scalars['bigint'];
  type_code?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  use?: Maybe<Scalars['Boolean']>;
};

/** aggregated selection of "promo_codes" */
export type Dms_Promo_Codes_Aggregate = {
  __typename?: 'dms_promo_codes_aggregate';
  aggregate?: Maybe<Dms_Promo_Codes_Aggregate_Fields>;
  nodes: Array<Dms_Promo_Codes>;
};

/** aggregate fields of "promo_codes" */
export type Dms_Promo_Codes_Aggregate_Fields = {
  __typename?: 'dms_promo_codes_aggregate_fields';
  avg?: Maybe<Dms_Promo_Codes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dms_Promo_Codes_Max_Fields>;
  min?: Maybe<Dms_Promo_Codes_Min_Fields>;
  stddev?: Maybe<Dms_Promo_Codes_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Promo_Codes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Promo_Codes_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Promo_Codes_Sum_Fields>;
  var_pop?: Maybe<Dms_Promo_Codes_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Promo_Codes_Var_Samp_Fields>;
  variance?: Maybe<Dms_Promo_Codes_Variance_Fields>;
};


/** aggregate fields of "promo_codes" */
export type Dms_Promo_Codes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Promo_Codes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dms_Promo_Codes_Avg_Fields = {
  __typename?: 'dms_promo_codes_avg_fields';
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "promo_codes". All fields are combined with a logical 'AND'. */
export type Dms_Promo_Codes_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Promo_Codes_Bool_Exp>>;
  _not?: InputMaybe<Dms_Promo_Codes_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Promo_Codes_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  discount?: InputMaybe<Int_Comparison_Exp>;
  expiration?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  type_code?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  use?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "promo_codes" */
export enum Dms_Promo_Codes_Constraint {
  /** unique or primary key constraint on columns "id" */
  PromoCodesPkey = 'promo_codes_pkey'
}

/** input type for incrementing numeric columns in table "promo_codes" */
export type Dms_Promo_Codes_Inc_Input = {
  discount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "promo_codes" */
export type Dms_Promo_Codes_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  discount?: InputMaybe<Scalars['Int']>;
  expiration?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  type_code?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  use?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Dms_Promo_Codes_Max_Fields = {
  __typename?: 'dms_promo_codes_max_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  discount?: Maybe<Scalars['Int']>;
  expiration?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  type_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Dms_Promo_Codes_Min_Fields = {
  __typename?: 'dms_promo_codes_min_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  discount?: Maybe<Scalars['Int']>;
  expiration?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  type_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "promo_codes" */
export type Dms_Promo_Codes_Mutation_Response = {
  __typename?: 'dms_promo_codes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Promo_Codes>;
};

/** on_conflict condition type for table "promo_codes" */
export type Dms_Promo_Codes_On_Conflict = {
  constraint: Dms_Promo_Codes_Constraint;
  update_columns?: Array<Dms_Promo_Codes_Update_Column>;
  where?: InputMaybe<Dms_Promo_Codes_Bool_Exp>;
};

/** Ordering options when selecting data from "promo_codes". */
export type Dms_Promo_Codes_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  use?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dms_promo_codes */
export type Dms_Promo_Codes_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "promo_codes" */
export enum Dms_Promo_Codes_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Discount = 'discount',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  Id = 'id',
  /** column name */
  TypeCode = 'type_code',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Use = 'use'
}

/** input type for updating data in table "promo_codes" */
export type Dms_Promo_Codes_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  discount?: InputMaybe<Scalars['Int']>;
  expiration?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  type_code?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  use?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Dms_Promo_Codes_Stddev_Fields = {
  __typename?: 'dms_promo_codes_stddev_fields';
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dms_Promo_Codes_Stddev_Pop_Fields = {
  __typename?: 'dms_promo_codes_stddev_pop_fields';
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dms_Promo_Codes_Stddev_Samp_Fields = {
  __typename?: 'dms_promo_codes_stddev_samp_fields';
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dms_promo_codes" */
export type Dms_Promo_Codes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Promo_Codes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Promo_Codes_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  discount?: InputMaybe<Scalars['Int']>;
  expiration?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  type_code?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  use?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Dms_Promo_Codes_Sum_Fields = {
  __typename?: 'dms_promo_codes_sum_fields';
  discount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "promo_codes" */
export enum Dms_Promo_Codes_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Discount = 'discount',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  Id = 'id',
  /** column name */
  TypeCode = 'type_code',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Use = 'use'
}

export type Dms_Promo_Codes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Promo_Codes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Promo_Codes_Set_Input>;
  where: Dms_Promo_Codes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Promo_Codes_Var_Pop_Fields = {
  __typename?: 'dms_promo_codes_var_pop_fields';
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dms_Promo_Codes_Var_Samp_Fields = {
  __typename?: 'dms_promo_codes_var_samp_fields';
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dms_Promo_Codes_Variance_Fields = {
  __typename?: 'dms_promo_codes_variance_fields';
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "statuses" */
export type Dms_Statuses = {
  __typename?: 'dms_statuses';
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "statuses" */
export type Dms_Statuses_Aggregate = {
  __typename?: 'dms_statuses_aggregate';
  aggregate?: Maybe<Dms_Statuses_Aggregate_Fields>;
  nodes: Array<Dms_Statuses>;
};

/** aggregate fields of "statuses" */
export type Dms_Statuses_Aggregate_Fields = {
  __typename?: 'dms_statuses_aggregate_fields';
  avg?: Maybe<Dms_Statuses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dms_Statuses_Max_Fields>;
  min?: Maybe<Dms_Statuses_Min_Fields>;
  stddev?: Maybe<Dms_Statuses_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Statuses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Statuses_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Statuses_Sum_Fields>;
  var_pop?: Maybe<Dms_Statuses_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Statuses_Var_Samp_Fields>;
  variance?: Maybe<Dms_Statuses_Variance_Fields>;
};


/** aggregate fields of "statuses" */
export type Dms_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dms_Statuses_Avg_Fields = {
  __typename?: 'dms_statuses_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "statuses". All fields are combined with a logical 'AND'. */
export type Dms_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Dms_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Statuses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "statuses" */
export enum Dms_Statuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  StatusesPkey = 'statuses_pkey'
}

/** input type for incrementing numeric columns in table "statuses" */
export type Dms_Statuses_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "statuses" */
export type Dms_Statuses_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dms_Statuses_Max_Fields = {
  __typename?: 'dms_statuses_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Dms_Statuses_Min_Fields = {
  __typename?: 'dms_statuses_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "statuses" */
export type Dms_Statuses_Mutation_Response = {
  __typename?: 'dms_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Statuses>;
};

/** input type for inserting object relation for remote table "statuses" */
export type Dms_Statuses_Obj_Rel_Insert_Input = {
  data: Dms_Statuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Dms_Statuses_On_Conflict>;
};

/** on_conflict condition type for table "statuses" */
export type Dms_Statuses_On_Conflict = {
  constraint: Dms_Statuses_Constraint;
  update_columns?: Array<Dms_Statuses_Update_Column>;
  where?: InputMaybe<Dms_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "statuses". */
export type Dms_Statuses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dms_statuses */
export type Dms_Statuses_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "statuses" */
export enum Dms_Statuses_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "statuses" */
export type Dms_Statuses_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dms_Statuses_Stddev_Fields = {
  __typename?: 'dms_statuses_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dms_Statuses_Stddev_Pop_Fields = {
  __typename?: 'dms_statuses_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dms_Statuses_Stddev_Samp_Fields = {
  __typename?: 'dms_statuses_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dms_statuses" */
export type Dms_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Statuses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Dms_Statuses_Sum_Fields = {
  __typename?: 'dms_statuses_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "statuses" */
export enum Dms_Statuses_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Dms_Statuses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Statuses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Statuses_Set_Input>;
  where: Dms_Statuses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Statuses_Var_Pop_Fields = {
  __typename?: 'dms_statuses_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dms_Statuses_Var_Samp_Fields = {
  __typename?: 'dms_statuses_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dms_Statuses_Variance_Fields = {
  __typename?: 'dms_statuses_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "users" */
export type Dms_Users = {
  __typename?: 'dms_users';
  allow_password_change?: Maybe<Scalars['Boolean']>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamp']>;
  country_code?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  email?: Maybe<Scalars['String']>;
  encrypted_password: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  is_active?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  otp_secret_key?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  remember_created_at?: Maybe<Scalars['timestamp']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']>;
  reset_password_token?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  unconfirmed_email?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  username?: Maybe<Scalars['String']>;
};

/** aggregated selection of "users" */
export type Dms_Users_Aggregate = {
  __typename?: 'dms_users_aggregate';
  aggregate?: Maybe<Dms_Users_Aggregate_Fields>;
  nodes: Array<Dms_Users>;
};

/** aggregate fields of "users" */
export type Dms_Users_Aggregate_Fields = {
  __typename?: 'dms_users_aggregate_fields';
  avg?: Maybe<Dms_Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dms_Users_Max_Fields>;
  min?: Maybe<Dms_Users_Min_Fields>;
  stddev?: Maybe<Dms_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Users_Sum_Fields>;
  var_pop?: Maybe<Dms_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Users_Var_Samp_Fields>;
  variance?: Maybe<Dms_Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Dms_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dms_Users_Avg_Fields = {
  __typename?: 'dms_users_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Dms_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Users_Bool_Exp>>;
  _not?: InputMaybe<Dms_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Users_Bool_Exp>>;
  allow_password_change?: InputMaybe<Boolean_Comparison_Exp>;
  confirmation_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  confirmation_token?: InputMaybe<String_Comparison_Exp>;
  confirmed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  encrypted_password?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  otp_secret_key?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  remember_created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  reset_password_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  reset_password_token?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
  unconfirmed_email?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Dms_Users_Constraint {
  /** unique or primary key constraint on columns "confirmation_token" */
  IndexUsersOnConfirmationToken = 'index_users_on_confirmation_token',
  /** unique or primary key constraint on columns "email" */
  IndexUsersOnEmail = 'index_users_on_email',
  /** unique or primary key constraint on columns "reset_password_token" */
  IndexUsersOnResetPasswordToken = 'index_users_on_reset_password_token',
  /** unique or primary key constraint on columns "username" */
  IndexUsersOnUsername = 'index_users_on_username',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** input type for incrementing numeric columns in table "users" */
export type Dms_Users_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "users" */
export type Dms_Users_Insert_Input = {
  allow_password_change?: InputMaybe<Scalars['Boolean']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']>;
  confirmation_token?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  encrypted_password?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  otp_secret_key?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_token?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  unconfirmed_email?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Dms_Users_Max_Fields = {
  __typename?: 'dms_users_max_fields';
  confirmation_sent_at?: Maybe<Scalars['timestamp']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamp']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  encrypted_password?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  otp_secret_key?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  remember_created_at?: Maybe<Scalars['timestamp']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']>;
  reset_password_token?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  unconfirmed_email?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Dms_Users_Min_Fields = {
  __typename?: 'dms_users_min_fields';
  confirmation_sent_at?: Maybe<Scalars['timestamp']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamp']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  encrypted_password?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  otp_secret_key?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  remember_created_at?: Maybe<Scalars['timestamp']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']>;
  reset_password_token?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  unconfirmed_email?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  username?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "users" */
export type Dms_Users_Mutation_Response = {
  __typename?: 'dms_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Users>;
};

/** on_conflict condition type for table "users" */
export type Dms_Users_On_Conflict = {
  constraint: Dms_Users_Constraint;
  update_columns?: Array<Dms_Users_Update_Column>;
  where?: InputMaybe<Dms_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Dms_Users_Order_By = {
  allow_password_change?: InputMaybe<Order_By>;
  confirmation_sent_at?: InputMaybe<Order_By>;
  confirmation_token?: InputMaybe<Order_By>;
  confirmed_at?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  encrypted_password?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  otp_secret_key?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  remember_created_at?: InputMaybe<Order_By>;
  reset_password_sent_at?: InputMaybe<Order_By>;
  reset_password_token?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  unconfirmed_email?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dms_users */
export type Dms_Users_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "users" */
export enum Dms_Users_Select_Column {
  /** column name */
  AllowPasswordChange = 'allow_password_change',
  /** column name */
  ConfirmationSentAt = 'confirmation_sent_at',
  /** column name */
  ConfirmationToken = 'confirmation_token',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EncryptedPassword = 'encrypted_password',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Name = 'name',
  /** column name */
  OtpSecretKey = 'otp_secret_key',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  RememberCreatedAt = 'remember_created_at',
  /** column name */
  ResetPasswordSentAt = 'reset_password_sent_at',
  /** column name */
  ResetPasswordToken = 'reset_password_token',
  /** column name */
  Role = 'role',
  /** column name */
  Uid = 'uid',
  /** column name */
  UnconfirmedEmail = 'unconfirmed_email',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "users" */
export type Dms_Users_Set_Input = {
  allow_password_change?: InputMaybe<Scalars['Boolean']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']>;
  confirmation_token?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  encrypted_password?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  otp_secret_key?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_token?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  unconfirmed_email?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Dms_Users_Stddev_Fields = {
  __typename?: 'dms_users_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dms_Users_Stddev_Pop_Fields = {
  __typename?: 'dms_users_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dms_Users_Stddev_Samp_Fields = {
  __typename?: 'dms_users_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dms_users" */
export type Dms_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Users_Stream_Cursor_Value_Input = {
  allow_password_change?: InputMaybe<Scalars['Boolean']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']>;
  confirmation_token?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  encrypted_password?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  otp_secret_key?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_token?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  unconfirmed_email?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Dms_Users_Sum_Fields = {
  __typename?: 'dms_users_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "users" */
export enum Dms_Users_Update_Column {
  /** column name */
  AllowPasswordChange = 'allow_password_change',
  /** column name */
  ConfirmationSentAt = 'confirmation_sent_at',
  /** column name */
  ConfirmationToken = 'confirmation_token',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EncryptedPassword = 'encrypted_password',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Name = 'name',
  /** column name */
  OtpSecretKey = 'otp_secret_key',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  RememberCreatedAt = 'remember_created_at',
  /** column name */
  ResetPasswordSentAt = 'reset_password_sent_at',
  /** column name */
  ResetPasswordToken = 'reset_password_token',
  /** column name */
  Role = 'role',
  /** column name */
  Uid = 'uid',
  /** column name */
  UnconfirmedEmail = 'unconfirmed_email',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

export type Dms_Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Users_Set_Input>;
  where: Dms_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Users_Var_Pop_Fields = {
  __typename?: 'dms_users_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dms_Users_Var_Samp_Fields = {
  __typename?: 'dms_users_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dms_Users_Variance_Fields = {
  __typename?: 'dms_users_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  billingDmsPaytureGetStatus?: Maybe<BillingDmsPaytureGetStatusOutput>;
  billingOrderPoliceCreate?: Maybe<BillingOrderPoliceCreateOutput>;
  clientCreate?: Maybe<ClientCreatePayload>;
  /** Удаление застрахованного клиента */
  clientDestroy?: Maybe<ClientDestroyPayload>;
  /** Изменение данных застрахованного клиента */
  clientUpdate?: Maybe<ClientUpdatePayload>;
  /** Создание сети клиник ДМС */
  clinicDmsCreate?: Maybe<ClinicDmsCreatePayload>;
  /** Удаление сети клиник ДМС */
  clinicDmsDelete?: Maybe<ClinicDmsDeletePayload>;
  /** Изменение сети клиник ДМС */
  clinicDmsUpdate?: Maybe<ClinicDmsUpdatePayload>;
  createAttachment?: Maybe<CreateAttachmentOutput>;
  createAttachments?: Maybe<CreateAttachmentsOutput>;
  createClient?: Maybe<CreateClientPayload>;
  createCountryCurrency?: Maybe<CreateCountryCurrencyPayload>;
  createCountryListInsurance?: Maybe<CreateCountryListInsurancePayload>;
  createCurrencyListInsurance?: Maybe<CreateCurrencyListInsurancePayload>;
  createListCountryProgram?: Maybe<CreateListCountryProgramPayload>;
  createProgramInsurance?: Maybe<CreateProgramInsurancePayload>;
  createPromoCode?: Maybe<CreatePromoCodePayload>;
  createSubscriber?: Maybe<CreateSubscriberPayload>;
  createSumMedicalList?: Maybe<CreateSumMedicalListPayload>;
  createUser?: Maybe<CreateUserPayload>;
  createVzrInsuranceCompany?: Maybe<CreateVzrInsuranceCompanyPayload>;
  createVzrListCountry?: Maybe<CreateVzrListCountryPayload>;
  createVzrListCurrency?: Maybe<CreateVzrListCurrencyPayload>;
  create_direct_upload?: Maybe<CreateDirectUploadOutput>;
  custom_update_users_by_pk?: Maybe<UpdateUserOutput>;
  custom_update_users_by_pk_test?: Maybe<UpdateUserOutput>;
  deleteClient?: Maybe<DeleteClientPayload>;
  deleteCountryCurrency?: Maybe<DeleteCountryCurrencyPayload>;
  deleteCountryListInsurance?: Maybe<DeleteCountryListInsurancePayload>;
  deleteCurrencyListInsurance?: Maybe<DeleteCurrencyListInsurancePayload>;
  deleteListCountryProgram?: Maybe<DeleteListCountryProgramPayload>;
  deleteOrderPolice?: Maybe<DeleteOrderPolicePayload>;
  deleteProgramInsurance?: Maybe<DeleteProgramInsurancePayload>;
  deletePromoCode?: Maybe<DeletePromoCodePayload>;
  deleteSumMedicalList?: Maybe<DeleteSumMedicalListPayload>;
  deleteUser?: Maybe<DeleteUserPayload>;
  deleteVzrInsuranceCompany?: Maybe<DeleteVzrInsuranceCompanyPayload>;
  deleteVzrListCountry?: Maybe<DeleteVzrListCountryPayload>;
  deleteVzrListCurrency?: Maybe<DeleteVzrListCurrencyPayload>;
  /** delete data from the table: "users" */
  delete_auth_users?: Maybe<Auth_Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_auth_users_by_pk?: Maybe<Auth_Users>;
  /** delete data from the table: "group_insureds" */
  delete_dms_group_insureds?: Maybe<Dms_Group_Insureds_Mutation_Response>;
  /** delete single row from the table: "group_insureds" */
  delete_dms_group_insureds_by_pk?: Maybe<Dms_Group_Insureds>;
  /** delete data from the table: "groups" */
  delete_dms_groups?: Maybe<Dms_Groups_Mutation_Response>;
  /** delete single row from the table: "groups" */
  delete_dms_groups_by_pk?: Maybe<Dms_Groups>;
  /** delete data from the table: "insurants" */
  delete_dms_insurants?: Maybe<Dms_Insurants_Mutation_Response>;
  /** delete single row from the table: "insurants" */
  delete_dms_insurants_by_pk?: Maybe<Dms_Insurants>;
  /** delete data from the table: "insureds" */
  delete_dms_insureds?: Maybe<Dms_Insureds_Mutation_Response>;
  /** delete single row from the table: "insureds" */
  delete_dms_insureds_by_pk?: Maybe<Dms_Insureds>;
  /** delete data from the table: "order_links" */
  delete_dms_order_links?: Maybe<Dms_Order_Links_Mutation_Response>;
  /** delete single row from the table: "order_links" */
  delete_dms_order_links_by_pk?: Maybe<Dms_Order_Links>;
  /** delete data from the table: "order_numbers" */
  delete_dms_order_numbers?: Maybe<Dms_Order_Numbers_Mutation_Response>;
  /** delete single row from the table: "order_numbers" */
  delete_dms_order_numbers_by_pk?: Maybe<Dms_Order_Numbers>;
  /** delete data from the table: "orders" */
  delete_dms_orders?: Maybe<Dms_Orders_Mutation_Response>;
  /** delete single row from the table: "orders" */
  delete_dms_orders_by_pk?: Maybe<Dms_Orders>;
  /** delete data from the table: "products" */
  delete_dms_products?: Maybe<Dms_Products_Mutation_Response>;
  /** delete single row from the table: "products" */
  delete_dms_products_by_pk?: Maybe<Dms_Products>;
  /** delete data from the table: "promo_codes" */
  delete_dms_promo_codes?: Maybe<Dms_Promo_Codes_Mutation_Response>;
  /** delete single row from the table: "promo_codes" */
  delete_dms_promo_codes_by_pk?: Maybe<Dms_Promo_Codes>;
  /** delete data from the table: "statuses" */
  delete_dms_statuses?: Maybe<Dms_Statuses_Mutation_Response>;
  /** delete single row from the table: "statuses" */
  delete_dms_statuses_by_pk?: Maybe<Dms_Statuses>;
  /** delete data from the table: "users" */
  delete_dms_users?: Maybe<Dms_Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_dms_users_by_pk?: Maybe<Dms_Users>;
  /** delete data from the table: "balances" */
  delete_partner_balances?: Maybe<Partner_Balances_Mutation_Response>;
  /** delete single row from the table: "balances" */
  delete_partner_balances_by_pk?: Maybe<Partner_Balances>;
  /** delete data from the table: "log_vzr_sales" */
  delete_partner_log_vzr_sales?: Maybe<Partner_Log_Vzr_Sales_Mutation_Response>;
  /** delete single row from the table: "log_vzr_sales" */
  delete_partner_log_vzr_sales_by_pk?: Maybe<Partner_Log_Vzr_Sales>;
  /** delete data from the table: "log_vzr_searches" */
  delete_partner_log_vzr_searches?: Maybe<Partner_Log_Vzr_Searches_Mutation_Response>;
  /** delete single row from the table: "log_vzr_searches" */
  delete_partner_log_vzr_searches_by_pk?: Maybe<Partner_Log_Vzr_Searches>;
  /** delete data from the table: "log_vzr_views" */
  delete_partner_log_vzr_views?: Maybe<Partner_Log_Vzr_Views_Mutation_Response>;
  /** delete single row from the table: "log_vzr_views" */
  delete_partner_log_vzr_views_by_pk?: Maybe<Partner_Log_Vzr_Views>;
  /** delete data from the table: "place_type_integrations" */
  delete_partner_place_type_integrations?: Maybe<Partner_Place_Type_Integrations_Mutation_Response>;
  /** delete single row from the table: "place_type_integrations" */
  delete_partner_place_type_integrations_by_pk?: Maybe<Partner_Place_Type_Integrations>;
  /** delete data from the table: "places" */
  delete_partner_places?: Maybe<Partner_Places_Mutation_Response>;
  /** delete single row from the table: "places" */
  delete_partner_places_by_pk?: Maybe<Partner_Places>;
  /** delete data from the table: "sale_types" */
  delete_partner_sale_types?: Maybe<Partner_Sale_Types_Mutation_Response>;
  /** delete single row from the table: "sale_types" */
  delete_partner_sale_types_by_pk?: Maybe<Partner_Sale_Types>;
  /** delete data from the table: "status_integrations" */
  delete_partner_status_integrations?: Maybe<Partner_Status_Integrations_Mutation_Response>;
  /** delete single row from the table: "status_integrations" */
  delete_partner_status_integrations_by_pk?: Maybe<Partner_Status_Integrations>;
  /** delete data from the table: "status_transactions" */
  delete_partner_status_transactions?: Maybe<Partner_Status_Transactions_Mutation_Response>;
  /** delete single row from the table: "status_transactions" */
  delete_partner_status_transactions_by_pk?: Maybe<Partner_Status_Transactions>;
  /** delete data from the table: "themes" */
  delete_partner_themes?: Maybe<Partner_Themes_Mutation_Response>;
  /** delete single row from the table: "themes" */
  delete_partner_themes_by_pk?: Maybe<Partner_Themes>;
  /** delete data from the table: "type_integrations" */
  delete_partner_type_integrations?: Maybe<Partner_Type_Integrations_Mutation_Response>;
  /** delete single row from the table: "type_integrations" */
  delete_partner_type_integrations_by_pk?: Maybe<Partner_Type_Integrations>;
  /** delete data from the table: "type_transactions" */
  delete_partner_type_transactions?: Maybe<Partner_Type_Transactions_Mutation_Response>;
  /** delete single row from the table: "type_transactions" */
  delete_partner_type_transactions_by_pk?: Maybe<Partner_Type_Transactions>;
  /** delete data from the table: "user_types" */
  delete_partner_user_types?: Maybe<Partner_User_Types_Mutation_Response>;
  /** delete single row from the table: "user_types" */
  delete_partner_user_types_by_pk?: Maybe<Partner_User_Types>;
  /** delete data from the table: "users" */
  delete_partner_users?: Maybe<Partner_Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_partner_users_by_pk?: Maybe<Partner_Users>;
  dmsCreateInsurant?: Maybe<DmsCreateInsurantOutput>;
  dmsCreateOrder?: Maybe<DmsCreateOrderOutput>;
  dmsCreateOrderNumber?: Maybe<DmsCreateOrderNumberOutput>;
  dmsPrintOrder?: Maybe<DmsPrintOrderOutput>;
  /** Создание филиал ДМС */
  filialDmsCreate?: Maybe<FilialDmsCreatePayload>;
  /** Удаление филиала клиники ДМС */
  filialDmsDelete?: Maybe<FilialDmsDeletePayload>;
  /** Удаление филиала клиники ДМС */
  filialDmsPhotoDelete?: Maybe<FilialDmsPhotoDeletePayload>;
  /** Изменение филиала клиники ДМС */
  filialDmsUpdate?: Maybe<FilialDmsUpdatePayload>;
  /** insert data into the table: "users" */
  insert_auth_users?: Maybe<Auth_Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_auth_users_one?: Maybe<Auth_Users>;
  /** insert data into the table: "group_insureds" */
  insert_dms_group_insureds?: Maybe<Dms_Group_Insureds_Mutation_Response>;
  /** insert a single row into the table: "group_insureds" */
  insert_dms_group_insureds_one?: Maybe<Dms_Group_Insureds>;
  /** insert data into the table: "groups" */
  insert_dms_groups?: Maybe<Dms_Groups_Mutation_Response>;
  /** insert a single row into the table: "groups" */
  insert_dms_groups_one?: Maybe<Dms_Groups>;
  /** insert data into the table: "insurants" */
  insert_dms_insurants?: Maybe<Dms_Insurants_Mutation_Response>;
  /** insert a single row into the table: "insurants" */
  insert_dms_insurants_one?: Maybe<Dms_Insurants>;
  /** insert data into the table: "insureds" */
  insert_dms_insureds?: Maybe<Dms_Insureds_Mutation_Response>;
  /** insert a single row into the table: "insureds" */
  insert_dms_insureds_one?: Maybe<Dms_Insureds>;
  /** insert data into the table: "order_links" */
  insert_dms_order_links?: Maybe<Dms_Order_Links_Mutation_Response>;
  /** insert a single row into the table: "order_links" */
  insert_dms_order_links_one?: Maybe<Dms_Order_Links>;
  /** insert data into the table: "order_numbers" */
  insert_dms_order_numbers?: Maybe<Dms_Order_Numbers_Mutation_Response>;
  /** insert a single row into the table: "order_numbers" */
  insert_dms_order_numbers_one?: Maybe<Dms_Order_Numbers>;
  /** insert data into the table: "orders" */
  insert_dms_orders?: Maybe<Dms_Orders_Mutation_Response>;
  /** insert a single row into the table: "orders" */
  insert_dms_orders_one?: Maybe<Dms_Orders>;
  /** insert data into the table: "products" */
  insert_dms_products?: Maybe<Dms_Products_Mutation_Response>;
  /** insert a single row into the table: "products" */
  insert_dms_products_one?: Maybe<Dms_Products>;
  /** insert data into the table: "promo_codes" */
  insert_dms_promo_codes?: Maybe<Dms_Promo_Codes_Mutation_Response>;
  /** insert a single row into the table: "promo_codes" */
  insert_dms_promo_codes_one?: Maybe<Dms_Promo_Codes>;
  /** insert data into the table: "statuses" */
  insert_dms_statuses?: Maybe<Dms_Statuses_Mutation_Response>;
  /** insert a single row into the table: "statuses" */
  insert_dms_statuses_one?: Maybe<Dms_Statuses>;
  /** insert data into the table: "users" */
  insert_dms_users?: Maybe<Dms_Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_dms_users_one?: Maybe<Dms_Users>;
  /** insert data into the table: "balances" */
  insert_partner_balances?: Maybe<Partner_Balances_Mutation_Response>;
  /** insert a single row into the table: "balances" */
  insert_partner_balances_one?: Maybe<Partner_Balances>;
  /** insert data into the table: "log_vzr_sales" */
  insert_partner_log_vzr_sales?: Maybe<Partner_Log_Vzr_Sales_Mutation_Response>;
  /** insert a single row into the table: "log_vzr_sales" */
  insert_partner_log_vzr_sales_one?: Maybe<Partner_Log_Vzr_Sales>;
  /** insert data into the table: "log_vzr_searches" */
  insert_partner_log_vzr_searches?: Maybe<Partner_Log_Vzr_Searches_Mutation_Response>;
  /** insert a single row into the table: "log_vzr_searches" */
  insert_partner_log_vzr_searches_one?: Maybe<Partner_Log_Vzr_Searches>;
  /** insert data into the table: "log_vzr_views" */
  insert_partner_log_vzr_views?: Maybe<Partner_Log_Vzr_Views_Mutation_Response>;
  /** insert a single row into the table: "log_vzr_views" */
  insert_partner_log_vzr_views_one?: Maybe<Partner_Log_Vzr_Views>;
  /** insert data into the table: "place_type_integrations" */
  insert_partner_place_type_integrations?: Maybe<Partner_Place_Type_Integrations_Mutation_Response>;
  /** insert a single row into the table: "place_type_integrations" */
  insert_partner_place_type_integrations_one?: Maybe<Partner_Place_Type_Integrations>;
  /** insert data into the table: "places" */
  insert_partner_places?: Maybe<Partner_Places_Mutation_Response>;
  /** insert a single row into the table: "places" */
  insert_partner_places_one?: Maybe<Partner_Places>;
  /** insert data into the table: "sale_types" */
  insert_partner_sale_types?: Maybe<Partner_Sale_Types_Mutation_Response>;
  /** insert a single row into the table: "sale_types" */
  insert_partner_sale_types_one?: Maybe<Partner_Sale_Types>;
  /** insert data into the table: "status_integrations" */
  insert_partner_status_integrations?: Maybe<Partner_Status_Integrations_Mutation_Response>;
  /** insert a single row into the table: "status_integrations" */
  insert_partner_status_integrations_one?: Maybe<Partner_Status_Integrations>;
  /** insert data into the table: "status_transactions" */
  insert_partner_status_transactions?: Maybe<Partner_Status_Transactions_Mutation_Response>;
  /** insert a single row into the table: "status_transactions" */
  insert_partner_status_transactions_one?: Maybe<Partner_Status_Transactions>;
  /** insert data into the table: "themes" */
  insert_partner_themes?: Maybe<Partner_Themes_Mutation_Response>;
  /** insert a single row into the table: "themes" */
  insert_partner_themes_one?: Maybe<Partner_Themes>;
  /** insert data into the table: "type_integrations" */
  insert_partner_type_integrations?: Maybe<Partner_Type_Integrations_Mutation_Response>;
  /** insert a single row into the table: "type_integrations" */
  insert_partner_type_integrations_one?: Maybe<Partner_Type_Integrations>;
  /** insert data into the table: "type_transactions" */
  insert_partner_type_transactions?: Maybe<Partner_Type_Transactions_Mutation_Response>;
  /** insert a single row into the table: "type_transactions" */
  insert_partner_type_transactions_one?: Maybe<Partner_Type_Transactions>;
  /** insert data into the table: "user_types" */
  insert_partner_user_types?: Maybe<Partner_User_Types_Mutation_Response>;
  /** insert a single row into the table: "user_types" */
  insert_partner_user_types_one?: Maybe<Partner_User_Types>;
  /** insert data into the table: "users" */
  insert_partner_users?: Maybe<Partner_Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_partner_users_one?: Maybe<Partner_Users>;
  /** Добавление страховой компании ДМС */
  insuranceDmsCreate?: Maybe<InsuranceDmsCreatePayload>;
  /** Удаление страховой компании ДМС */
  insuranceDmsDelete?: Maybe<InsuranceDmsDeletePayload>;
  /** Изменение страховой компании ДМС */
  insuranceDmsUpdate?: Maybe<InsuranceDmsUpdatePayload>;
  orderPoliceCreate?: Maybe<OrderPoliceCreatePayload>;
  partnerConfirmFileUploading?: Maybe<PartnerConfirmFileUploadingOutput>;
  partnerCreateFileAccess?: Maybe<PartnerCreateFileAccessOutput>;
  partnerCreatePlace?: Maybe<PartnerCreatePlaceOutput>;
  partnerCreatePlaceTypeIntegrations?: Maybe<PartnerCreatePlaceTypeIntegrationsOutput>;
  partnerInsertBalances?: Maybe<PartnerInsertBalancesOutput>;
  updateClient?: Maybe<UpdateClientPayload>;
  updateCountryCurrency?: Maybe<UpdateCountryCurrencyPayload>;
  updateCountryListInsurance?: Maybe<UpdateCountryListInsurancePayload>;
  updateCurrencyListInsurance?: Maybe<UpdateCurrencyListInsurancePayload>;
  updateListCountryProgram?: Maybe<UpdateListCountryProgramPayload>;
  updateProgramInsurance?: Maybe<UpdateProgramInsurancePayload>;
  updatePromoCode?: Maybe<UpdatePromoCodePayload>;
  updateSumMedicalList?: Maybe<UpdateSumMedicalListPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  updateVzrInsuranceCompany?: Maybe<UpdateVzrInsuranceCompanyPayload>;
  updateVzrListCountry?: Maybe<UpdateVzrListCountryPayload>;
  updateVzrListCurrency?: Maybe<UpdateVzrListCurrencyPayload>;
  /** update data of the table: "users" */
  update_auth_users?: Maybe<Auth_Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_auth_users_by_pk?: Maybe<Auth_Users>;
  /** update multiples rows of table: "users" */
  update_auth_users_many?: Maybe<Array<Maybe<Auth_Users_Mutation_Response>>>;
  /** update data of the table: "group_insureds" */
  update_dms_group_insureds?: Maybe<Dms_Group_Insureds_Mutation_Response>;
  /** update single row of the table: "group_insureds" */
  update_dms_group_insureds_by_pk?: Maybe<Dms_Group_Insureds>;
  /** update multiples rows of table: "group_insureds" */
  update_dms_group_insureds_many?: Maybe<Array<Maybe<Dms_Group_Insureds_Mutation_Response>>>;
  /** update data of the table: "groups" */
  update_dms_groups?: Maybe<Dms_Groups_Mutation_Response>;
  /** update single row of the table: "groups" */
  update_dms_groups_by_pk?: Maybe<Dms_Groups>;
  /** update multiples rows of table: "groups" */
  update_dms_groups_many?: Maybe<Array<Maybe<Dms_Groups_Mutation_Response>>>;
  /** update data of the table: "insurants" */
  update_dms_insurants?: Maybe<Dms_Insurants_Mutation_Response>;
  /** update single row of the table: "insurants" */
  update_dms_insurants_by_pk?: Maybe<Dms_Insurants>;
  /** update multiples rows of table: "insurants" */
  update_dms_insurants_many?: Maybe<Array<Maybe<Dms_Insurants_Mutation_Response>>>;
  /** update data of the table: "insureds" */
  update_dms_insureds?: Maybe<Dms_Insureds_Mutation_Response>;
  /** update single row of the table: "insureds" */
  update_dms_insureds_by_pk?: Maybe<Dms_Insureds>;
  /** update multiples rows of table: "insureds" */
  update_dms_insureds_many?: Maybe<Array<Maybe<Dms_Insureds_Mutation_Response>>>;
  /** update data of the table: "order_links" */
  update_dms_order_links?: Maybe<Dms_Order_Links_Mutation_Response>;
  /** update single row of the table: "order_links" */
  update_dms_order_links_by_pk?: Maybe<Dms_Order_Links>;
  /** update multiples rows of table: "order_links" */
  update_dms_order_links_many?: Maybe<Array<Maybe<Dms_Order_Links_Mutation_Response>>>;
  /** update data of the table: "order_numbers" */
  update_dms_order_numbers?: Maybe<Dms_Order_Numbers_Mutation_Response>;
  /** update single row of the table: "order_numbers" */
  update_dms_order_numbers_by_pk?: Maybe<Dms_Order_Numbers>;
  /** update multiples rows of table: "order_numbers" */
  update_dms_order_numbers_many?: Maybe<Array<Maybe<Dms_Order_Numbers_Mutation_Response>>>;
  /** update data of the table: "orders" */
  update_dms_orders?: Maybe<Dms_Orders_Mutation_Response>;
  /** update single row of the table: "orders" */
  update_dms_orders_by_pk?: Maybe<Dms_Orders>;
  /** update multiples rows of table: "orders" */
  update_dms_orders_many?: Maybe<Array<Maybe<Dms_Orders_Mutation_Response>>>;
  /** update data of the table: "products" */
  update_dms_products?: Maybe<Dms_Products_Mutation_Response>;
  /** update single row of the table: "products" */
  update_dms_products_by_pk?: Maybe<Dms_Products>;
  /** update multiples rows of table: "products" */
  update_dms_products_many?: Maybe<Array<Maybe<Dms_Products_Mutation_Response>>>;
  /** update data of the table: "promo_codes" */
  update_dms_promo_codes?: Maybe<Dms_Promo_Codes_Mutation_Response>;
  /** update single row of the table: "promo_codes" */
  update_dms_promo_codes_by_pk?: Maybe<Dms_Promo_Codes>;
  /** update multiples rows of table: "promo_codes" */
  update_dms_promo_codes_many?: Maybe<Array<Maybe<Dms_Promo_Codes_Mutation_Response>>>;
  /** update data of the table: "statuses" */
  update_dms_statuses?: Maybe<Dms_Statuses_Mutation_Response>;
  /** update single row of the table: "statuses" */
  update_dms_statuses_by_pk?: Maybe<Dms_Statuses>;
  /** update multiples rows of table: "statuses" */
  update_dms_statuses_many?: Maybe<Array<Maybe<Dms_Statuses_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_dms_users?: Maybe<Dms_Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_dms_users_by_pk?: Maybe<Dms_Users>;
  /** update multiples rows of table: "users" */
  update_dms_users_many?: Maybe<Array<Maybe<Dms_Users_Mutation_Response>>>;
  /** update data of the table: "balances" */
  update_partner_balances?: Maybe<Partner_Balances_Mutation_Response>;
  /** update single row of the table: "balances" */
  update_partner_balances_by_pk?: Maybe<Partner_Balances>;
  /** update multiples rows of table: "balances" */
  update_partner_balances_many?: Maybe<Array<Maybe<Partner_Balances_Mutation_Response>>>;
  /** update data of the table: "log_vzr_sales" */
  update_partner_log_vzr_sales?: Maybe<Partner_Log_Vzr_Sales_Mutation_Response>;
  /** update single row of the table: "log_vzr_sales" */
  update_partner_log_vzr_sales_by_pk?: Maybe<Partner_Log_Vzr_Sales>;
  /** update multiples rows of table: "log_vzr_sales" */
  update_partner_log_vzr_sales_many?: Maybe<Array<Maybe<Partner_Log_Vzr_Sales_Mutation_Response>>>;
  /** update data of the table: "log_vzr_searches" */
  update_partner_log_vzr_searches?: Maybe<Partner_Log_Vzr_Searches_Mutation_Response>;
  /** update single row of the table: "log_vzr_searches" */
  update_partner_log_vzr_searches_by_pk?: Maybe<Partner_Log_Vzr_Searches>;
  /** update multiples rows of table: "log_vzr_searches" */
  update_partner_log_vzr_searches_many?: Maybe<Array<Maybe<Partner_Log_Vzr_Searches_Mutation_Response>>>;
  /** update data of the table: "log_vzr_views" */
  update_partner_log_vzr_views?: Maybe<Partner_Log_Vzr_Views_Mutation_Response>;
  /** update single row of the table: "log_vzr_views" */
  update_partner_log_vzr_views_by_pk?: Maybe<Partner_Log_Vzr_Views>;
  /** update multiples rows of table: "log_vzr_views" */
  update_partner_log_vzr_views_many?: Maybe<Array<Maybe<Partner_Log_Vzr_Views_Mutation_Response>>>;
  /** update data of the table: "place_type_integrations" */
  update_partner_place_type_integrations?: Maybe<Partner_Place_Type_Integrations_Mutation_Response>;
  /** update single row of the table: "place_type_integrations" */
  update_partner_place_type_integrations_by_pk?: Maybe<Partner_Place_Type_Integrations>;
  /** update multiples rows of table: "place_type_integrations" */
  update_partner_place_type_integrations_many?: Maybe<Array<Maybe<Partner_Place_Type_Integrations_Mutation_Response>>>;
  /** update data of the table: "places" */
  update_partner_places?: Maybe<Partner_Places_Mutation_Response>;
  /** update single row of the table: "places" */
  update_partner_places_by_pk?: Maybe<Partner_Places>;
  /** update multiples rows of table: "places" */
  update_partner_places_many?: Maybe<Array<Maybe<Partner_Places_Mutation_Response>>>;
  /** update data of the table: "sale_types" */
  update_partner_sale_types?: Maybe<Partner_Sale_Types_Mutation_Response>;
  /** update single row of the table: "sale_types" */
  update_partner_sale_types_by_pk?: Maybe<Partner_Sale_Types>;
  /** update multiples rows of table: "sale_types" */
  update_partner_sale_types_many?: Maybe<Array<Maybe<Partner_Sale_Types_Mutation_Response>>>;
  /** update data of the table: "status_integrations" */
  update_partner_status_integrations?: Maybe<Partner_Status_Integrations_Mutation_Response>;
  /** update single row of the table: "status_integrations" */
  update_partner_status_integrations_by_pk?: Maybe<Partner_Status_Integrations>;
  /** update multiples rows of table: "status_integrations" */
  update_partner_status_integrations_many?: Maybe<Array<Maybe<Partner_Status_Integrations_Mutation_Response>>>;
  /** update data of the table: "status_transactions" */
  update_partner_status_transactions?: Maybe<Partner_Status_Transactions_Mutation_Response>;
  /** update single row of the table: "status_transactions" */
  update_partner_status_transactions_by_pk?: Maybe<Partner_Status_Transactions>;
  /** update multiples rows of table: "status_transactions" */
  update_partner_status_transactions_many?: Maybe<Array<Maybe<Partner_Status_Transactions_Mutation_Response>>>;
  /** update data of the table: "themes" */
  update_partner_themes?: Maybe<Partner_Themes_Mutation_Response>;
  /** update single row of the table: "themes" */
  update_partner_themes_by_pk?: Maybe<Partner_Themes>;
  /** update multiples rows of table: "themes" */
  update_partner_themes_many?: Maybe<Array<Maybe<Partner_Themes_Mutation_Response>>>;
  /** update data of the table: "type_integrations" */
  update_partner_type_integrations?: Maybe<Partner_Type_Integrations_Mutation_Response>;
  /** update single row of the table: "type_integrations" */
  update_partner_type_integrations_by_pk?: Maybe<Partner_Type_Integrations>;
  /** update multiples rows of table: "type_integrations" */
  update_partner_type_integrations_many?: Maybe<Array<Maybe<Partner_Type_Integrations_Mutation_Response>>>;
  /** update data of the table: "type_transactions" */
  update_partner_type_transactions?: Maybe<Partner_Type_Transactions_Mutation_Response>;
  /** update single row of the table: "type_transactions" */
  update_partner_type_transactions_by_pk?: Maybe<Partner_Type_Transactions>;
  /** update multiples rows of table: "type_transactions" */
  update_partner_type_transactions_many?: Maybe<Array<Maybe<Partner_Type_Transactions_Mutation_Response>>>;
  /** update data of the table: "user_types" */
  update_partner_user_types?: Maybe<Partner_User_Types_Mutation_Response>;
  /** update single row of the table: "user_types" */
  update_partner_user_types_by_pk?: Maybe<Partner_User_Types>;
  /** update multiples rows of table: "user_types" */
  update_partner_user_types_many?: Maybe<Array<Maybe<Partner_User_Types_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_partner_users?: Maybe<Partner_Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_partner_users_by_pk?: Maybe<Partner_Users>;
  /** update multiples rows of table: "users" */
  update_partner_users_many?: Maybe<Array<Maybe<Partner_Users_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootBillingDmsPaytureGetStatusArgs = {
  arg: BillingDmsPaytureGetStatusInput;
};


/** mutation root */
export type Mutation_RootBillingOrderPoliceCreateArgs = {
  arg: BillingOrderPoliceCreateInput;
};


/** mutation root */
export type Mutation_RootClientCreateArgs = {
  input: ClientCreateInput;
};


/** mutation root */
export type Mutation_RootClientDestroyArgs = {
  input: ClientDestroyInput;
};


/** mutation root */
export type Mutation_RootClientUpdateArgs = {
  input: ClientUpdateInput;
};


/** mutation root */
export type Mutation_RootClinicDmsCreateArgs = {
  input: ClinicDmsCreateInput;
};


/** mutation root */
export type Mutation_RootClinicDmsDeleteArgs = {
  input: ClinicDmsDeleteInput;
};


/** mutation root */
export type Mutation_RootClinicDmsUpdateArgs = {
  input: ClinicDmsUpdateInput;
};


/** mutation root */
export type Mutation_RootCreateAttachmentArgs = {
  input: CreateAttachmentInput;
};


/** mutation root */
export type Mutation_RootCreateAttachmentsArgs = {
  input: CreateAttachmentsInput;
};


/** mutation root */
export type Mutation_RootCreateClientArgs = {
  input: CreateClientInput;
};


/** mutation root */
export type Mutation_RootCreateCountryCurrencyArgs = {
  input: CreateCountryCurrencyInput;
};


/** mutation root */
export type Mutation_RootCreateCountryListInsuranceArgs = {
  input: CreateCountryListInsuranceInput;
};


/** mutation root */
export type Mutation_RootCreateCurrencyListInsuranceArgs = {
  input: CreateCurrencyListInsuranceInput;
};


/** mutation root */
export type Mutation_RootCreateListCountryProgramArgs = {
  input: CreateListCountryProgramInput;
};


/** mutation root */
export type Mutation_RootCreateProgramInsuranceArgs = {
  input: CreateProgramInsuranceInput;
};


/** mutation root */
export type Mutation_RootCreatePromoCodeArgs = {
  input: CreatePromoCodeInput;
};


/** mutation root */
export type Mutation_RootCreateSubscriberArgs = {
  input: CreateSubscriberInput;
};


/** mutation root */
export type Mutation_RootCreateSumMedicalListArgs = {
  input: CreateSumMedicalListInput;
};


/** mutation root */
export type Mutation_RootCreateUserArgs = {
  input: CreateUserInput;
};


/** mutation root */
export type Mutation_RootCreateVzrInsuranceCompanyArgs = {
  input: CreateVzrInsuranceCompanyInput;
};


/** mutation root */
export type Mutation_RootCreateVzrListCountryArgs = {
  input: CreateVzrListCountryInput;
};


/** mutation root */
export type Mutation_RootCreateVzrListCurrencyArgs = {
  input: CreateVzrListCurrencyInput;
};


/** mutation root */
export type Mutation_RootCreate_Direct_UploadArgs = {
  object: CreateDirectUploadInput;
};


/** mutation root */
export type Mutation_RootCustom_Update_Users_By_PkArgs = {
  _set?: InputMaybe<UpdateUserUsersSetInput>;
  pk_columns: UpdateUserUsersPkColumnsInput;
};


/** mutation root */
export type Mutation_RootCustom_Update_Users_By_Pk_TestArgs = {
  _set?: InputMaybe<UpdateUserUsersSetInput>;
  pk_columns: UpdateUserUsersPkColumnsInput;
};


/** mutation root */
export type Mutation_RootDeleteClientArgs = {
  input: DeleteClientInput;
};


/** mutation root */
export type Mutation_RootDeleteCountryCurrencyArgs = {
  input: DeleteCountryCurrencyInput;
};


/** mutation root */
export type Mutation_RootDeleteCountryListInsuranceArgs = {
  input: DeleteCountryListInsuranceInput;
};


/** mutation root */
export type Mutation_RootDeleteCurrencyListInsuranceArgs = {
  input: DeleteCurrencyListInsuranceInput;
};


/** mutation root */
export type Mutation_RootDeleteListCountryProgramArgs = {
  input: DeleteListCountryProgramInput;
};


/** mutation root */
export type Mutation_RootDeleteOrderPoliceArgs = {
  input: DeleteOrderPoliceInput;
};


/** mutation root */
export type Mutation_RootDeleteProgramInsuranceArgs = {
  input: DeleteProgramInsuranceInput;
};


/** mutation root */
export type Mutation_RootDeletePromoCodeArgs = {
  input: DeletePromoCodeInput;
};


/** mutation root */
export type Mutation_RootDeleteSumMedicalListArgs = {
  input: DeleteSumMedicalListInput;
};


/** mutation root */
export type Mutation_RootDeleteUserArgs = {
  input: DeleteUserInput;
};


/** mutation root */
export type Mutation_RootDeleteVzrInsuranceCompanyArgs = {
  input: DeleteVzrInsuranceCompanyInput;
};


/** mutation root */
export type Mutation_RootDeleteVzrListCountryArgs = {
  input: DeleteVzrListCountryInput;
};


/** mutation root */
export type Mutation_RootDeleteVzrListCurrencyArgs = {
  input: DeleteVzrListCurrencyInput;
};


/** mutation root */
export type Mutation_RootDelete_Auth_UsersArgs = {
  where: Auth_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_Group_InsuredsArgs = {
  where: Dms_Group_Insureds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Group_Insureds_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_GroupsArgs = {
  where: Dms_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Groups_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_InsurantsArgs = {
  where: Dms_Insurants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Insurants_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_InsuredsArgs = {
  where: Dms_Insureds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Insureds_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_Order_LinksArgs = {
  where: Dms_Order_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Order_Links_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_Order_NumbersArgs = {
  where: Dms_Order_Numbers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Order_Numbers_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_OrdersArgs = {
  where: Dms_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Orders_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_ProductsArgs = {
  where: Dms_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Products_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_Promo_CodesArgs = {
  where: Dms_Promo_Codes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Promo_Codes_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_StatusesArgs = {
  where: Dms_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Statuses_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_UsersArgs = {
  where: Dms_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_BalancesArgs = {
  where: Partner_Balances_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Balances_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_Log_Vzr_SalesArgs = {
  where: Partner_Log_Vzr_Sales_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Log_Vzr_Sales_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_Log_Vzr_SearchesArgs = {
  where: Partner_Log_Vzr_Searches_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Log_Vzr_Searches_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_Log_Vzr_ViewsArgs = {
  where: Partner_Log_Vzr_Views_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Log_Vzr_Views_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_Place_Type_IntegrationsArgs = {
  where: Partner_Place_Type_Integrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Place_Type_Integrations_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_PlacesArgs = {
  where: Partner_Places_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Places_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_Sale_TypesArgs = {
  where: Partner_Sale_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Sale_Types_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_Status_IntegrationsArgs = {
  where: Partner_Status_Integrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Status_Integrations_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_Status_TransactionsArgs = {
  where: Partner_Status_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Status_Transactions_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_ThemesArgs = {
  where: Partner_Themes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Themes_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_Type_IntegrationsArgs = {
  where: Partner_Type_Integrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Type_Integrations_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_Type_TransactionsArgs = {
  where: Partner_Type_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Type_Transactions_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_User_TypesArgs = {
  where: Partner_User_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_User_Types_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_UsersArgs = {
  where: Partner_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDmsCreateInsurantArgs = {
  arg: DmsCreateInsurantInput;
};


/** mutation root */
export type Mutation_RootDmsCreateOrderArgs = {
  arg: DmsCreateOrderInput;
};


/** mutation root */
export type Mutation_RootDmsPrintOrderArgs = {
  arg: DmsPrintOrderInput;
};


/** mutation root */
export type Mutation_RootFilialDmsCreateArgs = {
  input: FilialDmsCreateInput;
};


/** mutation root */
export type Mutation_RootFilialDmsDeleteArgs = {
  input: FilialDmsDeleteInput;
};


/** mutation root */
export type Mutation_RootFilialDmsPhotoDeleteArgs = {
  input: FilialDmsPhotoDeleteInput;
};


/** mutation root */
export type Mutation_RootFilialDmsUpdateArgs = {
  input: FilialDmsUpdateInput;
};


/** mutation root */
export type Mutation_RootInsert_Auth_UsersArgs = {
  objects: Array<Auth_Users_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Users_OneArgs = {
  object: Auth_Users_Insert_Input;
  on_conflict?: InputMaybe<Auth_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Group_InsuredsArgs = {
  objects: Array<Dms_Group_Insureds_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Group_Insureds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Group_Insureds_OneArgs = {
  object: Dms_Group_Insureds_Insert_Input;
  on_conflict?: InputMaybe<Dms_Group_Insureds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_GroupsArgs = {
  objects: Array<Dms_Groups_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Groups_OneArgs = {
  object: Dms_Groups_Insert_Input;
  on_conflict?: InputMaybe<Dms_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_InsurantsArgs = {
  objects: Array<Dms_Insurants_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Insurants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Insurants_OneArgs = {
  object: Dms_Insurants_Insert_Input;
  on_conflict?: InputMaybe<Dms_Insurants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_InsuredsArgs = {
  objects: Array<Dms_Insureds_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Insureds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Insureds_OneArgs = {
  object: Dms_Insureds_Insert_Input;
  on_conflict?: InputMaybe<Dms_Insureds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Order_LinksArgs = {
  objects: Array<Dms_Order_Links_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Order_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Order_Links_OneArgs = {
  object: Dms_Order_Links_Insert_Input;
  on_conflict?: InputMaybe<Dms_Order_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Order_NumbersArgs = {
  objects: Array<Dms_Order_Numbers_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Order_Numbers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Order_Numbers_OneArgs = {
  object: Dms_Order_Numbers_Insert_Input;
  on_conflict?: InputMaybe<Dms_Order_Numbers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_OrdersArgs = {
  objects: Array<Dms_Orders_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Orders_OneArgs = {
  object: Dms_Orders_Insert_Input;
  on_conflict?: InputMaybe<Dms_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_ProductsArgs = {
  objects: Array<Dms_Products_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Products_OneArgs = {
  object: Dms_Products_Insert_Input;
  on_conflict?: InputMaybe<Dms_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Promo_CodesArgs = {
  objects: Array<Dms_Promo_Codes_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Promo_Codes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Promo_Codes_OneArgs = {
  object: Dms_Promo_Codes_Insert_Input;
  on_conflict?: InputMaybe<Dms_Promo_Codes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_StatusesArgs = {
  objects: Array<Dms_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Statuses_OneArgs = {
  object: Dms_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Dms_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_UsersArgs = {
  objects: Array<Dms_Users_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Users_OneArgs = {
  object: Dms_Users_Insert_Input;
  on_conflict?: InputMaybe<Dms_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_BalancesArgs = {
  objects: Array<Partner_Balances_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Balances_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Balances_OneArgs = {
  object: Partner_Balances_Insert_Input;
  on_conflict?: InputMaybe<Partner_Balances_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Log_Vzr_SalesArgs = {
  objects: Array<Partner_Log_Vzr_Sales_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Log_Vzr_Sales_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Log_Vzr_Sales_OneArgs = {
  object: Partner_Log_Vzr_Sales_Insert_Input;
  on_conflict?: InputMaybe<Partner_Log_Vzr_Sales_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Log_Vzr_SearchesArgs = {
  objects: Array<Partner_Log_Vzr_Searches_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Log_Vzr_Searches_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Log_Vzr_Searches_OneArgs = {
  object: Partner_Log_Vzr_Searches_Insert_Input;
  on_conflict?: InputMaybe<Partner_Log_Vzr_Searches_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Log_Vzr_ViewsArgs = {
  objects: Array<Partner_Log_Vzr_Views_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Log_Vzr_Views_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Log_Vzr_Views_OneArgs = {
  object: Partner_Log_Vzr_Views_Insert_Input;
  on_conflict?: InputMaybe<Partner_Log_Vzr_Views_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Place_Type_IntegrationsArgs = {
  objects: Array<Partner_Place_Type_Integrations_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Place_Type_Integrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Place_Type_Integrations_OneArgs = {
  object: Partner_Place_Type_Integrations_Insert_Input;
  on_conflict?: InputMaybe<Partner_Place_Type_Integrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_PlacesArgs = {
  objects: Array<Partner_Places_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Places_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Places_OneArgs = {
  object: Partner_Places_Insert_Input;
  on_conflict?: InputMaybe<Partner_Places_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Sale_TypesArgs = {
  objects: Array<Partner_Sale_Types_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Sale_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Sale_Types_OneArgs = {
  object: Partner_Sale_Types_Insert_Input;
  on_conflict?: InputMaybe<Partner_Sale_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Status_IntegrationsArgs = {
  objects: Array<Partner_Status_Integrations_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Status_Integrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Status_Integrations_OneArgs = {
  object: Partner_Status_Integrations_Insert_Input;
  on_conflict?: InputMaybe<Partner_Status_Integrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Status_TransactionsArgs = {
  objects: Array<Partner_Status_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Status_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Status_Transactions_OneArgs = {
  object: Partner_Status_Transactions_Insert_Input;
  on_conflict?: InputMaybe<Partner_Status_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_ThemesArgs = {
  objects: Array<Partner_Themes_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Themes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Themes_OneArgs = {
  object: Partner_Themes_Insert_Input;
  on_conflict?: InputMaybe<Partner_Themes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Type_IntegrationsArgs = {
  objects: Array<Partner_Type_Integrations_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Type_Integrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Type_Integrations_OneArgs = {
  object: Partner_Type_Integrations_Insert_Input;
  on_conflict?: InputMaybe<Partner_Type_Integrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Type_TransactionsArgs = {
  objects: Array<Partner_Type_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Type_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Type_Transactions_OneArgs = {
  object: Partner_Type_Transactions_Insert_Input;
  on_conflict?: InputMaybe<Partner_Type_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_User_TypesArgs = {
  objects: Array<Partner_User_Types_Insert_Input>;
  on_conflict?: InputMaybe<Partner_User_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_User_Types_OneArgs = {
  object: Partner_User_Types_Insert_Input;
  on_conflict?: InputMaybe<Partner_User_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_UsersArgs = {
  objects: Array<Partner_Users_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Users_OneArgs = {
  object: Partner_Users_Insert_Input;
  on_conflict?: InputMaybe<Partner_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsuranceDmsCreateArgs = {
  input: InsuranceDmsCreateInput;
};


/** mutation root */
export type Mutation_RootInsuranceDmsDeleteArgs = {
  input: InsuranceDmsDeleteInput;
};


/** mutation root */
export type Mutation_RootInsuranceDmsUpdateArgs = {
  input: InsuranceDmsUpdateInput;
};


/** mutation root */
export type Mutation_RootOrderPoliceCreateArgs = {
  input: OrderPoliceCreateInput;
};


/** mutation root */
export type Mutation_RootPartnerConfirmFileUploadingArgs = {
  arg: PartnerConfirmFileUploadingInput;
};


/** mutation root */
export type Mutation_RootPartnerCreateFileAccessArgs = {
  arg: PartnerFileAccessInput;
};


/** mutation root */
export type Mutation_RootPartnerCreatePlaceArgs = {
  arg: PartnerCreatePlaceInput;
};


/** mutation root */
export type Mutation_RootPartnerCreatePlaceTypeIntegrationsArgs = {
  arg: PartnerCreatePlaceTypeIntegrationsInput;
};


/** mutation root */
export type Mutation_RootPartnerInsertBalancesArgs = {
  arg: PartnerInsertBalancesInput;
};


/** mutation root */
export type Mutation_RootUpdateClientArgs = {
  input: UpdateClientInput;
};


/** mutation root */
export type Mutation_RootUpdateCountryCurrencyArgs = {
  input: UpdateCountryCurrencyInput;
};


/** mutation root */
export type Mutation_RootUpdateCountryListInsuranceArgs = {
  input: UpdateCountryListInsuranceInput;
};


/** mutation root */
export type Mutation_RootUpdateCurrencyListInsuranceArgs = {
  input: UpdateCurrencyListInsuranceInput;
};


/** mutation root */
export type Mutation_RootUpdateListCountryProgramArgs = {
  input: UpdateListCountryProgramInput;
};


/** mutation root */
export type Mutation_RootUpdateProgramInsuranceArgs = {
  input: UpdateProgramInsuranceInput;
};


/** mutation root */
export type Mutation_RootUpdatePromoCodeArgs = {
  input: UpdatePromoCodeInput;
};


/** mutation root */
export type Mutation_RootUpdateSumMedicalListArgs = {
  input: UpdateSumMedicalListInput;
};


/** mutation root */
export type Mutation_RootUpdateUserArgs = {
  input: UpdateUserInput;
};


/** mutation root */
export type Mutation_RootUpdateVzrInsuranceCompanyArgs = {
  input: UpdateVzrInsuranceCompanyInput;
};


/** mutation root */
export type Mutation_RootUpdateVzrListCountryArgs = {
  input: UpdateVzrListCountryInput;
};


/** mutation root */
export type Mutation_RootUpdateVzrListCurrencyArgs = {
  input: UpdateVzrListCurrencyInput;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_UsersArgs = {
  _inc?: InputMaybe<Auth_Users_Inc_Input>;
  _set?: InputMaybe<Auth_Users_Set_Input>;
  where: Auth_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Users_By_PkArgs = {
  _inc?: InputMaybe<Auth_Users_Inc_Input>;
  _set?: InputMaybe<Auth_Users_Set_Input>;
  pk_columns: Auth_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Users_ManyArgs = {
  updates: Array<Auth_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Group_InsuredsArgs = {
  _inc?: InputMaybe<Dms_Group_Insureds_Inc_Input>;
  _set?: InputMaybe<Dms_Group_Insureds_Set_Input>;
  where: Dms_Group_Insureds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Group_Insureds_By_PkArgs = {
  _inc?: InputMaybe<Dms_Group_Insureds_Inc_Input>;
  _set?: InputMaybe<Dms_Group_Insureds_Set_Input>;
  pk_columns: Dms_Group_Insureds_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Group_Insureds_ManyArgs = {
  updates: Array<Dms_Group_Insureds_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_GroupsArgs = {
  _inc?: InputMaybe<Dms_Groups_Inc_Input>;
  _set?: InputMaybe<Dms_Groups_Set_Input>;
  where: Dms_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Groups_By_PkArgs = {
  _inc?: InputMaybe<Dms_Groups_Inc_Input>;
  _set?: InputMaybe<Dms_Groups_Set_Input>;
  pk_columns: Dms_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Groups_ManyArgs = {
  updates: Array<Dms_Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_InsurantsArgs = {
  _inc?: InputMaybe<Dms_Insurants_Inc_Input>;
  _set?: InputMaybe<Dms_Insurants_Set_Input>;
  where: Dms_Insurants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Insurants_By_PkArgs = {
  _inc?: InputMaybe<Dms_Insurants_Inc_Input>;
  _set?: InputMaybe<Dms_Insurants_Set_Input>;
  pk_columns: Dms_Insurants_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Insurants_ManyArgs = {
  updates: Array<Dms_Insurants_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_InsuredsArgs = {
  _append?: InputMaybe<Dms_Insureds_Append_Input>;
  _delete_at_path?: InputMaybe<Dms_Insureds_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Dms_Insureds_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Dms_Insureds_Delete_Key_Input>;
  _inc?: InputMaybe<Dms_Insureds_Inc_Input>;
  _prepend?: InputMaybe<Dms_Insureds_Prepend_Input>;
  _set?: InputMaybe<Dms_Insureds_Set_Input>;
  where: Dms_Insureds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Insureds_By_PkArgs = {
  _append?: InputMaybe<Dms_Insureds_Append_Input>;
  _delete_at_path?: InputMaybe<Dms_Insureds_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Dms_Insureds_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Dms_Insureds_Delete_Key_Input>;
  _inc?: InputMaybe<Dms_Insureds_Inc_Input>;
  _prepend?: InputMaybe<Dms_Insureds_Prepend_Input>;
  _set?: InputMaybe<Dms_Insureds_Set_Input>;
  pk_columns: Dms_Insureds_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Insureds_ManyArgs = {
  updates: Array<Dms_Insureds_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Order_LinksArgs = {
  _inc?: InputMaybe<Dms_Order_Links_Inc_Input>;
  _set?: InputMaybe<Dms_Order_Links_Set_Input>;
  where: Dms_Order_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Order_Links_By_PkArgs = {
  _inc?: InputMaybe<Dms_Order_Links_Inc_Input>;
  _set?: InputMaybe<Dms_Order_Links_Set_Input>;
  pk_columns: Dms_Order_Links_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Order_Links_ManyArgs = {
  updates: Array<Dms_Order_Links_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Order_NumbersArgs = {
  _inc?: InputMaybe<Dms_Order_Numbers_Inc_Input>;
  _set?: InputMaybe<Dms_Order_Numbers_Set_Input>;
  where: Dms_Order_Numbers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Order_Numbers_By_PkArgs = {
  _inc?: InputMaybe<Dms_Order_Numbers_Inc_Input>;
  _set?: InputMaybe<Dms_Order_Numbers_Set_Input>;
  pk_columns: Dms_Order_Numbers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Order_Numbers_ManyArgs = {
  updates: Array<Dms_Order_Numbers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_OrdersArgs = {
  _append?: InputMaybe<Dms_Orders_Append_Input>;
  _delete_at_path?: InputMaybe<Dms_Orders_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Dms_Orders_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Dms_Orders_Delete_Key_Input>;
  _inc?: InputMaybe<Dms_Orders_Inc_Input>;
  _prepend?: InputMaybe<Dms_Orders_Prepend_Input>;
  _set?: InputMaybe<Dms_Orders_Set_Input>;
  where: Dms_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Orders_By_PkArgs = {
  _append?: InputMaybe<Dms_Orders_Append_Input>;
  _delete_at_path?: InputMaybe<Dms_Orders_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Dms_Orders_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Dms_Orders_Delete_Key_Input>;
  _inc?: InputMaybe<Dms_Orders_Inc_Input>;
  _prepend?: InputMaybe<Dms_Orders_Prepend_Input>;
  _set?: InputMaybe<Dms_Orders_Set_Input>;
  pk_columns: Dms_Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Orders_ManyArgs = {
  updates: Array<Dms_Orders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_ProductsArgs = {
  _append?: InputMaybe<Dms_Products_Append_Input>;
  _delete_at_path?: InputMaybe<Dms_Products_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Dms_Products_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Dms_Products_Delete_Key_Input>;
  _inc?: InputMaybe<Dms_Products_Inc_Input>;
  _prepend?: InputMaybe<Dms_Products_Prepend_Input>;
  _set?: InputMaybe<Dms_Products_Set_Input>;
  where: Dms_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Products_By_PkArgs = {
  _append?: InputMaybe<Dms_Products_Append_Input>;
  _delete_at_path?: InputMaybe<Dms_Products_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Dms_Products_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Dms_Products_Delete_Key_Input>;
  _inc?: InputMaybe<Dms_Products_Inc_Input>;
  _prepend?: InputMaybe<Dms_Products_Prepend_Input>;
  _set?: InputMaybe<Dms_Products_Set_Input>;
  pk_columns: Dms_Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Products_ManyArgs = {
  updates: Array<Dms_Products_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Promo_CodesArgs = {
  _inc?: InputMaybe<Dms_Promo_Codes_Inc_Input>;
  _set?: InputMaybe<Dms_Promo_Codes_Set_Input>;
  where: Dms_Promo_Codes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Promo_Codes_By_PkArgs = {
  _inc?: InputMaybe<Dms_Promo_Codes_Inc_Input>;
  _set?: InputMaybe<Dms_Promo_Codes_Set_Input>;
  pk_columns: Dms_Promo_Codes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Promo_Codes_ManyArgs = {
  updates: Array<Dms_Promo_Codes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_StatusesArgs = {
  _inc?: InputMaybe<Dms_Statuses_Inc_Input>;
  _set?: InputMaybe<Dms_Statuses_Set_Input>;
  where: Dms_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Statuses_By_PkArgs = {
  _inc?: InputMaybe<Dms_Statuses_Inc_Input>;
  _set?: InputMaybe<Dms_Statuses_Set_Input>;
  pk_columns: Dms_Statuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Statuses_ManyArgs = {
  updates: Array<Dms_Statuses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_UsersArgs = {
  _inc?: InputMaybe<Dms_Users_Inc_Input>;
  _set?: InputMaybe<Dms_Users_Set_Input>;
  where: Dms_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Users_By_PkArgs = {
  _inc?: InputMaybe<Dms_Users_Inc_Input>;
  _set?: InputMaybe<Dms_Users_Set_Input>;
  pk_columns: Dms_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Users_ManyArgs = {
  updates: Array<Dms_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_BalancesArgs = {
  _inc?: InputMaybe<Partner_Balances_Inc_Input>;
  _set?: InputMaybe<Partner_Balances_Set_Input>;
  where: Partner_Balances_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Balances_By_PkArgs = {
  _inc?: InputMaybe<Partner_Balances_Inc_Input>;
  _set?: InputMaybe<Partner_Balances_Set_Input>;
  pk_columns: Partner_Balances_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Balances_ManyArgs = {
  updates: Array<Partner_Balances_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Log_Vzr_SalesArgs = {
  _inc?: InputMaybe<Partner_Log_Vzr_Sales_Inc_Input>;
  _set?: InputMaybe<Partner_Log_Vzr_Sales_Set_Input>;
  where: Partner_Log_Vzr_Sales_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Log_Vzr_Sales_By_PkArgs = {
  _inc?: InputMaybe<Partner_Log_Vzr_Sales_Inc_Input>;
  _set?: InputMaybe<Partner_Log_Vzr_Sales_Set_Input>;
  pk_columns: Partner_Log_Vzr_Sales_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Log_Vzr_Sales_ManyArgs = {
  updates: Array<Partner_Log_Vzr_Sales_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Log_Vzr_SearchesArgs = {
  _inc?: InputMaybe<Partner_Log_Vzr_Searches_Inc_Input>;
  _set?: InputMaybe<Partner_Log_Vzr_Searches_Set_Input>;
  where: Partner_Log_Vzr_Searches_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Log_Vzr_Searches_By_PkArgs = {
  _inc?: InputMaybe<Partner_Log_Vzr_Searches_Inc_Input>;
  _set?: InputMaybe<Partner_Log_Vzr_Searches_Set_Input>;
  pk_columns: Partner_Log_Vzr_Searches_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Log_Vzr_Searches_ManyArgs = {
  updates: Array<Partner_Log_Vzr_Searches_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Log_Vzr_ViewsArgs = {
  _inc?: InputMaybe<Partner_Log_Vzr_Views_Inc_Input>;
  _set?: InputMaybe<Partner_Log_Vzr_Views_Set_Input>;
  where: Partner_Log_Vzr_Views_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Log_Vzr_Views_By_PkArgs = {
  _inc?: InputMaybe<Partner_Log_Vzr_Views_Inc_Input>;
  _set?: InputMaybe<Partner_Log_Vzr_Views_Set_Input>;
  pk_columns: Partner_Log_Vzr_Views_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Log_Vzr_Views_ManyArgs = {
  updates: Array<Partner_Log_Vzr_Views_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Place_Type_IntegrationsArgs = {
  _inc?: InputMaybe<Partner_Place_Type_Integrations_Inc_Input>;
  _set?: InputMaybe<Partner_Place_Type_Integrations_Set_Input>;
  where: Partner_Place_Type_Integrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Place_Type_Integrations_By_PkArgs = {
  _inc?: InputMaybe<Partner_Place_Type_Integrations_Inc_Input>;
  _set?: InputMaybe<Partner_Place_Type_Integrations_Set_Input>;
  pk_columns: Partner_Place_Type_Integrations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Place_Type_Integrations_ManyArgs = {
  updates: Array<Partner_Place_Type_Integrations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_PlacesArgs = {
  _inc?: InputMaybe<Partner_Places_Inc_Input>;
  _set?: InputMaybe<Partner_Places_Set_Input>;
  where: Partner_Places_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Places_By_PkArgs = {
  _inc?: InputMaybe<Partner_Places_Inc_Input>;
  _set?: InputMaybe<Partner_Places_Set_Input>;
  pk_columns: Partner_Places_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Places_ManyArgs = {
  updates: Array<Partner_Places_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Sale_TypesArgs = {
  _inc?: InputMaybe<Partner_Sale_Types_Inc_Input>;
  _set?: InputMaybe<Partner_Sale_Types_Set_Input>;
  where: Partner_Sale_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Sale_Types_By_PkArgs = {
  _inc?: InputMaybe<Partner_Sale_Types_Inc_Input>;
  _set?: InputMaybe<Partner_Sale_Types_Set_Input>;
  pk_columns: Partner_Sale_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Sale_Types_ManyArgs = {
  updates: Array<Partner_Sale_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Status_IntegrationsArgs = {
  _inc?: InputMaybe<Partner_Status_Integrations_Inc_Input>;
  _set?: InputMaybe<Partner_Status_Integrations_Set_Input>;
  where: Partner_Status_Integrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Status_Integrations_By_PkArgs = {
  _inc?: InputMaybe<Partner_Status_Integrations_Inc_Input>;
  _set?: InputMaybe<Partner_Status_Integrations_Set_Input>;
  pk_columns: Partner_Status_Integrations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Status_Integrations_ManyArgs = {
  updates: Array<Partner_Status_Integrations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Status_TransactionsArgs = {
  _inc?: InputMaybe<Partner_Status_Transactions_Inc_Input>;
  _set?: InputMaybe<Partner_Status_Transactions_Set_Input>;
  where: Partner_Status_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Status_Transactions_By_PkArgs = {
  _inc?: InputMaybe<Partner_Status_Transactions_Inc_Input>;
  _set?: InputMaybe<Partner_Status_Transactions_Set_Input>;
  pk_columns: Partner_Status_Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Status_Transactions_ManyArgs = {
  updates: Array<Partner_Status_Transactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_ThemesArgs = {
  _inc?: InputMaybe<Partner_Themes_Inc_Input>;
  _set?: InputMaybe<Partner_Themes_Set_Input>;
  where: Partner_Themes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Themes_By_PkArgs = {
  _inc?: InputMaybe<Partner_Themes_Inc_Input>;
  _set?: InputMaybe<Partner_Themes_Set_Input>;
  pk_columns: Partner_Themes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Themes_ManyArgs = {
  updates: Array<Partner_Themes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Type_IntegrationsArgs = {
  _inc?: InputMaybe<Partner_Type_Integrations_Inc_Input>;
  _set?: InputMaybe<Partner_Type_Integrations_Set_Input>;
  where: Partner_Type_Integrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Type_Integrations_By_PkArgs = {
  _inc?: InputMaybe<Partner_Type_Integrations_Inc_Input>;
  _set?: InputMaybe<Partner_Type_Integrations_Set_Input>;
  pk_columns: Partner_Type_Integrations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Type_Integrations_ManyArgs = {
  updates: Array<Partner_Type_Integrations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Type_TransactionsArgs = {
  _inc?: InputMaybe<Partner_Type_Transactions_Inc_Input>;
  _set?: InputMaybe<Partner_Type_Transactions_Set_Input>;
  where: Partner_Type_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Type_Transactions_By_PkArgs = {
  _inc?: InputMaybe<Partner_Type_Transactions_Inc_Input>;
  _set?: InputMaybe<Partner_Type_Transactions_Set_Input>;
  pk_columns: Partner_Type_Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Type_Transactions_ManyArgs = {
  updates: Array<Partner_Type_Transactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_User_TypesArgs = {
  _inc?: InputMaybe<Partner_User_Types_Inc_Input>;
  _set?: InputMaybe<Partner_User_Types_Set_Input>;
  where: Partner_User_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_User_Types_By_PkArgs = {
  _inc?: InputMaybe<Partner_User_Types_Inc_Input>;
  _set?: InputMaybe<Partner_User_Types_Set_Input>;
  pk_columns: Partner_User_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_User_Types_ManyArgs = {
  updates: Array<Partner_User_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_UsersArgs = {
  _inc?: InputMaybe<Partner_Users_Inc_Input>;
  _set?: InputMaybe<Partner_Users_Set_Input>;
  where: Partner_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Users_By_PkArgs = {
  _inc?: InputMaybe<Partner_Users_Inc_Input>;
  _set?: InputMaybe<Partner_Users_Set_Input>;
  pk_columns: Partner_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Users_ManyArgs = {
  updates: Array<Partner_Users_Updates>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type PartnerConfirmFileUploadingInput = {
  link: Scalars['String'];
  uid: Scalars['String'];
};

export type PartnerConfirmFileUploadingOutput = {
  __typename?: 'partnerConfirmFileUploadingOutput';
  error: Scalars['String'];
  status_right: Scalars['String'];
};

export type PartnerCreateFileAccessOutput = {
  __typename?: 'partnerCreateFileAccessOutput';
  error: Scalars['String'];
  file_name: Scalars['String'];
  url: Scalars['String'];
};

export type PartnerCreatePlaceInput = {
  address_url: Scalars['String'];
  name: Scalars['String'];
  theme_id: Scalars['Int'];
};

export type PartnerCreatePlaceOutput = {
  __typename?: 'partnerCreatePlaceOutput';
  address_url: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  theme_id: Scalars['Int'];
  uid: Scalars['String'];
  user_id: Scalars['Int'];
};

export type PartnerCreatePlaceTypeIntegrationsInput = {
  place_id: Scalars['Int'];
  type_integration_id: Scalars['Int'];
};

export type PartnerCreatePlaceTypeIntegrationsOutput = {
  __typename?: 'partnerCreatePlaceTypeIntegrationsOutput';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  place_id: Scalars['Int'];
  status_integration_id: Scalars['Int'];
  type_integration_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['Int'];
};

export type PartnerCurrentBalanceOutput = {
  __typename?: 'partnerCurrentBalanceOutput';
  balance: Scalars['String'];
};

export type PartnerFileAccessInput = {
  id: Scalars['Int'];
  user_id: Scalars['Int'];
};

export type PartnerInsertBalancesInput = {
  amount: Scalars['Float'];
  id_sale?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  sale_type_id?: InputMaybe<Scalars['Int']>;
  type_transaction_id: Scalars['Int'];
};

export type PartnerInsertBalancesOutput = {
  __typename?: 'partnerInsertBalancesOutput';
  amount: Scalars['Float'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  id_sale: Scalars['String'];
  name: Scalars['String'];
  sale_type_id?: Maybe<Scalars['String']>;
  status_transaction_id: Scalars['Int'];
  type_transaction_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['Int'];
};

/** columns and relationships of "balances" */
export type Partner_Balances = {
  __typename?: 'partner_balances';
  amount?: Maybe<Scalars['float8']>;
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  id_sale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sale_type_id?: Maybe<Scalars['bigint']>;
  status_transaction_id?: Maybe<Scalars['bigint']>;
  type_transaction_id?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamp'];
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "balances" */
export type Partner_Balances_Aggregate = {
  __typename?: 'partner_balances_aggregate';
  aggregate?: Maybe<Partner_Balances_Aggregate_Fields>;
  nodes: Array<Partner_Balances>;
};

/** aggregate fields of "balances" */
export type Partner_Balances_Aggregate_Fields = {
  __typename?: 'partner_balances_aggregate_fields';
  avg?: Maybe<Partner_Balances_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partner_Balances_Max_Fields>;
  min?: Maybe<Partner_Balances_Min_Fields>;
  stddev?: Maybe<Partner_Balances_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Balances_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Balances_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Balances_Sum_Fields>;
  var_pop?: Maybe<Partner_Balances_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Balances_Var_Samp_Fields>;
  variance?: Maybe<Partner_Balances_Variance_Fields>;
};


/** aggregate fields of "balances" */
export type Partner_Balances_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Balances_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Partner_Balances_Avg_Fields = {
  __typename?: 'partner_balances_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sale_type_id?: Maybe<Scalars['Float']>;
  status_transaction_id?: Maybe<Scalars['Float']>;
  type_transaction_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "balances". All fields are combined with a logical 'AND'. */
export type Partner_Balances_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Balances_Bool_Exp>>;
  _not?: InputMaybe<Partner_Balances_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Balances_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  id_sale?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  sale_type_id?: InputMaybe<Bigint_Comparison_Exp>;
  status_transaction_id?: InputMaybe<Bigint_Comparison_Exp>;
  type_transaction_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "balances" */
export enum Partner_Balances_Constraint {
  /** unique or primary key constraint on columns "id" */
  BalancesPkey = 'balances_pkey'
}

/** input type for incrementing numeric columns in table "balances" */
export type Partner_Balances_Inc_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['bigint']>;
  sale_type_id?: InputMaybe<Scalars['bigint']>;
  status_transaction_id?: InputMaybe<Scalars['bigint']>;
  type_transaction_id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "balances" */
export type Partner_Balances_Insert_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  id_sale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  sale_type_id?: InputMaybe<Scalars['bigint']>;
  status_transaction_id?: InputMaybe<Scalars['bigint']>;
  type_transaction_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Partner_Balances_Max_Fields = {
  __typename?: 'partner_balances_max_fields';
  amount?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  id_sale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sale_type_id?: Maybe<Scalars['bigint']>;
  status_transaction_id?: Maybe<Scalars['bigint']>;
  type_transaction_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Partner_Balances_Min_Fields = {
  __typename?: 'partner_balances_min_fields';
  amount?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  id_sale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sale_type_id?: Maybe<Scalars['bigint']>;
  status_transaction_id?: Maybe<Scalars['bigint']>;
  type_transaction_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "balances" */
export type Partner_Balances_Mutation_Response = {
  __typename?: 'partner_balances_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Balances>;
};

/** on_conflict condition type for table "balances" */
export type Partner_Balances_On_Conflict = {
  constraint: Partner_Balances_Constraint;
  update_columns?: Array<Partner_Balances_Update_Column>;
  where?: InputMaybe<Partner_Balances_Bool_Exp>;
};

/** Ordering options when selecting data from "balances". */
export type Partner_Balances_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_sale?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sale_type_id?: InputMaybe<Order_By>;
  status_transaction_id?: InputMaybe<Order_By>;
  type_transaction_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: partner_balances */
export type Partner_Balances_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "balances" */
export enum Partner_Balances_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdSale = 'id_sale',
  /** column name */
  Name = 'name',
  /** column name */
  SaleTypeId = 'sale_type_id',
  /** column name */
  StatusTransactionId = 'status_transaction_id',
  /** column name */
  TypeTransactionId = 'type_transaction_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "balances" */
export type Partner_Balances_Set_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  id_sale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  sale_type_id?: InputMaybe<Scalars['bigint']>;
  status_transaction_id?: InputMaybe<Scalars['bigint']>;
  type_transaction_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Partner_Balances_Stddev_Fields = {
  __typename?: 'partner_balances_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sale_type_id?: Maybe<Scalars['Float']>;
  status_transaction_id?: Maybe<Scalars['Float']>;
  type_transaction_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Balances_Stddev_Pop_Fields = {
  __typename?: 'partner_balances_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sale_type_id?: Maybe<Scalars['Float']>;
  status_transaction_id?: Maybe<Scalars['Float']>;
  type_transaction_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Balances_Stddev_Samp_Fields = {
  __typename?: 'partner_balances_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sale_type_id?: Maybe<Scalars['Float']>;
  status_transaction_id?: Maybe<Scalars['Float']>;
  type_transaction_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "partner_balances" */
export type Partner_Balances_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Balances_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Balances_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  id_sale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  sale_type_id?: InputMaybe<Scalars['bigint']>;
  status_transaction_id?: InputMaybe<Scalars['bigint']>;
  type_transaction_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Partner_Balances_Sum_Fields = {
  __typename?: 'partner_balances_sum_fields';
  amount?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  sale_type_id?: Maybe<Scalars['bigint']>;
  status_transaction_id?: Maybe<Scalars['bigint']>;
  type_transaction_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "balances" */
export enum Partner_Balances_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdSale = 'id_sale',
  /** column name */
  Name = 'name',
  /** column name */
  SaleTypeId = 'sale_type_id',
  /** column name */
  StatusTransactionId = 'status_transaction_id',
  /** column name */
  TypeTransactionId = 'type_transaction_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Partner_Balances_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Balances_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Balances_Set_Input>;
  where: Partner_Balances_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Balances_Var_Pop_Fields = {
  __typename?: 'partner_balances_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sale_type_id?: Maybe<Scalars['Float']>;
  status_transaction_id?: Maybe<Scalars['Float']>;
  type_transaction_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Partner_Balances_Var_Samp_Fields = {
  __typename?: 'partner_balances_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sale_type_id?: Maybe<Scalars['Float']>;
  status_transaction_id?: Maybe<Scalars['Float']>;
  type_transaction_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Partner_Balances_Variance_Fields = {
  __typename?: 'partner_balances_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sale_type_id?: Maybe<Scalars['Float']>;
  status_transaction_id?: Maybe<Scalars['Float']>;
  type_transaction_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "log_vzr_sales" */
export type Partner_Log_Vzr_Sales = {
  __typename?: 'partner_log_vzr_sales';
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  date_find?: Maybe<Scalars['date']>;
  flight_end_date?: Maybe<Scalars['date']>;
  flight_start_date?: Maybe<Scalars['date']>;
  holiday_type?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  place_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  succefull?: Maybe<Scalars['Boolean']>;
  tourist_number?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamp'];
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "log_vzr_sales" */
export type Partner_Log_Vzr_Sales_Aggregate = {
  __typename?: 'partner_log_vzr_sales_aggregate';
  aggregate?: Maybe<Partner_Log_Vzr_Sales_Aggregate_Fields>;
  nodes: Array<Partner_Log_Vzr_Sales>;
};

/** aggregate fields of "log_vzr_sales" */
export type Partner_Log_Vzr_Sales_Aggregate_Fields = {
  __typename?: 'partner_log_vzr_sales_aggregate_fields';
  avg?: Maybe<Partner_Log_Vzr_Sales_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partner_Log_Vzr_Sales_Max_Fields>;
  min?: Maybe<Partner_Log_Vzr_Sales_Min_Fields>;
  stddev?: Maybe<Partner_Log_Vzr_Sales_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Log_Vzr_Sales_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Log_Vzr_Sales_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Log_Vzr_Sales_Sum_Fields>;
  var_pop?: Maybe<Partner_Log_Vzr_Sales_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Log_Vzr_Sales_Var_Samp_Fields>;
  variance?: Maybe<Partner_Log_Vzr_Sales_Variance_Fields>;
};


/** aggregate fields of "log_vzr_sales" */
export type Partner_Log_Vzr_Sales_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Log_Vzr_Sales_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Partner_Log_Vzr_Sales_Avg_Fields = {
  __typename?: 'partner_log_vzr_sales_avg_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  tourist_number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "log_vzr_sales". All fields are combined with a logical 'AND'. */
export type Partner_Log_Vzr_Sales_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Log_Vzr_Sales_Bool_Exp>>;
  _not?: InputMaybe<Partner_Log_Vzr_Sales_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Log_Vzr_Sales_Bool_Exp>>;
  country?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  date_find?: InputMaybe<Date_Comparison_Exp>;
  flight_end_date?: InputMaybe<Date_Comparison_Exp>;
  flight_start_date?: InputMaybe<Date_Comparison_Exp>;
  holiday_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  place_id?: InputMaybe<Bigint_Comparison_Exp>;
  price?: InputMaybe<Float8_Comparison_Exp>;
  succefull?: InputMaybe<Boolean_Comparison_Exp>;
  tourist_number?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "log_vzr_sales" */
export enum Partner_Log_Vzr_Sales_Constraint {
  /** unique or primary key constraint on columns "id" */
  LogVzrSalesPkey = 'log_vzr_sales_pkey'
}

/** input type for incrementing numeric columns in table "log_vzr_sales" */
export type Partner_Log_Vzr_Sales_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  place_id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['float8']>;
  tourist_number?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "log_vzr_sales" */
export type Partner_Log_Vzr_Sales_Insert_Input = {
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  date_find?: InputMaybe<Scalars['date']>;
  flight_end_date?: InputMaybe<Scalars['date']>;
  flight_start_date?: InputMaybe<Scalars['date']>;
  holiday_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  place_id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['float8']>;
  succefull?: InputMaybe<Scalars['Boolean']>;
  tourist_number?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Partner_Log_Vzr_Sales_Max_Fields = {
  __typename?: 'partner_log_vzr_sales_max_fields';
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_find?: Maybe<Scalars['date']>;
  flight_end_date?: Maybe<Scalars['date']>;
  flight_start_date?: Maybe<Scalars['date']>;
  holiday_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  place_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  tourist_number?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Partner_Log_Vzr_Sales_Min_Fields = {
  __typename?: 'partner_log_vzr_sales_min_fields';
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_find?: Maybe<Scalars['date']>;
  flight_end_date?: Maybe<Scalars['date']>;
  flight_start_date?: Maybe<Scalars['date']>;
  holiday_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  place_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  tourist_number?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "log_vzr_sales" */
export type Partner_Log_Vzr_Sales_Mutation_Response = {
  __typename?: 'partner_log_vzr_sales_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Log_Vzr_Sales>;
};

/** on_conflict condition type for table "log_vzr_sales" */
export type Partner_Log_Vzr_Sales_On_Conflict = {
  constraint: Partner_Log_Vzr_Sales_Constraint;
  update_columns?: Array<Partner_Log_Vzr_Sales_Update_Column>;
  where?: InputMaybe<Partner_Log_Vzr_Sales_Bool_Exp>;
};

/** Ordering options when selecting data from "log_vzr_sales". */
export type Partner_Log_Vzr_Sales_Order_By = {
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_find?: InputMaybe<Order_By>;
  flight_end_date?: InputMaybe<Order_By>;
  flight_start_date?: InputMaybe<Order_By>;
  holiday_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  succefull?: InputMaybe<Order_By>;
  tourist_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: partner_log_vzr_sales */
export type Partner_Log_Vzr_Sales_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "log_vzr_sales" */
export enum Partner_Log_Vzr_Sales_Select_Column {
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateFind = 'date_find',
  /** column name */
  FlightEndDate = 'flight_end_date',
  /** column name */
  FlightStartDate = 'flight_start_date',
  /** column name */
  HolidayType = 'holiday_type',
  /** column name */
  Id = 'id',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  Price = 'price',
  /** column name */
  Succefull = 'succefull',
  /** column name */
  TouristNumber = 'tourist_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "log_vzr_sales" */
export type Partner_Log_Vzr_Sales_Set_Input = {
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  date_find?: InputMaybe<Scalars['date']>;
  flight_end_date?: InputMaybe<Scalars['date']>;
  flight_start_date?: InputMaybe<Scalars['date']>;
  holiday_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  place_id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['float8']>;
  succefull?: InputMaybe<Scalars['Boolean']>;
  tourist_number?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Partner_Log_Vzr_Sales_Stddev_Fields = {
  __typename?: 'partner_log_vzr_sales_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  tourist_number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Log_Vzr_Sales_Stddev_Pop_Fields = {
  __typename?: 'partner_log_vzr_sales_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  tourist_number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Log_Vzr_Sales_Stddev_Samp_Fields = {
  __typename?: 'partner_log_vzr_sales_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  tourist_number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "partner_log_vzr_sales" */
export type Partner_Log_Vzr_Sales_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Log_Vzr_Sales_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Log_Vzr_Sales_Stream_Cursor_Value_Input = {
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  date_find?: InputMaybe<Scalars['date']>;
  flight_end_date?: InputMaybe<Scalars['date']>;
  flight_start_date?: InputMaybe<Scalars['date']>;
  holiday_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  place_id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['float8']>;
  succefull?: InputMaybe<Scalars['Boolean']>;
  tourist_number?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Partner_Log_Vzr_Sales_Sum_Fields = {
  __typename?: 'partner_log_vzr_sales_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  place_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  tourist_number?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "log_vzr_sales" */
export enum Partner_Log_Vzr_Sales_Update_Column {
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateFind = 'date_find',
  /** column name */
  FlightEndDate = 'flight_end_date',
  /** column name */
  FlightStartDate = 'flight_start_date',
  /** column name */
  HolidayType = 'holiday_type',
  /** column name */
  Id = 'id',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  Price = 'price',
  /** column name */
  Succefull = 'succefull',
  /** column name */
  TouristNumber = 'tourist_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Partner_Log_Vzr_Sales_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Log_Vzr_Sales_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Log_Vzr_Sales_Set_Input>;
  where: Partner_Log_Vzr_Sales_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Log_Vzr_Sales_Var_Pop_Fields = {
  __typename?: 'partner_log_vzr_sales_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  tourist_number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Partner_Log_Vzr_Sales_Var_Samp_Fields = {
  __typename?: 'partner_log_vzr_sales_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  tourist_number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Partner_Log_Vzr_Sales_Variance_Fields = {
  __typename?: 'partner_log_vzr_sales_variance_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  tourist_number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "log_vzr_searches" */
export type Partner_Log_Vzr_Searches = {
  __typename?: 'partner_log_vzr_searches';
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  date_find?: Maybe<Scalars['date']>;
  flight_end_date?: Maybe<Scalars['date']>;
  flight_start_date?: Maybe<Scalars['date']>;
  holiday_type?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  place_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  tourist_number?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamp'];
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "log_vzr_searches" */
export type Partner_Log_Vzr_Searches_Aggregate = {
  __typename?: 'partner_log_vzr_searches_aggregate';
  aggregate?: Maybe<Partner_Log_Vzr_Searches_Aggregate_Fields>;
  nodes: Array<Partner_Log_Vzr_Searches>;
};

/** aggregate fields of "log_vzr_searches" */
export type Partner_Log_Vzr_Searches_Aggregate_Fields = {
  __typename?: 'partner_log_vzr_searches_aggregate_fields';
  avg?: Maybe<Partner_Log_Vzr_Searches_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partner_Log_Vzr_Searches_Max_Fields>;
  min?: Maybe<Partner_Log_Vzr_Searches_Min_Fields>;
  stddev?: Maybe<Partner_Log_Vzr_Searches_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Log_Vzr_Searches_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Log_Vzr_Searches_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Log_Vzr_Searches_Sum_Fields>;
  var_pop?: Maybe<Partner_Log_Vzr_Searches_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Log_Vzr_Searches_Var_Samp_Fields>;
  variance?: Maybe<Partner_Log_Vzr_Searches_Variance_Fields>;
};


/** aggregate fields of "log_vzr_searches" */
export type Partner_Log_Vzr_Searches_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Log_Vzr_Searches_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Partner_Log_Vzr_Searches_Avg_Fields = {
  __typename?: 'partner_log_vzr_searches_avg_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  tourist_number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "log_vzr_searches". All fields are combined with a logical 'AND'. */
export type Partner_Log_Vzr_Searches_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Log_Vzr_Searches_Bool_Exp>>;
  _not?: InputMaybe<Partner_Log_Vzr_Searches_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Log_Vzr_Searches_Bool_Exp>>;
  country?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  date_find?: InputMaybe<Date_Comparison_Exp>;
  flight_end_date?: InputMaybe<Date_Comparison_Exp>;
  flight_start_date?: InputMaybe<Date_Comparison_Exp>;
  holiday_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  place_id?: InputMaybe<Bigint_Comparison_Exp>;
  price?: InputMaybe<Float8_Comparison_Exp>;
  tourist_number?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "log_vzr_searches" */
export enum Partner_Log_Vzr_Searches_Constraint {
  /** unique or primary key constraint on columns "id" */
  LogVzrSearchesPkey = 'log_vzr_searches_pkey'
}

/** input type for incrementing numeric columns in table "log_vzr_searches" */
export type Partner_Log_Vzr_Searches_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  place_id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['float8']>;
  tourist_number?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "log_vzr_searches" */
export type Partner_Log_Vzr_Searches_Insert_Input = {
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  date_find?: InputMaybe<Scalars['date']>;
  flight_end_date?: InputMaybe<Scalars['date']>;
  flight_start_date?: InputMaybe<Scalars['date']>;
  holiday_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  place_id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['float8']>;
  tourist_number?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Partner_Log_Vzr_Searches_Max_Fields = {
  __typename?: 'partner_log_vzr_searches_max_fields';
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_find?: Maybe<Scalars['date']>;
  flight_end_date?: Maybe<Scalars['date']>;
  flight_start_date?: Maybe<Scalars['date']>;
  holiday_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  place_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  tourist_number?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Partner_Log_Vzr_Searches_Min_Fields = {
  __typename?: 'partner_log_vzr_searches_min_fields';
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_find?: Maybe<Scalars['date']>;
  flight_end_date?: Maybe<Scalars['date']>;
  flight_start_date?: Maybe<Scalars['date']>;
  holiday_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  place_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  tourist_number?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "log_vzr_searches" */
export type Partner_Log_Vzr_Searches_Mutation_Response = {
  __typename?: 'partner_log_vzr_searches_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Log_Vzr_Searches>;
};

/** on_conflict condition type for table "log_vzr_searches" */
export type Partner_Log_Vzr_Searches_On_Conflict = {
  constraint: Partner_Log_Vzr_Searches_Constraint;
  update_columns?: Array<Partner_Log_Vzr_Searches_Update_Column>;
  where?: InputMaybe<Partner_Log_Vzr_Searches_Bool_Exp>;
};

/** Ordering options when selecting data from "log_vzr_searches". */
export type Partner_Log_Vzr_Searches_Order_By = {
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_find?: InputMaybe<Order_By>;
  flight_end_date?: InputMaybe<Order_By>;
  flight_start_date?: InputMaybe<Order_By>;
  holiday_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  tourist_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: partner_log_vzr_searches */
export type Partner_Log_Vzr_Searches_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "log_vzr_searches" */
export enum Partner_Log_Vzr_Searches_Select_Column {
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateFind = 'date_find',
  /** column name */
  FlightEndDate = 'flight_end_date',
  /** column name */
  FlightStartDate = 'flight_start_date',
  /** column name */
  HolidayType = 'holiday_type',
  /** column name */
  Id = 'id',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  Price = 'price',
  /** column name */
  TouristNumber = 'tourist_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "log_vzr_searches" */
export type Partner_Log_Vzr_Searches_Set_Input = {
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  date_find?: InputMaybe<Scalars['date']>;
  flight_end_date?: InputMaybe<Scalars['date']>;
  flight_start_date?: InputMaybe<Scalars['date']>;
  holiday_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  place_id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['float8']>;
  tourist_number?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Partner_Log_Vzr_Searches_Stddev_Fields = {
  __typename?: 'partner_log_vzr_searches_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  tourist_number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Log_Vzr_Searches_Stddev_Pop_Fields = {
  __typename?: 'partner_log_vzr_searches_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  tourist_number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Log_Vzr_Searches_Stddev_Samp_Fields = {
  __typename?: 'partner_log_vzr_searches_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  tourist_number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "partner_log_vzr_searches" */
export type Partner_Log_Vzr_Searches_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Log_Vzr_Searches_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Log_Vzr_Searches_Stream_Cursor_Value_Input = {
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  date_find?: InputMaybe<Scalars['date']>;
  flight_end_date?: InputMaybe<Scalars['date']>;
  flight_start_date?: InputMaybe<Scalars['date']>;
  holiday_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  place_id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['float8']>;
  tourist_number?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Partner_Log_Vzr_Searches_Sum_Fields = {
  __typename?: 'partner_log_vzr_searches_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  place_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  tourist_number?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "log_vzr_searches" */
export enum Partner_Log_Vzr_Searches_Update_Column {
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateFind = 'date_find',
  /** column name */
  FlightEndDate = 'flight_end_date',
  /** column name */
  FlightStartDate = 'flight_start_date',
  /** column name */
  HolidayType = 'holiday_type',
  /** column name */
  Id = 'id',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  Price = 'price',
  /** column name */
  TouristNumber = 'tourist_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Partner_Log_Vzr_Searches_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Log_Vzr_Searches_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Log_Vzr_Searches_Set_Input>;
  where: Partner_Log_Vzr_Searches_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Log_Vzr_Searches_Var_Pop_Fields = {
  __typename?: 'partner_log_vzr_searches_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  tourist_number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Partner_Log_Vzr_Searches_Var_Samp_Fields = {
  __typename?: 'partner_log_vzr_searches_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  tourist_number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Partner_Log_Vzr_Searches_Variance_Fields = {
  __typename?: 'partner_log_vzr_searches_variance_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  tourist_number?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "log_vzr_views" */
export type Partner_Log_Vzr_Views = {
  __typename?: 'partner_log_vzr_views';
  browser?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  ip?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  place_id?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamp'];
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "log_vzr_views" */
export type Partner_Log_Vzr_Views_Aggregate = {
  __typename?: 'partner_log_vzr_views_aggregate';
  aggregate?: Maybe<Partner_Log_Vzr_Views_Aggregate_Fields>;
  nodes: Array<Partner_Log_Vzr_Views>;
};

/** aggregate fields of "log_vzr_views" */
export type Partner_Log_Vzr_Views_Aggregate_Fields = {
  __typename?: 'partner_log_vzr_views_aggregate_fields';
  avg?: Maybe<Partner_Log_Vzr_Views_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partner_Log_Vzr_Views_Max_Fields>;
  min?: Maybe<Partner_Log_Vzr_Views_Min_Fields>;
  stddev?: Maybe<Partner_Log_Vzr_Views_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Log_Vzr_Views_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Log_Vzr_Views_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Log_Vzr_Views_Sum_Fields>;
  var_pop?: Maybe<Partner_Log_Vzr_Views_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Log_Vzr_Views_Var_Samp_Fields>;
  variance?: Maybe<Partner_Log_Vzr_Views_Variance_Fields>;
};


/** aggregate fields of "log_vzr_views" */
export type Partner_Log_Vzr_Views_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Log_Vzr_Views_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Partner_Log_Vzr_Views_Avg_Fields = {
  __typename?: 'partner_log_vzr_views_avg_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "log_vzr_views". All fields are combined with a logical 'AND'. */
export type Partner_Log_Vzr_Views_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Log_Vzr_Views_Bool_Exp>>;
  _not?: InputMaybe<Partner_Log_Vzr_Views_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Log_Vzr_Views_Bool_Exp>>;
  browser?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  ip?: InputMaybe<String_Comparison_Exp>;
  os?: InputMaybe<String_Comparison_Exp>;
  place_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "log_vzr_views" */
export enum Partner_Log_Vzr_Views_Constraint {
  /** unique or primary key constraint on columns "id" */
  LogVzrViewsPkey = 'log_vzr_views_pkey'
}

/** input type for incrementing numeric columns in table "log_vzr_views" */
export type Partner_Log_Vzr_Views_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  place_id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "log_vzr_views" */
export type Partner_Log_Vzr_Views_Insert_Input = {
  browser?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  ip?: InputMaybe<Scalars['String']>;
  os?: InputMaybe<Scalars['String']>;
  place_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Partner_Log_Vzr_Views_Max_Fields = {
  __typename?: 'partner_log_vzr_views_max_fields';
  browser?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  ip?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  place_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Partner_Log_Vzr_Views_Min_Fields = {
  __typename?: 'partner_log_vzr_views_min_fields';
  browser?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  ip?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  place_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "log_vzr_views" */
export type Partner_Log_Vzr_Views_Mutation_Response = {
  __typename?: 'partner_log_vzr_views_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Log_Vzr_Views>;
};

/** on_conflict condition type for table "log_vzr_views" */
export type Partner_Log_Vzr_Views_On_Conflict = {
  constraint: Partner_Log_Vzr_Views_Constraint;
  update_columns?: Array<Partner_Log_Vzr_Views_Update_Column>;
  where?: InputMaybe<Partner_Log_Vzr_Views_Bool_Exp>;
};

/** Ordering options when selecting data from "log_vzr_views". */
export type Partner_Log_Vzr_Views_Order_By = {
  browser?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ip?: InputMaybe<Order_By>;
  os?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: partner_log_vzr_views */
export type Partner_Log_Vzr_Views_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "log_vzr_views" */
export enum Partner_Log_Vzr_Views_Select_Column {
  /** column name */
  Browser = 'browser',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  Os = 'os',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "log_vzr_views" */
export type Partner_Log_Vzr_Views_Set_Input = {
  browser?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  ip?: InputMaybe<Scalars['String']>;
  os?: InputMaybe<Scalars['String']>;
  place_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Partner_Log_Vzr_Views_Stddev_Fields = {
  __typename?: 'partner_log_vzr_views_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Log_Vzr_Views_Stddev_Pop_Fields = {
  __typename?: 'partner_log_vzr_views_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Log_Vzr_Views_Stddev_Samp_Fields = {
  __typename?: 'partner_log_vzr_views_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "partner_log_vzr_views" */
export type Partner_Log_Vzr_Views_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Log_Vzr_Views_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Log_Vzr_Views_Stream_Cursor_Value_Input = {
  browser?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  ip?: InputMaybe<Scalars['String']>;
  os?: InputMaybe<Scalars['String']>;
  place_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Partner_Log_Vzr_Views_Sum_Fields = {
  __typename?: 'partner_log_vzr_views_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  place_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "log_vzr_views" */
export enum Partner_Log_Vzr_Views_Update_Column {
  /** column name */
  Browser = 'browser',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  Os = 'os',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Partner_Log_Vzr_Views_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Log_Vzr_Views_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Log_Vzr_Views_Set_Input>;
  where: Partner_Log_Vzr_Views_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Log_Vzr_Views_Var_Pop_Fields = {
  __typename?: 'partner_log_vzr_views_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Partner_Log_Vzr_Views_Var_Samp_Fields = {
  __typename?: 'partner_log_vzr_views_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Partner_Log_Vzr_Views_Variance_Fields = {
  __typename?: 'partner_log_vzr_views_variance_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "place_type_integrations" */
export type Partner_Place_Type_Integrations = {
  __typename?: 'partner_place_type_integrations';
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  /** An object relationship */
  place?: Maybe<Partner_Places>;
  place_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  status_integration?: Maybe<Partner_Status_Integrations>;
  status_integration_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  type_integration?: Maybe<Partner_Type_Integrations>;
  type_integration_id?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamp'];
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "place_type_integrations" */
export type Partner_Place_Type_Integrations_Aggregate = {
  __typename?: 'partner_place_type_integrations_aggregate';
  aggregate?: Maybe<Partner_Place_Type_Integrations_Aggregate_Fields>;
  nodes: Array<Partner_Place_Type_Integrations>;
};

/** aggregate fields of "place_type_integrations" */
export type Partner_Place_Type_Integrations_Aggregate_Fields = {
  __typename?: 'partner_place_type_integrations_aggregate_fields';
  avg?: Maybe<Partner_Place_Type_Integrations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partner_Place_Type_Integrations_Max_Fields>;
  min?: Maybe<Partner_Place_Type_Integrations_Min_Fields>;
  stddev?: Maybe<Partner_Place_Type_Integrations_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Place_Type_Integrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Place_Type_Integrations_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Place_Type_Integrations_Sum_Fields>;
  var_pop?: Maybe<Partner_Place_Type_Integrations_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Place_Type_Integrations_Var_Samp_Fields>;
  variance?: Maybe<Partner_Place_Type_Integrations_Variance_Fields>;
};


/** aggregate fields of "place_type_integrations" */
export type Partner_Place_Type_Integrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Place_Type_Integrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Partner_Place_Type_Integrations_Avg_Fields = {
  __typename?: 'partner_place_type_integrations_avg_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  status_integration_id?: Maybe<Scalars['Float']>;
  type_integration_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "place_type_integrations". All fields are combined with a logical 'AND'. */
export type Partner_Place_Type_Integrations_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Place_Type_Integrations_Bool_Exp>>;
  _not?: InputMaybe<Partner_Place_Type_Integrations_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Place_Type_Integrations_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  place?: InputMaybe<Partner_Places_Bool_Exp>;
  place_id?: InputMaybe<Bigint_Comparison_Exp>;
  status_integration?: InputMaybe<Partner_Status_Integrations_Bool_Exp>;
  status_integration_id?: InputMaybe<Bigint_Comparison_Exp>;
  type_integration?: InputMaybe<Partner_Type_Integrations_Bool_Exp>;
  type_integration_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "place_type_integrations" */
export enum Partner_Place_Type_Integrations_Constraint {
  /** unique or primary key constraint on columns "id" */
  PlaceTypeIntegrationsPkey = 'place_type_integrations_pkey'
}

/** input type for incrementing numeric columns in table "place_type_integrations" */
export type Partner_Place_Type_Integrations_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  place_id?: InputMaybe<Scalars['bigint']>;
  status_integration_id?: InputMaybe<Scalars['bigint']>;
  type_integration_id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "place_type_integrations" */
export type Partner_Place_Type_Integrations_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  place?: InputMaybe<Partner_Places_Obj_Rel_Insert_Input>;
  place_id?: InputMaybe<Scalars['bigint']>;
  status_integration?: InputMaybe<Partner_Status_Integrations_Obj_Rel_Insert_Input>;
  status_integration_id?: InputMaybe<Scalars['bigint']>;
  type_integration?: InputMaybe<Partner_Type_Integrations_Obj_Rel_Insert_Input>;
  type_integration_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Partner_Place_Type_Integrations_Max_Fields = {
  __typename?: 'partner_place_type_integrations_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  place_id?: Maybe<Scalars['bigint']>;
  status_integration_id?: Maybe<Scalars['bigint']>;
  type_integration_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Partner_Place_Type_Integrations_Min_Fields = {
  __typename?: 'partner_place_type_integrations_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  place_id?: Maybe<Scalars['bigint']>;
  status_integration_id?: Maybe<Scalars['bigint']>;
  type_integration_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "place_type_integrations" */
export type Partner_Place_Type_Integrations_Mutation_Response = {
  __typename?: 'partner_place_type_integrations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Place_Type_Integrations>;
};

/** on_conflict condition type for table "place_type_integrations" */
export type Partner_Place_Type_Integrations_On_Conflict = {
  constraint: Partner_Place_Type_Integrations_Constraint;
  update_columns?: Array<Partner_Place_Type_Integrations_Update_Column>;
  where?: InputMaybe<Partner_Place_Type_Integrations_Bool_Exp>;
};

/** Ordering options when selecting data from "place_type_integrations". */
export type Partner_Place_Type_Integrations_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  place?: InputMaybe<Partner_Places_Order_By>;
  place_id?: InputMaybe<Order_By>;
  status_integration?: InputMaybe<Partner_Status_Integrations_Order_By>;
  status_integration_id?: InputMaybe<Order_By>;
  type_integration?: InputMaybe<Partner_Type_Integrations_Order_By>;
  type_integration_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: partner_place_type_integrations */
export type Partner_Place_Type_Integrations_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "place_type_integrations" */
export enum Partner_Place_Type_Integrations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  StatusIntegrationId = 'status_integration_id',
  /** column name */
  TypeIntegrationId = 'type_integration_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "place_type_integrations" */
export type Partner_Place_Type_Integrations_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  place_id?: InputMaybe<Scalars['bigint']>;
  status_integration_id?: InputMaybe<Scalars['bigint']>;
  type_integration_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Partner_Place_Type_Integrations_Stddev_Fields = {
  __typename?: 'partner_place_type_integrations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  status_integration_id?: Maybe<Scalars['Float']>;
  type_integration_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Place_Type_Integrations_Stddev_Pop_Fields = {
  __typename?: 'partner_place_type_integrations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  status_integration_id?: Maybe<Scalars['Float']>;
  type_integration_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Place_Type_Integrations_Stddev_Samp_Fields = {
  __typename?: 'partner_place_type_integrations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  status_integration_id?: Maybe<Scalars['Float']>;
  type_integration_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "partner_place_type_integrations" */
export type Partner_Place_Type_Integrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Place_Type_Integrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Place_Type_Integrations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  place_id?: InputMaybe<Scalars['bigint']>;
  status_integration_id?: InputMaybe<Scalars['bigint']>;
  type_integration_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Partner_Place_Type_Integrations_Sum_Fields = {
  __typename?: 'partner_place_type_integrations_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  place_id?: Maybe<Scalars['bigint']>;
  status_integration_id?: Maybe<Scalars['bigint']>;
  type_integration_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "place_type_integrations" */
export enum Partner_Place_Type_Integrations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  StatusIntegrationId = 'status_integration_id',
  /** column name */
  TypeIntegrationId = 'type_integration_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Partner_Place_Type_Integrations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Place_Type_Integrations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Place_Type_Integrations_Set_Input>;
  where: Partner_Place_Type_Integrations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Place_Type_Integrations_Var_Pop_Fields = {
  __typename?: 'partner_place_type_integrations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  status_integration_id?: Maybe<Scalars['Float']>;
  type_integration_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Partner_Place_Type_Integrations_Var_Samp_Fields = {
  __typename?: 'partner_place_type_integrations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  status_integration_id?: Maybe<Scalars['Float']>;
  type_integration_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Partner_Place_Type_Integrations_Variance_Fields = {
  __typename?: 'partner_place_type_integrations_variance_fields';
  id?: Maybe<Scalars['Float']>;
  place_id?: Maybe<Scalars['Float']>;
  status_integration_id?: Maybe<Scalars['Float']>;
  type_integration_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "places" */
export type Partner_Places = {
  __typename?: 'partner_places';
  address_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id: Scalars['bigint'];
  moderation?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  status_right?: Maybe<Scalars['String']>;
  theme_id?: Maybe<Scalars['bigint']>;
  uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "places" */
export type Partner_Places_Aggregate = {
  __typename?: 'partner_places_aggregate';
  aggregate?: Maybe<Partner_Places_Aggregate_Fields>;
  nodes: Array<Partner_Places>;
};

/** aggregate fields of "places" */
export type Partner_Places_Aggregate_Fields = {
  __typename?: 'partner_places_aggregate_fields';
  avg?: Maybe<Partner_Places_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partner_Places_Max_Fields>;
  min?: Maybe<Partner_Places_Min_Fields>;
  stddev?: Maybe<Partner_Places_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Places_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Places_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Places_Sum_Fields>;
  var_pop?: Maybe<Partner_Places_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Places_Var_Samp_Fields>;
  variance?: Maybe<Partner_Places_Variance_Fields>;
};


/** aggregate fields of "places" */
export type Partner_Places_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Places_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Partner_Places_Avg_Fields = {
  __typename?: 'partner_places_avg_fields';
  id?: Maybe<Scalars['Float']>;
  theme_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "places". All fields are combined with a logical 'AND'. */
export type Partner_Places_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Places_Bool_Exp>>;
  _not?: InputMaybe<Partner_Places_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Places_Bool_Exp>>;
  address_url?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  moderation?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  status_right?: InputMaybe<String_Comparison_Exp>;
  theme_id?: InputMaybe<Bigint_Comparison_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "places" */
export enum Partner_Places_Constraint {
  /** unique or primary key constraint on columns "id" */
  PlacesPkey = 'places_pkey'
}

/** input type for incrementing numeric columns in table "places" */
export type Partner_Places_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  theme_id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "places" */
export type Partner_Places_Insert_Input = {
  address_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  moderation?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  status_right?: InputMaybe<Scalars['String']>;
  theme_id?: InputMaybe<Scalars['bigint']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Partner_Places_Max_Fields = {
  __typename?: 'partner_places_max_fields';
  address_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  status_right?: Maybe<Scalars['String']>;
  theme_id?: Maybe<Scalars['bigint']>;
  uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Partner_Places_Min_Fields = {
  __typename?: 'partner_places_min_fields';
  address_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  status_right?: Maybe<Scalars['String']>;
  theme_id?: Maybe<Scalars['bigint']>;
  uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "places" */
export type Partner_Places_Mutation_Response = {
  __typename?: 'partner_places_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Places>;
};

/** input type for inserting object relation for remote table "places" */
export type Partner_Places_Obj_Rel_Insert_Input = {
  data: Partner_Places_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Partner_Places_On_Conflict>;
};

/** on_conflict condition type for table "places" */
export type Partner_Places_On_Conflict = {
  constraint: Partner_Places_Constraint;
  update_columns?: Array<Partner_Places_Update_Column>;
  where?: InputMaybe<Partner_Places_Bool_Exp>;
};

/** Ordering options when selecting data from "places". */
export type Partner_Places_Order_By = {
  address_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  moderation?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  status_right?: InputMaybe<Order_By>;
  theme_id?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: partner_places */
export type Partner_Places_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "places" */
export enum Partner_Places_Select_Column {
  /** column name */
  AddressUrl = 'address_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Moderation = 'moderation',
  /** column name */
  Name = 'name',
  /** column name */
  StatusRight = 'status_right',
  /** column name */
  ThemeId = 'theme_id',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "places" */
export type Partner_Places_Set_Input = {
  address_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  moderation?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  status_right?: InputMaybe<Scalars['String']>;
  theme_id?: InputMaybe<Scalars['bigint']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Partner_Places_Stddev_Fields = {
  __typename?: 'partner_places_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  theme_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Places_Stddev_Pop_Fields = {
  __typename?: 'partner_places_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  theme_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Places_Stddev_Samp_Fields = {
  __typename?: 'partner_places_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  theme_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "partner_places" */
export type Partner_Places_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Places_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Places_Stream_Cursor_Value_Input = {
  address_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  moderation?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  status_right?: InputMaybe<Scalars['String']>;
  theme_id?: InputMaybe<Scalars['bigint']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Partner_Places_Sum_Fields = {
  __typename?: 'partner_places_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  theme_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "places" */
export enum Partner_Places_Update_Column {
  /** column name */
  AddressUrl = 'address_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Moderation = 'moderation',
  /** column name */
  Name = 'name',
  /** column name */
  StatusRight = 'status_right',
  /** column name */
  ThemeId = 'theme_id',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Partner_Places_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Places_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Places_Set_Input>;
  where: Partner_Places_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Places_Var_Pop_Fields = {
  __typename?: 'partner_places_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  theme_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Partner_Places_Var_Samp_Fields = {
  __typename?: 'partner_places_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  theme_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Partner_Places_Variance_Fields = {
  __typename?: 'partner_places_variance_fields';
  id?: Maybe<Scalars['Float']>;
  theme_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "sale_types" */
export type Partner_Sale_Types = {
  __typename?: 'partner_sale_types';
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  label?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  value?: Maybe<Scalars['String']>;
};

/** aggregated selection of "sale_types" */
export type Partner_Sale_Types_Aggregate = {
  __typename?: 'partner_sale_types_aggregate';
  aggregate?: Maybe<Partner_Sale_Types_Aggregate_Fields>;
  nodes: Array<Partner_Sale_Types>;
};

/** aggregate fields of "sale_types" */
export type Partner_Sale_Types_Aggregate_Fields = {
  __typename?: 'partner_sale_types_aggregate_fields';
  avg?: Maybe<Partner_Sale_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partner_Sale_Types_Max_Fields>;
  min?: Maybe<Partner_Sale_Types_Min_Fields>;
  stddev?: Maybe<Partner_Sale_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Sale_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Sale_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Sale_Types_Sum_Fields>;
  var_pop?: Maybe<Partner_Sale_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Sale_Types_Var_Samp_Fields>;
  variance?: Maybe<Partner_Sale_Types_Variance_Fields>;
};


/** aggregate fields of "sale_types" */
export type Partner_Sale_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Sale_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Partner_Sale_Types_Avg_Fields = {
  __typename?: 'partner_sale_types_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "sale_types". All fields are combined with a logical 'AND'. */
export type Partner_Sale_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Sale_Types_Bool_Exp>>;
  _not?: InputMaybe<Partner_Sale_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Sale_Types_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sale_types" */
export enum Partner_Sale_Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  SaleTypesPkey = 'sale_types_pkey'
}

/** input type for incrementing numeric columns in table "sale_types" */
export type Partner_Sale_Types_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "sale_types" */
export type Partner_Sale_Types_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  label?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Partner_Sale_Types_Max_Fields = {
  __typename?: 'partner_sale_types_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  label?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Partner_Sale_Types_Min_Fields = {
  __typename?: 'partner_sale_types_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  label?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "sale_types" */
export type Partner_Sale_Types_Mutation_Response = {
  __typename?: 'partner_sale_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Sale_Types>;
};

/** on_conflict condition type for table "sale_types" */
export type Partner_Sale_Types_On_Conflict = {
  constraint: Partner_Sale_Types_Constraint;
  update_columns?: Array<Partner_Sale_Types_Update_Column>;
  where?: InputMaybe<Partner_Sale_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "sale_types". */
export type Partner_Sale_Types_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: partner_sale_types */
export type Partner_Sale_Types_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "sale_types" */
export enum Partner_Sale_Types_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "sale_types" */
export type Partner_Sale_Types_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  label?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Partner_Sale_Types_Stddev_Fields = {
  __typename?: 'partner_sale_types_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Sale_Types_Stddev_Pop_Fields = {
  __typename?: 'partner_sale_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Sale_Types_Stddev_Samp_Fields = {
  __typename?: 'partner_sale_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "partner_sale_types" */
export type Partner_Sale_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Sale_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Sale_Types_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  label?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Partner_Sale_Types_Sum_Fields = {
  __typename?: 'partner_sale_types_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "sale_types" */
export enum Partner_Sale_Types_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Partner_Sale_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Sale_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Sale_Types_Set_Input>;
  where: Partner_Sale_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Sale_Types_Var_Pop_Fields = {
  __typename?: 'partner_sale_types_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Partner_Sale_Types_Var_Samp_Fields = {
  __typename?: 'partner_sale_types_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Partner_Sale_Types_Variance_Fields = {
  __typename?: 'partner_sale_types_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "status_integrations" */
export type Partner_Status_Integrations = {
  __typename?: 'partner_status_integrations';
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  label?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  value?: Maybe<Scalars['String']>;
};

/** aggregated selection of "status_integrations" */
export type Partner_Status_Integrations_Aggregate = {
  __typename?: 'partner_status_integrations_aggregate';
  aggregate?: Maybe<Partner_Status_Integrations_Aggregate_Fields>;
  nodes: Array<Partner_Status_Integrations>;
};

/** aggregate fields of "status_integrations" */
export type Partner_Status_Integrations_Aggregate_Fields = {
  __typename?: 'partner_status_integrations_aggregate_fields';
  avg?: Maybe<Partner_Status_Integrations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partner_Status_Integrations_Max_Fields>;
  min?: Maybe<Partner_Status_Integrations_Min_Fields>;
  stddev?: Maybe<Partner_Status_Integrations_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Status_Integrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Status_Integrations_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Status_Integrations_Sum_Fields>;
  var_pop?: Maybe<Partner_Status_Integrations_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Status_Integrations_Var_Samp_Fields>;
  variance?: Maybe<Partner_Status_Integrations_Variance_Fields>;
};


/** aggregate fields of "status_integrations" */
export type Partner_Status_Integrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Status_Integrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Partner_Status_Integrations_Avg_Fields = {
  __typename?: 'partner_status_integrations_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "status_integrations". All fields are combined with a logical 'AND'. */
export type Partner_Status_Integrations_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Status_Integrations_Bool_Exp>>;
  _not?: InputMaybe<Partner_Status_Integrations_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Status_Integrations_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "status_integrations" */
export enum Partner_Status_Integrations_Constraint {
  /** unique or primary key constraint on columns "id" */
  StatusIntegrationsPkey = 'status_integrations_pkey'
}

/** input type for incrementing numeric columns in table "status_integrations" */
export type Partner_Status_Integrations_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "status_integrations" */
export type Partner_Status_Integrations_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  label?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Partner_Status_Integrations_Max_Fields = {
  __typename?: 'partner_status_integrations_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  label?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Partner_Status_Integrations_Min_Fields = {
  __typename?: 'partner_status_integrations_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  label?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "status_integrations" */
export type Partner_Status_Integrations_Mutation_Response = {
  __typename?: 'partner_status_integrations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Status_Integrations>;
};

/** input type for inserting object relation for remote table "status_integrations" */
export type Partner_Status_Integrations_Obj_Rel_Insert_Input = {
  data: Partner_Status_Integrations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Partner_Status_Integrations_On_Conflict>;
};

/** on_conflict condition type for table "status_integrations" */
export type Partner_Status_Integrations_On_Conflict = {
  constraint: Partner_Status_Integrations_Constraint;
  update_columns?: Array<Partner_Status_Integrations_Update_Column>;
  where?: InputMaybe<Partner_Status_Integrations_Bool_Exp>;
};

/** Ordering options when selecting data from "status_integrations". */
export type Partner_Status_Integrations_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: partner_status_integrations */
export type Partner_Status_Integrations_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "status_integrations" */
export enum Partner_Status_Integrations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "status_integrations" */
export type Partner_Status_Integrations_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  label?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Partner_Status_Integrations_Stddev_Fields = {
  __typename?: 'partner_status_integrations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Status_Integrations_Stddev_Pop_Fields = {
  __typename?: 'partner_status_integrations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Status_Integrations_Stddev_Samp_Fields = {
  __typename?: 'partner_status_integrations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "partner_status_integrations" */
export type Partner_Status_Integrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Status_Integrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Status_Integrations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  label?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Partner_Status_Integrations_Sum_Fields = {
  __typename?: 'partner_status_integrations_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "status_integrations" */
export enum Partner_Status_Integrations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Partner_Status_Integrations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Status_Integrations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Status_Integrations_Set_Input>;
  where: Partner_Status_Integrations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Status_Integrations_Var_Pop_Fields = {
  __typename?: 'partner_status_integrations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Partner_Status_Integrations_Var_Samp_Fields = {
  __typename?: 'partner_status_integrations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Partner_Status_Integrations_Variance_Fields = {
  __typename?: 'partner_status_integrations_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "status_transactions" */
export type Partner_Status_Transactions = {
  __typename?: 'partner_status_transactions';
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "status_transactions" */
export type Partner_Status_Transactions_Aggregate = {
  __typename?: 'partner_status_transactions_aggregate';
  aggregate?: Maybe<Partner_Status_Transactions_Aggregate_Fields>;
  nodes: Array<Partner_Status_Transactions>;
};

/** aggregate fields of "status_transactions" */
export type Partner_Status_Transactions_Aggregate_Fields = {
  __typename?: 'partner_status_transactions_aggregate_fields';
  avg?: Maybe<Partner_Status_Transactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partner_Status_Transactions_Max_Fields>;
  min?: Maybe<Partner_Status_Transactions_Min_Fields>;
  stddev?: Maybe<Partner_Status_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Status_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Status_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Status_Transactions_Sum_Fields>;
  var_pop?: Maybe<Partner_Status_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Status_Transactions_Var_Samp_Fields>;
  variance?: Maybe<Partner_Status_Transactions_Variance_Fields>;
};


/** aggregate fields of "status_transactions" */
export type Partner_Status_Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Status_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Partner_Status_Transactions_Avg_Fields = {
  __typename?: 'partner_status_transactions_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "status_transactions". All fields are combined with a logical 'AND'. */
export type Partner_Status_Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Status_Transactions_Bool_Exp>>;
  _not?: InputMaybe<Partner_Status_Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Status_Transactions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "status_transactions" */
export enum Partner_Status_Transactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  StatusTransactionsPkey = 'status_transactions_pkey'
}

/** input type for incrementing numeric columns in table "status_transactions" */
export type Partner_Status_Transactions_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "status_transactions" */
export type Partner_Status_Transactions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Partner_Status_Transactions_Max_Fields = {
  __typename?: 'partner_status_transactions_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Partner_Status_Transactions_Min_Fields = {
  __typename?: 'partner_status_transactions_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "status_transactions" */
export type Partner_Status_Transactions_Mutation_Response = {
  __typename?: 'partner_status_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Status_Transactions>;
};

/** on_conflict condition type for table "status_transactions" */
export type Partner_Status_Transactions_On_Conflict = {
  constraint: Partner_Status_Transactions_Constraint;
  update_columns?: Array<Partner_Status_Transactions_Update_Column>;
  where?: InputMaybe<Partner_Status_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "status_transactions". */
export type Partner_Status_Transactions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: partner_status_transactions */
export type Partner_Status_Transactions_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "status_transactions" */
export enum Partner_Status_Transactions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "status_transactions" */
export type Partner_Status_Transactions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Partner_Status_Transactions_Stddev_Fields = {
  __typename?: 'partner_status_transactions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Status_Transactions_Stddev_Pop_Fields = {
  __typename?: 'partner_status_transactions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Status_Transactions_Stddev_Samp_Fields = {
  __typename?: 'partner_status_transactions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "partner_status_transactions" */
export type Partner_Status_Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Status_Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Status_Transactions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Partner_Status_Transactions_Sum_Fields = {
  __typename?: 'partner_status_transactions_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "status_transactions" */
export enum Partner_Status_Transactions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Partner_Status_Transactions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Status_Transactions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Status_Transactions_Set_Input>;
  where: Partner_Status_Transactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Status_Transactions_Var_Pop_Fields = {
  __typename?: 'partner_status_transactions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Partner_Status_Transactions_Var_Samp_Fields = {
  __typename?: 'partner_status_transactions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Partner_Status_Transactions_Variance_Fields = {
  __typename?: 'partner_status_transactions_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "themes" */
export type Partner_Themes = {
  __typename?: 'partner_themes';
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "themes" */
export type Partner_Themes_Aggregate = {
  __typename?: 'partner_themes_aggregate';
  aggregate?: Maybe<Partner_Themes_Aggregate_Fields>;
  nodes: Array<Partner_Themes>;
};

/** aggregate fields of "themes" */
export type Partner_Themes_Aggregate_Fields = {
  __typename?: 'partner_themes_aggregate_fields';
  avg?: Maybe<Partner_Themes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partner_Themes_Max_Fields>;
  min?: Maybe<Partner_Themes_Min_Fields>;
  stddev?: Maybe<Partner_Themes_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Themes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Themes_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Themes_Sum_Fields>;
  var_pop?: Maybe<Partner_Themes_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Themes_Var_Samp_Fields>;
  variance?: Maybe<Partner_Themes_Variance_Fields>;
};


/** aggregate fields of "themes" */
export type Partner_Themes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Themes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Partner_Themes_Avg_Fields = {
  __typename?: 'partner_themes_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "themes". All fields are combined with a logical 'AND'. */
export type Partner_Themes_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Themes_Bool_Exp>>;
  _not?: InputMaybe<Partner_Themes_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Themes_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "themes" */
export enum Partner_Themes_Constraint {
  /** unique or primary key constraint on columns "id" */
  ThemesPkey = 'themes_pkey'
}

/** input type for incrementing numeric columns in table "themes" */
export type Partner_Themes_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "themes" */
export type Partner_Themes_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Partner_Themes_Max_Fields = {
  __typename?: 'partner_themes_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Partner_Themes_Min_Fields = {
  __typename?: 'partner_themes_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "themes" */
export type Partner_Themes_Mutation_Response = {
  __typename?: 'partner_themes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Themes>;
};

/** on_conflict condition type for table "themes" */
export type Partner_Themes_On_Conflict = {
  constraint: Partner_Themes_Constraint;
  update_columns?: Array<Partner_Themes_Update_Column>;
  where?: InputMaybe<Partner_Themes_Bool_Exp>;
};

/** Ordering options when selecting data from "themes". */
export type Partner_Themes_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: partner_themes */
export type Partner_Themes_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "themes" */
export enum Partner_Themes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "themes" */
export type Partner_Themes_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Partner_Themes_Stddev_Fields = {
  __typename?: 'partner_themes_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Themes_Stddev_Pop_Fields = {
  __typename?: 'partner_themes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Themes_Stddev_Samp_Fields = {
  __typename?: 'partner_themes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "partner_themes" */
export type Partner_Themes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Themes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Themes_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Partner_Themes_Sum_Fields = {
  __typename?: 'partner_themes_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "themes" */
export enum Partner_Themes_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Partner_Themes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Themes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Themes_Set_Input>;
  where: Partner_Themes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Themes_Var_Pop_Fields = {
  __typename?: 'partner_themes_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Partner_Themes_Var_Samp_Fields = {
  __typename?: 'partner_themes_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Partner_Themes_Variance_Fields = {
  __typename?: 'partner_themes_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "type_integrations" */
export type Partner_Type_Integrations = {
  __typename?: 'partner_type_integrations';
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  label?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  value?: Maybe<Scalars['String']>;
};

/** aggregated selection of "type_integrations" */
export type Partner_Type_Integrations_Aggregate = {
  __typename?: 'partner_type_integrations_aggregate';
  aggregate?: Maybe<Partner_Type_Integrations_Aggregate_Fields>;
  nodes: Array<Partner_Type_Integrations>;
};

/** aggregate fields of "type_integrations" */
export type Partner_Type_Integrations_Aggregate_Fields = {
  __typename?: 'partner_type_integrations_aggregate_fields';
  avg?: Maybe<Partner_Type_Integrations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partner_Type_Integrations_Max_Fields>;
  min?: Maybe<Partner_Type_Integrations_Min_Fields>;
  stddev?: Maybe<Partner_Type_Integrations_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Type_Integrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Type_Integrations_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Type_Integrations_Sum_Fields>;
  var_pop?: Maybe<Partner_Type_Integrations_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Type_Integrations_Var_Samp_Fields>;
  variance?: Maybe<Partner_Type_Integrations_Variance_Fields>;
};


/** aggregate fields of "type_integrations" */
export type Partner_Type_Integrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Type_Integrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Partner_Type_Integrations_Avg_Fields = {
  __typename?: 'partner_type_integrations_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "type_integrations". All fields are combined with a logical 'AND'. */
export type Partner_Type_Integrations_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Type_Integrations_Bool_Exp>>;
  _not?: InputMaybe<Partner_Type_Integrations_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Type_Integrations_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "type_integrations" */
export enum Partner_Type_Integrations_Constraint {
  /** unique or primary key constraint on columns "id" */
  TypeIntegrationsPkey = 'type_integrations_pkey'
}

/** input type for incrementing numeric columns in table "type_integrations" */
export type Partner_Type_Integrations_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "type_integrations" */
export type Partner_Type_Integrations_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  label?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Partner_Type_Integrations_Max_Fields = {
  __typename?: 'partner_type_integrations_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  label?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Partner_Type_Integrations_Min_Fields = {
  __typename?: 'partner_type_integrations_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  label?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "type_integrations" */
export type Partner_Type_Integrations_Mutation_Response = {
  __typename?: 'partner_type_integrations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Type_Integrations>;
};

/** input type for inserting object relation for remote table "type_integrations" */
export type Partner_Type_Integrations_Obj_Rel_Insert_Input = {
  data: Partner_Type_Integrations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Partner_Type_Integrations_On_Conflict>;
};

/** on_conflict condition type for table "type_integrations" */
export type Partner_Type_Integrations_On_Conflict = {
  constraint: Partner_Type_Integrations_Constraint;
  update_columns?: Array<Partner_Type_Integrations_Update_Column>;
  where?: InputMaybe<Partner_Type_Integrations_Bool_Exp>;
};

/** Ordering options when selecting data from "type_integrations". */
export type Partner_Type_Integrations_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: partner_type_integrations */
export type Partner_Type_Integrations_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "type_integrations" */
export enum Partner_Type_Integrations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "type_integrations" */
export type Partner_Type_Integrations_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  label?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Partner_Type_Integrations_Stddev_Fields = {
  __typename?: 'partner_type_integrations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Type_Integrations_Stddev_Pop_Fields = {
  __typename?: 'partner_type_integrations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Type_Integrations_Stddev_Samp_Fields = {
  __typename?: 'partner_type_integrations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "partner_type_integrations" */
export type Partner_Type_Integrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Type_Integrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Type_Integrations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  label?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Partner_Type_Integrations_Sum_Fields = {
  __typename?: 'partner_type_integrations_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "type_integrations" */
export enum Partner_Type_Integrations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Partner_Type_Integrations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Type_Integrations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Type_Integrations_Set_Input>;
  where: Partner_Type_Integrations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Type_Integrations_Var_Pop_Fields = {
  __typename?: 'partner_type_integrations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Partner_Type_Integrations_Var_Samp_Fields = {
  __typename?: 'partner_type_integrations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Partner_Type_Integrations_Variance_Fields = {
  __typename?: 'partner_type_integrations_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "type_transactions" */
export type Partner_Type_Transactions = {
  __typename?: 'partner_type_transactions';
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "type_transactions" */
export type Partner_Type_Transactions_Aggregate = {
  __typename?: 'partner_type_transactions_aggregate';
  aggregate?: Maybe<Partner_Type_Transactions_Aggregate_Fields>;
  nodes: Array<Partner_Type_Transactions>;
};

/** aggregate fields of "type_transactions" */
export type Partner_Type_Transactions_Aggregate_Fields = {
  __typename?: 'partner_type_transactions_aggregate_fields';
  avg?: Maybe<Partner_Type_Transactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partner_Type_Transactions_Max_Fields>;
  min?: Maybe<Partner_Type_Transactions_Min_Fields>;
  stddev?: Maybe<Partner_Type_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Type_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Type_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Type_Transactions_Sum_Fields>;
  var_pop?: Maybe<Partner_Type_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Type_Transactions_Var_Samp_Fields>;
  variance?: Maybe<Partner_Type_Transactions_Variance_Fields>;
};


/** aggregate fields of "type_transactions" */
export type Partner_Type_Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Type_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Partner_Type_Transactions_Avg_Fields = {
  __typename?: 'partner_type_transactions_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "type_transactions". All fields are combined with a logical 'AND'. */
export type Partner_Type_Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Type_Transactions_Bool_Exp>>;
  _not?: InputMaybe<Partner_Type_Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Type_Transactions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "type_transactions" */
export enum Partner_Type_Transactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  TypeTransactionsPkey = 'type_transactions_pkey'
}

/** input type for incrementing numeric columns in table "type_transactions" */
export type Partner_Type_Transactions_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "type_transactions" */
export type Partner_Type_Transactions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Partner_Type_Transactions_Max_Fields = {
  __typename?: 'partner_type_transactions_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Partner_Type_Transactions_Min_Fields = {
  __typename?: 'partner_type_transactions_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "type_transactions" */
export type Partner_Type_Transactions_Mutation_Response = {
  __typename?: 'partner_type_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Type_Transactions>;
};

/** on_conflict condition type for table "type_transactions" */
export type Partner_Type_Transactions_On_Conflict = {
  constraint: Partner_Type_Transactions_Constraint;
  update_columns?: Array<Partner_Type_Transactions_Update_Column>;
  where?: InputMaybe<Partner_Type_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "type_transactions". */
export type Partner_Type_Transactions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: partner_type_transactions */
export type Partner_Type_Transactions_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "type_transactions" */
export enum Partner_Type_Transactions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "type_transactions" */
export type Partner_Type_Transactions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Partner_Type_Transactions_Stddev_Fields = {
  __typename?: 'partner_type_transactions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Type_Transactions_Stddev_Pop_Fields = {
  __typename?: 'partner_type_transactions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Type_Transactions_Stddev_Samp_Fields = {
  __typename?: 'partner_type_transactions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "partner_type_transactions" */
export type Partner_Type_Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Type_Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Type_Transactions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Partner_Type_Transactions_Sum_Fields = {
  __typename?: 'partner_type_transactions_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "type_transactions" */
export enum Partner_Type_Transactions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Partner_Type_Transactions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Type_Transactions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Type_Transactions_Set_Input>;
  where: Partner_Type_Transactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Type_Transactions_Var_Pop_Fields = {
  __typename?: 'partner_type_transactions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Partner_Type_Transactions_Var_Samp_Fields = {
  __typename?: 'partner_type_transactions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Partner_Type_Transactions_Variance_Fields = {
  __typename?: 'partner_type_transactions_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_types" */
export type Partner_User_Types = {
  __typename?: 'partner_user_types';
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  type_title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "user_types" */
export type Partner_User_Types_Aggregate = {
  __typename?: 'partner_user_types_aggregate';
  aggregate?: Maybe<Partner_User_Types_Aggregate_Fields>;
  nodes: Array<Partner_User_Types>;
};

/** aggregate fields of "user_types" */
export type Partner_User_Types_Aggregate_Fields = {
  __typename?: 'partner_user_types_aggregate_fields';
  avg?: Maybe<Partner_User_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partner_User_Types_Max_Fields>;
  min?: Maybe<Partner_User_Types_Min_Fields>;
  stddev?: Maybe<Partner_User_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_User_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_User_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_User_Types_Sum_Fields>;
  var_pop?: Maybe<Partner_User_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_User_Types_Var_Samp_Fields>;
  variance?: Maybe<Partner_User_Types_Variance_Fields>;
};


/** aggregate fields of "user_types" */
export type Partner_User_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_User_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Partner_User_Types_Avg_Fields = {
  __typename?: 'partner_user_types_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_types". All fields are combined with a logical 'AND'. */
export type Partner_User_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_User_Types_Bool_Exp>>;
  _not?: InputMaybe<Partner_User_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_User_Types_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  type_title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_types" */
export enum Partner_User_Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserTypesPkey = 'user_types_pkey'
}

/** input type for incrementing numeric columns in table "user_types" */
export type Partner_User_Types_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user_types" */
export type Partner_User_Types_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  type_title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Partner_User_Types_Max_Fields = {
  __typename?: 'partner_user_types_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  type_title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Partner_User_Types_Min_Fields = {
  __typename?: 'partner_user_types_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  type_title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "user_types" */
export type Partner_User_Types_Mutation_Response = {
  __typename?: 'partner_user_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_User_Types>;
};

/** on_conflict condition type for table "user_types" */
export type Partner_User_Types_On_Conflict = {
  constraint: Partner_User_Types_Constraint;
  update_columns?: Array<Partner_User_Types_Update_Column>;
  where?: InputMaybe<Partner_User_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "user_types". */
export type Partner_User_Types_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type_title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: partner_user_types */
export type Partner_User_Types_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "user_types" */
export enum Partner_User_Types_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TypeTitle = 'type_title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "user_types" */
export type Partner_User_Types_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  type_title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Partner_User_Types_Stddev_Fields = {
  __typename?: 'partner_user_types_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Partner_User_Types_Stddev_Pop_Fields = {
  __typename?: 'partner_user_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Partner_User_Types_Stddev_Samp_Fields = {
  __typename?: 'partner_user_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "partner_user_types" */
export type Partner_User_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_User_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_User_Types_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  type_title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Partner_User_Types_Sum_Fields = {
  __typename?: 'partner_user_types_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "user_types" */
export enum Partner_User_Types_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TypeTitle = 'type_title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Partner_User_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_User_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_User_Types_Set_Input>;
  where: Partner_User_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_User_Types_Var_Pop_Fields = {
  __typename?: 'partner_user_types_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Partner_User_Types_Var_Samp_Fields = {
  __typename?: 'partner_user_types_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Partner_User_Types_Variance_Fields = {
  __typename?: 'partner_user_types_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "users" */
export type Partner_Users = {
  __typename?: 'partner_users';
  allow_password_change?: Maybe<Scalars['Boolean']>;
  /** Корреспондентский счёт */
  bank_correspondent_account?: Maybe<Scalars['String']>;
  /** Название банка */
  bank_inn?: Maybe<Scalars['String']>;
  /** Название банка */
  bank_name?: Maybe<Scalars['String']>;
  /** Название банка */
  bank_number_account?: Maybe<Scalars['String']>;
  /** Дата рождения */
  birthday?: Maybe<Scalars['date']>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamp']>;
  country_code?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  date_send_code?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  encrypted_password: Scalars['String'];
  /** Имя */
  first_name?: Maybe<Scalars['String']>;
  full_name: Scalars['String'];
  id: Scalars['bigint'];
  is_active?: Maybe<Scalars['Boolean']>;
  /** Фамилия */
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  otp_secret_key?: Maybe<Scalars['String']>;
  /** Паспорт */
  passport?: Maybe<Scalars['String']>;
  /** Кем выдан */
  passport_author?: Maybe<Scalars['String']>;
  /** Дата выдачи */
  passport_issue_date?: Maybe<Scalars['date']>;
  /** Код подразделения */
  passport_registration?: Maybe<Scalars['String']>;
  /** Код подразделения */
  passport_unit_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  /** Место рождения */
  place_birth?: Maybe<Scalars['String']>;
  remember_created_at?: Maybe<Scalars['timestamp']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']>;
  reset_password_token?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  /** Отчество */
  second_name?: Maybe<Scalars['String']>;
  uid: Scalars['String'];
  unconfirmed_email?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  user_type_id?: Maybe<Scalars['bigint']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregated selection of "users" */
export type Partner_Users_Aggregate = {
  __typename?: 'partner_users_aggregate';
  aggregate?: Maybe<Partner_Users_Aggregate_Fields>;
  nodes: Array<Partner_Users>;
};

/** aggregate fields of "users" */
export type Partner_Users_Aggregate_Fields = {
  __typename?: 'partner_users_aggregate_fields';
  avg?: Maybe<Partner_Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partner_Users_Max_Fields>;
  min?: Maybe<Partner_Users_Min_Fields>;
  stddev?: Maybe<Partner_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Users_Sum_Fields>;
  var_pop?: Maybe<Partner_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Users_Var_Samp_Fields>;
  variance?: Maybe<Partner_Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Partner_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Partner_Users_Avg_Fields = {
  __typename?: 'partner_users_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_type_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Partner_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Users_Bool_Exp>>;
  _not?: InputMaybe<Partner_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Users_Bool_Exp>>;
  allow_password_change?: InputMaybe<Boolean_Comparison_Exp>;
  bank_correspondent_account?: InputMaybe<String_Comparison_Exp>;
  bank_inn?: InputMaybe<String_Comparison_Exp>;
  bank_name?: InputMaybe<String_Comparison_Exp>;
  bank_number_account?: InputMaybe<String_Comparison_Exp>;
  birthday?: InputMaybe<Date_Comparison_Exp>;
  confirmation_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  confirmation_token?: InputMaybe<String_Comparison_Exp>;
  confirmed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  date_send_code?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  encrypted_password?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  otp_secret_key?: InputMaybe<String_Comparison_Exp>;
  passport?: InputMaybe<String_Comparison_Exp>;
  passport_author?: InputMaybe<String_Comparison_Exp>;
  passport_issue_date?: InputMaybe<Date_Comparison_Exp>;
  passport_registration?: InputMaybe<String_Comparison_Exp>;
  passport_unit_code?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  place_birth?: InputMaybe<String_Comparison_Exp>;
  remember_created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  reset_password_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  reset_password_token?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  second_name?: InputMaybe<String_Comparison_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
  unconfirmed_email?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_type_id?: InputMaybe<Bigint_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Partner_Users_Constraint {
  /** unique or primary key constraint on columns "confirmation_token" */
  IndexUsersOnConfirmationToken = 'index_users_on_confirmation_token',
  /** unique or primary key constraint on columns "email" */
  IndexUsersOnEmail = 'index_users_on_email',
  /** unique or primary key constraint on columns "reset_password_token" */
  IndexUsersOnResetPasswordToken = 'index_users_on_reset_password_token',
  /** unique or primary key constraint on columns "username" */
  IndexUsersOnUsername = 'index_users_on_username',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** input type for incrementing numeric columns in table "users" */
export type Partner_Users_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  user_type_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "users" */
export type Partner_Users_Insert_Input = {
  allow_password_change?: InputMaybe<Scalars['Boolean']>;
  /** Корреспондентский счёт */
  bank_correspondent_account?: InputMaybe<Scalars['String']>;
  /** Название банка */
  bank_inn?: InputMaybe<Scalars['String']>;
  /** Название банка */
  bank_name?: InputMaybe<Scalars['String']>;
  /** Название банка */
  bank_number_account?: InputMaybe<Scalars['String']>;
  /** Дата рождения */
  birthday?: InputMaybe<Scalars['date']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']>;
  confirmation_token?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  date_send_code?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  encrypted_password?: InputMaybe<Scalars['String']>;
  /** Имя */
  first_name?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  /** Фамилия */
  last_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  otp_secret_key?: InputMaybe<Scalars['String']>;
  /** Паспорт */
  passport?: InputMaybe<Scalars['String']>;
  /** Кем выдан */
  passport_author?: InputMaybe<Scalars['String']>;
  /** Дата выдачи */
  passport_issue_date?: InputMaybe<Scalars['date']>;
  /** Код подразделения */
  passport_registration?: InputMaybe<Scalars['String']>;
  /** Код подразделения */
  passport_unit_code?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  /** Место рождения */
  place_birth?: InputMaybe<Scalars['String']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_token?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  /** Отчество */
  second_name?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  unconfirmed_email?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_type_id?: InputMaybe<Scalars['bigint']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Partner_Users_Max_Fields = {
  __typename?: 'partner_users_max_fields';
  /** Корреспондентский счёт */
  bank_correspondent_account?: Maybe<Scalars['String']>;
  /** Название банка */
  bank_inn?: Maybe<Scalars['String']>;
  /** Название банка */
  bank_name?: Maybe<Scalars['String']>;
  /** Название банка */
  bank_number_account?: Maybe<Scalars['String']>;
  /** Дата рождения */
  birthday?: Maybe<Scalars['date']>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamp']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_send_code?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  encrypted_password?: Maybe<Scalars['String']>;
  /** Имя */
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  /** Фамилия */
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  otp_secret_key?: Maybe<Scalars['String']>;
  /** Паспорт */
  passport?: Maybe<Scalars['String']>;
  /** Кем выдан */
  passport_author?: Maybe<Scalars['String']>;
  /** Дата выдачи */
  passport_issue_date?: Maybe<Scalars['date']>;
  /** Код подразделения */
  passport_registration?: Maybe<Scalars['String']>;
  /** Код подразделения */
  passport_unit_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  /** Место рождения */
  place_birth?: Maybe<Scalars['String']>;
  remember_created_at?: Maybe<Scalars['timestamp']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']>;
  reset_password_token?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  /** Отчество */
  second_name?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  unconfirmed_email?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_type_id?: Maybe<Scalars['bigint']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Partner_Users_Min_Fields = {
  __typename?: 'partner_users_min_fields';
  /** Корреспондентский счёт */
  bank_correspondent_account?: Maybe<Scalars['String']>;
  /** Название банка */
  bank_inn?: Maybe<Scalars['String']>;
  /** Название банка */
  bank_name?: Maybe<Scalars['String']>;
  /** Название банка */
  bank_number_account?: Maybe<Scalars['String']>;
  /** Дата рождения */
  birthday?: Maybe<Scalars['date']>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamp']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_send_code?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  encrypted_password?: Maybe<Scalars['String']>;
  /** Имя */
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  /** Фамилия */
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  otp_secret_key?: Maybe<Scalars['String']>;
  /** Паспорт */
  passport?: Maybe<Scalars['String']>;
  /** Кем выдан */
  passport_author?: Maybe<Scalars['String']>;
  /** Дата выдачи */
  passport_issue_date?: Maybe<Scalars['date']>;
  /** Код подразделения */
  passport_registration?: Maybe<Scalars['String']>;
  /** Код подразделения */
  passport_unit_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  /** Место рождения */
  place_birth?: Maybe<Scalars['String']>;
  remember_created_at?: Maybe<Scalars['timestamp']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']>;
  reset_password_token?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  /** Отчество */
  second_name?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  unconfirmed_email?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_type_id?: Maybe<Scalars['bigint']>;
  username?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "users" */
export type Partner_Users_Mutation_Response = {
  __typename?: 'partner_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Users>;
};

/** on_conflict condition type for table "users" */
export type Partner_Users_On_Conflict = {
  constraint: Partner_Users_Constraint;
  update_columns?: Array<Partner_Users_Update_Column>;
  where?: InputMaybe<Partner_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Partner_Users_Order_By = {
  allow_password_change?: InputMaybe<Order_By>;
  bank_correspondent_account?: InputMaybe<Order_By>;
  bank_inn?: InputMaybe<Order_By>;
  bank_name?: InputMaybe<Order_By>;
  bank_number_account?: InputMaybe<Order_By>;
  birthday?: InputMaybe<Order_By>;
  confirmation_sent_at?: InputMaybe<Order_By>;
  confirmation_token?: InputMaybe<Order_By>;
  confirmed_at?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_send_code?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  encrypted_password?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  otp_secret_key?: InputMaybe<Order_By>;
  passport?: InputMaybe<Order_By>;
  passport_author?: InputMaybe<Order_By>;
  passport_issue_date?: InputMaybe<Order_By>;
  passport_registration?: InputMaybe<Order_By>;
  passport_unit_code?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  place_birth?: InputMaybe<Order_By>;
  remember_created_at?: InputMaybe<Order_By>;
  reset_password_sent_at?: InputMaybe<Order_By>;
  reset_password_token?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  second_name?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  unconfirmed_email?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_type_id?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: partner_users */
export type Partner_Users_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "users" */
export enum Partner_Users_Select_Column {
  /** column name */
  AllowPasswordChange = 'allow_password_change',
  /** column name */
  BankCorrespondentAccount = 'bank_correspondent_account',
  /** column name */
  BankInn = 'bank_inn',
  /** column name */
  BankName = 'bank_name',
  /** column name */
  BankNumberAccount = 'bank_number_account',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  ConfirmationSentAt = 'confirmation_sent_at',
  /** column name */
  ConfirmationToken = 'confirmation_token',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateSendCode = 'date_send_code',
  /** column name */
  Email = 'email',
  /** column name */
  EncryptedPassword = 'encrypted_password',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Name = 'name',
  /** column name */
  OtpSecretKey = 'otp_secret_key',
  /** column name */
  Passport = 'passport',
  /** column name */
  PassportAuthor = 'passport_author',
  /** column name */
  PassportIssueDate = 'passport_issue_date',
  /** column name */
  PassportRegistration = 'passport_registration',
  /** column name */
  PassportUnitCode = 'passport_unit_code',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PlaceBirth = 'place_birth',
  /** column name */
  RememberCreatedAt = 'remember_created_at',
  /** column name */
  ResetPasswordSentAt = 'reset_password_sent_at',
  /** column name */
  ResetPasswordToken = 'reset_password_token',
  /** column name */
  Role = 'role',
  /** column name */
  SecondName = 'second_name',
  /** column name */
  Uid = 'uid',
  /** column name */
  UnconfirmedEmail = 'unconfirmed_email',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserTypeId = 'user_type_id',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "users" */
export type Partner_Users_Set_Input = {
  allow_password_change?: InputMaybe<Scalars['Boolean']>;
  /** Корреспондентский счёт */
  bank_correspondent_account?: InputMaybe<Scalars['String']>;
  /** Название банка */
  bank_inn?: InputMaybe<Scalars['String']>;
  /** Название банка */
  bank_name?: InputMaybe<Scalars['String']>;
  /** Название банка */
  bank_number_account?: InputMaybe<Scalars['String']>;
  /** Дата рождения */
  birthday?: InputMaybe<Scalars['date']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']>;
  confirmation_token?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  date_send_code?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  encrypted_password?: InputMaybe<Scalars['String']>;
  /** Имя */
  first_name?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  /** Фамилия */
  last_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  otp_secret_key?: InputMaybe<Scalars['String']>;
  /** Паспорт */
  passport?: InputMaybe<Scalars['String']>;
  /** Кем выдан */
  passport_author?: InputMaybe<Scalars['String']>;
  /** Дата выдачи */
  passport_issue_date?: InputMaybe<Scalars['date']>;
  /** Код подразделения */
  passport_registration?: InputMaybe<Scalars['String']>;
  /** Код подразделения */
  passport_unit_code?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  /** Место рождения */
  place_birth?: InputMaybe<Scalars['String']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_token?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  /** Отчество */
  second_name?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  unconfirmed_email?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_type_id?: InputMaybe<Scalars['bigint']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Partner_Users_Stddev_Fields = {
  __typename?: 'partner_users_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_type_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Users_Stddev_Pop_Fields = {
  __typename?: 'partner_users_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_type_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Users_Stddev_Samp_Fields = {
  __typename?: 'partner_users_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_type_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "partner_users" */
export type Partner_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Users_Stream_Cursor_Value_Input = {
  allow_password_change?: InputMaybe<Scalars['Boolean']>;
  /** Корреспондентский счёт */
  bank_correspondent_account?: InputMaybe<Scalars['String']>;
  /** Название банка */
  bank_inn?: InputMaybe<Scalars['String']>;
  /** Название банка */
  bank_name?: InputMaybe<Scalars['String']>;
  /** Название банка */
  bank_number_account?: InputMaybe<Scalars['String']>;
  /** Дата рождения */
  birthday?: InputMaybe<Scalars['date']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']>;
  confirmation_token?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  date_send_code?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  encrypted_password?: InputMaybe<Scalars['String']>;
  /** Имя */
  first_name?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  /** Фамилия */
  last_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  otp_secret_key?: InputMaybe<Scalars['String']>;
  /** Паспорт */
  passport?: InputMaybe<Scalars['String']>;
  /** Кем выдан */
  passport_author?: InputMaybe<Scalars['String']>;
  /** Дата выдачи */
  passport_issue_date?: InputMaybe<Scalars['date']>;
  /** Код подразделения */
  passport_registration?: InputMaybe<Scalars['String']>;
  /** Код подразделения */
  passport_unit_code?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  /** Место рождения */
  place_birth?: InputMaybe<Scalars['String']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']>;
  reset_password_token?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  /** Отчество */
  second_name?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  unconfirmed_email?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_type_id?: InputMaybe<Scalars['bigint']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Partner_Users_Sum_Fields = {
  __typename?: 'partner_users_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  user_type_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "users" */
export enum Partner_Users_Update_Column {
  /** column name */
  AllowPasswordChange = 'allow_password_change',
  /** column name */
  BankCorrespondentAccount = 'bank_correspondent_account',
  /** column name */
  BankInn = 'bank_inn',
  /** column name */
  BankName = 'bank_name',
  /** column name */
  BankNumberAccount = 'bank_number_account',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  ConfirmationSentAt = 'confirmation_sent_at',
  /** column name */
  ConfirmationToken = 'confirmation_token',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateSendCode = 'date_send_code',
  /** column name */
  Email = 'email',
  /** column name */
  EncryptedPassword = 'encrypted_password',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Name = 'name',
  /** column name */
  OtpSecretKey = 'otp_secret_key',
  /** column name */
  Passport = 'passport',
  /** column name */
  PassportAuthor = 'passport_author',
  /** column name */
  PassportIssueDate = 'passport_issue_date',
  /** column name */
  PassportRegistration = 'passport_registration',
  /** column name */
  PassportUnitCode = 'passport_unit_code',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PlaceBirth = 'place_birth',
  /** column name */
  RememberCreatedAt = 'remember_created_at',
  /** column name */
  ResetPasswordSentAt = 'reset_password_sent_at',
  /** column name */
  ResetPasswordToken = 'reset_password_token',
  /** column name */
  Role = 'role',
  /** column name */
  SecondName = 'second_name',
  /** column name */
  Uid = 'uid',
  /** column name */
  UnconfirmedEmail = 'unconfirmed_email',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserTypeId = 'user_type_id',
  /** column name */
  Username = 'username'
}

export type Partner_Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Users_Set_Input>;
  where: Partner_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Users_Var_Pop_Fields = {
  __typename?: 'partner_users_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_type_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Partner_Users_Var_Samp_Fields = {
  __typename?: 'partner_users_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_type_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Partner_Users_Variance_Fields = {
  __typename?: 'partner_users_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_type_id?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** Получение списка адресов прикрепленных к лицензии */
  addressPlace: Array<AddressPlace>;
  /** File relation for active storage models */
  attachment?: Maybe<Attachment>;
  /** Attachments relation for active storage models */
  attachments: Array<Attachment>;
  /** Аунтефикация пользователя */
  auth: Auth;
  authFindUser?: Maybe<AuthFindUserOutput>;
  /** fetch data from the table: "users" */
  auth_users: Array<Auth_Users>;
  /** fetch aggregated fields from the table: "users" */
  auth_users_aggregate: Auth_Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  auth_users_by_pk?: Maybe<Auth_Users>;
  billingCheckPromoCode?: Maybe<BillingCheckPromoCodeOutput>;
  billingGetPaytureOrder?: Maybe<BillingGetPaytureOrderOutput>;
  /** Расчет стоимости страховки */
  calc?: Maybe<Calc>;
  /**
   *
   *       Проверка промо-кода на валидность:
   *       1. Не найден полис
   *       2. Код устарел
   *       3. Код уже был использован
   *
   */
  checkPromoCode: Scalars['String'];
  /** Получение списка гражданств */
  citizenship: Array<Citizenship>;
  /** Создание застрахованного клиента */
  clientCreate: SignUp;
  /** Список подключенных клиентов пользователя */
  clientList: Array<ClientList>;
  /** Изменение данных застрахованного клиента */
  clientUpdate: SignUp;
  /** Lists clients */
  clients: Array<Client>;
  /** Получить список клиник ДМС */
  clinicDms: Array<ClinicDms>;
  /** Получение списка стран */
  countries: Array<Country>;
  /** Получение информации о стране */
  country: Country;
  /** Lists countrycurrencies */
  countryCurrencies: Array<CountryCurrency>;
  /** Lists countrylistinsurances */
  countryListInsurances: Array<CountryListInsurance>;
  /** Создание email для подписки на рассылку */
  createSubscriber: Scalars['String'];
  /** Получение списка валют для страховок */
  currencyList?: Maybe<Array<CurrencyList>>;
  /** Lists currencylistinsurances */
  currencyListInsurances: Array<CurrencyListInsurance>;
  /** Данные авторизованного пользователя */
  currentUser: User;
  dmsCheckPromoCode?: Maybe<DmsCheckPromoCodeOutput>;
  dmsGetOrderDiscount?: Maybe<DmsGetOrderDiscountOutput>;
  dmsGetPolicyCard?: Maybe<DmsGetPolicyCardOutput>;
  /** fetch data from the table: "group_insureds" */
  dms_group_insureds: Array<Dms_Group_Insureds>;
  /** fetch aggregated fields from the table: "group_insureds" */
  dms_group_insureds_aggregate: Dms_Group_Insureds_Aggregate;
  /** fetch data from the table: "group_insureds" using primary key columns */
  dms_group_insureds_by_pk?: Maybe<Dms_Group_Insureds>;
  /** fetch data from the table: "groups" */
  dms_groups: Array<Dms_Groups>;
  /** fetch aggregated fields from the table: "groups" */
  dms_groups_aggregate: Dms_Groups_Aggregate;
  /** fetch data from the table: "groups" using primary key columns */
  dms_groups_by_pk?: Maybe<Dms_Groups>;
  /** fetch data from the table: "insurants" */
  dms_insurants: Array<Dms_Insurants>;
  /** fetch aggregated fields from the table: "insurants" */
  dms_insurants_aggregate: Dms_Insurants_Aggregate;
  /** fetch data from the table: "insurants" using primary key columns */
  dms_insurants_by_pk?: Maybe<Dms_Insurants>;
  /** fetch data from the table: "insureds" */
  dms_insureds: Array<Dms_Insureds>;
  /** fetch aggregated fields from the table: "insureds" */
  dms_insureds_aggregate: Dms_Insureds_Aggregate;
  /** fetch data from the table: "insureds" using primary key columns */
  dms_insureds_by_pk?: Maybe<Dms_Insureds>;
  /** fetch data from the table: "order_links" */
  dms_order_links: Array<Dms_Order_Links>;
  /** fetch aggregated fields from the table: "order_links" */
  dms_order_links_aggregate: Dms_Order_Links_Aggregate;
  /** fetch data from the table: "order_links" using primary key columns */
  dms_order_links_by_pk?: Maybe<Dms_Order_Links>;
  /** fetch data from the table: "order_numbers" */
  dms_order_numbers: Array<Dms_Order_Numbers>;
  /** fetch aggregated fields from the table: "order_numbers" */
  dms_order_numbers_aggregate: Dms_Order_Numbers_Aggregate;
  /** fetch data from the table: "order_numbers" using primary key columns */
  dms_order_numbers_by_pk?: Maybe<Dms_Order_Numbers>;
  /** fetch data from the table: "orders" */
  dms_orders: Array<Dms_Orders>;
  /** fetch aggregated fields from the table: "orders" */
  dms_orders_aggregate: Dms_Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  dms_orders_by_pk?: Maybe<Dms_Orders>;
  /** fetch data from the table: "products" */
  dms_products: Array<Dms_Products>;
  /** fetch aggregated fields from the table: "products" */
  dms_products_aggregate: Dms_Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  dms_products_by_pk?: Maybe<Dms_Products>;
  /** fetch data from the table: "promo_codes" */
  dms_promo_codes: Array<Dms_Promo_Codes>;
  /** fetch aggregated fields from the table: "promo_codes" */
  dms_promo_codes_aggregate: Dms_Promo_Codes_Aggregate;
  /** fetch data from the table: "promo_codes" using primary key columns */
  dms_promo_codes_by_pk?: Maybe<Dms_Promo_Codes>;
  /** fetch data from the table: "statuses" */
  dms_statuses: Array<Dms_Statuses>;
  /** fetch aggregated fields from the table: "statuses" */
  dms_statuses_aggregate: Dms_Statuses_Aggregate;
  /** fetch data from the table: "statuses" using primary key columns */
  dms_statuses_by_pk?: Maybe<Dms_Statuses>;
  /** fetch data from the table: "users" */
  dms_users: Array<Dms_Users>;
  /** fetch aggregated fields from the table: "users" */
  dms_users_aggregate: Dms_Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  dms_users_by_pk?: Maybe<Dms_Users>;
  /** Ограничения по въезду в иностранные государства */
  entryRestrictions: Array<RestrictionsCountryList>;
  /** Ограничения по въезду в иностранное государство */
  entryRestrictionsCountry: Array<RestrictionsCountryList>;
  /** Получить список филиал */
  filialDms: Array<ClinicDms>;
  /** Получение списка стран */
  generalCountries: Array<VzrListCountry>;
  /** Получение списка программ */
  generalProgram: Array<ProgramInsurance>;
  /** Получить список страховых компаний ДМС */
  insuranceDms: Array<InsuranceDms>;
  /** Получение постов с API safe2travel.ru */
  lastPost: Array<Post>;
  /** Получение списка последних 10 полюсов */
  lastProduct: Array<Calc>;
  /** Lists listcountryprograms */
  listCountryPrograms: Array<ListCountryProgram>;
  mailCode: Scalars['String'];
  me?: Maybe<MeOutput>;
  /** Получение списка сумм покрытия страховки */
  medicalSumList?: Maybe<Array<Scalars['Int']>>;
  /** Lists orderpolices */
  orderPolices: Array<OrderPolice>;
  partnerCurrentBalance?: Maybe<PartnerCurrentBalanceOutput>;
  /** fetch data from the table: "balances" */
  partner_balances: Array<Partner_Balances>;
  /** fetch aggregated fields from the table: "balances" */
  partner_balances_aggregate: Partner_Balances_Aggregate;
  /** fetch data from the table: "balances" using primary key columns */
  partner_balances_by_pk?: Maybe<Partner_Balances>;
  /** fetch data from the table: "log_vzr_sales" */
  partner_log_vzr_sales: Array<Partner_Log_Vzr_Sales>;
  /** fetch aggregated fields from the table: "log_vzr_sales" */
  partner_log_vzr_sales_aggregate: Partner_Log_Vzr_Sales_Aggregate;
  /** fetch data from the table: "log_vzr_sales" using primary key columns */
  partner_log_vzr_sales_by_pk?: Maybe<Partner_Log_Vzr_Sales>;
  /** fetch data from the table: "log_vzr_searches" */
  partner_log_vzr_searches: Array<Partner_Log_Vzr_Searches>;
  /** fetch aggregated fields from the table: "log_vzr_searches" */
  partner_log_vzr_searches_aggregate: Partner_Log_Vzr_Searches_Aggregate;
  /** fetch data from the table: "log_vzr_searches" using primary key columns */
  partner_log_vzr_searches_by_pk?: Maybe<Partner_Log_Vzr_Searches>;
  /** fetch data from the table: "log_vzr_views" */
  partner_log_vzr_views: Array<Partner_Log_Vzr_Views>;
  /** fetch aggregated fields from the table: "log_vzr_views" */
  partner_log_vzr_views_aggregate: Partner_Log_Vzr_Views_Aggregate;
  /** fetch data from the table: "log_vzr_views" using primary key columns */
  partner_log_vzr_views_by_pk?: Maybe<Partner_Log_Vzr_Views>;
  /** fetch data from the table: "place_type_integrations" */
  partner_place_type_integrations: Array<Partner_Place_Type_Integrations>;
  /** fetch aggregated fields from the table: "place_type_integrations" */
  partner_place_type_integrations_aggregate: Partner_Place_Type_Integrations_Aggregate;
  /** fetch data from the table: "place_type_integrations" using primary key columns */
  partner_place_type_integrations_by_pk?: Maybe<Partner_Place_Type_Integrations>;
  /** fetch data from the table: "places" */
  partner_places: Array<Partner_Places>;
  /** fetch aggregated fields from the table: "places" */
  partner_places_aggregate: Partner_Places_Aggregate;
  /** fetch data from the table: "places" using primary key columns */
  partner_places_by_pk?: Maybe<Partner_Places>;
  /** fetch data from the table: "sale_types" */
  partner_sale_types: Array<Partner_Sale_Types>;
  /** fetch aggregated fields from the table: "sale_types" */
  partner_sale_types_aggregate: Partner_Sale_Types_Aggregate;
  /** fetch data from the table: "sale_types" using primary key columns */
  partner_sale_types_by_pk?: Maybe<Partner_Sale_Types>;
  /** fetch data from the table: "status_integrations" */
  partner_status_integrations: Array<Partner_Status_Integrations>;
  /** fetch aggregated fields from the table: "status_integrations" */
  partner_status_integrations_aggregate: Partner_Status_Integrations_Aggregate;
  /** fetch data from the table: "status_integrations" using primary key columns */
  partner_status_integrations_by_pk?: Maybe<Partner_Status_Integrations>;
  /** fetch data from the table: "status_transactions" */
  partner_status_transactions: Array<Partner_Status_Transactions>;
  /** fetch aggregated fields from the table: "status_transactions" */
  partner_status_transactions_aggregate: Partner_Status_Transactions_Aggregate;
  /** fetch data from the table: "status_transactions" using primary key columns */
  partner_status_transactions_by_pk?: Maybe<Partner_Status_Transactions>;
  /** fetch data from the table: "themes" */
  partner_themes: Array<Partner_Themes>;
  /** fetch aggregated fields from the table: "themes" */
  partner_themes_aggregate: Partner_Themes_Aggregate;
  /** fetch data from the table: "themes" using primary key columns */
  partner_themes_by_pk?: Maybe<Partner_Themes>;
  /** fetch data from the table: "type_integrations" */
  partner_type_integrations: Array<Partner_Type_Integrations>;
  /** fetch aggregated fields from the table: "type_integrations" */
  partner_type_integrations_aggregate: Partner_Type_Integrations_Aggregate;
  /** fetch data from the table: "type_integrations" using primary key columns */
  partner_type_integrations_by_pk?: Maybe<Partner_Type_Integrations>;
  /** fetch data from the table: "type_transactions" */
  partner_type_transactions: Array<Partner_Type_Transactions>;
  /** fetch aggregated fields from the table: "type_transactions" */
  partner_type_transactions_aggregate: Partner_Type_Transactions_Aggregate;
  /** fetch data from the table: "type_transactions" using primary key columns */
  partner_type_transactions_by_pk?: Maybe<Partner_Type_Transactions>;
  /** fetch data from the table: "user_types" */
  partner_user_types: Array<Partner_User_Types>;
  /** fetch aggregated fields from the table: "user_types" */
  partner_user_types_aggregate: Partner_User_Types_Aggregate;
  /** fetch data from the table: "user_types" using primary key columns */
  partner_user_types_by_pk?: Maybe<Partner_User_Types>;
  /** fetch data from the table: "users" */
  partner_users: Array<Partner_Users>;
  /** fetch aggregated fields from the table: "users" */
  partner_users_aggregate: Partner_Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  partner_users_by_pk?: Maybe<Partner_Users>;
  /** Статус платежа */
  paymentStatus: PaymentStatus;
  /** Платежная ссылка */
  paymentUrl: PaymentUrl;
  /** Получить полис */
  police?: Maybe<Police>;
  /** Информация о полисе пользователя */
  policeData: PoliceData;
  /** Список подключенных полисов пользователя */
  policeList: PoliceList;
  /** Получить продукт */
  product?: Maybe<Calc>;
  /** Lists programinsurances */
  programInsurances: Array<ProgramInsurance>;
  /** Получение списка програм страхования */
  programList?: Maybe<Array<ProgramList>>;
  /** Lists promocodes */
  promocodes: Array<PromoCode>;
  /** Перерасчет из-за изменения даты рождения */
  reCalcBirth: ProductRecalc;
  /** Переиздание полиса */
  reCreatePolice: Scalars['String'];
  /** Полнотекстовый поиск по реестру медицинских лицензий */
  searchLicense: Array<License>;
  /** Поиск постов с API safe2travel.ru */
  searchPost: Post;
  /** Поиск подходящих страховок */
  searchVzr?: Maybe<SearchVzr>;
  /** Авторизация пользователя */
  signin: SignIn;
  /** Регистрация пользователя */
  signup: SignUp;
  /** Отправка sms-кода */
  smssend: SignUp;
  /** Получение кол-ва пользователей и полисов */
  static: Static;
  /** Lists subscribers */
  subscribers: Array<Subscriber>;
  /** Lists summedicallists */
  sumMedicalLists: Array<SumMedicalList>;
  /** Получение списка всех пользователей */
  userAll: Array<User>;
  /** Изменение данных пользователя */
  userUpdate: SignUp;
  /** Lists users */
  users: Array<User>;
  /** Lists vzrinsurancecompanies */
  vzrInsuranceCompanies: Array<VzrInsuranceCompany>;
  /** Lists vzrlistcountries */
  vzrListCountries: Array<VzrListCountry>;
  /** Lists vzrlistcurrencies */
  vzrListCurrencies: Array<VzrListCurrency>;
};


export type Query_RootAddressPlaceArgs = {
  licenseId: Scalars['Int'];
};


export type Query_RootAttachmentArgs = {
  input: AttachmentsAttributes;
};


export type Query_RootAttachmentsArgs = {
  input: AttachmentsAttributes;
};


export type Query_RootAuthArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type Query_RootAuthFindUserArgs = {
  arg: AuthFindUserInput;
};


export type Query_RootAuth_UsersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Users_Order_By>>;
  where?: InputMaybe<Auth_Users_Bool_Exp>;
};


export type Query_RootAuth_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Users_Order_By>>;
  where?: InputMaybe<Auth_Users_Bool_Exp>;
};


export type Query_RootAuth_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootBillingCheckPromoCodeArgs = {
  arg: BillingCheckPromoCodeInput;
};


export type Query_RootBillingGetPaytureOrderArgs = {
  arg: BillingGetPaytureOrderInput;
};


export type Query_RootCalcArgs = {
  country: Array<Scalars['String']>;
  currency: Scalars['String'];
  duration: Scalars['Int'];
  endDate: Scalars['String'];
  insured: Array<Scalars['Int']>;
  medicalSum: Scalars['Int'];
  program: Scalars['String'];
  sport?: InputMaybe<Scalars['String']>;
  startDate: Scalars['String'];
};


export type Query_RootCheckPromoCodeArgs = {
  code: Scalars['String'];
};


export type Query_RootClientCreateArgs = {
  bithday?: InputMaybe<Scalars['String']>;
  cloneUser?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  foreigner?: InputMaybe<Scalars['Boolean']>;
  lastname?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  policeOms?: InputMaybe<Scalars['String']>;
  secondname?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['Boolean']>;
  token: Scalars['String'];
};


export type Query_RootClientListArgs = {
  token: Scalars['String'];
};


export type Query_RootClientUpdateArgs = {
  bithday?: InputMaybe<Scalars['String']>;
  cloneUser?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  foreigner?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  lastname?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  policeOms?: InputMaybe<Scalars['String']>;
  secondname?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['Boolean']>;
  token: Scalars['String'];
};


export type Query_RootClientsArgs = {
  addressResidence?: InputMaybe<Scalars['Int']>;
  bithday?: InputMaybe<Scalars['ISO8601Date']>;
  cloneUser?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  foreigner?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  internationalPassport?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  placeBirth?: InputMaybe<Scalars['Int']>;
  policeOms?: InputMaybe<Scalars['String']>;
  secondname?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  userId?: InputMaybe<Scalars['Int']>;
};


export type Query_RootClinicDmsArgs = {
  token: Scalars['String'];
};


export type Query_RootCountryArgs = {
  id: Scalars['ID'];
};


export type Query_RootCountryCurrenciesArgs = {
  countryListInsurancesId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  currencyListInsurancesId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
};


export type Query_RootCountryListInsurancesArgs = {
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  label?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  value?: InputMaybe<Scalars['String']>;
  vzrInsuranceCompaniesId?: InputMaybe<Scalars['Int']>;
  vzrListCountriesId?: InputMaybe<Scalars['Int']>;
};


export type Query_RootCreateSubscriberArgs = {
  email: Scalars['String'];
};


export type Query_RootCurrencyListArgs = {
  country: Array<Scalars['String']>;
};


export type Query_RootCurrencyListInsurancesArgs = {
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  label?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  value?: InputMaybe<Scalars['String']>;
  vzrInsuranceCompaniesId?: InputMaybe<Scalars['Int']>;
};


export type Query_RootDmsCheckPromoCodeArgs = {
  arg: DmsCheckPromoCodeInput;
};


export type Query_RootDmsGetOrderDiscountArgs = {
  arg: DmsGetOrderDiscountInput;
};


export type Query_RootDmsGetPolicyCardArgs = {
  arg: DmsGetPolicyCardInput;
};


export type Query_RootDms_Group_InsuredsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Group_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Group_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
};


export type Query_RootDms_Group_Insureds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Group_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Group_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
};


export type Query_RootDms_Group_Insureds_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDms_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Groups_Order_By>>;
  where?: InputMaybe<Dms_Groups_Bool_Exp>;
};


export type Query_RootDms_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Groups_Order_By>>;
  where?: InputMaybe<Dms_Groups_Bool_Exp>;
};


export type Query_RootDms_Groups_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDms_InsurantsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Insurants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Insurants_Order_By>>;
  where?: InputMaybe<Dms_Insurants_Bool_Exp>;
};


export type Query_RootDms_Insurants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Insurants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Insurants_Order_By>>;
  where?: InputMaybe<Dms_Insurants_Bool_Exp>;
};


export type Query_RootDms_Insurants_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDms_InsuredsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Insureds_Bool_Exp>;
};


export type Query_RootDms_Insureds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Insureds_Bool_Exp>;
};


export type Query_RootDms_Insureds_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDms_Order_LinksArgs = {
  distinct_on?: InputMaybe<Array<Dms_Order_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Order_Links_Order_By>>;
  where?: InputMaybe<Dms_Order_Links_Bool_Exp>;
};


export type Query_RootDms_Order_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Order_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Order_Links_Order_By>>;
  where?: InputMaybe<Dms_Order_Links_Bool_Exp>;
};


export type Query_RootDms_Order_Links_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDms_Order_NumbersArgs = {
  distinct_on?: InputMaybe<Array<Dms_Order_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Order_Numbers_Order_By>>;
  where?: InputMaybe<Dms_Order_Numbers_Bool_Exp>;
};


export type Query_RootDms_Order_Numbers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Order_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Order_Numbers_Order_By>>;
  where?: InputMaybe<Dms_Order_Numbers_Bool_Exp>;
};


export type Query_RootDms_Order_Numbers_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDms_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Dms_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Orders_Order_By>>;
  where?: InputMaybe<Dms_Orders_Bool_Exp>;
};


export type Query_RootDms_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Orders_Order_By>>;
  where?: InputMaybe<Dms_Orders_Bool_Exp>;
};


export type Query_RootDms_Orders_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDms_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Products_Order_By>>;
  where?: InputMaybe<Dms_Products_Bool_Exp>;
};


export type Query_RootDms_Products_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Products_Order_By>>;
  where?: InputMaybe<Dms_Products_Bool_Exp>;
};


export type Query_RootDms_Products_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDms_Promo_CodesArgs = {
  distinct_on?: InputMaybe<Array<Dms_Promo_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Promo_Codes_Order_By>>;
  where?: InputMaybe<Dms_Promo_Codes_Bool_Exp>;
};


export type Query_RootDms_Promo_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Promo_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Promo_Codes_Order_By>>;
  where?: InputMaybe<Dms_Promo_Codes_Bool_Exp>;
};


export type Query_RootDms_Promo_Codes_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDms_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Dms_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Statuses_Order_By>>;
  where?: InputMaybe<Dms_Statuses_Bool_Exp>;
};


export type Query_RootDms_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Statuses_Order_By>>;
  where?: InputMaybe<Dms_Statuses_Bool_Exp>;
};


export type Query_RootDms_Statuses_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDms_UsersArgs = {
  distinct_on?: InputMaybe<Array<Dms_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Users_Order_By>>;
  where?: InputMaybe<Dms_Users_Bool_Exp>;
};


export type Query_RootDms_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Users_Order_By>>;
  where?: InputMaybe<Dms_Users_Bool_Exp>;
};


export type Query_RootDms_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEntryRestrictionsCountryArgs = {
  country: Scalars['String'];
};


export type Query_RootFilialDmsArgs = {
  clinicId: Scalars['Int'];
  token: Scalars['String'];
};


export type Query_RootInsuranceDmsArgs = {
  token: Scalars['String'];
};


export type Query_RootListCountryProgramsArgs = {
  countryListInsurancesId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  programInsurancesId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  vzrInsuranceCompaniesId?: InputMaybe<Scalars['Int']>;
};


export type Query_RootMailCodeArgs = {
  code: Scalars['String'];
  email: Scalars['String'];
};


export type Query_RootMedicalSumListArgs = {
  currency: Scalars['String'];
  program: Scalars['String'];
};


export type Query_RootOrderPolicesArgs = {
  bonuscard?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  insurant?: InputMaybe<Scalars['String']>;
  insurants?: InputMaybe<Scalars['String']>;
  paymentUrl?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Scalars['String']>;
  promoCodeId?: InputMaybe<Scalars['Int']>;
  typePolicy?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  userId?: InputMaybe<Scalars['Int']>;
};


export type Query_RootPartner_BalancesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Balances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Balances_Order_By>>;
  where?: InputMaybe<Partner_Balances_Bool_Exp>;
};


export type Query_RootPartner_Balances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Balances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Balances_Order_By>>;
  where?: InputMaybe<Partner_Balances_Bool_Exp>;
};


export type Query_RootPartner_Balances_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPartner_Log_Vzr_SalesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Sales_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Sales_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Sales_Bool_Exp>;
};


export type Query_RootPartner_Log_Vzr_Sales_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Sales_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Sales_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Sales_Bool_Exp>;
};


export type Query_RootPartner_Log_Vzr_Sales_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPartner_Log_Vzr_SearchesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Searches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Searches_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Searches_Bool_Exp>;
};


export type Query_RootPartner_Log_Vzr_Searches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Searches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Searches_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Searches_Bool_Exp>;
};


export type Query_RootPartner_Log_Vzr_Searches_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPartner_Log_Vzr_ViewsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Views_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Views_Bool_Exp>;
};


export type Query_RootPartner_Log_Vzr_Views_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Views_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Views_Bool_Exp>;
};


export type Query_RootPartner_Log_Vzr_Views_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPartner_Place_Type_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Place_Type_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Place_Type_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Place_Type_Integrations_Bool_Exp>;
};


export type Query_RootPartner_Place_Type_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Place_Type_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Place_Type_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Place_Type_Integrations_Bool_Exp>;
};


export type Query_RootPartner_Place_Type_Integrations_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPartner_PlacesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Places_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Places_Order_By>>;
  where?: InputMaybe<Partner_Places_Bool_Exp>;
};


export type Query_RootPartner_Places_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Places_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Places_Order_By>>;
  where?: InputMaybe<Partner_Places_Bool_Exp>;
};


export type Query_RootPartner_Places_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPartner_Sale_TypesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Sale_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Sale_Types_Order_By>>;
  where?: InputMaybe<Partner_Sale_Types_Bool_Exp>;
};


export type Query_RootPartner_Sale_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Sale_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Sale_Types_Order_By>>;
  where?: InputMaybe<Partner_Sale_Types_Bool_Exp>;
};


export type Query_RootPartner_Sale_Types_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPartner_Status_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Status_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Status_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Status_Integrations_Bool_Exp>;
};


export type Query_RootPartner_Status_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Status_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Status_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Status_Integrations_Bool_Exp>;
};


export type Query_RootPartner_Status_Integrations_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPartner_Status_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Status_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Status_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Status_Transactions_Bool_Exp>;
};


export type Query_RootPartner_Status_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Status_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Status_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Status_Transactions_Bool_Exp>;
};


export type Query_RootPartner_Status_Transactions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPartner_ThemesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Themes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Themes_Order_By>>;
  where?: InputMaybe<Partner_Themes_Bool_Exp>;
};


export type Query_RootPartner_Themes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Themes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Themes_Order_By>>;
  where?: InputMaybe<Partner_Themes_Bool_Exp>;
};


export type Query_RootPartner_Themes_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPartner_Type_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Type_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Type_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Type_Integrations_Bool_Exp>;
};


export type Query_RootPartner_Type_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Type_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Type_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Type_Integrations_Bool_Exp>;
};


export type Query_RootPartner_Type_Integrations_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPartner_Type_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Type_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Type_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Type_Transactions_Bool_Exp>;
};


export type Query_RootPartner_Type_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Type_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Type_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Type_Transactions_Bool_Exp>;
};


export type Query_RootPartner_Type_Transactions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPartner_User_TypesArgs = {
  distinct_on?: InputMaybe<Array<Partner_User_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_User_Types_Order_By>>;
  where?: InputMaybe<Partner_User_Types_Bool_Exp>;
};


export type Query_RootPartner_User_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_User_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_User_Types_Order_By>>;
  where?: InputMaybe<Partner_User_Types_Bool_Exp>;
};


export type Query_RootPartner_User_Types_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPartner_UsersArgs = {
  distinct_on?: InputMaybe<Array<Partner_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Users_Order_By>>;
  where?: InputMaybe<Partner_Users_Bool_Exp>;
};


export type Query_RootPartner_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Users_Order_By>>;
  where?: InputMaybe<Partner_Users_Bool_Exp>;
};


export type Query_RootPartner_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPaymentStatusArgs = {
  orderId: Scalars['String'];
};


export type Query_RootPaymentUrlArgs = {
  ammount: Scalars['Float'];
  backUrl: Scalars['String'];
  orderId: Scalars['String'];
};


export type Query_RootPoliceArgs = {
  insurant?: InputMaybe<Scalars['String']>;
  insurants?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Scalars['String']>;
};


export type Query_RootPoliceDataArgs = {
  id: Scalars['String'];
  token: Scalars['String'];
};


export type Query_RootPoliceListArgs = {
  token: Scalars['String'];
};


export type Query_RootProductArgs = {
  id: Scalars['String'];
};


export type Query_RootProgramInsurancesArgs = {
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  label?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
};


export type Query_RootProgramListArgs = {
  country: Array<Scalars['String']>;
  duration: Scalars['Int'];
};


export type Query_RootPromocodesArgs = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  discount?: InputMaybe<Scalars['Int']>;
  expiration?: InputMaybe<Scalars['ISO8601Date']>;
  id?: InputMaybe<Scalars['ID']>;
  typeCode?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  use?: InputMaybe<Scalars['Boolean']>;
};


export type Query_RootReCalcBirthArgs = {
  dateOfBirth: Array<Scalars['String']>;
  orderId: Scalars['String'];
};


export type Query_RootReCreatePoliceArgs = {
  orderId: Scalars['String'];
};


export type Query_RootSearchLicenseArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};


export type Query_RootSearchPostArgs = {
  search: Scalars['String'];
};


export type Query_RootSearchVzrArgs = {
  country: Array<Scalars['String']>;
  currencyIndex?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  endDate: Scalars['String'];
  insured: Array<Scalars['Int']>;
  multy?: InputMaybe<Scalars['Boolean']>;
  sessionQuery: Scalars['String'];
  startDate: Scalars['String'];
};


export type Query_RootSigninArgs = {
  phone: Scalars['String'];
  smscode: Scalars['String'];
};


export type Query_RootSignupArgs = {
  firstname?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
};


export type Query_RootSmssendArgs = {
  phone: Scalars['String'];
};


export type Query_RootSubscribersArgs = {
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
};


export type Query_RootSumMedicalListsArgs = {
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  currencyListInsuranceId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  programInsurancesId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  value?: InputMaybe<Scalars['JSON']>;
};


export type Query_RootUserUpdateArgs = {
  bithday?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  foreigner?: InputMaybe<Scalars['Boolean']>;
  lastname?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  secondname?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['Boolean']>;
  token: Scalars['String'];
};


export type Query_RootUsersArgs = {
  addressResidence?: InputMaybe<Scalars['String']>;
  bithday?: InputMaybe<Scalars['ISO8601Date']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  datecode?: InputMaybe<Scalars['ISO8601DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  foreigner?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  internationalPassport?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  placeBirth?: InputMaybe<Scalars['String']>;
  secondname?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['Boolean']>;
  smscode?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
};


export type Query_RootVzrInsuranceCompaniesArgs = {
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  label?: InputMaybe<Scalars['String']>;
  site?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  value?: InputMaybe<Scalars['String']>;
};


export type Query_RootVzrListCountriesArgs = {
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  label?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  value?: InputMaybe<Scalars['String']>;
};


export type Query_RootVzrListCurrenciesArgs = {
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  label?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "users" */
  auth_users: Array<Auth_Users>;
  /** fetch aggregated fields from the table: "users" */
  auth_users_aggregate: Auth_Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  auth_users_by_pk?: Maybe<Auth_Users>;
  /** fetch data from the table in a streaming manner : "users" */
  auth_users_stream: Array<Auth_Users>;
  /** Расчет стоимости группы страховки */
  calcvzr?: Maybe<Calc>;
  /** fetch data from the table: "group_insureds" */
  dms_group_insureds: Array<Dms_Group_Insureds>;
  /** fetch aggregated fields from the table: "group_insureds" */
  dms_group_insureds_aggregate: Dms_Group_Insureds_Aggregate;
  /** fetch data from the table: "group_insureds" using primary key columns */
  dms_group_insureds_by_pk?: Maybe<Dms_Group_Insureds>;
  /** fetch data from the table in a streaming manner : "group_insureds" */
  dms_group_insureds_stream: Array<Dms_Group_Insureds>;
  /** fetch data from the table: "groups" */
  dms_groups: Array<Dms_Groups>;
  /** fetch aggregated fields from the table: "groups" */
  dms_groups_aggregate: Dms_Groups_Aggregate;
  /** fetch data from the table: "groups" using primary key columns */
  dms_groups_by_pk?: Maybe<Dms_Groups>;
  /** fetch data from the table in a streaming manner : "groups" */
  dms_groups_stream: Array<Dms_Groups>;
  /** fetch data from the table: "insurants" */
  dms_insurants: Array<Dms_Insurants>;
  /** fetch aggregated fields from the table: "insurants" */
  dms_insurants_aggregate: Dms_Insurants_Aggregate;
  /** fetch data from the table: "insurants" using primary key columns */
  dms_insurants_by_pk?: Maybe<Dms_Insurants>;
  /** fetch data from the table in a streaming manner : "insurants" */
  dms_insurants_stream: Array<Dms_Insurants>;
  /** fetch data from the table: "insureds" */
  dms_insureds: Array<Dms_Insureds>;
  /** fetch aggregated fields from the table: "insureds" */
  dms_insureds_aggregate: Dms_Insureds_Aggregate;
  /** fetch data from the table: "insureds" using primary key columns */
  dms_insureds_by_pk?: Maybe<Dms_Insureds>;
  /** fetch data from the table in a streaming manner : "insureds" */
  dms_insureds_stream: Array<Dms_Insureds>;
  /** fetch data from the table: "order_links" */
  dms_order_links: Array<Dms_Order_Links>;
  /** fetch aggregated fields from the table: "order_links" */
  dms_order_links_aggregate: Dms_Order_Links_Aggregate;
  /** fetch data from the table: "order_links" using primary key columns */
  dms_order_links_by_pk?: Maybe<Dms_Order_Links>;
  /** fetch data from the table in a streaming manner : "order_links" */
  dms_order_links_stream: Array<Dms_Order_Links>;
  /** fetch data from the table: "order_numbers" */
  dms_order_numbers: Array<Dms_Order_Numbers>;
  /** fetch aggregated fields from the table: "order_numbers" */
  dms_order_numbers_aggregate: Dms_Order_Numbers_Aggregate;
  /** fetch data from the table: "order_numbers" using primary key columns */
  dms_order_numbers_by_pk?: Maybe<Dms_Order_Numbers>;
  /** fetch data from the table in a streaming manner : "order_numbers" */
  dms_order_numbers_stream: Array<Dms_Order_Numbers>;
  /** fetch data from the table: "orders" */
  dms_orders: Array<Dms_Orders>;
  /** fetch aggregated fields from the table: "orders" */
  dms_orders_aggregate: Dms_Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  dms_orders_by_pk?: Maybe<Dms_Orders>;
  /** fetch data from the table in a streaming manner : "orders" */
  dms_orders_stream: Array<Dms_Orders>;
  /** fetch data from the table: "products" */
  dms_products: Array<Dms_Products>;
  /** fetch aggregated fields from the table: "products" */
  dms_products_aggregate: Dms_Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  dms_products_by_pk?: Maybe<Dms_Products>;
  /** fetch data from the table in a streaming manner : "products" */
  dms_products_stream: Array<Dms_Products>;
  /** fetch data from the table: "promo_codes" */
  dms_promo_codes: Array<Dms_Promo_Codes>;
  /** fetch aggregated fields from the table: "promo_codes" */
  dms_promo_codes_aggregate: Dms_Promo_Codes_Aggregate;
  /** fetch data from the table: "promo_codes" using primary key columns */
  dms_promo_codes_by_pk?: Maybe<Dms_Promo_Codes>;
  /** fetch data from the table in a streaming manner : "promo_codes" */
  dms_promo_codes_stream: Array<Dms_Promo_Codes>;
  /** fetch data from the table: "statuses" */
  dms_statuses: Array<Dms_Statuses>;
  /** fetch aggregated fields from the table: "statuses" */
  dms_statuses_aggregate: Dms_Statuses_Aggregate;
  /** fetch data from the table: "statuses" using primary key columns */
  dms_statuses_by_pk?: Maybe<Dms_Statuses>;
  /** fetch data from the table in a streaming manner : "statuses" */
  dms_statuses_stream: Array<Dms_Statuses>;
  /** fetch data from the table: "users" */
  dms_users: Array<Dms_Users>;
  /** fetch aggregated fields from the table: "users" */
  dms_users_aggregate: Dms_Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  dms_users_by_pk?: Maybe<Dms_Users>;
  /** fetch data from the table in a streaming manner : "users" */
  dms_users_stream: Array<Dms_Users>;
  /** Привет мир */
  newhello?: Maybe<Hello>;
  /** Проверка получения токена Pari */
  pariTokenCheck?: Maybe<PariTokenCheck>;
  /** fetch data from the table: "balances" */
  partner_balances: Array<Partner_Balances>;
  /** fetch aggregated fields from the table: "balances" */
  partner_balances_aggregate: Partner_Balances_Aggregate;
  /** fetch data from the table: "balances" using primary key columns */
  partner_balances_by_pk?: Maybe<Partner_Balances>;
  /** fetch data from the table in a streaming manner : "balances" */
  partner_balances_stream: Array<Partner_Balances>;
  /** fetch data from the table: "log_vzr_sales" */
  partner_log_vzr_sales: Array<Partner_Log_Vzr_Sales>;
  /** fetch aggregated fields from the table: "log_vzr_sales" */
  partner_log_vzr_sales_aggregate: Partner_Log_Vzr_Sales_Aggregate;
  /** fetch data from the table: "log_vzr_sales" using primary key columns */
  partner_log_vzr_sales_by_pk?: Maybe<Partner_Log_Vzr_Sales>;
  /** fetch data from the table in a streaming manner : "log_vzr_sales" */
  partner_log_vzr_sales_stream: Array<Partner_Log_Vzr_Sales>;
  /** fetch data from the table: "log_vzr_searches" */
  partner_log_vzr_searches: Array<Partner_Log_Vzr_Searches>;
  /** fetch aggregated fields from the table: "log_vzr_searches" */
  partner_log_vzr_searches_aggregate: Partner_Log_Vzr_Searches_Aggregate;
  /** fetch data from the table: "log_vzr_searches" using primary key columns */
  partner_log_vzr_searches_by_pk?: Maybe<Partner_Log_Vzr_Searches>;
  /** fetch data from the table in a streaming manner : "log_vzr_searches" */
  partner_log_vzr_searches_stream: Array<Partner_Log_Vzr_Searches>;
  /** fetch data from the table: "log_vzr_views" */
  partner_log_vzr_views: Array<Partner_Log_Vzr_Views>;
  /** fetch aggregated fields from the table: "log_vzr_views" */
  partner_log_vzr_views_aggregate: Partner_Log_Vzr_Views_Aggregate;
  /** fetch data from the table: "log_vzr_views" using primary key columns */
  partner_log_vzr_views_by_pk?: Maybe<Partner_Log_Vzr_Views>;
  /** fetch data from the table in a streaming manner : "log_vzr_views" */
  partner_log_vzr_views_stream: Array<Partner_Log_Vzr_Views>;
  /** fetch data from the table: "place_type_integrations" */
  partner_place_type_integrations: Array<Partner_Place_Type_Integrations>;
  /** fetch aggregated fields from the table: "place_type_integrations" */
  partner_place_type_integrations_aggregate: Partner_Place_Type_Integrations_Aggregate;
  /** fetch data from the table: "place_type_integrations" using primary key columns */
  partner_place_type_integrations_by_pk?: Maybe<Partner_Place_Type_Integrations>;
  /** fetch data from the table in a streaming manner : "place_type_integrations" */
  partner_place_type_integrations_stream: Array<Partner_Place_Type_Integrations>;
  /** fetch data from the table: "places" */
  partner_places: Array<Partner_Places>;
  /** fetch aggregated fields from the table: "places" */
  partner_places_aggregate: Partner_Places_Aggregate;
  /** fetch data from the table: "places" using primary key columns */
  partner_places_by_pk?: Maybe<Partner_Places>;
  /** fetch data from the table in a streaming manner : "places" */
  partner_places_stream: Array<Partner_Places>;
  /** fetch data from the table: "sale_types" */
  partner_sale_types: Array<Partner_Sale_Types>;
  /** fetch aggregated fields from the table: "sale_types" */
  partner_sale_types_aggregate: Partner_Sale_Types_Aggregate;
  /** fetch data from the table: "sale_types" using primary key columns */
  partner_sale_types_by_pk?: Maybe<Partner_Sale_Types>;
  /** fetch data from the table in a streaming manner : "sale_types" */
  partner_sale_types_stream: Array<Partner_Sale_Types>;
  /** fetch data from the table: "status_integrations" */
  partner_status_integrations: Array<Partner_Status_Integrations>;
  /** fetch aggregated fields from the table: "status_integrations" */
  partner_status_integrations_aggregate: Partner_Status_Integrations_Aggregate;
  /** fetch data from the table: "status_integrations" using primary key columns */
  partner_status_integrations_by_pk?: Maybe<Partner_Status_Integrations>;
  /** fetch data from the table in a streaming manner : "status_integrations" */
  partner_status_integrations_stream: Array<Partner_Status_Integrations>;
  /** fetch data from the table: "status_transactions" */
  partner_status_transactions: Array<Partner_Status_Transactions>;
  /** fetch aggregated fields from the table: "status_transactions" */
  partner_status_transactions_aggregate: Partner_Status_Transactions_Aggregate;
  /** fetch data from the table: "status_transactions" using primary key columns */
  partner_status_transactions_by_pk?: Maybe<Partner_Status_Transactions>;
  /** fetch data from the table in a streaming manner : "status_transactions" */
  partner_status_transactions_stream: Array<Partner_Status_Transactions>;
  /** fetch data from the table: "themes" */
  partner_themes: Array<Partner_Themes>;
  /** fetch aggregated fields from the table: "themes" */
  partner_themes_aggregate: Partner_Themes_Aggregate;
  /** fetch data from the table: "themes" using primary key columns */
  partner_themes_by_pk?: Maybe<Partner_Themes>;
  /** fetch data from the table in a streaming manner : "themes" */
  partner_themes_stream: Array<Partner_Themes>;
  /** fetch data from the table: "type_integrations" */
  partner_type_integrations: Array<Partner_Type_Integrations>;
  /** fetch aggregated fields from the table: "type_integrations" */
  partner_type_integrations_aggregate: Partner_Type_Integrations_Aggregate;
  /** fetch data from the table: "type_integrations" using primary key columns */
  partner_type_integrations_by_pk?: Maybe<Partner_Type_Integrations>;
  /** fetch data from the table in a streaming manner : "type_integrations" */
  partner_type_integrations_stream: Array<Partner_Type_Integrations>;
  /** fetch data from the table: "type_transactions" */
  partner_type_transactions: Array<Partner_Type_Transactions>;
  /** fetch aggregated fields from the table: "type_transactions" */
  partner_type_transactions_aggregate: Partner_Type_Transactions_Aggregate;
  /** fetch data from the table: "type_transactions" using primary key columns */
  partner_type_transactions_by_pk?: Maybe<Partner_Type_Transactions>;
  /** fetch data from the table in a streaming manner : "type_transactions" */
  partner_type_transactions_stream: Array<Partner_Type_Transactions>;
  /** fetch data from the table: "user_types" */
  partner_user_types: Array<Partner_User_Types>;
  /** fetch aggregated fields from the table: "user_types" */
  partner_user_types_aggregate: Partner_User_Types_Aggregate;
  /** fetch data from the table: "user_types" using primary key columns */
  partner_user_types_by_pk?: Maybe<Partner_User_Types>;
  /** fetch data from the table in a streaming manner : "user_types" */
  partner_user_types_stream: Array<Partner_User_Types>;
  /** fetch data from the table: "users" */
  partner_users: Array<Partner_Users>;
  /** fetch aggregated fields from the table: "users" */
  partner_users_aggregate: Partner_Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  partner_users_by_pk?: Maybe<Partner_Users>;
  /** fetch data from the table in a streaming manner : "users" */
  partner_users_stream: Array<Partner_Users>;
};


export type Subscription_RootAuth_UsersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Users_Order_By>>;
  where?: InputMaybe<Auth_Users_Bool_Exp>;
};


export type Subscription_RootAuth_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Users_Order_By>>;
  where?: InputMaybe<Auth_Users_Bool_Exp>;
};


export type Subscription_RootAuth_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAuth_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Users_Bool_Exp>;
};


export type Subscription_RootCalcvzrArgs = {
  room: Scalars['String'];
};


export type Subscription_RootDms_Group_InsuredsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Group_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Group_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
};


export type Subscription_RootDms_Group_Insureds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Group_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Group_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
};


export type Subscription_RootDms_Group_Insureds_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDms_Group_Insureds_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dms_Group_Insureds_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
};


export type Subscription_RootDms_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Groups_Order_By>>;
  where?: InputMaybe<Dms_Groups_Bool_Exp>;
};


export type Subscription_RootDms_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Groups_Order_By>>;
  where?: InputMaybe<Dms_Groups_Bool_Exp>;
};


export type Subscription_RootDms_Groups_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDms_Groups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dms_Groups_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Groups_Bool_Exp>;
};


export type Subscription_RootDms_InsurantsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Insurants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Insurants_Order_By>>;
  where?: InputMaybe<Dms_Insurants_Bool_Exp>;
};


export type Subscription_RootDms_Insurants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Insurants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Insurants_Order_By>>;
  where?: InputMaybe<Dms_Insurants_Bool_Exp>;
};


export type Subscription_RootDms_Insurants_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDms_Insurants_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dms_Insurants_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Insurants_Bool_Exp>;
};


export type Subscription_RootDms_InsuredsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Insureds_Bool_Exp>;
};


export type Subscription_RootDms_Insureds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Insureds_Bool_Exp>;
};


export type Subscription_RootDms_Insureds_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDms_Insureds_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dms_Insureds_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Insureds_Bool_Exp>;
};


export type Subscription_RootDms_Order_LinksArgs = {
  distinct_on?: InputMaybe<Array<Dms_Order_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Order_Links_Order_By>>;
  where?: InputMaybe<Dms_Order_Links_Bool_Exp>;
};


export type Subscription_RootDms_Order_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Order_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Order_Links_Order_By>>;
  where?: InputMaybe<Dms_Order_Links_Bool_Exp>;
};


export type Subscription_RootDms_Order_Links_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDms_Order_Links_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dms_Order_Links_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Order_Links_Bool_Exp>;
};


export type Subscription_RootDms_Order_NumbersArgs = {
  distinct_on?: InputMaybe<Array<Dms_Order_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Order_Numbers_Order_By>>;
  where?: InputMaybe<Dms_Order_Numbers_Bool_Exp>;
};


export type Subscription_RootDms_Order_Numbers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Order_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Order_Numbers_Order_By>>;
  where?: InputMaybe<Dms_Order_Numbers_Bool_Exp>;
};


export type Subscription_RootDms_Order_Numbers_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDms_Order_Numbers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dms_Order_Numbers_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Order_Numbers_Bool_Exp>;
};


export type Subscription_RootDms_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Dms_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Orders_Order_By>>;
  where?: InputMaybe<Dms_Orders_Bool_Exp>;
};


export type Subscription_RootDms_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Orders_Order_By>>;
  where?: InputMaybe<Dms_Orders_Bool_Exp>;
};


export type Subscription_RootDms_Orders_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDms_Orders_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dms_Orders_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Orders_Bool_Exp>;
};


export type Subscription_RootDms_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Products_Order_By>>;
  where?: InputMaybe<Dms_Products_Bool_Exp>;
};


export type Subscription_RootDms_Products_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Products_Order_By>>;
  where?: InputMaybe<Dms_Products_Bool_Exp>;
};


export type Subscription_RootDms_Products_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDms_Products_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dms_Products_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Products_Bool_Exp>;
};


export type Subscription_RootDms_Promo_CodesArgs = {
  distinct_on?: InputMaybe<Array<Dms_Promo_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Promo_Codes_Order_By>>;
  where?: InputMaybe<Dms_Promo_Codes_Bool_Exp>;
};


export type Subscription_RootDms_Promo_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Promo_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Promo_Codes_Order_By>>;
  where?: InputMaybe<Dms_Promo_Codes_Bool_Exp>;
};


export type Subscription_RootDms_Promo_Codes_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDms_Promo_Codes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dms_Promo_Codes_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Promo_Codes_Bool_Exp>;
};


export type Subscription_RootDms_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Dms_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Statuses_Order_By>>;
  where?: InputMaybe<Dms_Statuses_Bool_Exp>;
};


export type Subscription_RootDms_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Statuses_Order_By>>;
  where?: InputMaybe<Dms_Statuses_Bool_Exp>;
};


export type Subscription_RootDms_Statuses_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDms_Statuses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dms_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Statuses_Bool_Exp>;
};


export type Subscription_RootDms_UsersArgs = {
  distinct_on?: InputMaybe<Array<Dms_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Users_Order_By>>;
  where?: InputMaybe<Dms_Users_Bool_Exp>;
};


export type Subscription_RootDms_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dms_Users_Order_By>>;
  where?: InputMaybe<Dms_Users_Bool_Exp>;
};


export type Subscription_RootDms_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDms_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dms_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Users_Bool_Exp>;
};


export type Subscription_RootPariTokenCheckArgs = {
  room: Scalars['String'];
};


export type Subscription_RootPartner_BalancesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Balances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Balances_Order_By>>;
  where?: InputMaybe<Partner_Balances_Bool_Exp>;
};


export type Subscription_RootPartner_Balances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Balances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Balances_Order_By>>;
  where?: InputMaybe<Partner_Balances_Bool_Exp>;
};


export type Subscription_RootPartner_Balances_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPartner_Balances_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Partner_Balances_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Balances_Bool_Exp>;
};


export type Subscription_RootPartner_Log_Vzr_SalesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Sales_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Sales_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Sales_Bool_Exp>;
};


export type Subscription_RootPartner_Log_Vzr_Sales_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Sales_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Sales_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Sales_Bool_Exp>;
};


export type Subscription_RootPartner_Log_Vzr_Sales_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPartner_Log_Vzr_Sales_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Partner_Log_Vzr_Sales_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Log_Vzr_Sales_Bool_Exp>;
};


export type Subscription_RootPartner_Log_Vzr_SearchesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Searches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Searches_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Searches_Bool_Exp>;
};


export type Subscription_RootPartner_Log_Vzr_Searches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Searches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Searches_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Searches_Bool_Exp>;
};


export type Subscription_RootPartner_Log_Vzr_Searches_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPartner_Log_Vzr_Searches_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Partner_Log_Vzr_Searches_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Log_Vzr_Searches_Bool_Exp>;
};


export type Subscription_RootPartner_Log_Vzr_ViewsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Views_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Views_Bool_Exp>;
};


export type Subscription_RootPartner_Log_Vzr_Views_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Views_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Views_Bool_Exp>;
};


export type Subscription_RootPartner_Log_Vzr_Views_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPartner_Log_Vzr_Views_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Partner_Log_Vzr_Views_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Log_Vzr_Views_Bool_Exp>;
};


export type Subscription_RootPartner_Place_Type_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Place_Type_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Place_Type_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Place_Type_Integrations_Bool_Exp>;
};


export type Subscription_RootPartner_Place_Type_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Place_Type_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Place_Type_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Place_Type_Integrations_Bool_Exp>;
};


export type Subscription_RootPartner_Place_Type_Integrations_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPartner_Place_Type_Integrations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Partner_Place_Type_Integrations_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Place_Type_Integrations_Bool_Exp>;
};


export type Subscription_RootPartner_PlacesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Places_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Places_Order_By>>;
  where?: InputMaybe<Partner_Places_Bool_Exp>;
};


export type Subscription_RootPartner_Places_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Places_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Places_Order_By>>;
  where?: InputMaybe<Partner_Places_Bool_Exp>;
};


export type Subscription_RootPartner_Places_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPartner_Places_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Partner_Places_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Places_Bool_Exp>;
};


export type Subscription_RootPartner_Sale_TypesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Sale_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Sale_Types_Order_By>>;
  where?: InputMaybe<Partner_Sale_Types_Bool_Exp>;
};


export type Subscription_RootPartner_Sale_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Sale_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Sale_Types_Order_By>>;
  where?: InputMaybe<Partner_Sale_Types_Bool_Exp>;
};


export type Subscription_RootPartner_Sale_Types_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPartner_Sale_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Partner_Sale_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Sale_Types_Bool_Exp>;
};


export type Subscription_RootPartner_Status_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Status_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Status_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Status_Integrations_Bool_Exp>;
};


export type Subscription_RootPartner_Status_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Status_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Status_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Status_Integrations_Bool_Exp>;
};


export type Subscription_RootPartner_Status_Integrations_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPartner_Status_Integrations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Partner_Status_Integrations_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Status_Integrations_Bool_Exp>;
};


export type Subscription_RootPartner_Status_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Status_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Status_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Status_Transactions_Bool_Exp>;
};


export type Subscription_RootPartner_Status_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Status_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Status_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Status_Transactions_Bool_Exp>;
};


export type Subscription_RootPartner_Status_Transactions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPartner_Status_Transactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Partner_Status_Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Status_Transactions_Bool_Exp>;
};


export type Subscription_RootPartner_ThemesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Themes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Themes_Order_By>>;
  where?: InputMaybe<Partner_Themes_Bool_Exp>;
};


export type Subscription_RootPartner_Themes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Themes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Themes_Order_By>>;
  where?: InputMaybe<Partner_Themes_Bool_Exp>;
};


export type Subscription_RootPartner_Themes_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPartner_Themes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Partner_Themes_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Themes_Bool_Exp>;
};


export type Subscription_RootPartner_Type_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Type_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Type_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Type_Integrations_Bool_Exp>;
};


export type Subscription_RootPartner_Type_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Type_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Type_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Type_Integrations_Bool_Exp>;
};


export type Subscription_RootPartner_Type_Integrations_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPartner_Type_Integrations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Partner_Type_Integrations_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Type_Integrations_Bool_Exp>;
};


export type Subscription_RootPartner_Type_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Type_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Type_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Type_Transactions_Bool_Exp>;
};


export type Subscription_RootPartner_Type_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Type_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Type_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Type_Transactions_Bool_Exp>;
};


export type Subscription_RootPartner_Type_Transactions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPartner_Type_Transactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Partner_Type_Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Type_Transactions_Bool_Exp>;
};


export type Subscription_RootPartner_User_TypesArgs = {
  distinct_on?: InputMaybe<Array<Partner_User_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_User_Types_Order_By>>;
  where?: InputMaybe<Partner_User_Types_Bool_Exp>;
};


export type Subscription_RootPartner_User_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_User_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_User_Types_Order_By>>;
  where?: InputMaybe<Partner_User_Types_Bool_Exp>;
};


export type Subscription_RootPartner_User_Types_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPartner_User_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Partner_User_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_User_Types_Bool_Exp>;
};


export type Subscription_RootPartner_UsersArgs = {
  distinct_on?: InputMaybe<Array<Partner_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Users_Order_By>>;
  where?: InputMaybe<Partner_Users_Bool_Exp>;
};


export type Subscription_RootPartner_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Users_Order_By>>;
  where?: InputMaybe<Partner_Users_Bool_Exp>;
};


export type Subscription_RootPartner_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPartner_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Partner_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Users_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

export type AddInsuredArrayToGroupMutationVariables = Exact<{
  objects: Array<Dms_Group_Insureds_Insert_Input> | Dms_Group_Insureds_Insert_Input;
}>;


export type AddInsuredArrayToGroupMutation = { __typename?: 'mutation_root', insert_dms_group_insureds?: { __typename?: 'dms_group_insureds_mutation_response', returning: Array<{ __typename?: 'dms_group_insureds', insured?: { __typename?: 'dms_insureds', id: any } | null }> } | null };

export type AddInsuredOneMutationVariables = Exact<{
  user?: InputMaybe<Dms_Insureds_Insert_Input>;
}>;


export type AddInsuredOneMutation = { __typename?: 'mutation_root', insert_dms_insureds_one?: { __typename?: 'dms_insureds', id: any } | null };

export type AddInsuredToGroupMutationVariables = Exact<{
  insured_id: Scalars['bigint'];
  group_id: Scalars['bigint'];
  user_id: Scalars['Int'];
}>;


export type AddInsuredToGroupMutation = { __typename?: 'mutation_root', insert_dms_group_insureds_one?: { __typename?: 'dms_group_insureds', group?: { __typename?: 'dms_groups', id: any } | null, insured?: { __typename?: 'dms_insureds', id: any } | null } | null };

export type AddInsuredToGroupInsuredsMutationVariables = Exact<{
  user_id?: InputMaybe<Scalars['Int']>;
  group_id: Scalars['bigint'];
  insured_id: Scalars['bigint'];
}>;


export type AddInsuredToGroupInsuredsMutation = { __typename?: 'mutation_root', insert_dms_group_insureds_one?: { __typename?: 'dms_group_insureds', group?: { __typename?: 'dms_groups', group_name?: string | null, id: any } | null, insured?: { __typename?: 'dms_insureds', first_name: string, id: any, email: string, last_name: string, bithday: any, group_id?: any | null, insurant?: { __typename?: 'dms_insurants', id: any, email: string, first_name: string, last_name: string } | null } | null } | null };

export type CreateDmsGroupMutationVariables = Exact<{
  group_name: Scalars['String'];
  user_id: Scalars['Int'];
}>;


export type CreateDmsGroupMutation = { __typename?: 'mutation_root', insert_dms_groups_one?: { __typename?: 'dms_groups', id: any, user_id?: number | null, group_name?: string | null } | null };

export type DeleteDmsGroupByPkMutationVariables = Exact<{
  group_id: Scalars['bigint'];
}>;


export type DeleteDmsGroupByPkMutation = { __typename?: 'mutation_root', delete_dms_groups_by_pk?: { __typename?: 'dms_groups', id: any } | null };

export type DeleteDmsInsuredByPkMutationVariables = Exact<{
  user_id: Scalars['bigint'];
}>;


export type DeleteDmsInsuredByPkMutation = { __typename?: 'mutation_root', delete_dms_group_insureds?: { __typename?: 'dms_group_insureds_mutation_response', returning: Array<{ __typename?: 'dms_group_insureds', id: any }> } | null, delete_dms_insureds_by_pk?: { __typename?: 'dms_insureds', id: any } | null };

export type DeleteDmsInsuredFromGroupByPkMutationVariables = Exact<{
  insured_id: Scalars['bigint'];
}>;


export type DeleteDmsInsuredFromGroupByPkMutation = { __typename?: 'mutation_root', delete_dms_group_insureds_by_pk?: { __typename?: 'dms_group_insureds', insured_id?: any | null, id: any } | null };

export type DmsCleanGroupMutationVariables = Exact<{
  group_id: Scalars['bigint'];
}>;


export type DmsCleanGroupMutation = { __typename?: 'mutation_root', delete_dms_group_insureds?: { __typename?: 'dms_group_insureds_mutation_response', returning: Array<{ __typename?: 'dms_group_insureds', id: any }> } | null };

export type UpdateCurrentUserMutationVariables = Exact<{
  id: Scalars['bigint'];
  birthday?: InputMaybe<Scalars['date']>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  foreigner: Scalars['Boolean'];
  last_name: Scalars['String'];
  passport?: InputMaybe<Scalars['String']>;
  phone_number: Scalars['String'];
  second_name?: InputMaybe<Scalars['String']>;
  sex: Scalars['Boolean'];
  international_passport?: InputMaybe<Scalars['String']>;
}>;


export type UpdateCurrentUserMutation = { __typename?: 'mutation_root', update_auth_users?: { __typename?: 'auth_users_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'auth_users', id: any, birthday?: any | null, email?: string | null, first_name?: string | null, foreigner?: boolean | null, last_name?: string | null, passport?: string | null, phone_number?: string | null, second_name?: string | null, sex?: boolean | null, international_passport?: string | null }> } | null };

export type UpdateInsuredByPkMutationVariables = Exact<{
  user_id: Scalars['bigint'];
  bithday: Scalars['date'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  gender: Scalars['Boolean'];
  foreign?: InputMaybe<Scalars['Boolean']>;
  foreign_passport?: InputMaybe<Scalars['String']>;
  oms?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<Scalars['String']>;
  second_name?: InputMaybe<Scalars['String']>;
  group_id?: InputMaybe<Scalars['bigint']>;
}>;


export type UpdateInsuredByPkMutation = { __typename?: 'mutation_root', update_dms_insureds_by_pk?: { __typename?: 'dms_insureds', id: any, insurant_id?: any | null, last_name: string, number?: number | null, oms?: string | null, order_uid?: string | null, passport: string, phone: string, gender?: boolean | null, first_name: string, bithday: any, second_name?: string | null, user_id?: any | null, foreign?: boolean | null, foreign_passport?: string | null, email: string } | null };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'query_root', current_user: Array<{ __typename?: 'auth_users', id: any, address_residence?: string | null, birthday?: any | null, email?: string | null, first_name?: string | null, foreigner?: boolean | null, last_name?: string | null, passport?: string | null, phone_number?: string | null, place_birth?: string | null, second_name?: string | null, sex?: boolean | null, international_passport?: string | null }> };

export type DmsGroupByPkQueryVariables = Exact<{
  group_id: Scalars['bigint'];
}>;


export type DmsGroupByPkQuery = { __typename?: 'query_root', dms_groups_by_pk?: { __typename?: 'dms_groups', id: any, group_name?: string | null, group_insureds: Array<{ __typename?: 'dms_group_insureds', group?: { __typename?: 'dms_groups', group_name?: string | null, id: any } | null, insured?: { __typename?: 'dms_insureds', id: any, first_name: string, last_name: string, second_name?: string | null, bithday: any, gender?: boolean | null } | null }> } | null };

export type DmsGroupGetPolicyCardsQueryVariables = Exact<{
  group_id?: InputMaybe<Scalars['Int']>;
}>;


export type DmsGroupGetPolicyCardsQuery = { __typename?: 'query_root', dmsGetPolicyCard?: { __typename?: 'dmsGetPolicyCardOutput', policy_card: any } | null };

export type DmsGroupInsuredQueryVariables = Exact<{ [key: string]: never; }>;


export type DmsGroupInsuredQuery = { __typename?: 'query_root', dms_group_insureds: Array<{ __typename?: 'dms_group_insureds', group_id?: any | null, user_id?: number | null, group?: { __typename?: 'dms_groups', group_name?: string | null, id: any } | null, insured?: { __typename?: 'dms_insureds', id: any, first_name: string, last_name: string, second_name?: string | null, bithday: any, gender?: boolean | null } | null }> };

export type DmsGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type DmsGroupsQuery = { __typename?: 'query_root', dms_groups: Array<{ __typename?: 'dms_groups', group_name?: string | null, id: any, user_id?: number | null, group_insureds: Array<{ __typename?: 'dms_group_insureds', insured?: { __typename?: 'dms_insureds', id: any, first_name: string, email: string, bithday: any, last_name: string, oms?: string | null, gender?: boolean | null } | null }> }> };

export type DmsGroupsWithInsuredsQueryVariables = Exact<{ [key: string]: never; }>;


export type DmsGroupsWithInsuredsQuery = { __typename?: 'query_root', dms_groups: Array<{ __typename?: 'dms_groups', id: any, group_name?: string | null, user_id?: number | null, group_insureds: Array<{ __typename?: 'dms_group_insureds', group?: { __typename?: 'dms_groups', group_name?: string | null, id: any } | null, insured?: { __typename?: 'dms_insureds', id: any, first_name: string, last_name: string, second_name?: string | null, bithday: any, gender?: boolean | null } | null }> }> };

export type DmsUserGroupsWithInsuredsQueryVariables = Exact<{ [key: string]: never; }>;


export type DmsUserGroupsWithInsuredsQuery = { __typename?: 'query_root', dms_group_insureds: Array<{ __typename?: 'dms_group_insureds', insured?: { __typename?: 'dms_insureds', first_name: string, last_name: string, bithday: any, gender?: boolean | null, email: string, id: any } | null, group?: { __typename?: 'dms_groups', group_name?: string | null, id: any } | null }> };

export type GetInsuredByPkQueryVariables = Exact<{
  insuredId: Scalars['bigint'];
}>;


export type GetInsuredByPkQuery = { __typename?: 'query_root', dms_insureds_by_pk?: { __typename?: 'dms_insureds', bithday: any, email: string, first_name: string, foreign?: boolean | null, foreign_passport?: string | null, gender?: boolean | null, last_name: string, id: any, oms?: string | null, passport: string, phone: string, second_name?: string | null, user_id?: any | null, group_insureds: Array<{ __typename?: 'dms_group_insureds', id: any, group?: { __typename?: 'dms_groups', group_name?: string | null, id: any } | null }> } | null };

export type GetUserInsuredsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserInsuredsQuery = { __typename?: 'query_root', dms_insureds: Array<{ __typename?: 'dms_insureds', id: any, last_name: string, oms?: string | null, passport: string, phone: string, user_id?: any | null, second_name?: string | null, gender?: boolean | null, foreign_passport?: string | null, foreign?: boolean | null, first_name: string, email: string, bithday: any, group_insureds: Array<{ __typename?: 'dms_group_insureds', id: any, group?: { __typename?: 'dms_groups', group_name?: string | null, id: any } | null }> }> };

export type DmsOrderLinksSubscriptionVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type DmsOrderLinksSubscription = { __typename?: 'subscription_root', dms_order_links: Array<{ __typename?: 'dms_order_links', link?: string | null, status?: string | null }> };


export const AddInsuredArrayToGroupDocument = gql`
    mutation addInsuredArrayToGroup($objects: [dms_group_insureds_insert_input!]!) {
  insert_dms_group_insureds(objects: $objects) {
    returning {
      insured {
        id
      }
    }
  }
}
    `;
export type AddInsuredArrayToGroupMutationFn = Apollo.MutationFunction<AddInsuredArrayToGroupMutation, AddInsuredArrayToGroupMutationVariables>;

/**
 * __useAddInsuredArrayToGroupMutation__
 *
 * To run a mutation, you first call `useAddInsuredArrayToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInsuredArrayToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInsuredArrayToGroupMutation, { data, loading, error }] = useAddInsuredArrayToGroupMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddInsuredArrayToGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddInsuredArrayToGroupMutation, AddInsuredArrayToGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInsuredArrayToGroupMutation, AddInsuredArrayToGroupMutationVariables>(AddInsuredArrayToGroupDocument, options);
      }
export type AddInsuredArrayToGroupMutationHookResult = ReturnType<typeof useAddInsuredArrayToGroupMutation>;
export type AddInsuredArrayToGroupMutationResult = Apollo.MutationResult<AddInsuredArrayToGroupMutation>;
export type AddInsuredArrayToGroupMutationOptions = Apollo.BaseMutationOptions<AddInsuredArrayToGroupMutation, AddInsuredArrayToGroupMutationVariables>;
export const AddInsuredOneDocument = gql`
    mutation AddInsuredOne($user: dms_insureds_insert_input = {}) {
  insert_dms_insureds_one(object: $user) {
    id
  }
}
    `;
export type AddInsuredOneMutationFn = Apollo.MutationFunction<AddInsuredOneMutation, AddInsuredOneMutationVariables>;

/**
 * __useAddInsuredOneMutation__
 *
 * To run a mutation, you first call `useAddInsuredOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInsuredOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInsuredOneMutation, { data, loading, error }] = useAddInsuredOneMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useAddInsuredOneMutation(baseOptions?: Apollo.MutationHookOptions<AddInsuredOneMutation, AddInsuredOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInsuredOneMutation, AddInsuredOneMutationVariables>(AddInsuredOneDocument, options);
      }
export type AddInsuredOneMutationHookResult = ReturnType<typeof useAddInsuredOneMutation>;
export type AddInsuredOneMutationResult = Apollo.MutationResult<AddInsuredOneMutation>;
export type AddInsuredOneMutationOptions = Apollo.BaseMutationOptions<AddInsuredOneMutation, AddInsuredOneMutationVariables>;
export const AddInsuredToGroupDocument = gql`
    mutation AddInsuredToGroup($insured_id: bigint!, $group_id: bigint!, $user_id: Int!) {
  insert_dms_group_insureds_one(
    object: {group_id: $group_id, insured_id: $insured_id, user_id: $user_id}
  ) {
    group {
      id
    }
    insured {
      id
    }
  }
}
    `;
export type AddInsuredToGroupMutationFn = Apollo.MutationFunction<AddInsuredToGroupMutation, AddInsuredToGroupMutationVariables>;

/**
 * __useAddInsuredToGroupMutation__
 *
 * To run a mutation, you first call `useAddInsuredToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInsuredToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInsuredToGroupMutation, { data, loading, error }] = useAddInsuredToGroupMutation({
 *   variables: {
 *      insured_id: // value for 'insured_id'
 *      group_id: // value for 'group_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useAddInsuredToGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddInsuredToGroupMutation, AddInsuredToGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInsuredToGroupMutation, AddInsuredToGroupMutationVariables>(AddInsuredToGroupDocument, options);
      }
export type AddInsuredToGroupMutationHookResult = ReturnType<typeof useAddInsuredToGroupMutation>;
export type AddInsuredToGroupMutationResult = Apollo.MutationResult<AddInsuredToGroupMutation>;
export type AddInsuredToGroupMutationOptions = Apollo.BaseMutationOptions<AddInsuredToGroupMutation, AddInsuredToGroupMutationVariables>;
export const AddInsuredToGroupInsuredsDocument = gql`
    mutation addInsuredToGroupInsureds($user_id: Int, $group_id: bigint!, $insured_id: bigint!) {
  insert_dms_group_insureds_one(
    object: {user_id: $user_id, insured_id: $insured_id, group_id: $group_id}
  ) {
    group {
      group_name
      id
    }
    insured {
      first_name
      id
      email
      last_name
      bithday
      group_id
      insurant {
        id
        email
        first_name
        last_name
      }
    }
  }
}
    `;
export type AddInsuredToGroupInsuredsMutationFn = Apollo.MutationFunction<AddInsuredToGroupInsuredsMutation, AddInsuredToGroupInsuredsMutationVariables>;

/**
 * __useAddInsuredToGroupInsuredsMutation__
 *
 * To run a mutation, you first call `useAddInsuredToGroupInsuredsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInsuredToGroupInsuredsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInsuredToGroupInsuredsMutation, { data, loading, error }] = useAddInsuredToGroupInsuredsMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      group_id: // value for 'group_id'
 *      insured_id: // value for 'insured_id'
 *   },
 * });
 */
export function useAddInsuredToGroupInsuredsMutation(baseOptions?: Apollo.MutationHookOptions<AddInsuredToGroupInsuredsMutation, AddInsuredToGroupInsuredsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInsuredToGroupInsuredsMutation, AddInsuredToGroupInsuredsMutationVariables>(AddInsuredToGroupInsuredsDocument, options);
      }
export type AddInsuredToGroupInsuredsMutationHookResult = ReturnType<typeof useAddInsuredToGroupInsuredsMutation>;
export type AddInsuredToGroupInsuredsMutationResult = Apollo.MutationResult<AddInsuredToGroupInsuredsMutation>;
export type AddInsuredToGroupInsuredsMutationOptions = Apollo.BaseMutationOptions<AddInsuredToGroupInsuredsMutation, AddInsuredToGroupInsuredsMutationVariables>;
export const CreateDmsGroupDocument = gql`
    mutation CreateDmsGroup($group_name: String!, $user_id: Int!) {
  insert_dms_groups_one(
    object: {created_at: "09-09-2022", updated_at: "09-09-2022", group_name: $group_name, user_id: $user_id}
  ) {
    id
    user_id
    group_name
  }
}
    `;
export type CreateDmsGroupMutationFn = Apollo.MutationFunction<CreateDmsGroupMutation, CreateDmsGroupMutationVariables>;

/**
 * __useCreateDmsGroupMutation__
 *
 * To run a mutation, you first call `useCreateDmsGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDmsGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDmsGroupMutation, { data, loading, error }] = useCreateDmsGroupMutation({
 *   variables: {
 *      group_name: // value for 'group_name'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useCreateDmsGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateDmsGroupMutation, CreateDmsGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDmsGroupMutation, CreateDmsGroupMutationVariables>(CreateDmsGroupDocument, options);
      }
export type CreateDmsGroupMutationHookResult = ReturnType<typeof useCreateDmsGroupMutation>;
export type CreateDmsGroupMutationResult = Apollo.MutationResult<CreateDmsGroupMutation>;
export type CreateDmsGroupMutationOptions = Apollo.BaseMutationOptions<CreateDmsGroupMutation, CreateDmsGroupMutationVariables>;
export const DeleteDmsGroupByPkDocument = gql`
    mutation deleteDmsGroupByPk($group_id: bigint!) {
  delete_dms_groups_by_pk(id: $group_id) {
    id
  }
}
    `;
export type DeleteDmsGroupByPkMutationFn = Apollo.MutationFunction<DeleteDmsGroupByPkMutation, DeleteDmsGroupByPkMutationVariables>;

/**
 * __useDeleteDmsGroupByPkMutation__
 *
 * To run a mutation, you first call `useDeleteDmsGroupByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDmsGroupByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDmsGroupByPkMutation, { data, loading, error }] = useDeleteDmsGroupByPkMutation({
 *   variables: {
 *      group_id: // value for 'group_id'
 *   },
 * });
 */
export function useDeleteDmsGroupByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDmsGroupByPkMutation, DeleteDmsGroupByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDmsGroupByPkMutation, DeleteDmsGroupByPkMutationVariables>(DeleteDmsGroupByPkDocument, options);
      }
export type DeleteDmsGroupByPkMutationHookResult = ReturnType<typeof useDeleteDmsGroupByPkMutation>;
export type DeleteDmsGroupByPkMutationResult = Apollo.MutationResult<DeleteDmsGroupByPkMutation>;
export type DeleteDmsGroupByPkMutationOptions = Apollo.BaseMutationOptions<DeleteDmsGroupByPkMutation, DeleteDmsGroupByPkMutationVariables>;
export const DeleteDmsInsuredByPkDocument = gql`
    mutation deleteDmsInsuredByPk($user_id: bigint!) {
  delete_dms_group_insureds(where: {insured_id: {_eq: $user_id}}) {
    returning {
      id
    }
  }
  delete_dms_insureds_by_pk(id: $user_id) {
    id
  }
}
    `;
export type DeleteDmsInsuredByPkMutationFn = Apollo.MutationFunction<DeleteDmsInsuredByPkMutation, DeleteDmsInsuredByPkMutationVariables>;

/**
 * __useDeleteDmsInsuredByPkMutation__
 *
 * To run a mutation, you first call `useDeleteDmsInsuredByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDmsInsuredByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDmsInsuredByPkMutation, { data, loading, error }] = useDeleteDmsInsuredByPkMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useDeleteDmsInsuredByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDmsInsuredByPkMutation, DeleteDmsInsuredByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDmsInsuredByPkMutation, DeleteDmsInsuredByPkMutationVariables>(DeleteDmsInsuredByPkDocument, options);
      }
export type DeleteDmsInsuredByPkMutationHookResult = ReturnType<typeof useDeleteDmsInsuredByPkMutation>;
export type DeleteDmsInsuredByPkMutationResult = Apollo.MutationResult<DeleteDmsInsuredByPkMutation>;
export type DeleteDmsInsuredByPkMutationOptions = Apollo.BaseMutationOptions<DeleteDmsInsuredByPkMutation, DeleteDmsInsuredByPkMutationVariables>;
export const DeleteDmsInsuredFromGroupByPkDocument = gql`
    mutation DeleteDmsInsuredFromGroupByPk($insured_id: bigint!) {
  delete_dms_group_insureds_by_pk(id: $insured_id) {
    insured_id
    id
  }
}
    `;
export type DeleteDmsInsuredFromGroupByPkMutationFn = Apollo.MutationFunction<DeleteDmsInsuredFromGroupByPkMutation, DeleteDmsInsuredFromGroupByPkMutationVariables>;

/**
 * __useDeleteDmsInsuredFromGroupByPkMutation__
 *
 * To run a mutation, you first call `useDeleteDmsInsuredFromGroupByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDmsInsuredFromGroupByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDmsInsuredFromGroupByPkMutation, { data, loading, error }] = useDeleteDmsInsuredFromGroupByPkMutation({
 *   variables: {
 *      insured_id: // value for 'insured_id'
 *   },
 * });
 */
export function useDeleteDmsInsuredFromGroupByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDmsInsuredFromGroupByPkMutation, DeleteDmsInsuredFromGroupByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDmsInsuredFromGroupByPkMutation, DeleteDmsInsuredFromGroupByPkMutationVariables>(DeleteDmsInsuredFromGroupByPkDocument, options);
      }
export type DeleteDmsInsuredFromGroupByPkMutationHookResult = ReturnType<typeof useDeleteDmsInsuredFromGroupByPkMutation>;
export type DeleteDmsInsuredFromGroupByPkMutationResult = Apollo.MutationResult<DeleteDmsInsuredFromGroupByPkMutation>;
export type DeleteDmsInsuredFromGroupByPkMutationOptions = Apollo.BaseMutationOptions<DeleteDmsInsuredFromGroupByPkMutation, DeleteDmsInsuredFromGroupByPkMutationVariables>;
export const DmsCleanGroupDocument = gql`
    mutation dmsCleanGroup($group_id: bigint!) {
  delete_dms_group_insureds(where: {group_id: {_eq: $group_id}}) {
    returning {
      id
    }
  }
}
    `;
export type DmsCleanGroupMutationFn = Apollo.MutationFunction<DmsCleanGroupMutation, DmsCleanGroupMutationVariables>;

/**
 * __useDmsCleanGroupMutation__
 *
 * To run a mutation, you first call `useDmsCleanGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDmsCleanGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dmsCleanGroupMutation, { data, loading, error }] = useDmsCleanGroupMutation({
 *   variables: {
 *      group_id: // value for 'group_id'
 *   },
 * });
 */
export function useDmsCleanGroupMutation(baseOptions?: Apollo.MutationHookOptions<DmsCleanGroupMutation, DmsCleanGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DmsCleanGroupMutation, DmsCleanGroupMutationVariables>(DmsCleanGroupDocument, options);
      }
export type DmsCleanGroupMutationHookResult = ReturnType<typeof useDmsCleanGroupMutation>;
export type DmsCleanGroupMutationResult = Apollo.MutationResult<DmsCleanGroupMutation>;
export type DmsCleanGroupMutationOptions = Apollo.BaseMutationOptions<DmsCleanGroupMutation, DmsCleanGroupMutationVariables>;
export const UpdateCurrentUserDocument = gql`
    mutation updateCurrentUser($id: bigint!, $birthday: date, $email: String!, $first_name: String!, $foreigner: Boolean!, $last_name: String!, $passport: String = "", $phone_number: String!, $second_name: String = "", $sex: Boolean!, $international_passport: String = "") {
  update_auth_users(
    where: {id: {_eq: $id}}
    _set: {id: $id, birthday: $birthday, email: $email, first_name: $first_name, foreigner: $foreigner, last_name: $last_name, passport: $passport, phone_number: $phone_number, second_name: $second_name, sex: $sex, international_passport: $international_passport}
  ) {
    affected_rows
    returning {
      id
      birthday
      email
      first_name
      foreigner
      last_name
      passport
      phone_number
      second_name
      sex
      international_passport
    }
  }
}
    `;
export type UpdateCurrentUserMutationFn = Apollo.MutationFunction<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>;

/**
 * __useUpdateCurrentUserMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserMutation, { data, loading, error }] = useUpdateCurrentUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      birthday: // value for 'birthday'
 *      email: // value for 'email'
 *      first_name: // value for 'first_name'
 *      foreigner: // value for 'foreigner'
 *      last_name: // value for 'last_name'
 *      passport: // value for 'passport'
 *      phone_number: // value for 'phone_number'
 *      second_name: // value for 'second_name'
 *      sex: // value for 'sex'
 *      international_passport: // value for 'international_passport'
 *   },
 * });
 */
export function useUpdateCurrentUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>(UpdateCurrentUserDocument, options);
      }
export type UpdateCurrentUserMutationHookResult = ReturnType<typeof useUpdateCurrentUserMutation>;
export type UpdateCurrentUserMutationResult = Apollo.MutationResult<UpdateCurrentUserMutation>;
export type UpdateCurrentUserMutationOptions = Apollo.BaseMutationOptions<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>;
export const UpdateInsuredByPkDocument = gql`
    mutation updateInsuredByPk($user_id: bigint!, $bithday: date!, $first_name: String!, $last_name: String!, $gender: Boolean!, $foreign: Boolean, $foreign_passport: String, $oms: String, $passport: String, $second_name: String, $group_id: bigint) {
  update_dms_insureds_by_pk(
    pk_columns: {id: $user_id}
    _set: {bithday: $bithday, first_name: $first_name, last_name: $last_name, gender: $gender, foreign: $foreign, foreign_passport: $foreign_passport, oms: $oms, passport: $passport, second_name: $second_name, group_id: $group_id}
  ) {
    id
    insurant_id
    last_name
    number
    oms
    order_uid
    passport
    phone
    gender
    first_name
    bithday
    second_name
    user_id
    foreign
    foreign_passport
    email
  }
}
    `;
export type UpdateInsuredByPkMutationFn = Apollo.MutationFunction<UpdateInsuredByPkMutation, UpdateInsuredByPkMutationVariables>;

/**
 * __useUpdateInsuredByPkMutation__
 *
 * To run a mutation, you first call `useUpdateInsuredByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInsuredByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInsuredByPkMutation, { data, loading, error }] = useUpdateInsuredByPkMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      bithday: // value for 'bithday'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      gender: // value for 'gender'
 *      foreign: // value for 'foreign'
 *      foreign_passport: // value for 'foreign_passport'
 *      oms: // value for 'oms'
 *      passport: // value for 'passport'
 *      second_name: // value for 'second_name'
 *      group_id: // value for 'group_id'
 *   },
 * });
 */
export function useUpdateInsuredByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInsuredByPkMutation, UpdateInsuredByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInsuredByPkMutation, UpdateInsuredByPkMutationVariables>(UpdateInsuredByPkDocument, options);
      }
export type UpdateInsuredByPkMutationHookResult = ReturnType<typeof useUpdateInsuredByPkMutation>;
export type UpdateInsuredByPkMutationResult = Apollo.MutationResult<UpdateInsuredByPkMutation>;
export type UpdateInsuredByPkMutationOptions = Apollo.BaseMutationOptions<UpdateInsuredByPkMutation, UpdateInsuredByPkMutationVariables>;
export const CurrentUserDocument = gql`
    query currentUser {
  current_user: auth_users {
    id
    address_residence
    birthday
    email
    first_name
    foreigner
    last_name
    passport
    phone_number
    place_birth
    second_name
    sex
    international_passport
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const DmsGroupByPkDocument = gql`
    query dmsGroupByPk($group_id: bigint!) {
  dms_groups_by_pk(id: $group_id) {
    id
    group_name
    group_insureds {
      group {
        group_name
        id
      }
      insured {
        id
        first_name
        last_name
        second_name
        bithday
        gender
      }
    }
  }
}
    `;

/**
 * __useDmsGroupByPkQuery__
 *
 * To run a query within a React component, call `useDmsGroupByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useDmsGroupByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDmsGroupByPkQuery({
 *   variables: {
 *      group_id: // value for 'group_id'
 *   },
 * });
 */
export function useDmsGroupByPkQuery(baseOptions: Apollo.QueryHookOptions<DmsGroupByPkQuery, DmsGroupByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DmsGroupByPkQuery, DmsGroupByPkQueryVariables>(DmsGroupByPkDocument, options);
      }
export function useDmsGroupByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DmsGroupByPkQuery, DmsGroupByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DmsGroupByPkQuery, DmsGroupByPkQueryVariables>(DmsGroupByPkDocument, options);
        }
export type DmsGroupByPkQueryHookResult = ReturnType<typeof useDmsGroupByPkQuery>;
export type DmsGroupByPkLazyQueryHookResult = ReturnType<typeof useDmsGroupByPkLazyQuery>;
export type DmsGroupByPkQueryResult = Apollo.QueryResult<DmsGroupByPkQuery, DmsGroupByPkQueryVariables>;
export const DmsGroupGetPolicyCardsDocument = gql`
    query dmsGroupGetPolicyCards($group_id: Int) {
  dmsGetPolicyCard(arg: {group_id: $group_id}) {
    policy_card
  }
}
    `;

/**
 * __useDmsGroupGetPolicyCardsQuery__
 *
 * To run a query within a React component, call `useDmsGroupGetPolicyCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDmsGroupGetPolicyCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDmsGroupGetPolicyCardsQuery({
 *   variables: {
 *      group_id: // value for 'group_id'
 *   },
 * });
 */
export function useDmsGroupGetPolicyCardsQuery(baseOptions?: Apollo.QueryHookOptions<DmsGroupGetPolicyCardsQuery, DmsGroupGetPolicyCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DmsGroupGetPolicyCardsQuery, DmsGroupGetPolicyCardsQueryVariables>(DmsGroupGetPolicyCardsDocument, options);
      }
export function useDmsGroupGetPolicyCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DmsGroupGetPolicyCardsQuery, DmsGroupGetPolicyCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DmsGroupGetPolicyCardsQuery, DmsGroupGetPolicyCardsQueryVariables>(DmsGroupGetPolicyCardsDocument, options);
        }
export type DmsGroupGetPolicyCardsQueryHookResult = ReturnType<typeof useDmsGroupGetPolicyCardsQuery>;
export type DmsGroupGetPolicyCardsLazyQueryHookResult = ReturnType<typeof useDmsGroupGetPolicyCardsLazyQuery>;
export type DmsGroupGetPolicyCardsQueryResult = Apollo.QueryResult<DmsGroupGetPolicyCardsQuery, DmsGroupGetPolicyCardsQueryVariables>;
export const DmsGroupInsuredDocument = gql`
    query dmsGroupInsured {
  dms_group_insureds {
    group_id
    group {
      group_name
      id
    }
    insured {
      id
      first_name
      last_name
      second_name
      bithday
      gender
    }
    user_id
  }
}
    `;

/**
 * __useDmsGroupInsuredQuery__
 *
 * To run a query within a React component, call `useDmsGroupInsuredQuery` and pass it any options that fit your needs.
 * When your component renders, `useDmsGroupInsuredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDmsGroupInsuredQuery({
 *   variables: {
 *   },
 * });
 */
export function useDmsGroupInsuredQuery(baseOptions?: Apollo.QueryHookOptions<DmsGroupInsuredQuery, DmsGroupInsuredQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DmsGroupInsuredQuery, DmsGroupInsuredQueryVariables>(DmsGroupInsuredDocument, options);
      }
export function useDmsGroupInsuredLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DmsGroupInsuredQuery, DmsGroupInsuredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DmsGroupInsuredQuery, DmsGroupInsuredQueryVariables>(DmsGroupInsuredDocument, options);
        }
export type DmsGroupInsuredQueryHookResult = ReturnType<typeof useDmsGroupInsuredQuery>;
export type DmsGroupInsuredLazyQueryHookResult = ReturnType<typeof useDmsGroupInsuredLazyQuery>;
export type DmsGroupInsuredQueryResult = Apollo.QueryResult<DmsGroupInsuredQuery, DmsGroupInsuredQueryVariables>;
export const DmsGroupsDocument = gql`
    query DMSGroups {
  dms_groups {
    group_name
    id
    user_id
    group_insureds {
      insured {
        id
        first_name
        email
        bithday
        last_name
        oms
        gender
      }
    }
  }
}
    `;

/**
 * __useDmsGroupsQuery__
 *
 * To run a query within a React component, call `useDmsGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDmsGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDmsGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDmsGroupsQuery(baseOptions?: Apollo.QueryHookOptions<DmsGroupsQuery, DmsGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DmsGroupsQuery, DmsGroupsQueryVariables>(DmsGroupsDocument, options);
      }
export function useDmsGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DmsGroupsQuery, DmsGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DmsGroupsQuery, DmsGroupsQueryVariables>(DmsGroupsDocument, options);
        }
export type DmsGroupsQueryHookResult = ReturnType<typeof useDmsGroupsQuery>;
export type DmsGroupsLazyQueryHookResult = ReturnType<typeof useDmsGroupsLazyQuery>;
export type DmsGroupsQueryResult = Apollo.QueryResult<DmsGroupsQuery, DmsGroupsQueryVariables>;
export const DmsGroupsWithInsuredsDocument = gql`
    query DMSGroupsWithInsureds {
  dms_groups {
    id
    group_name
    user_id
    group_insureds {
      group {
        group_name
        id
      }
      insured {
        id
        first_name
        last_name
        second_name
        bithday
        gender
      }
    }
  }
}
    `;

/**
 * __useDmsGroupsWithInsuredsQuery__
 *
 * To run a query within a React component, call `useDmsGroupsWithInsuredsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDmsGroupsWithInsuredsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDmsGroupsWithInsuredsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDmsGroupsWithInsuredsQuery(baseOptions?: Apollo.QueryHookOptions<DmsGroupsWithInsuredsQuery, DmsGroupsWithInsuredsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DmsGroupsWithInsuredsQuery, DmsGroupsWithInsuredsQueryVariables>(DmsGroupsWithInsuredsDocument, options);
      }
export function useDmsGroupsWithInsuredsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DmsGroupsWithInsuredsQuery, DmsGroupsWithInsuredsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DmsGroupsWithInsuredsQuery, DmsGroupsWithInsuredsQueryVariables>(DmsGroupsWithInsuredsDocument, options);
        }
export type DmsGroupsWithInsuredsQueryHookResult = ReturnType<typeof useDmsGroupsWithInsuredsQuery>;
export type DmsGroupsWithInsuredsLazyQueryHookResult = ReturnType<typeof useDmsGroupsWithInsuredsLazyQuery>;
export type DmsGroupsWithInsuredsQueryResult = Apollo.QueryResult<DmsGroupsWithInsuredsQuery, DmsGroupsWithInsuredsQueryVariables>;
export const DmsUserGroupsWithInsuredsDocument = gql`
    query dmsUserGroupsWithInsureds {
  dms_group_insureds {
    insured {
      first_name
      last_name
      bithday
      gender
      email
      id
    }
    group {
      group_name
      id
    }
  }
}
    `;

/**
 * __useDmsUserGroupsWithInsuredsQuery__
 *
 * To run a query within a React component, call `useDmsUserGroupsWithInsuredsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDmsUserGroupsWithInsuredsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDmsUserGroupsWithInsuredsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDmsUserGroupsWithInsuredsQuery(baseOptions?: Apollo.QueryHookOptions<DmsUserGroupsWithInsuredsQuery, DmsUserGroupsWithInsuredsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DmsUserGroupsWithInsuredsQuery, DmsUserGroupsWithInsuredsQueryVariables>(DmsUserGroupsWithInsuredsDocument, options);
      }
export function useDmsUserGroupsWithInsuredsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DmsUserGroupsWithInsuredsQuery, DmsUserGroupsWithInsuredsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DmsUserGroupsWithInsuredsQuery, DmsUserGroupsWithInsuredsQueryVariables>(DmsUserGroupsWithInsuredsDocument, options);
        }
export type DmsUserGroupsWithInsuredsQueryHookResult = ReturnType<typeof useDmsUserGroupsWithInsuredsQuery>;
export type DmsUserGroupsWithInsuredsLazyQueryHookResult = ReturnType<typeof useDmsUserGroupsWithInsuredsLazyQuery>;
export type DmsUserGroupsWithInsuredsQueryResult = Apollo.QueryResult<DmsUserGroupsWithInsuredsQuery, DmsUserGroupsWithInsuredsQueryVariables>;
export const GetInsuredByPkDocument = gql`
    query GetInsuredByPK($insuredId: bigint!) {
  dms_insureds_by_pk(id: $insuredId) {
    bithday
    email
    first_name
    foreign
    foreign_passport
    gender
    group_insureds {
      id
      group {
        group_name
        id
      }
    }
    last_name
    id
    oms
    passport
    phone
    second_name
    user_id
  }
}
    `;

/**
 * __useGetInsuredByPkQuery__
 *
 * To run a query within a React component, call `useGetInsuredByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInsuredByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInsuredByPkQuery({
 *   variables: {
 *      insuredId: // value for 'insuredId'
 *   },
 * });
 */
export function useGetInsuredByPkQuery(baseOptions: Apollo.QueryHookOptions<GetInsuredByPkQuery, GetInsuredByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInsuredByPkQuery, GetInsuredByPkQueryVariables>(GetInsuredByPkDocument, options);
      }
export function useGetInsuredByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInsuredByPkQuery, GetInsuredByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInsuredByPkQuery, GetInsuredByPkQueryVariables>(GetInsuredByPkDocument, options);
        }
export type GetInsuredByPkQueryHookResult = ReturnType<typeof useGetInsuredByPkQuery>;
export type GetInsuredByPkLazyQueryHookResult = ReturnType<typeof useGetInsuredByPkLazyQuery>;
export type GetInsuredByPkQueryResult = Apollo.QueryResult<GetInsuredByPkQuery, GetInsuredByPkQueryVariables>;
export const GetUserInsuredsDocument = gql`
    query getUserInsureds {
  dms_insureds {
    id
    group_insureds {
      id
      group {
        group_name
        id
      }
    }
    last_name
    oms
    passport
    phone
    user_id
    second_name
    gender
    foreign_passport
    foreign
    first_name
    email
    bithday
  }
}
    `;

/**
 * __useGetUserInsuredsQuery__
 *
 * To run a query within a React component, call `useGetUserInsuredsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInsuredsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInsuredsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserInsuredsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserInsuredsQuery, GetUserInsuredsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserInsuredsQuery, GetUserInsuredsQueryVariables>(GetUserInsuredsDocument, options);
      }
export function useGetUserInsuredsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserInsuredsQuery, GetUserInsuredsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserInsuredsQuery, GetUserInsuredsQueryVariables>(GetUserInsuredsDocument, options);
        }
export type GetUserInsuredsQueryHookResult = ReturnType<typeof useGetUserInsuredsQuery>;
export type GetUserInsuredsLazyQueryHookResult = ReturnType<typeof useGetUserInsuredsLazyQuery>;
export type GetUserInsuredsQueryResult = Apollo.QueryResult<GetUserInsuredsQuery, GetUserInsuredsQueryVariables>;
export const DmsOrderLinksDocument = gql`
    subscription DmsOrderLinks($orderId: String!) {
  dms_order_links(where: {uid: {_eq: $orderId}}) {
    link
    status
  }
}
    `;

/**
 * __useDmsOrderLinksSubscription__
 *
 * To run a query within a React component, call `useDmsOrderLinksSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDmsOrderLinksSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDmsOrderLinksSubscription({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useDmsOrderLinksSubscription(baseOptions: Apollo.SubscriptionHookOptions<DmsOrderLinksSubscription, DmsOrderLinksSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DmsOrderLinksSubscription, DmsOrderLinksSubscriptionVariables>(DmsOrderLinksDocument, options);
      }
export type DmsOrderLinksSubscriptionHookResult = ReturnType<typeof useDmsOrderLinksSubscription>;
export type DmsOrderLinksSubscriptionResult = Apollo.SubscriptionResult<DmsOrderLinksSubscription>;