/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';

import { useStoreon } from 'storeon/react';
import WebFont from 'webfontloader';
import { UI } from '@etnamed/ui-kit';
import { TabPanel, useTabs } from 'react-headless-tabs';

import * as Blocks from 'components';
import * as Icons from 'assets/svgs';
import { useNavigate, Link } from 'react-router-dom';

function LkIndex() {
  const { dispatch } = useStoreon('token');

  const [selectedTab, setSelectedTab] = useTabs(['profile', 'groups', 'polices']);
  const [selectedDropdown, setSelectedDropdown] = useState('dropdown-0');
  const [isComplete, setIscomplete] = useState(false);
  const [insuredGroups, setinsuredGroups] = useState<Array<any> | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add('min-h-screen', 'bg-lightGray');

    WebFont.load({
      custom: {
        families: ['Avenir Next Cyr'],
        urls: ['assets/fonts/fonts.css'],
      },
    });
    setIscomplete(true);
  }, []);

  const handleSignOut = () => {
    dispatch('clear');
  };

  const toggleDropdown = (dropdown: string) => {
    if (selectedDropdown === dropdown) {
      setSelectedDropdown('dropdown-0');
    } else {
      setSelectedDropdown(dropdown);
    }
  };

  const groupsWithInsured = (groups: any) => {
    if (isComplete) {
      setinsuredGroups(groups);
    }
  };

  const handleDropdown = () => {
    setSelectedDropdown('dropdown-0');
  };
  // @ts-ignore
  // @ts-ignore
  return (
    <div className="min-h-screen relative">
      <div className="container mx-auto p-2 mt-0 lg:mt-10">
        <UI.Logo link='/' />
        <h1 className="mt-10 font-bold text-primary text-2xl">Личный кабинет</h1>
        <div className="mt-10 tabs relative w-full lg:w-3/4 hidden lg:block">
          <ul>
            <li className={` ${selectedTab === 'profile' ? 'tab-active' : ''}`} onClick={() => setSelectedTab('profile')}>
              Личные данные
            </li>
            <li className={` ${selectedTab === 'groups' ? 'tab-active' : ''}`} onClick={() => setSelectedTab('groups')}>
              Группы
            </li>
            <li className={` ${selectedTab === 'polices' ? 'tab-active' : ''}`} onClick={() => setSelectedTab('polices')}>
              Полисы
            </li>
          </ul>
          <UI.Button className="relative lg:absolute w-48 right-0 top-0 bottom-0" primary onClick={handleSignOut}>Выход</UI.Button>
        </div>
        <div className="tabs block lg:hidden w-full mt-10">
          {selectedDropdown !== 'dropdown-0' && (
            <div className="overlay" onClick={handleDropdown} />
          )}
          <ul className="relative">
            <li className={`relative ${selectedDropdown === 'dropdown-1' ? 'mobile-tab-active' : ''}`} onClick={() => toggleDropdown('dropdown-1')}>
              <div className="flex flex-row justify-between items-center gap-4">
                <span>Личные данные</span>
                <span><Icons.Arrow /></span>
              </div>
            </li>

            {
              selectedDropdown === 'dropdown-1'
              && (
                <div className="dropdown absolute left-0 right-0 top-[100%] bg-white">
                  <div onClick={() => { setSelectedTab('profile'); setSelectedDropdown('dropdown-0'); }}>
                    Личные данные
                  </div>
                  <Icons.Line className="my-3" />
                  <div onClick={() => { setSelectedTab('polices'); setSelectedDropdown('dropdown-0'); }}>
                    Полисы
                  </div>
                  <Icons.Line className="my-3" />
                  <div onClick={() => { handleSignOut(); setSelectedDropdown('dropdown-0'); }}>
                    Выход
                  </div>
                </div>
              )
            }

            <li className={`relative ${selectedDropdown === 'dropdown-2' ? 'mobile-tab-active' : ''}`} onClick={() => toggleDropdown('dropdown-2')}>
              <div className="flex flex-row justify-between items-center gap-4">
                <span>Группы</span>
                <span><Icons.Arrow /></span>
              </div>
            </li>

            {
              selectedDropdown === 'dropdown-2'
              && (
                <div className="dropdown absolute left-0 right-0 top-[100%] bg-white">
                  {
                    insuredGroups?.map((group, index) => (
                      <div key={index} className="my-2">
                        <Link to={`/group/${group.id}`}>{group.group_name}</Link>
                        <Icons.Line className="mt-2" />
                      </div>

                    ))
                  }
                  <UI.Button primary onClick={() => navigate('addgroup')} className="mt-4">
                    Добавить группу
                  </UI.Button>
                </div>
              )
            }

          </ul>
        </div>
        <TabPanel hidden={selectedTab !== 'profile'}>

          <Blocks.Profile />
        </TabPanel>
        <TabPanel hidden={selectedTab !== 'groups'}>
          <Blocks.Groups onGroupsReturned={groupsWithInsured} />
        </TabPanel>
        <TabPanel hidden={selectedTab !== 'polices'}>
          <Blocks.Polices />
        </TabPanel>
      </div>
    </div>
  );
}

export default LkIndex;
