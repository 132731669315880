/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { FormSpy } from 'react-final-form';
import diff from 'object-diff';

function AutoSave(props:any) {
  const [values, setValues] = useState(props.values);
  const [submitting, setSubmitting] = useState(false);
  const [promise, setPromise] = useState<any>(null);

  const save = async () => {
    if (promise !== null) {
      await promise;
    }

    const difference = diff(values, props.values);
    if (Object.keys(difference).length) {
      setSubmitting(true);
      setValues(props.values);

      setPromise(props.save(difference));
      await promise;
      setPromise(null);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(save, props.debounce);
    return () => {
      clearTimeout(timeout);
    };
  }, [props]);

  return (
    submitting && <div className="submitting">Submitting...</div>
  );
}

export default function (props:any) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <FormSpy {...props} subscription={{ values: true }} component={AutoSave} />;
}
