import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Interfaces, UI, Validators } from '@etnamed/ui-kit';

import isEmpty from 'lodash/isEmpty';

import { Form } from 'react-final-form';

import { useToast } from 'components/ui/Toast';

import * as Icons from 'assets/svgs';

import {
  UpdateInsuredByPkMutation,
  useUpdateInsuredByPkMutation,
  DeleteDmsInsuredByPkMutation,
  useDeleteDmsInsuredByPkMutation,
  useDeleteDmsInsuredFromGroupByPkMutation,
  DeleteDmsInsuredFromGroupByPkMutation,
} from 'generated/graphql';

import { Adapted } from 'components/ui/Сontrols';

import AutoSave from './autoSaveForm';
import {useModal} from "components/ui/Modal";

function Card({ person, refetchInsureds }:any) {
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [personGroups, setPesonGroups] = useState(person.group_insureds);
  const [removingPerson, setRemovingPerson] = useState(false);
  const toast = useToast();
  const modal = useModal();

  const navigate = useNavigate();

  const [updateInsuredByPkMutation] = useUpdateInsuredByPkMutation({
    onCompleted: (updateInsuredByPkData: UpdateInsuredByPkMutation) => {
      const result = updateInsuredByPkData;
      toast.open('Данные сохранены');
    },
    fetchPolicy: 'network-only',
  });

  const [deleteInsuredByPkMutation] = useDeleteDmsInsuredByPkMutation({
    onCompleted: (deleteInsuredByPkData: DeleteDmsInsuredByPkMutation) => {
      const result = deleteInsuredByPkData;
      toast.open('Данные сохранены');
    },
    fetchPolicy: 'network-only',
  });

  const [deleteInsuredFromGroupByPk] = useDeleteDmsInsuredFromGroupByPkMutation({
    onCompleted: async (deleteInsuredByPkData: DeleteDmsInsuredFromGroupByPkMutation) => {
      console.log('deleteInsuredByPkData:', deleteInsuredByPkData);
      const removedLinkPersonGroupId = deleteInsuredByPkData.delete_dms_group_insureds_by_pk?.id;

      if (removedLinkPersonGroupId !== undefined || removedLinkPersonGroupId !== null) {
        setPesonGroups((oldState:any) => oldState.filter(
          (item:any) => item.id !== removedLinkPersonGroupId,
        ));
      }
    },
    fetchPolicy: 'network-only',
  });

  const deletePersonFromAllGropus = () => {
    personGroups.forEach((item:any) => {
      const deletePersonFromGroup = deleteInsuredFromGroupByPk({
        variables: {
          insured_id: item.id,
        },
      });
    });
    return true;
  };


  useEffect(() => {
    if (!removingPerson) {
      return;
    }
    console.log('PERSON GROUPS:', personGroups);
    if (isEmpty(personGroups)) {
      deleteInsuredByPkMutation({
        variables: {
          user_id: person.id,
        },
      }).then(() => {
        setRemovingPerson(false);
        refetchInsureds();
      });
    }
  }, [removingPerson, personGroups]);

  function deletePerson() {
    console.log('PERSON ON DELDETE:', person);
    setRemovingPerson(true);
    deletePersonFromAllGropus();
  }

  const handleShowAdditionalInfo = () => {
    setShowAdditionalInfo((oldState) => !oldState);
  };

  const onSubmit = async (values: any) => {
    console.log('VALUES:', values);
    const resultUpdateInsuredPerson = await updateInsuredByPkMutation({
      variables: {
        ...person,
        user_id: values.id,
        first_name: values.first_name || '',
        last_name: values.last_name || '',
        gender: values.gender || false,
        bithday: values.bithday,
        passport: values.passport,
      },
    });
  };

  const handleOpenEditView = () => {
    navigate(`/insured/${person.id}`);
  };

  const renderForm = ({ handleSubmit }:any) => (
    <form onSubmit={handleSubmit}>
      <AutoSave debounce={600} save={handleSubmit} />
      <div className={`card insuredPerson_${person.id}  select-none`}>
        <div className="flex flex-row justify-between items-center mb-4">
          <div className="flex items-center">
            <Icons.Human />
            <div className="ml-4">
              Застрахованный
            </div>
          </div>
          <button type="button" className="cursor-pointer" onClick={()=>modal.open('Вы хотите удалить?', deletePerson, "Конечно!", "Нет.")}>
            <Icons.X />
          </button>
        </div>
        <div
          role="button"
          tabIndex={0}
          aria-hidden="true"
          className="flex items-center justify-between cursor-pointer"
          onClick={handleShowAdditionalInfo}
        >
          <div className=" text-primary font-medium text-[18px]">
            {person.last_name}
            {' '}
            {person.first_name}
            {' '}
            {person.second_name || ''}
          </div>
          <div className={` mr-1 ${!showAdditionalInfo && 'rotate-180'}`}>
            <Icons.Arrow />
          </div>
        </div>
        {showAdditionalInfo && (
          <>
            <div className="form-control mt-6">
              <div className="grid grid-cols-3 gap-3">
                <div className="relative col-span-2">
                  <Adapted.Input type="date" className="datepicker" name="bithday" />
                  <Icons.DatePicker className="absolute top-[19px] left-2" />
                </div>
                <div className="flex flex-col justify-center">
                  <Adapted.GenderSelector name="gender" />
                </div>
              </div>
            </div>
            <div className="mt-6">
              <UI.Button type="button" onClick={handleOpenEditView}>
                Редактировать
              </UI.Button>
            </div>
          </>
        )}
      </div>
    </form>
  );

  return (
    <Form
      validateOnBlur
      onSubmit={onSubmit}
      render={renderForm}
      initialValues={person}
    />
  );
}

export default Card;
