/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';

import { Form } from 'react-final-form';

import { UI, Validators } from '@etnamed/ui-kit';

import { useStoreon } from 'storeon/react';
import { useToast } from 'components/ui/Toast';
import { FormValuesProps } from 'interfaces';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useDmsGroupByPkQuery,
  useDeleteDmsInsuredByPkMutation,
  DeleteDmsInsuredByPkMutation,
  useDeleteDmsGroupByPkMutation,
  DeleteDmsGroupByPkMutation,
} from 'generated/graphql';

import {
  Adapted,
} from 'components/ui/Сontrols';

import * as Icons from '../assets/svgs';

import AddInsuredToGroupForm from '../components/blocks/groups/AddInsuredToGroupForm';

const validationFields = {
  ...Validators.presenceFieldConstraint('last_name'),
  ...Validators.presenceFieldConstraint('first_name'),
  ...Validators.emailConstraint('email'),
  ...Validators.phoneNumberConstraint('tel'),
};

function ViewGroupPage() {
  const { user } = useStoreon('user');
  const [showMore, setShowMore] = useState<any>(false);
  const [groupData, setGroupData] = useState<any>(null);
  const [isComplete, setisComplete] = useState(false);
  const [showAddInsured, setShowAddInsured] = useState<any>(false);
  const [personToDelete, setPersonToDelete] = useState(-1);
  const [deleteHumanModalActive, setDeleteHumanModalActive] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const dmsGroupByPk = useDmsGroupByPkQuery({
    fetchPolicy: 'network-only',
    onCompleted: (dmsGroupByPkData) => {
      setisComplete(true);
    },
    variables: {
      group_id: params.groupId,
    },
  });
  const [deleteDmsGroupByPkMutation] = useDeleteDmsGroupByPkMutation({
    onCompleted: (deleteDmsGroupByPkData: DeleteDmsGroupByPkMutation) => {
      const result = deleteDmsGroupByPkData;
      toast.open('Данные сохранены');
    },
    fetchPolicy: 'network-only',
  });
  const [deleteInsuredByPkMutation] = useDeleteDmsInsuredByPkMutation({
    onCompleted: (deleteInsuredByPkData: DeleteDmsInsuredByPkMutation) => {
      const result = deleteInsuredByPkData;
      setDeleteHumanModalActive(false);
      toast.open('Данные сохранены');
    },
    fetchPolicy: 'network-only',
  });
  const onSubmit = async (values: any, update: any) => {
    // console.log('====================================');
    // console.log(result?.data?.insert_dms_groups_one?.id);
    // console.log('====================================');
    toast.open('Данные сохранены');
    // navigate('/')
  };
  function deletePerson(id: number) {
    if (deleteHumanModalActive === false) {
      setDeleteHumanModalActive(true);
      document.body.classList.add('overflow-hidden');
      setPersonToDelete(id);
    } else {
      setDeleteHumanModalActive(false);
    }
  }

  function closeModal() {
    document.body.classList.remove('overflow-hidden');
    setDeleteHumanModalActive(false);
  }

  async function deletePersonApproved() {
    const deletePersonResult = await deleteInsuredByPkMutation({
      variables: {
        user_id: personToDelete,
      },
    });

    dmsGroupByPk.refetch();
  }
  const refetchGroups = () => {
    dmsGroupByPk.refetch();
    setShowAddInsured(false);
  };

  async function deleteGroup(id: any) {
    const deleteGroupResult = await deleteDmsGroupByPkMutation({
      variables: {
        group_id: id,
      },
      onCompleted: () => {
        navigate('/');
      },
    });
  }
  useEffect(() => {
    if (isComplete && dmsGroupByPk) {
      setGroupData(dmsGroupByPk?.data?.dms_groups_by_pk);
    }
    // console.log('====================================');
    // console.log(groupData);
    // console.log('====================================');
  }, [user, dmsGroupByPk, isComplete]);

  const formValidate = (values: FormValuesProps) => {
    const res = Validators.default(values.insurant, validationFields, {
      fullMessages: false,
    });
    return { insurant: res };
  };
    // eslint-disable-next-line max-len
  const sklonenie = (number: number, txt: string[], cases = [2, 0, 1, 1, 1, 2]) => txt[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
  if (isComplete && groupData) {
    return (
      <div>
        <div className={`modal delete_modal ${deleteHumanModalActive === true ? '' : 'hidden'} `}>
          <div className="modal_overlay flex flex-col justify-center items-center">
            <div className="modal_content text-center max-w-xs">
              <b className="text-[18px] mb-8 block">
                Вы действительно хотите удалить застрахованного?
              </b>
              <div className="mb-4">
                <UI.Button secondary onClick={() => closeModal()}>
                  Отменить
                </UI.Button>
              </div>
              <div>
                <UI.Button primary onClick={() => deletePersonApproved()}>
                  Удалить
                </UI.Button>
              </div>
            </div>
          </div>
        </div>
        <div className="min-h-screen relative">
          <div className="container mx-auto p-2 mt-0 lg:mt-10">
            <UI.Logo link='/'/>

            <div className="flex flex-row justify-start items-center gap-4 mt-10">
              <Icons.GreyLeftArrow onClick={() => navigate('/')} className=" cursor-pointer" />
              {' '}
              <h1 className=" font-bold text-primary text-2xl">Личный кабинет</h1>
            </div>
            <div className="card mt-10 w-full">
              <h2 className="text-primary text-[18px] font-bold">{groupData.group_name}</h2>
              <div className="flex flex-row items-center justify-start mt-3">
                <span className="text-[15px] mb-2">
                  Застраховано:
                  {' '}
                  {groupData.insureds.length}
                  {' '}
                  {sklonenie(groupData.insureds.length, ['человек', 'человека', 'человек'])}
                </span>
              </div>

              {!showMore && (
              <div>
                {groupData.insureds.map((person: any, insured_index: any) => (

                  <div className="flex flex-row items-center justify-start mt-3" key={insured_index}>
                    <Icons.Human />
                    <span className="text-[15px] ml-2">
                      {person.first_name ?? person.first_name}
                      {' '}
                      {person.second_name ?? person.second_name}
                    </span>
                  </div>
                ))}
                {groupData.insureds.length !== 0 && (
                <UI.Button secondary onClick={() => setShowMore(true)} className="mt-6">
                  Подробнее
                </UI.Button>
                )}
                <UI.Button primary onClick={() => setShowAddInsured(true)} className="my-6">
                  Добавить застрахованного
                </UI.Button>
                {groupData.insureds.length === 0 && (
                <UI.Button
                  secondary
                  onClick={() => deleteGroup(groupData.id)}
                  disabled={groupData.insureds.length !== 0}
                >
                  Удалить группу
                </UI.Button>
                )}
                <AddInsuredToGroupForm group={groupData.id} index={0} refetch={refetchGroups} isOne={showAddInsured} />
              </div>
              )}
            </div>

            {showMore && (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-8">
              {groupData.insureds.map((person: any, insured_index: any) => (
                <Form
                  key={insured_index}
                  validateOnBlur
                  onSubmit={onSubmit}
                  render={({ handleSubmit, pristine, invalid }) => (
                    <form onSubmit={handleSubmit}>
                      <div className={`card insuredPerson_${person.id}`} key={insured_index}>
                        <div className="flex flex-row justify-between items-center">
                          <h3 className=" text-primary font-bold text-[18px]">
                            {person.first_name ?? person.first_name}
                            {' '}
                            {person.second_name ?? person.second_name}
                          </h3>
                          <span className=" cursor-pointer">
                            <Icons.X onClick={() => deletePerson(person.id)} />
                          </span>
                        </div>
                        <div className="form-control mt-6">
                          <div className="grid grid-cols-3 gap-3">
                            <div className="relative col-span-2">
                              <Adapted.Input type="date" className="datepicker" name="bithday" />
                              <Icons.DatePicker className=" absolute top-[19px] left-2" />
                            </div>
                            <div className="flex flex-col justify-center">
                              <Adapted.GenderSelector name="gender" />
                            </div>
                          </div>
                        </div>
                        <div className="mt-6">
                          <UI.Button secondary type="submit">
                            Редактировать
                          </UI.Button>
                        </div>
                      </div>
                    </form>
                  )}
                  initialValues={person}
                />
              ))}
              <UI.Button
                primary
                onClick={() => deleteGroup(groupData.id)}
                disabled={groupData.insureds.length !== 0}
              >
                Удалить группу
              </UI.Button>
            </div>
            )}
          </div>

        </div>
      </div>

    );
  }
  return <></>;
}

export default ViewGroupPage;
