/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Interfaces, UI, Validators } from '@etnamed/ui-kit';

import { Form } from 'react-final-form';

import { useStoreon } from 'storeon/react';

import * as I from 'interfaces';

import { useToast } from 'components/ui/Toast';

import {
  useDmsGroupsQuery,
  useCreateDmsGroupMutation,
  CreateDmsGroupMutation,
  useAddInsuredToGroupMutation,
  useGetInsuredByPkLazyQuery,
  UpdateInsuredByPkMutation,
  useUpdateInsuredByPkMutation,
  AddInsuredToGroupMutation,
} from 'generated/graphql';

import { Adapted } from 'components/ui/Сontrols';

import isEmpty from 'lodash/isEmpty';

const validationFields = {
  ...Validators.presenceFieldConstraint('last_name'),
  ...Validators.presenceFieldConstraint('first_name'),
  ...Validators.presenceFieldConstraint('bday'),
};

function EditInsured() {
  const { user } = useStoreon('user');
  const params = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const [groups, setGroups] = useState<Array<Interfaces.OptionProps> | null>(null);
  const [addedGroupId, setAddedGroupId] = useState(0);
  const [sameAsInsurant, setSameAsInsurant] = useState(false);
  const [formInitValues, setFormInitValues] = useState<any>(null);

  const [getInsuredByPK] = useGetInsuredByPkLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const insuredInfo = data.dms_insureds_by_pk;
      if (insuredInfo === undefined || insuredInfo === null) {
        return;
      }
      const initInsurant: I.LKPerson = {
        id: insuredInfo.id,
        bday: insuredInfo.bithday,
        email: insuredInfo.email,
        first_name: insuredInfo.first_name,
        foreigner: insuredInfo.foreign || false,
        last_name: insuredInfo.last_name,
        passport: insuredInfo.passport,
        tel: insuredInfo.phone,
        additional_name: insuredInfo.second_name || '',
        sex: insuredInfo.gender || false,
        foreign_passport: insuredInfo.foreign_passport || '',
        oms: insuredInfo.oms || '',
        groups: insuredInfo.group_insureds.map(
          (item) => ({ value: item.group?.id, label: item.group?.group_name }),
        )[0] || null,
      };
      setFormInitValues({
        ...initInsurant,
      });
    },
  });

  const [updateInsuredByPkMutation] = useUpdateInsuredByPkMutation({
    onCompleted: (updateInsuredByPkData: UpdateInsuredByPkMutation) => {
      const result = updateInsuredByPkData;
      toast.open('Данные сохранены');
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (params.insuredId) {
      getInsuredByPK({
        variables: {
          insuredId: params.insuredId,
        },
      });
    }
  }, [params, groups]);

  const userGroups = useDmsGroupsQuery({
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const groupsOptions = data.dms_groups.map(
        (item) => ({ value: item.id, label: item.group_name || '' }),
      );
      setGroups(groupsOptions);
    },
  });

  const [createDmsGroupMutation] = useCreateDmsGroupMutation({
    onCompleted: (createDmsGroupData: CreateDmsGroupMutation) => {
      const createdGroupId = createDmsGroupData.insert_dms_groups_one?.id;
      setAddedGroupId(createdGroupId);
      userGroups.refetch();
    },
    fetchPolicy: 'network-only',
  });

  const [addInsuredToGroupMutation] = useAddInsuredToGroupMutation({
    fetchPolicy: 'network-only',
    onCompleted: (addInsuredToGroupData: AddInsuredToGroupMutation) => {
      if (addInsuredToGroupData.insert_dms_group_insureds_one?.insured?.id) {
        navigate('/');
      }
    },
  });

  useEffect(() => {
    if (!sameAsInsurant) {
      return;
    }
    setFormInitValues((oldValue:any) => ({
      ...oldValue,
      bday: user.birthday,
      first_name: user.first_name,
      sex: user.sex,
      last_name: user.last_name,
      passport: user.passport,
      additional_name: user.second_name,
      foreign_passport: user.foreign_passport,
      oms: user.oms,
      groups: null,
    }

    ));
  }, [sameAsInsurant]);

  const handleCreateGroup = (groupName: string) => {
    createDmsGroupMutation({
      variables: {
        group_name: groupName,
        user_id: user.id,
      },
    });
  };

  const handleUpdatePerson = async (values:any) => {

    const resultUpdateInsuredPerson = await updateInsuredByPkMutation({
      variables: {
        user_id: values.id,
        first_name: values.first_name || '',
        last_name: values.last_name || '',
        gender: values.gender || false,
        bithday: values.bday,
        second_name: values.additional_name,
        passport: values.passport,
        foreign: values.foreigner,
        foreign_passport: values.foreign_passport,
        oms: values.oms,
        group_id: values.groups.value,
      },
    });

    if (resultUpdateInsuredPerson.data?.update_dms_insureds_by_pk?.user_id) {
      const resultAddInsuredToGroup = await addInsuredToGroupMutation({
        variables: {
          group_id: values.groups.value,
          insured_id: resultUpdateInsuredPerson.data.update_dms_insureds_by_pk.user_id,
          user_id: user.id,
        },
      });
    }
  };

  const formValidate = (values: any) => {
    const res = Validators.default(values, validationFields, {
      fullMessages: false,
    });
    return res;
  };

  const handleSameAsInsurant = () => {
    setSameAsInsurant((oldState) => !oldState);
  };

  const handleGoBack = () => {
    navigate('/');
  };
  const renderForm = useCallback(({ handleSubmit }:any) => (
    <form onSubmit={handleSubmit}>
      <div className="card mt-10 w-auto lg:w-3/4 block m-1 lg:m-0 lg:mt-10">
        <div className="form">
          <div className="grid  lg:grid-cols-2 gap-6">
            <div className="form-control">
              <div className="flex flex-row justify-between items-center w-3/4 mb-4">
                <span>Совпадает со страхователем</span>
                <UI.Checkbox type="checkbox" onChange={handleSameAsInsurant} />
              </div>

              <div className="flex flex-row justify-between items-center w-3/4 mb-6 hidden">
                <span>Иностранный гражданин</span>
                <Adapted.Checkbox disabled={sameAsInsurant} name="foreigner" />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="form-control">
              <label>Фамилия:</label>
              <Adapted.Input disabled={sameAsInsurant && !isEmpty(user.last_name)} name="last_name" type="text" placeholder="Введите фамилию" />
            </div>
            <div className="form-control">
              <label>Имя:</label>
              <Adapted.Input disabled={sameAsInsurant && !isEmpty(user.first_name)} name="first_name" type="text" placeholder="Введите имя" />
            </div>
            <div className="form-control">
              <label>Отчество:</label>
              <Adapted.Input disabled={sameAsInsurant && !isEmpty(user.second_name)} name="additional_name" type="text" placeholder="Введите отчество" />
            </div>
            <div className="form-control">
              <label>Дата рождения:</label>
              <div className="grid grid-cols-2 gap-3">
                <div className="relative">
                  <Adapted.Input disabled={sameAsInsurant && !isEmpty(user.birthday)} type="date" placeholder="Выберите дату рождения" name="bday" />
                </div>
                <div className="flex flex-col justify-center">
                  <Adapted.GenderSelector disabled={sameAsInsurant && !isEmpty(user.sex)} name="sex" />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-4">
            <div className="form-control">
              <label>Паспортные данные (РФ):</label>
              <Adapted.Input disabled={sameAsInsurant && !isEmpty(user.passport)} name="passport" type="text" placeholder="Введите паспортные данные (РФ)" />
            </div>
            <div className="form-control">
              <label>Паспортные данные (загран РФ):</label>
              <Adapted.Input disabled={false} name="foreign_passport" type="text" placeholder="Введите паспортные данные (Загран)" />
            </div>
            <div className="form-control">
              <label>Номер полиса:</label>
              <Adapted.Input name="oms" type="text" placeholder="Введите номер полиса" />
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-4">
            <div className="form-control">
              <label>Группа:</label>
              <Adapted.CreatableSelect
                name="groups"
                options={groups}
                placeholder="Выберите группу"
                onCreateOption={handleCreateGroup}
                defaultValue={addedGroupId}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full">
        <div className="mt-10 mb-10 lg:w-2/12 mr-8">
          <UI.Button primary type="submit">
            Сохранить
          </UI.Button>
        </div>
        <div className="mt-10 mb-10 lg:w-2/12">
          <UI.Button secondary type="button" onClick={handleGoBack}>
            Назад
          </UI.Button>
        </div>
      </div>

    </form>
  ), [groups, sameAsInsurant]);

  return (
    <div className="min-h-screen relative">
      <div className="container mx-auto p-2 mt-0 lg:mt-10">
        <UI.Logo link='/'/>
        <h1 className="mt-10 font-bold text-primary text-2xl">Редактировать застрахованного</h1>
        <div className="mt-10 tabs relative w-full lg:w-3/4 hidden lg:block">
          <Form
            onSubmit={handleUpdatePerson}
            validate={formValidate}
            validateOnBlur
            render={renderForm}
            initialValues={formInitValues}
          />
        </div>
      </div>
    </div>
  );
}

export default EditInsured;
