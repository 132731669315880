/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, {
  useCallback, useState, useEffect,
} from 'react';

import { UI } from '@etnamed/ui-kit';

import { TabPanel } from 'react-headless-tabs';

import * as Icons from 'assets/svgs';

import { useToast } from 'components/ui/Toast';

import { Form } from 'react-final-form';
import {
  DeleteDmsInsuredByPkMutation,
  useDeleteDmsInsuredByPkMutation,
  UpdateInsuredByPkMutation,
  useUpdateInsuredByPkMutation,
  DeleteDmsGroupByPkMutation,
  useDeleteDmsGroupByPkMutation,
  useCreateDmsGroupMutation,
  CreateDmsGroupMutation,
  useGetUserInsuredsQuery,
  useDmsUserGroupsWithInsuredsQuery,
  useDmsGroupsQuery,
  useDmsGroupInsuredQuery,
} from 'generated/graphql';
import { useStoreon } from 'storeon/react';

import * as I from 'interfaces';
import { Adapted, formPersonInitialValues, formInitialValues } from 'components/ui/Сontrols';
import AddInsuredToGroupList from './AddInsuredToGroupList';

function Groups(props: any) {
  const toast = useToast();
  const { user, dispatch } = useStoreon('user');
  const showToast = () => toast.open('Застрахованный создан');
  const [tabs, setTabs] = useState([
    'group_',
    'group0',
    'group1',
    'group2',
    'group3',
  ]);

  const [currentTab, setCurrentTab] = useState('group_');

  const [selectedGroupTabId, setSelectedGroupTabId] = useState(-1);
  const [personToDelete, setPersonToDelete] = useState(-1);
  const [deleteHumanModalActive, setDeleteHumanModalActive] = useState(false);
  const [isComplete, setisComplete] = useState(false);
  const [showAddGroup, setShowAddGroup] = useState(false);
  const [initGroupName, setInitGroupName] = useState('');
  const [insuredGroups, setInsuredGroups] = useState<Array<any> | null>(null);
  // eslint-disable-next-line max-len
  const sklonenie = (number: number, txt: string[], cases = [2, 0, 1, 1, 1, 2]) => txt[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
  function groupBy(list: any, keyGetter: any) {
    const map = new Map();
    list.forEach((item: any) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  const handleAddInsured = (index: any) => {
    document.getElementById(`addInsuredForm_${index}`)?.classList.toggle('hidden');
  };
  const userInsureds = useGetUserInsuredsQuery({
    fetchPolicy: 'network-only',
  });
  const groupsInsured = useDmsGroupInsuredQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      console.log('----')
      console.log(data)
      console.log('----')
    },
  });

  const userGroups = useDmsGroupsQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {

      setisComplete(true);
      setInsuredGroups(data.dms_groups);
      const groupsTabs = ['group_'];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < data.dms_groups.length; i++) {
        groupsTabs.push(`group${i}`);
        setTabs(groupsTabs);
      }
    },
  });

  const [updateInsuredByPkMutation] = useUpdateInsuredByPkMutation({
    onCompleted: (updateInsuredByPkData: UpdateInsuredByPkMutation) => {
      const result = updateInsuredByPkData;
      toast.open('Данные сохранены');
    },
    fetchPolicy: 'network-only',
  });
  const [deleteInsuredByPkMutation] = useDeleteDmsInsuredByPkMutation({
    onCompleted: (deleteInsuredByPkData: DeleteDmsInsuredByPkMutation) => {
      const result = deleteInsuredByPkData;
      setDeleteHumanModalActive(false);
      toast.open('Данные сохранены');
    },
    fetchPolicy: 'network-only',
  });
  const [deleteDmsGroupByPkMutation] = useDeleteDmsGroupByPkMutation({
    onCompleted: (deleteDmsGroupByPkData: DeleteDmsGroupByPkMutation) => {
      const result = deleteDmsGroupByPkData;
      toast.open('Данные сохранены');
    },
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    userGroups.refetch();
    groupsInsured.refetch();
    userInsureds.refetch();
    if (isComplete && userGroups?.data) {
      // eslint-disable-next-line react/destructuring-assignment
      props.onGroupsReturned(userGroups.data.dms_groups);
    }
    // }
  }, [userGroups, isComplete, selectedGroupTabId, user, userInsureds]);
  const refetchGroups = () => {
    userGroups.refetch();
  };

  function deletePerson(id: number) {
    if (deleteHumanModalActive === false) {
      setDeleteHumanModalActive(true);
      document.body.classList.add('overflow-hidden');
      setPersonToDelete(id);
    } else {
      setDeleteHumanModalActive(false);
    }
  }

  function closeModal() {
    document.body.classList.remove('overflow-hidden');
    setDeleteHumanModalActive(false);
  }

  async function deletePersonApproved() {
    const deletePersonResult = await deleteInsuredByPkMutation({
      variables: {
        user_id: personToDelete,
      },
    });

    userGroups.refetch();
  }

  async function deleteGroup(id: any) {
    const deleteGroupResult = await deleteDmsGroupByPkMutation({
      variables: {
        group_id: id,
      },
      onCompleted: () => {
        userGroups.refetch();
      },
    });

    userGroups.refetch();
  }

  const onSubmit = async (values: any) => {
    const resultUpdateInsuredPerson = await updateInsuredByPkMutation({
      variables: {
        user_id: values.id,
        first_name: values.first_name || '',
        last_name: values.last_name || '',
        gender: values.gender || false,
        bithday: values.bithday,
      },
    });
  };

  function toggleGroupTab(tabName: any) {
    if (currentTab === tabName) {
      setCurrentTab('group_');
      setSelectedGroupTabId(-1);
    } else {
      setCurrentTab(tabName);
      setSelectedGroupTabId(tabName);
    }
  }

  const RenderGroups = useCallback(() => {
    if (isComplete && userGroups.data) {
      return userGroups.data?.dms_groups.map((item, index) => (
        <div key={item.id} className={`insuredGroup_${item.id}`}>
          <div className="card mt-10 w-3/4">
            <div
              className={`
              flex flex-row
              justify-between items-center cursor-pointer ${currentTab === `group${index}` ? 'grouptab-active' : ''
                }
            `}
              onClick={() => toggleGroupTab(`group${index}`)}
            >
              <div>
                <p className=" text-secondary text-[15px] mb-2">
                  Группа
                  {index + 1}

                </p>
                <h2 className="text-primary text-[18px] font-bold">{item.group_name}-{item.id}</h2>
              </div>
              <Icons.Rarrow />
            </div>
            <TabPanel hidden={currentTab !== `group${index}`}>
              <div className="flex flex-row items-center justify-start mt-3">
                <Icons.Human />
                {' '}
                <span className="ml-2 text-[15px]">
                  Застраховано:
                  {' '}
                  {item.group_insureds.length}
                  {' '}
                  {sklonenie(item.group_insureds.length, ['человек', 'человека', 'человек'])}
                </span>
              </div>
              <div className="grid grid-cols-2 mt-8 gap-8 relative">
                <UI.Button
                  primary
                  onClick={() => handleAddInsured(index)}
                >
                  Добавить застрахованного

                </UI.Button>
                <UI.Button
                  secondary
                  onClick={() => deleteGroup(item.id)}
                  disabled={item.group_insureds.length !== 0}
                >
                  Удалить группу
                </UI.Button>
                <div className="add_insured_modal absolute top-full left-0 right-0 w-auto max-w-sm bg-white rounded-xl shadow-1 mt-2 p-2 border-[#f5f5f5] z-20 h-80 overflow-y-scroll hidden" id={`addInsuredForm_${index}`}>
                  <AddInsuredToGroupList
                    group={item.id}
                    index={index}
                    refetch={refetchGroups}
                    insureds={userInsureds.data}
                    groupInsureds={groupsInsured.data?.dms_group_insureds}
                    complete={isComplete}
                  />
                </div>
              </div>
            </TabPanel>
          </div>
          <TabPanel hidden={currentTab !== `group${index}`}>

            <div className="grid grid-cols-3 gap-8 mt-8">
              {item.group_insureds.map((person: any, insured_index: any) => {

                if(person.insured !== null){
                  return (
                      <Form
                          key={insured_index}
                          validateOnBlur
                          onSubmit={onSubmit}
                          render={({ handleSubmit, pristine, invalid }) => (
                              <form onSubmit={handleSubmit}>
                                <div className={`card insuredPerson_${person.insured.id}`} key={insured_index}>
                                  <div className="flex flex-row justify-between items-center">
                                    <h3 className=" text-primary font-bold text-[18px]">
                                      {person.insured.first_name ?? person.insured.first_name}
                                      {' '}
                                      {person.insured.last_name ?? person.insured.last_name}
                                    </h3>
                                    <span className=" cursor-pointer">
                            <Icons.X onClick={() => deletePerson(person.insured.id)} />
                          </span>
                                  </div>
                                  <div className="form-control mt-6">
                                    <div className="grid grid-cols-3 gap-3">
                                      <div className="relative col-span-2">
                                        <Adapted.Input type="date" className="datepicker" name="bithday" />
                                        <Icons.DatePicker className=" absolute top-[19px] left-2" />
                                      </div>
                                      <div className="flex flex-col justify-center">
                                        <Adapted.GenderSelector name="gender" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-6">
                                    <UI.Button secondary type="submit">
                                      Редактировать
                                    </UI.Button>
                                  </div>
                                </div>
                              </form>
                          )}
                          initialValues={person.insured}
                      />
                  )
                }else{
                  return JSON.stringify(person)
                }

              })}
            </div>
          </TabPanel>
        </div>
      ));
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }, [insuredGroups, currentTab]);
  const [createDmsGroupMutation] = useCreateDmsGroupMutation({
    onCompleted: (createDmsGroupData: CreateDmsGroupMutation) => {
      const createdGroupId = createDmsGroupData.insert_dms_groups_one?.id;
      userGroups.refetch();
    },
    fetchPolicy: 'network-only',
  });
  const onSubmitAddForm = async (values: any, form: any) => {
    const result = await createDmsGroupMutation({
      variables: {
        group_name: values.group_name,
        user_id: user.id,
      },
    });

    toast.open('Данные сохранены');
    form.reset();
    setShowAddGroup(false);
  };
  const renderAddGroupForm = ({
    handleSubmit,
    reset,
    values,
    form,
  }: any) => (
    <form onSubmit={handleSubmit}>
      <div className=" mt-10 w-auto block m-1 lg:m-0 p-2">
        <h2 className="font-bold text-primary text-lg mb-4">
          Добавить группу
        </h2>
        <div className="flex flex-row justify-between items-center gap-4">
          <Adapted.Input name="group_name" type="text" placeholder="Введите название группы" className="w-full" />
          <div className="w-auto">
            <UI.Button primary type="submit" className="text-xs px-2">
              Добавить
            </UI.Button>
          </div>

        </div>

      </div>
    </form>
  );
  return (
    <>
      <div className={`modal delete_modal ${deleteHumanModalActive === true ? '' : 'hidden'} `}>
        <div className="modal_overlay flex flex-col justify-center items-center">
          <div className="modal_content text-center max-w-xs">
            <b className="text-[18px] mb-8 block">
              Вы действительно хотите удалить застрахованного?
            </b>
            <div className="mb-4">
              <UI.Button secondary onClick={() => closeModal()}>
                Отменить
              </UI.Button>
            </div>
            <div>
              <UI.Button primary onClick={() => deletePersonApproved()}>
                Удалить
              </UI.Button>
            </div>
          </div>
        </div>
      </div>
      <div className="my-4 w-3/4">
        <UI.Button primary className="w-[200px] text-xs" onClick={() => setShowAddGroup(!showAddGroup)}>Добавить группу</UI.Button>
        {showAddGroup && (
          <div className="card mt-2">
            <Form
              onSubmit={onSubmitAddForm}
              validateOnBlur
              render={renderAddGroupForm}
              initialValues={{ group_name: initGroupName }}
            />
          </div>
        )}
      </div>
      {RenderGroups()}

      <div className="w-full mt-10">&nbsp;</div>
    </>
  );
}

export default Groups;
